import * as React from 'react';
import { Backdrop, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { axiosPrivate } from '../../api/axios';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { useParams } from 'react-router-dom';
import { Form, UseForm } from '../../components/UseForm';
import { useSelector } from 'react-redux';
import Controls from '../../components/controls/Controls';
import { FormAdmin, UseFormAdmin } from '../components/UseFormAdmin';
import { styled } from '@mui/material/styles';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({

    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
      // backgroundColor: 'beige',
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
      // backgroundColor: 'beige',
    },
  }));

export default function EditOrgModalAdmin({ org, open, setOpen, handleClose, }) {

    // useAxiosPrivate()
       
    const [loadingForm, setLoadingForm] = React.useState(false)
    

    const initialValues = {
        name: org?.org?.name,
        // sectorId,
        // locationId,
        street: org?.org?.street,
        area: org?.org?.area,
        pin: org?.org?.pin,
        website: org?.org?.website,
        email: org?.org?.email,
        phone:org?.org?.phone,
        desc: org?.org?.desc,
        isGovt:org?.org?.isGovt,
    } 
        
    const validate = (fieldValues = values) => {
        let temp = { ...errors }
        if ('name' in fieldValues)
            temp.name = fieldValues.name ? "" : "Company name required."
        if ('street' in fieldValues)
            temp.street = fieldValues.street ? "" : "Street required."
        if ('area' in fieldValues)
            temp.area = fieldValues.area ? "" : "Area required."
        if ('pin' in fieldValues) 
            temp.pin = (fieldValues.pin.toString().trim().length === 6) ? "" : "Pin/Zip required."        
       
        setErrors({
            ...temp
            })
            console.log(temp)

        if ((fieldValues = values))
            return Object.values(temp).every(x => x === "")
    }

    // const validateRevert = (fieldValues = values) => {
    //     let temp = { ...errors }
    //     setErrors({
    //         ...temp
    //     })

    //     if ((fieldValues = values))
    //         return Object.values(temp).every(x => x === "")
    // }

    const {
        values,
        setValues,
        files,
        setFiles,
        errors,
        setErrors,
        optionsData,
        setOptionsData,        
        loading,
        setLoading,
        handleInputChange,        
    } = UseFormAdmin(initialValues, true, validate);

    
    const orgInfo = {
        name: values.name,
        // sectorId: values.sectorId ? values.sectorId?.id : null,
        // locationId: values.locationId ? values.locationId?.id : null,
        // sector: JSON.stringify(sector),
        // headquarters: JSON.stringify(address),
        street: values.street,
        area: values.area,
        pin: values.pin,
        website: values.website,
        email: values.email,
        phone: values.phone,
        desc: values.desc,
        isGovt: values.isGovt,
    }
    console.log(values)

    const handleSubmit = async (e) => {
        e.preventDefault()        
        if (validate()) {
            // setSubmitted(true)
            setLoadingForm(true)
            const formData = new FormData()
            for (let key in orgInfo) {
                formData.append(key, orgInfo[key])
            } 
                     
            try {
                const res = await axiosPrivate.put(`/api/org/${org.org._id}/update_org`, formData);
                if (!res) return
                if (res.status === 201) {
                    //   setSubmitted(true)
                    // resetForm()
                    const data = res.data.data
                }
                setLoadingForm(false)
                setOpen(false)
                console.log(res)

            } catch (err) {
                console.error(err);
                setLoadingForm(false)
                // setSubmitted(false)
            }
        }
    }

    // console.log(loadingForm)

    // React.useEffect(() => {
       
    // }, [])   

    return (
        <BootstrapDialog
            fullScreen
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
        >
            <DialogTitle id="responsive-dialog-title" sx={{ bgcolor: 'background.main', display: 'flex', position: "fixed", width: "100%", px: '10px', height: '25px', zIndex: 1600, }}>
                <Typography variant='subtitle2' >EDIT - {org?.org?.name}</Typography>

                <Box sx={{ flexGrow: 1 }} />
                <IconButton onClick={handleClose} sx={{ mr: '10px' }} >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>

            <DialogContent sx={{ mt: '52px', }} >

                <FormAdmin id="org-update-form-update" >

                    <Grid container spacing={0}
                        sx={{
                            display: 'flex',
                            flexDirection: { xs: 'column', md: 'row' },
                            // alignItems: 'center',
                            justifyContent: 'center',
                            m: ' 10px auto',                            
                            py: '20px',
                            bgcolor: 'background.paper',
                            overflow: 'hidden',
                            borderRadius: '12px',
                            boxShadow: 2,
                            // height: '100 vh',
                            maxWidth: { xs: 320, md: 820 },
                        }} >
                        <Grid item gap={2} md={5} sx={{
                            display: 'flex', flexDirection: 'column',
                            // alignItems: 'center',
                            // justifyContent: 'center',
                            // '& .MuiTextField-root': { mx: 2, my: 1, width: '90%' },
                            '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                                display: 'none'
                            },
                            // width: '90%',
                            mx: '10px'
                        }} >

                            <Controls.Input
                                name="name"
                                label="Institution/Company Name"
                                required={true}
                                placeholder="eg. Company Name Pvt Ltd"
                                value={values.name}
                                onChange={handleInputChange}
                                error={errors.name}
                            />

                            {/* <Controls.AutoComplete
                                name="sectorId"
                                label="Sector"
                                //   size='small'
                                // listFontSize='14px'
                                // labelFontSize='12px'
                                // inputFontSize='12px'
                                optionsData={optionsData}
                                setOptionsData={setOptionsData}
                                values={values}
                                setValues={setValues}
                                handleFetch={handleFetch}
                                loading={loading}
                                required={true}
                                disableClearable={true}
                            /> */}

                            {/* <Controls.Input
                                name="sectorId"
                                label="Sector"
                                // required={true}
                                value={sectorName}
                                disabled={true}                             
                            /> */}

                            {/* <Controls.Input
                                name="locationId"
                                label="Headquarters City"
                                // required={true}
                                value={city}
                                disabled={true}                                
                            /> */}

                            <Controls.Input
                                name="street"
                                label="Street"
                                required={true}
                                value={values.street}
                                onChange={handleInputChange}
                                error={errors.street}
                                placeholder="eg. 2nd Cross, Raj Street"
                            />
                            <Controls.Input
                                name="area"
                                label="Locality/Town"
                                required={true}
                                value={values.area}
                                onChange={handleInputChange}
                                error={errors.area}
                                placeholder="eg. Edapally"
                            />
                            <Controls.Input
                                name="pin"
                                label="Pin/Zip"
                                required={true}
                                value={values.pin}
                                onChange={handleInputChange}
                                error={errors.pin}
                                type="number"
                            />
                        </Grid>

                        <Grid item gap={2} md={5} sx={{
                            display: 'flex', flexDirection: 'column',
                            // '& .MuiTextField-root': { m: 1, width: '90%' },
                            '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                                display: 'none'
                            },
                            mx: '10px'
                        }} >

                            <Controls.Input
                                name="website"
                                label="Website"
                                value={values.website}
                                onChange={handleInputChange}
                            // error={errors.website}
                            />
                            <Controls.Input
                                name="email"
                                label="Email"
                                value={values.email}
                                onChange={handleInputChange}
                                type="email"
                            // error={errors.email}
                            />
                            <Controls.Input
                                name="phone"
                                label="Phone"
                                type="number"
                                value={values.phone}
                                onChange={handleInputChange}
                            // error={errors.phone}
                            />
                            <Controls.TextareaAutosizeBox
                                name="desc"
                                label="Description"
                                placeholder="Enter Description"
                                minRows={4}
                                maxRows={7}
                                value={values.desc}
                                // error={errors.desc}
                                onChange={handleInputChange}
                            />

                            <Box sx={{ pl: '17px' }}>
                                <Controls.Chkbox
                                    name="isGovt"
                                    label="Government Owned"
                                    value={values.isGovt}
                                    onChange={handleInputChange}
                                />
                            </Box>
                        </Grid>
                    </Grid>   
                    {loadingForm && (
                        <Backdrop
                            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                            open={loadingForm}
                        // onClick={handleClose}
                        >
                            <CircularProgress
                                size={24}
                                sx={{
                                    color: 'inherit',
                                    // position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    marginTop: '-12px',
                                    marginLeft: '-12px',
                                }}
                            />
                        </Backdrop>
                    )}                 
                </FormAdmin>

            </DialogContent>
            <DialogActions sx={{ display: 'flex' }}>

                <Button variant="outlined" onClick={handleClose} color='warning' sx={{ m: '5px', textTransform: 'none', fontSize: '11px' }} >  Close
                </Button>
                <Button onClick={handleSubmit} color="warning" variant="outlined" form="org-update-form-update" sx={{ m: '5px', textTransform: 'none', fontSize: '11px' }} >Update
                </Button>
            </DialogActions>
            
        </BootstrapDialog>

    );
}
