import { Navigate } from "react-router-dom";
import axios, { axiosPrivate } from "../../api/axios";

const API_URL = '/api/org/exam/';

// Login user
const fetchExam = async (examId) => {
    console.log(examId)
  const res = await axiosPrivate.get(API_URL + `${examId}`); 
  
  if (res.data) {    
    localStorage.setItem('exam', JSON.stringify(res.data.data));    
  }
  return res.data.data
}
const examService = {  
    fetchExam,
}

export default examService