import { useNavigate } from "react-router-dom";
import { axiosPrivate } from "../api/axios";
import useRefreshToken from "./useRefreshToken";
import { useDispatch } from 'react-redux'
import { logout } from '../features/userAuth/authSlice';
import { logout as adminLogout } from '../features/admin/adminSlice';

const useAxiosPrivate = () => {
    const refresh = useRefreshToken();
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const user = JSON.parse(localStorage.getItem('user'))
    const admin = JSON.parse(localStorage.getItem('admin'))

    function delay(time) {
        return new Promise(function(resolve) {
            setTimeout(resolve, time)
        })
       }

    const requestIntercept = axiosPrivate.interceptors.request.use(
        config => {
            // if (!config.headers['Authorization']) {
            //     config.headers['Authorization'] = `Bearer ${auth?.accessToken}`;
            // } 
            console.log(1)
            return config;
        }, (error) => Promise.reject(error)
    );

    let isRefreshing = false

    const responseIntercept = axiosPrivate.interceptors.response.use(
        response => { return response },
        async (error) => {
            console.log(2)
            const prevRequest = error?.config;
            if (error?.response?.status === 403 && !prevRequest?.sent) {
                if (!isRefreshing) {
                    prevRequest.sent = true;
                    isRefreshing = true
                    console.log(3)
                    await refresh();
                    isRefreshing = false                    
                    console.log(4)
                    return axiosPrivate(prevRequest);
                } else {
                    prevRequest.sent = true;   
                    await delay(800)                
                    console.log(5)
                    return axiosPrivate(prevRequest);
                }

            }
            if ((error?.response?.status === 405 || error?.response?.status === 400) && !prevRequest?.sent) {
                if (user) {                    
                    dispatch(logout())
                    navigate('/login')
                    console.log(6)
                    return
                }
                if (admin) {                    
                    dispatch(adminLogout())
                    navigate('/admin/login')
                    console.log(7)
                    return
                }

            } return Promise.reject(error);
        }
    );

    return () => {
        axiosPrivate.interceptors.request.eject(requestIntercept);
        axiosPrivate.interceptors.response.eject(responseIntercept);
    }

}

export default useAxiosPrivate;