import * as React from 'react';
// import React from 'react'
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';

import { axiosPrivate } from '../../api/axios';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import styled from 'styled-components';
import { Box, Typography } from '@mui/material';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import MemberRequestModal from '../../admin/modals/MemberRequestModal';
import { UseFormAdmin } from '../../admin/components/UseFormAdmin';
import MemberReqModal from '../modals/MemberReqModal';
import { DataGrid, GridToolbarQuickFilter, GridLinkOperator, gridClasses } from '@mui/x-data-grid';

const NoData = styled.div`
display: flex;
max-height: 450px;
align-items: center;
justify-content: center;
font-size: 24px;
padding-top: 40px;
// color: darkslategray;
`

function QuickSearchToolbar() {
  return (
    <Box
      sx={{
        p: 0.5,
        pb: 0,
      }}
    >
      <GridToolbarQuickFilter
        quickFilterParser={(searchInput) =>
          searchInput
            .split(',')
            .map((value) => value.trim())
            .filter((value) => value !== '')
        }
      />
    </Box>
  );
}

const columns = [

  {
    id: 'firstName',
    label: 'First Name',
    minWidth: 170,
  },
  {
    id: 'lastName',
    label: 'Last Name',
    minWidth: 170,
  },
  {
    id: 'designationName',
    label: 'Designation',
    minWidth: 170,
  },

  {
    id: 'createdAt',
    label: 'Date & Time',
    minWidth: 170,
    // dateSetting: { locale: "en-IN"}
    // align: 'right',
    // format: (value) => value.toFixed(2),
    // renderOption: (params) =>
    //     moment(params.row.createdAt).format('YYYY-MM-DD HH:MM:SS'),

  },



  //   {
  //     id: 'district',
  //     label: 'Home Town',
  //     minWidth: 170,
  //   },
  // { id: '_id', label: 'ID', align: 'right', minWidth: 100 },
];

export default function TableOrgMemberRequest() {

  useAxiosPrivate()
  const { orgId } = useParams()

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [user, setUser] = React.useState([])
  const [member, setMember] = React.useState({})
  const [selectedRow, setSelectedRow] = React.useState({})
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false)
  const [openModal, setOpenModal] = React.useState(false);
  const [openRevertModal, setOpenRevertModal] = React.useState(false);
  const [openRefuteModal, setOpenRefuteModal] = React.useState(false);
  // const [errors, setErrors] = React.useState({});
  const [pageSize, setPageSize] = React.useState(10);
  const [rowId, setRowId] = React.useState(null);

  const initialValues = {
    firstName: Object.keys(selectedRow).length === 0 ? "" : selectedRow.firstName,
    lastName: Object.keys(selectedRow).length === 0 ? "" : selectedRow.lastName,
    gender: Object.keys(selectedRow).length === 0 ? "" : selectedRow.gender,
    dateOfBirth: Object.keys(selectedRow).length === 0 ? "" : new Date(selectedRow.dateOfBirth).getTime(),
    certs: Object.keys(selectedRow).length === 0 ? "" : selectedRow.certs,
    institution: Object.keys(selectedRow).length === 0 ? "" : selectedRow.orgId,
    designation: Object.keys(selectedRow).length === 0 ? "" : selectedRow.designationId,
    course: Object.keys(selectedRow).length === 0 ? "" : selectedRow.courseId,
    cgpa: "",
    joinedDate: Object.keys(selectedRow).length === 0 ? "" : new Date(selectedRow.joinedDate).getTime(),
    leftDate: Object.keys(selectedRow).length === 0 ? "" : selectedRow.submittedLeftDate ? new Date(selectedRow.submittedLeftDate).getTime() : new Date(selectedRow.leftDate).getTime(),
    level: Object.keys(selectedRow).length === 0 ? "" : selectedRow.submittedLevel ? selectedRow.submittedLevel : selectedRow.level,
    // infoMessages: Object.keys(selectedRow).length === 0 ? "" : selectedRow.infoMessages.length === 0 ? "First Submission" : selectedRow.infoMessages,
    // infoMessage: "",
    // sender: Object.keys(selectedRow).length === 0 ? "" : selectedRow.sender,
  }

  const validate = (fieldValues = values) => {
    let temp = { ...errors }
    if ('firstName' in fieldValues)
      temp.firstName = fieldValues.firstName ? "" : "First name without space is required."
    if ('lastName' in fieldValues)
      temp.lastName = fieldValues.lastName ? "" : "Last name with initial is required."
    if ('dateOfBirth' in fieldValues)
      temp.dateOfBirth = fieldValues.dateOfBirth ? "" : "Date of Birth is required."
    // if ('aadhar' in fieldValues)
    //   temp.aadhar = fieldValues.aadhar ? "" : "Valid Aadhar Number is required."
    if ('cgpa' in fieldValues)
      temp.cgpa = fieldValues.cgpa ? "" : "CGPA is required."
    if ('gender' in fieldValues)
      temp.gender = fieldValues.gender ? "" : "Gender is required."
    if ('joinedDate' in fieldValues)
      temp.joinedDate = fieldValues.joinedDate ? "" : "Joined Date is required."
    if ('leftDate' in fieldValues)
      temp.leftDate = fieldValues.leftDate ? "" : "Left Date is required."

    setErrors({
      ...temp
    })
    // console.log(temp)

    if ((fieldValues = values))
      return Object.values(temp).every(x => x === "")
  }

  const validateRevert = (fieldValues = values) => {

    let temp = { ...errors }
    // if ('infoMessage' in fieldValues)
    //   temp.infoMessage = fieldValues.infoMessage ? "" : "Revert Message Required."

    setErrors({
      ...temp
    })

    if ((fieldValues = values))
      return Object.values(temp).every(x => x === "")
  }

  const {
    values,
    setValues,
    files,
    setFiles,
    optionsData,
    setOptionsData,
    errors,
    setErrors,
    handleInputChange,
  } = UseFormAdmin(initialValues, true, validate, validateRevert, selectedRow);

  // let flattendObj = [];
  //       const flattenObject = (data, keyName) => {
  //         Object.keys(data).forEach(key => {
  //           var newKey = `${keyName}_${key}`
  //           if (typeof data[key] === "object") {
  //             // calling the function again
  //             flattenObject(data[key], newKey);
  //           } else {
  //             flattendObj[newKey] = data[key];
  //           }
  //         });
  //       };

  let flattenedData = [];
  const flatData = (memberData) => {
    memberData.forEach((item) => {
      // console.log(item)          
      item.firstName = item.sender.firstName
      item.lastName = item.sender.lastName
      item.senderId = item.sender._id
      item.dateOfBirth = item.sender.dateOfBirth
      item.gender = item.sender.gender
      delete item.sender

      item.orgName = item.receiver.name
      item.orgId = item.receiver._id
      item.createdAt = moment(item.createdAt).format("DD-MM-YYYY / HH:MM")
      delete item.receiver

      if (item.designation) {
        item.designationName = item?.designation.name
        item.designationId = item?.designation._id
        delete item?.designation
      }
      if (item.course) {
        item.courseName = item?.course.name
        item.courseId = item?.course._id
        delete item?.course
      }
      // if (item.course) {
      //   item.designationName = "Student"      
      //   delete item?.course
      // }
      flattenedData.push(item)
    })
  }

  const columns = React.useMemo(
    () => [
      {
        field: 'firstName',
        headerName: 'First Name',
        width: 170,
      },
      {
        field: 'lastName',
        headerName: 'Last Name',
        width: 170,
      },
      {
        field: 'designationName',
        headerName: 'Designation',
        width: 200,
      },
      {
        field: 'createdAt',
        headerName: 'Date & Time',
        width: 170,
      },

      { field: '_id', hide: true },

    ],
    [rowId]
  );

  const handleFetchAllRequests = async () => {
    try {
      setLoading(true)
      const res = await axiosPrivate.get(`/api/org/member_request/${orgId}/fetch_all_request`)
      if (res) {
        setLoading(false)
        const memberData = res.data.data
        // setRawUser(memberData)
        // console.log(memberData)
        flatData(memberData)
        setUser(flattenedData)
      }
    } catch (err) {
      console.error(err);
    }
  }
  console.log(values)

  // const handleFetch = async () => {
  //   try {
  //     setLoading(true)
  //     const res = await axiosPrivate.get('/api/user/member_request/fetch')
  //     if (res) {
  //       setLoading(false)       
  //       const memberData = res.data.data 
  //       // setRawUser(memberData)
  //       // console.log(memberData)
  //       flatData(memberData)
  //       setMember(flattenedData)               
  //     }
  //   } catch (err) {
  //     console.error(err);
  //   }
  // }

  React.useEffect(() => {
    handleFetchAllRequests()
  }, [])

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleNestedClose = () => {
    setOpenModal(false);
    setOpenRevertModal(false);
    setOpenRefuteModal(false);

  };

  return (
    <Box sx={{ width: '75vw', height: 475, }}>

      {
        loading ? <Typography>Loading...</Typography> : user.length !== 0 ?
          <>
            <DataGrid
              // {...user}
              columns={columns}
              getRowId={(row) => row._id}
              // getRowId={(row) => row.id }
              rowsPerPageOptions={[10, 25, 50, 100]}
              // rows={
              //   isAdmin(currentUser)
              //     ? rooms
              //     : rooms.filter((room) => room.uid === currentUser.id)
              // }
              rows={user}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              onSelectionModelChange={(id) => {
                const selectedId = new Set(id);
                const selectedRowData = user.filter((row) => selectedId.has(row._id.toString()));
                // const selectedOrgId = selectedRowData[0]._id
                setSelectedRow(selectedRowData[0])
                console.log(selectedRowData)
                // handleFetchOrgById(selectedOrgId)
                setOpen(true)
                setOpenModal(false)
                setOpenRevertModal(false)
                setOpenRefuteModal(false)
              }}

              sx={{ '& .MuiDataGrid-columnHeaders': { bgcolor: 'background.paper' }, '& .MuiDataGrid-footerContainer': { bgcolor: 'background.paper' } }}
              initialState={{
                // ...user.initialState,
                // pagination,
                filter: {
                  filterModel: {
                    items: [],
                    quickFilterLogicOperator: GridLinkOperator.Or,
                  },
                },
              }}
              components={{ Toolbar: QuickSearchToolbar }}
            />


            {/* <TableContainer sx={{ maxHeight: 475, }}>
            <Table stickyHeader aria-label="sticky table"
              
            >
              <TableHead sx={{ '& .MuiTableCell-head': { bgcolor: 'background.paper' } }} >
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}  
                      // option={column.renderOption}                 
                    >
                      {column.label}                      
                    </TableCell>
                  ))}
                </TableRow>

              </TableHead>
              <TableBody>
                {user
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    return (
                      <TableRow onClick={() => {

                        return (
                          <>
                            {setSelectedRow(row)}
                            {setOpen(true)}                           
                            {setOpenModal(false)}
                            {setOpenRevertModal(false)}
                            {setOpenRefuteModal(false)}
                            {setErrors({})}
                          </>
                        )
                      }
                      }
                        hover role="checkbox" tabIndex={-1} key={row._id} sx={{ cursor: 'pointer' }}>
                        {columns.map((column) => {
                          const value = row[column.id];
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {column.format && typeof value === 'number'
                                ? column.format(value)
                                : value}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={user.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          /> */}
            {open &&
              <MemberReqModal
                handleFetch={handleFetchAllRequests}
                orgId={orgId}
                open={open}
                setOpen={setOpen}
                handleClose={handleClose}
                selectedRow={selectedRow}
                loading={loading}
                setLoading={setLoading}
                openModal={openModal}
                setOpenModal={setOpenModal}
                openRevertModal={openRevertModal}
                setOpenRevertModal={setOpenRevertModal}
                openRefuteModal={openRefuteModal}
                setOpenRefuteModal={setOpenRefuteModal}
                handleNestedClose={handleNestedClose}
                errors={errors}
                setErrors={setErrors}
                values={values}
                setValues={setValues}
                files={files}
                setFiles={setFiles}
                optionsData={optionsData}
                setOptionsData={setOptionsData}
                validate={validate}
                validateRevert={validateRevert}
                handleInputChange={handleInputChange}
              />
            }
          </> :
          <Typography>No Data Remaining!!!</Typography>
      }

    </Box>
  );
}
