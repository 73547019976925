import * as React from 'react';
// import React from 'react'
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
// import { useEffect } from 'react';
import { axiosPrivate } from '../../api/axios';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import styled from 'styled-components';
import { DataGrid, GridToolbarQuickFilter, GridLinkOperator, gridClasses } from '@mui/x-data-grid';
import { Typography, Box, Button, IconButton } from '@mui/material';
import moment from 'moment';
import { grey } from '@mui/material/colors';
import { Edit } from '@mui/icons-material';
import CourseSelectModal from '../modals/CourseSelectModal';

const NoData = styled.div`
display: flex;
max-height: 450px;
align-items: center;
justify-content: center;
font-size: 24px;
padding-top: 40px;
// color: darkslategray;
`
function QuickSearchToolbar() {
  return (
    <Box
      sx={{
        p: 0.5,
        pb: 0,
      }}
    >
      <GridToolbarQuickFilter
        quickFilterParser={(searchInput) =>
          searchInput
            .split(',')
            .map((value) => value.trim())
            .filter((value) => value !== '')
        }
      />
    </Box>
  );
}

export default function TableCourseList() {

  useAxiosPrivate()
  
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10); 
  const [selectedRow, setSelectedRow] = React.useState(null)
  const [open, setOpen] = React.useState(false);
  const [institution, setInstitution] = React.useState([])
  const [course, setCourse] = React.useState([])
  const [selectInstitution, setSelectInstitution] = React.useState("");
  const [selectCourse, setSelectCourse] = React.useState("");
  const [loading, setLoading] = React.useState(false)  
  const [openModal, setOpenModal] = React.useState(false);
  const [openRevertModal, setOpenRevertModal] = React.useState(false);
  const [openRefuteModal, setOpenRefuteModal] = React.useState(false);
  const [errors, setErrors] = React.useState({});
  const [pageSize, setPageSize] = React.useState(10);
  const [rowId, setRowId] = React.useState(null);
  const [rowData, setRowData] = React.useState({})
  
  const handleFetch = async () => {
    try {
      setLoading(true)
      const res = await axiosPrivate.get('/api/org/admin/courses/fetch_all')
      if (res) {
        setLoading(false)
        const data = res.data.data
        setCourse(data)
      }
    } catch (err) {
      console.error(err);
    }
  }
  console.log(open)  
 
  React.useEffect(() => {    
    handleFetch()   
  }, [])


  const columns = React.useMemo(
    () => [
     
      {
        field: 'name',
        headerName: 'Name',
        width: 200,        
      },
      {
        field: 'type',
        headerName: 'Type',
        width: 70,        
      },
      {
        field: 'duration',
        headerName: 'Duration',
        width: 70,        
      },      
      
      {
        field: 'createdAt',
        headerName: 'Created At',
        width: 200,
        renderCell: (params) =>
          moment(params.row.createdAt).format('YYYY-MM-DD HH:MM:SS'),
      },
      { field: '_id', hide: true },       
     
    ],
    [rowId]
  );

  const handleClose = () => {
    setOpen(false);
  };
 
  return (
    <Box sx={{ width: '100%', height: 475, }}>
    {
      loading ? <Typography>Loading...</Typography> : course.length !== 0 ?
      <>
      <Typography
    variant="h5"
    component="h5"
    sx={{ textAlign: 'center', my: 1 }}
  >
    Manage Courses
  </Typography>
  <DataGrid
  // {...user}
  
    columns={columns}
    getRowId={(row) => row._id }
    // getRowId={(row) => row.id }
    rowsPerPageOptions={[10, 25, 50, 100]}
    loading={loading}
    // rows={
    //   isAdmin(currentUser)
    //     ? rooms
    //     : rooms.filter((room) => room.uid === currentUser.id)
    // }
    rows={course}        
    pageSize={pageSize}
    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
    onSelectionModelChange={(id) => {
      const selectedId = new Set(id);
      const selectedRowData = course.filter((row) => selectedId.has(row._id.toString()));
      // const selectedOrgId = selectedRowData[0]._id
      setSelectedRow(selectedRowData)
      // console.log(selectedOrgId)
      // handleFetchOrgById(selectedOrgId)
      setOpen(true)
    } }
    
    sx={{ '& .MuiDataGrid-columnHeaders': { bgcolor: 'background.paper' }, '& .MuiDataGrid-footerContainer': { bgcolor: 'background.paper' } }}
    initialState={{
      // ...user.initialState,
      // pagination,
      filter: {
        filterModel: {
          items: [],
          quickFilterLogicOperator: GridLinkOperator.Or,
        },
      },          
    }}
    components={{ Toolbar: QuickSearchToolbar }}
  />
  { selectedRow && <CourseSelectModal      
      open={open} 
      setOpen={setOpen} 
      handleClose={handleClose} 
      selectedRow={selectedRow[0]}         
      /> }
    </> : 
    <Typography>No Data Remaining!!!</Typography>
    } 
    </Box>
    
  );
}
