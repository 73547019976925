import React, { useRef, useState } from 'react'
import { Avatar, Card, Grid, Typography, Box, InputLabel, CircularProgress, Button, Snackbar } from '@mui/material'
import ManRoundedIcon from '@mui/icons-material/ManRounded';
import WorkRoundedIcon from '@mui/icons-material/WorkRounded';
import SchoolRoundedIcon from '@mui/icons-material/SchoolRounded';
import BusinessRoundedIcon from '@mui/icons-material/BusinessRounded';
import AddAPhotoOutlinedIcon from '@mui/icons-material/AddAPhotoOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import IconButton from '@mui/material/IconButton';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import UploadPicModal from '../modals/UploadPicModal';
import axios, { axiosPrivate } from '../../api/axios';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import styled from 'styled-components';
import { reset, updatePic } from '../../features/userAuth/authSlice';
import { fetchOrg } from '../../features/org/orgSlice';
import UploadPhotoModal from '../modals/UploadPhotoModal';
// import UploadPhotoModal from '../modals/UploadPhotoModal';

const ProfileImg = styled.img`
  border-radius: 6px;
  margin: 20px;
  width: 200px;
  height: 256px;
  object-fit: cover;  
`;

const ProfileSidebarSelf = () => {
  // useAxiosPrivate();
  const dispatch = useDispatch();
  // const { orgId } = useParams()

  const {userId, orgId, profile_pic, firstName, lastName, designation, org, course, isVerified } = useSelector(
    (state) => state.auth    
  )
  const DEFAULT_MAX_FILE_SIZE_IN_BYTES = process.env.REACT_APP_DEFAULT_MAX_FILE_SIZE_IN_BYTES;

  const [fileName, setFileName] = useState('');
  const [file, setFile] = useState('');
  const [image, setImage] = useState('');
  const [open, setOpen] = useState(false);
  const [previewSource, setPreviewSource] = useState('');
  const [loading, setLoading] = useState(false) 
  // const [file, setFile] = useState('');
  const [crop, setCrop] = useState();
  const [error, setError] = useState("");  
  
  // const handleClickAddPhoto = (e) => {
  //   e.preventDefault()
  //   const fileData = e.target.files[0];
  //   setFile(fileData)
  //   setFileName(e.target.files[0].name)
  //   setOpen(true)
  //   previewFile(fileData);
  // }

// const previewFile = (fileData) => {
//   const reader = new FileReader();
//   console.log(reader)
//   reader.readAsDataURL(fileData);
//   reader.onloadend = () => {
//     setPreviewSource(reader.result);
//   };
// };

const ASPECT_RATIO = process.env.REACT_APP_ASPECT_RATIO_PHOTO;
const MIN_DIMENSION = process.env.REACT_APP_MIN_DIMENSION;

const handleClickAddPhoto = (e) => {  
  const fileData = e.target.files[0];
  setOpen(true)
  if (fileData.size > DEFAULT_MAX_FILE_SIZE_IN_BYTES) return setError("Image must be less than 1mb.");
  console.log(fileData.size)
  setFileName(e.target.files[0].name)
  if (!fileData) return;
    setFile(fileData) //Turn off if cropping is used
   
   
  const reader = new FileReader();
  reader.addEventListener("load", () => {
    const imageElement = new Image();
    const imageUrl = reader.result?.toString() || "";
    // console.log(imageUrl)
    // setFile(imageUrl)
    imageElement.src = imageUrl;

    imageElement.addEventListener("load", (e) => {
      if (error) setError("");
      // console.log(e.target)
      const { naturalWidth, naturalHeight } = e.currentTarget;
      if (naturalWidth < MIN_DIMENSION || naturalHeight < MIN_DIMENSION) {
        setError("Image must be at least 150 x 150 pixels.");
        return setImage("");
      }
    });
    setImage(imageUrl);
  });
  reader.readAsDataURL(fileData);
};

console.log(file)


  const handleClickSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)
    console.log('submitted');
    setOpen(false)
      
    const formData = new FormData()
   
      formData.append('file', file)      
      console.log(formData.get("file"))
  
      try {
        const res = await axiosPrivate.put('/api/user/profile_pic/update', formData)
        dispatch(updatePic(res.data.data))
        const profileUpdate = JSON.parse(localStorage.getItem('user'))
        profileUpdate.profile_pic = res.data.data
        localStorage.setItem('user', JSON.stringify(profileUpdate))
        setLoading(false)
  
      } catch (err) {
        console.error(err);
      }    
    // setFile('');
  

  }

  const handleClose = (e) => {
    e.preventDefault()
    console.log('cancelled');
    setOpen(false)
  }

  const handleClickDeletePhoto = async (e) => {
    e.preventDefault()
    setLoading(true)
    const filename = profile_pic.split('/').pop()   
    console.log(filename); 
    try {      
      const res = await axiosPrivate.put(`/api/user/profile_pic/${filename}/delete`)
      console.log(res)
      dispatch(updatePic(""))
      const profileUpdate = JSON.parse(localStorage.getItem('user'))
      profileUpdate.profile_pic = ""
      localStorage.setItem('user', JSON.stringify(profileUpdate))
      setLoading(false)
    } catch (err) {
      console.error(err);
    }   
  };

  // const handleClickOrg = () => {        
  //   dispatch(fetchOrg(orgId))    
  // };

  const handleClickAdd = (e) => {
    e.preventDefault()
    console.log('add');
    
  }
  // const urlQuery = new Date().getTime()
  // const profile_pic_updated = profile_pic + `?${urlQuery}`

  // console.log(profile_pic_updated)
// console.log(profile_pic)
// console.log(loading)
  return (
    <Card sx={{ my: '10px' }} >      
      <Grid container spacing={0.5} sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row', md: 'column' }, alignItems: 'center' }} >
        <Grid item sx={{ position: 'relative' }} >
          { profile_pic ? <ProfileImg src={profile_pic} alt='P' /> : <Avatar variant="rounded" sx={{ m: '20px', width: '200px', height: '256px',}} />}
          {loading &&  <CircularProgress
                    size={30}
                    sx={{
                      color: 'white',                      
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      marginTop: '-12px',
                      marginLeft: '-12px',                      
                    }}
                  />
                  }
          <Grid item sx={{ display: 'flex', position: 'absolute' }} >          
            <InputLabel htmlFor="file" sx={{ my: '-50px' }} >
              {
                profile_pic ? <AddAPhotoOutlinedIcon disabled fontSize="1.8 rem" sx={{ mx: '30px', zIndex: 1000, color: 'gray' }} />
                :
                <>
                <AddAPhotoOutlinedIcon fontSize="1.8 rem" sx={{ mx: '30px', zIndex: 1000, cursor: 'pointer', color: 'white' }} />
            <input type="file" id="file" accept='.jpg, .jpeg, .png' onChange={handleClickAddPhoto} style={{ display: 'none' }} />
                </>
              }
              
            </InputLabel>
            {
              profile_pic ? <DeleteOutlinedIcon fontSize="1.8 rem" onClick={handleClickDeletePhoto} sx={{ my: '-50px', mx: '120px', zIndex: 1000, cursor: 'pointer', color: 'white' }} />
              :
              <DeleteOutlinedIcon fontSize="1.8 rem" sx={{ my: '-50px', mx: '120px', zIndex: 1000, color: 'darkGray' }} />
            }
          </Grid>
        </Grid>

        <Grid item sx={{ display: 'block', mb:'10px' }} >
          <Grid item >
            <Typography component="div" sx={{ fontSize: '15px',  fontWeight: 500, display: 'flex', alignItems: 'center', maxWidth: { xs: '350px', sm: '350px', md: '220px', lg: '220px' }, overflow: 'hidden', whiteSpace: 'nowrap' }}>
              <ManRoundedIcon fontSize="1.5 rem" sx={{ mx: '5px' }} /> {firstName} {lastName}
            </Typography>
          </Grid>
          <Grid item >
            { isVerified ? <Typography component="div" sx={{ fontSize: '12px',  display: 'flex', alignItems: 'center', maxWidth: { xs: '350px', sm: '350px', md: '220px', lg: '220px' }, overflow: 'hidden', whiteSpace: 'nowrap' }}>
              <SchoolRoundedIcon fontSize="1.3 rem" sx={{ mx: '5px', }} /> {course}
            </Typography>
            :
            <Typography component="div" sx={{ fontSize: '12px',  display: 'flex', alignItems: 'center', maxWidth: { xs: '350px', sm: '350px', md: '220px', lg: '220px' }, overflow: 'hidden', whiteSpace: 'nowrap' }}>
              <SchoolRoundedIcon fontSize="1.3 rem" sx={{ mx: '5px', }} /> Unspecified
            </Typography>
            }
          </Grid>
          {isVerified && <Grid item>
            <Typography component="div" sx={{ fontSize: '12px',  display: 'flex', alignItems: 'center', maxWidth: { xs: '350px', sm: '350px', md: '220px', lg: '220px' }, overflow: 'hidden', whiteSpace: 'nowrap' }}>
              <WorkRoundedIcon fontSize="1.3 rem" sx={{ mx: '5px',  }} /> {designation}
            </Typography>
          </Grid> }
          {isVerified && <Grid item component={Link} to={`/org/${orgId}`} sx={{ textDecoration: 'none', color: 'inherit' }}>
            <Typography gutterBottom component="div" sx={{ fontSize: '12px',  display: 'flex', alignItems: 'center', maxWidth: { xs: '350px', sm: '350px', md: '220px', lg: '220px' }, overflow: 'hidden', whiteSpace: 'nowrap' }}>
              <BusinessRoundedIcon fontSize="1.3 rem" sx={{ mx: '5px'  }} /> {org}
            </Typography>
          </Grid>}
          
        </Grid>
      </Grid>
      
      {/* <UploadPhotoModal open={open} handleClose={handleClose} handleSubmit={handleClickSubmit} crop={crop} setCrop={setCrop} imgSrc={image} error={error} fileName={fileName} file={file} setFile={setFile} /> */}
      <UploadPicModal open={open} handleClose={handleClose} handleSubmit={handleClickSubmit} previewSource={image} />

    </Card>
  )
}

export default ProfileSidebarSelf