import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import AddAPhotoOutlinedIcon from '@mui/icons-material/AddAPhotoOutlined';
import { CircularProgress, DialogActions, DialogContent, DialogTitle, Divider, FormControl, Grid, IconButton, MenuItem, Select, Input } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import WorkIcon from '@mui/icons-material/Work';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import { Link, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { axiosPrivate } from '../../api/axios';
import Controls from '../../components/controls/Controls';
import { Form, UseForm } from '../../components/UseForm';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

export default function AdmissionEnquiryModalAdmin({ selectedRow, open, handleClose }) {

  useAxiosPrivate();


  const handleRead = async () => {            
    try {
        await axiosPrivate.put(`/api/org/enquiry/admission_enquiry/${selectedRow._id}/read`)             
    } catch (err) {
      console.error(err);      
    }  
  }
  
  React.useEffect(() => {    
    handleRead()    
  }, [])

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >      
     <DialogTitle id="responsive-dialog-title" sx={{ bgcolor: 'background.paper', minWidth: 250, height: 30, display: 'flex', alignItems: 'center', zIndex: 1600, fontSize: 16 }}>
    {`Admission Enquiry`}
    <Box sx={{ flexGrow: 1 }} />
    <IconButton size="small" onClick={handleClose} >
      <CloseIcon />
    </IconButton>
  </DialogTitle>  
           
        <DialogContent sx={{ bgcolor: 'background.default', overflowX: 'hidden', minWidth: 250, height: '69vh', display: 'flex', flexDirection: 'column', zIndex: 1600 }}>
          <Box gap sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center',  }}>
          <Typography sx={{ mb: 1.5 }} color="text.secondary" variant="subtitle2" component="div">
              Name: {selectedRow.name}
            </Typography>
            <Typography sx={{ mb: 1.5 }} color="text.secondary" variant="subtitle2" component="div">
              Phone: {selectedRow.phone}
            </Typography>
            <Typography sx={{ mb: 1.5 }} color="text.secondary" variant="subtitle2" component="div">
              Email: {selectedRow.email}
            </Typography>
            <Typography sx={{ mb: 1.5 }} color="text.secondary" variant="subtitle2" component="div">
              Message: {selectedRow.query}
            </Typography>

          </Box>
        </DialogContent>
         
      {/* <DialogActions sx={{ my: 1}}>
        <Button variant="outlined" color='warning' onClick={handleClose} sx={{  textTransform: 'none', fontSize: '11px',}} startIcon={<CancelOutlinedIcon fontSize="small" />}>  Close
        </Button>
        
      </DialogActions> */}
     
    </Dialog>

  );
}
