import { CircularProgress, Card, Grid, Typography, Box } from '@mui/material'
import CorporateFareTwoToneIcon from '@mui/icons-material/CorporateFareTwoTone';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { axiosPrivate } from '../../api/axios';
import { useDispatch, useSelector } from 'react-redux';
import { fetchOrg } from '../../features/org/orgSlice';
import styled from 'styled-components';

const ProfileImg = styled.img`
  border-radius: 5px;  
  width: 45px;
  height: 45px;
  object-fit: contain;
`;

function OrgSuggestion() {
  useAxiosPrivate();
  const { orgId } = useParams()
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { userId } = useSelector(
    (state) => state.auth
  )

  const [orgFetched, setOrgFetched] = useState([]);
  const [loading, setLoading] = useState(false)
  const [keyValue, setKeyValue] = useState("")

  // console.log(orgFetched)

  const fetchRecommendedOrgs = async () => {
    // e.preventDedault() 
    try {
      // setLoading(true)
      const res = await axiosPrivate.get(`/api/org/fetchorg/${orgId}/reccomended_org/fetch`)
      if (res) {
        // setLoading(false)
        // const data = res.data.orgPost
        // setOrgPost(data)
        const data = res.data.orgs
        setOrgFetched(data)
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    fetchRecommendedOrgs()
  }, [orgId])

  // const shuffled = orgFetched.sort(() => 0.5 - Math.random());
  let selected = orgFetched.slice(0, 5);

  
  return (
    <Card sx={{ my: '10px', p: '5px' }}>
      <Typography variant='h6' sx={{ mx: '3px', mb: '5px', fontSize: '14px', fontWeight: '500' }} > Organization Suggestions</Typography>
      <Grid container spacing={0.5} sx={{ mx: '3px', display: 'flex', flexDirection: 'column', justifyContent: 'spaceEvenly' }}>
        {orgFetched && selected.map((element) => <Box key={element.id}  >
          <Grid item sx={{ mx: '3px', my: '5px', display: 'flex' }} >
            <Box onClick={(e) => {
              e.preventDefault() 
              const info = {
                userId,
                orgId: element.id,
              }             
              navigate(`/org/${element.id}`)
              dispatch(fetchOrg(info)) 
              console.log(element)
            }}
              sx={{ display: 'flex', textDecoration: 'none', color: 'inherit', cursor: 'pointer' }}>
              {element.logo !== "" ? <ProfileImg src={element.logo} alt='Logo' /> : <CorporateFareTwoToneIcon variant="rounded" sx={{ width: '45px', height: 'auto', bgcolor: 'lightGray', color: 'gray', borderRadius: '5px', cursor: 'pointer' }} />}
              <Box sx={{ m: '3px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                <Typography variant='h6' sx={{ fontSize: '12px', fontWeight: '400', maxWidth: { xs: '150px', sm: '150px', md: '150px', lg: '160px' }, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} > {element.name} </Typography>
                <Typography variant='h6' sx={{ fontSize: '9px', fontWeight: '200' }} > {element.followers.length} Followers </Typography>
              </Box>
            </Box>

            <Box sx={{ flexGrow: 1 }} />
            {/* {!follow ? (<Box sx={{ m: '3px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                        <DoneOutlineIcon sx={{ mr: '5px', cursor: 'pointer', color: 'green' }} />
                                                </Box>) : (
                                                        <Box sx={{ m: '3px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                                <DoneOutlineIcon sx={{ mr: '5px', cursor: 'pointer', color: 'darkGray' }} />
                                                        </Box>
                                                )} */}

            {element.followers.includes(userId) ? (<Box sx={{ m: '3px', display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'relative', }}>
              <DoneOutlineIcon onClick={
                async () => {
                  let followersArray = element.followers;
                  const orgId = element.id
                  console.log(followersArray)
                  try {
                    setKeyValue(orgId)
                    setLoading(true)
                    const res = await axiosPrivate.put(`/api/org/${orgId}/unfollow`)
                    if (res) {
                      setLoading(false)
                      setKeyValue("")
                      // orgArray.pop()
                      const filtered = followersArray.indexOf(userId)
                      // console.log(filtered)
                      if (filtered !== -1) {
                        followersArray.splice(filtered, 1)
                      }
                      console.log(followersArray)
                      // const data = res.data.data        
                    }
                  } catch (err) {
                    setLoading(false)
                    setKeyValue("")
                    console.error(err);
                  }
                }

              } sx={{ mr: '4px', cursor: 'pointer', color: 'orange', }} />
              {(loading && keyValue === element.id) && <CircularProgress
                // key ={element._id}
                size={35}
                sx={{
                  color: 'orange',
                  position: 'absolute',
                  // top: '50%',
                  // left: '50%',
                  // marginTop: '-12px',
                  // marginLeft: '-12px',
                }}
              />
              }
            </Box>) : (
              <Box sx={{ m: '3px', display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'relative', }}>
                <DoneOutlineIcon onClick={
                  async () => {
                    let followersArray = element.followers;
                    const orgId = element.id
                    console.log(followersArray)
                    try {
                      setKeyValue(orgId)
                      setLoading(true)
                      const res = await axiosPrivate.put(`/api/org/${orgId}/follow`)
                      if (res) {
                        setLoading(false)
                        setKeyValue("")
                        followersArray.push(userId)
                        console.log(res)
                        // const data = res.data.data        
                      }
                    } catch (err) {
                      setLoading(false)
                      setKeyValue("")
                      console.error(err);
                    }
                  }
                } sx={{ mr: '4px', cursor: 'pointer', color: 'darkGray' }} />
                {(loading && keyValue === element.id) && <CircularProgress
                  // key ={element._id}
                  size={35}
                  sx={{
                    color: 'orange',
                    position: 'absolute',
                    // top: '50%',
                    // left: '50%',
                    // marginTop: '-12px',
                    // marginLeft: '-12px',
                  }}
                />
                }
              </Box>
            )}
          </Grid>
        </Box>
        )
        }
      </Grid >
      {/* <Box sx={{ mt: '3px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>

        <Typography variant='h6' component={Link} to='/praveen/following' sx={{ mx: '3px', mb: '5px', fontSize: '10px', color: 'green', fontWeight: '200', cursor: 'pointer', }} > Show All</Typography>
      </Box> */}
    </Card >
  )
}

export default OrgSuggestion