import { Navigate } from "react-router-dom";
import axios, { axiosPrivate } from "../../api/axios";

const API_URL = '/api/org/org/';


// Login user
const fetchOrg = async (info) => {
  console.log(info.orgId)
  if (info.userId) {
    const res = await axiosPrivate.get(API_URL + `${info.orgId}`);

    if (res.data) {
      localStorage.setItem('org', JSON.stringify(res.data.data));
    }
    return res.data.data
  } else {
    const res = await axios.get(API_URL + `${info.orgId}/public`);

    if (res.data) {
      localStorage.setItem('org', JSON.stringify(res.data.data));
    }
    return res.data.data
  }
}
const orgService = {
  fetchOrg,
}

export default orgService