import React, { useState } from 'react'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Controls from '../../components/controls/Controls';
// import { Form, UseForm } from '../../components/UseForm';
import { Backdrop, Box, CardMedia, CircularProgress, FormHelperText, Grid, IconButton, Link, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { FormAdmin, UseFormAdmin } from '../components/UseFormAdmin';
import ImageSlider from '../components/ImageSlider';
import CloseIcon from '@mui/icons-material/Close';
import AddModeratorOutlinedIcon from '@mui/icons-material/AddModeratorOutlined';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import VerifiedModal from './alertModal/VerifiedModal';
import RevertModal from './alertModal/RevertModal';
import RefuteModal from './alertModal/RefuteModal';
import { axiosPrivate } from '../../api/axios';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import ModeratorModalAdmin from './ModeratorModalAdmin';
import DesignationModalAdmin from './DesignationModalAdmin';
import CourseModalAdmin from './CourseModalAdmin';
import styled from 'styled-components';
import UploadPicModal from '../../pages/modals/UploadPicModal';
import AddAPhotoOutlinedIcon from '@mui/icons-material/AddAPhotoOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import PublicIcon from '@mui/icons-material/Public';
// import { Link } from 'react-router-dom';

const examBoard = [
  { id: 'State Level', title: 'State Level' },
  { id: 'National Level', title: 'National Level' },
  { id: 'Internationally', title: 'International Level' },
]

const OrgLogo = styled.img`
  border-radius: 5px;
  margin: 20px;
  width: 150px;
  height: 150px;
  object-fit: contain;
`;

export default function CompetitiveExamSelectModal(props) {

  const {
    // handleFetch,
    open,
    setOpen,
    handleClose,
    selectedRow,
  } = props
  // const rowData = selectedRow[0]

  let logoUrl;

  useAxiosPrivate()
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [loading, setLoading] = React.useState(false)
  const [moderatorModal, setModeratorModal] = React.useState(false);
  const [moderator, setModerator] = React.useState([])
  const [designationModal, setDesignationModal] = React.useState(false);
  const [designation, setDesignation] = React.useState([])
  const [courseModal, setCourseModal] = React.useState(false);
  const [course, setCourse] = React.useState([])
  const [enabledCourses, setEnabledCourses] = React.useState([])

  const [file, setFile] = useState('');
  const [image, setImage] = useState('');
  const [openPhoto, setOpenPhoto] = useState(false);
  const [previewSource, setPreviewSource] = useState('');
  const [fileName, setFileName] = useState('');
  const [uploadPhoto, setUploadPhoto] = React.useState(false);
  const [error, setError] = useState("");
  const [loadingPhoto, setLoadingPhoto] = React.useState(false)
  const [logo, setLogo] = React.useState(logoUrl)

  const logoFetched = () => {
    logoUrl = selectedRow?.logo;
    setLogo(logoUrl)
    return logoUrl
  }

  const MIN_DIMENSION = process.env.REACT_APP_MIN_DIMENSION;
  const DEFAULT_MAX_FILE_SIZE_IN_BYTES = process.env.REACT_APP_DEFAULT_MAX_FILE_SIZE_IN_BYTES;

  // console.log(selectedRow)

  const initialValues = {
    name: selectedRow?.name,
    expansion: selectedRow?.expansion,
    place: selectedRow?.place,
    board: selectedRow?.board,
    desc: selectedRow?.desc,
    courses: selectedRow?.courses,
    sectorId: selectedRow?.sectorId,
    eligibilityShort: selectedRow?.eligibilityShort,
    eligibility: selectedRow?.eligibility,
    regDate: selectedRow?.regDate,
    examDate: selectedRow?.examDate,
    info: selectedRow?.info,
  }
  // console.log(initialValues)

  const validate = (fieldValues = values) => {
    let temp = { ...errors }
    if ('name' in fieldValues)
      temp.name = fieldValues.name ? "" : "Competitive Exam name required."

    // if ('description' in fieldValues)
    //     temp.description = fieldValues.description ? "" : "Pin/Zip required."

    setErrors({
      ...temp
    })
    // console.log(temp)

    if ((fieldValues = values))
      return Object.values(temp).every(x => x === "")
  }

  const validateRevert = (fieldValues = values) => {
    let temp = { ...errors }
    setErrors({
      ...temp
    })

    if ((fieldValues = values))
      return Object.values(temp).every(x => x === "")
  }

  const {
    values,
    setValues,
    files,
    setFiles,
    errors,
    setErrors,
    optionsData,
    setOptionsData,
    checkedItems,
    setCheckedItems,
    country,
    setCountry,
    state,
    setState,
    handleInputChange,
  } = UseFormAdmin(initialValues, true, validate, validateRevert, selectedRow);

  const filteredCourses = course.map((item) => item.id).filter(item => !enabledCourses.includes(item))

  let initialCourseState = {}

  // const updateCourses = (enabledCourses) => { 
  for (const item of filteredCourses) {
    initialCourseState[item] = false
  }
  for (const item of enabledCourses) {
    initialCourseState[item] = true
  }
  // }

  const [courseState, setCourseState] = React.useState(initialCourseState);

  console.log(courseState)

  const handleCheckCourses = async (event) => {
    setCourseState({
      ...courseState,
      [event.target.name]: event.target.checked,
    });
    let updatedList = [...checkedItems];
    if (event.target.checked) {
      updatedList = [...checkedItems, event.target.value];
    } else {
      updatedList.splice(checkedItems.indexOf(event.target.value), 1);
    }
    setCheckedItems(updatedList);
  };
  // console.log(values)

  const examId = selectedRow?._id

  const handleFetchCheckedCourse = async () => {
    try {
      // setLoading(true)
      setEnabledCourses([])
      setCourse([])
      const res = await axiosPrivate.get(`/api/org/competitive_exam/${examId}/fetch_courses`)
      if (res) {
        // setLoading(false)        
        const data = res.data.data
        setEnabledCourses(data.map((item) => item.id))
        setCourse(data)
        // console.log(res.data)
      }
    } catch (err) {
      console.error(err);
    }
  }


  const handleClickAddPhoto = (e) => {
    setUploadPhoto(true)
    e.preventDefault()
    const fileData = e.target.files[0];
    if (fileData.size > DEFAULT_MAX_FILE_SIZE_IN_BYTES) return setError("Image must be at less than 1mb.");
    console.log(fileData)
    setFileName(e.target.files[0].name)
    if (!fileData) return;
    setFile(fileData) // disable if cropping enabled
    setOpenPhoto(true)

    const reader = new FileReader();
    reader.addEventListener("load", () => {
      const imageElement = new Image();
      const imageUrl = reader.result?.toString() || "";
      // console.log(imageUrl)
      // setFile(imageUrl)
      imageElement.src = imageUrl;

      imageElement.addEventListener("load", (e) => {
        if (error) setError("");
        const { naturalWidth, naturalHeight } = e.currentTarget;
        if (naturalWidth < MIN_DIMENSION || naturalHeight < MIN_DIMENSION) {
          setError("Image must be at least 150 x 150 pixels.");
          return setImage("");
        }
      });
      setImage(imageUrl);
    });
    reader.readAsDataURL(fileData);
  };

  const handleClickSubmitPhoto = async (e) => {
    e.preventDefault()
    console.log('submitted');
    setOpenPhoto(false)

    const formData = new FormData()
    formData.append("file", file)
    formData.append("fileName", fileName)
    console.log(formData.get("file"))

    try {
      setLoadingPhoto(true)
      // dispatch(updateLogo(""))
      const res = await axiosPrivate.put(`/api/user/exam/${examId}/update_logo`, formData)
      if (res.status === 201) {
        setLoadingPhoto(false)
        setLogo(res.data.data)
      }

    } catch (err) {
      console.error(err);
      setLoadingPhoto(false)
    }
    setFile('');
    setPreviewSource('');
  }

  const handleClickDeletePhoto = async (e) => {
    e.preventDefault()
    setLoadingPhoto(true)
    const filename = selectedRow.logo.split('/').pop()
    console.log(filename);
    try {
      const res = await axiosPrivate.put(`/api/user/exam/${examId}/${filename}/delete`)
      setLoadingPhoto(false)
      setLogo("")
    } catch (err) {
      console.error(err);
    }
  };

  const handlePhotoClose = (e) => {
    e.preventDefault()
    console.log('cancelled');
    setOpenPhoto(false)
  }



  const handleFetch = async () => {
    try {
      setLoading(true)
      setOptionsData([])
      // setValues(null)
      const res = await axiosPrivate.get('/api/org/course_type/fetch')
      if (res) {
        setLoading(false)
        const data = res.data.data
        setOptionsData(data)
      }
    } catch (err) {
      console.error(err);
    }
  }

  const handleFetchCourseTypeCourses = async () => {
    try {
      // setLoading(true)
      const res = await axiosPrivate.post(`api/org/course_type/courses/fetch?courseType=${values.courseType.id}`);
      if (!res) return

      if (res.status === 200) {
        // setLoading(false)
        const data = res.data
        setCourse(data)
      }
    } catch (err) {
      console.error(err);
    }
  }

  const examInfo = {
    name: values.name,
    expansion: values.expansion,
    board: values.board,
    place: values.place,
    desc: values.desc,
    courses: JSON.stringify(checkedItems),
    enabledCourses: JSON.stringify(enabledCourses),
    sectorId: values.sectorId,
    eligibilityShort: values.eligibilityShort,
    eligibility: values.eligibility,
    regDate: values.regDate,
    examDate: values.examDate,
    info: values.info,
  }
  console.log(logo)
  console.log(selectedRow)

  const handleClickSubmit = async (e) => {
    e.preventDefault()
    try {
      setLoading(true)
      const formData = new FormData()
      for (let key in examInfo) {
        formData.append(key, examInfo[key])
      }
      const res = await axiosPrivate.put(`/api/user/competitive_exam/${examId}/update`, formData)
      if (res.status === 200) {
        setLoading(false)
        console.log(res.data)
      }
    } catch (err) {
      console.error(err);
    }
  }
  // console.log(enabledCourses)

  React.useEffect(() => {
    setCourseState(initialCourseState)
    setCheckedItems(enabledCourses)
  }, [enabledCourses])


  React.useEffect(() => {
    handleFetchCheckedCourse()
    // setCheckedItems(enabledCourses)       
  }, [selectedRow])

  React.useEffect(() => {
    if (values?.courseType?.id.slice(0, 2) === "CT") handleFetchCourseTypeCourses();
  }, [values])

  React.useEffect(() => {
    logoFetched()
  }, [selectedRow])

  // const locationData = selectedRow?.locationId

  return (
    <div>
      <Dialog
        // fullScreen={fullScreen}

        fullScreen
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      // sx={{zIndex: 1700}}
      // sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: 1080, height: { xs: 'auto', md: '100vh' } }}
      >
        <DialogTitle id="responsive-dialog-title" sx={{ bgcolor: 'background.paper', display: 'flex', position: "fixed", width: "97%", zIndex: 1600 }}>
          {/* {`User Data of ${selectedRow.given_name} ${selectedRow.family_name}`} */}
          {`Manage ${selectedRow?.name}`}
          <Box sx={{ flexGrow: 1 }} />
          <IconButton onClick={handleClose} >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <FormAdmin onSubmit={handleClickSubmit} id="hook-form" >
          <DialogContent sx={{ bgcolor: 'background.default', pt: "70px" }} >

            <Grid container spacing={2} sx={{ px: '3px', py: '15px' }} >

              <Grid item md={4} sx={{
                display: 'flex', flexDirection: 'column',
                '& .MuiTextField-root': { m: 1, width: '90%' },
              }} >

                <Grid item sx={{ position: 'relative' }} >
                  {logo ? <OrgLogo src={logo} alt='L' /> : <PublicIcon variant="rounded" sx={{ m: '20px', width: '150px', height: 'auto', bgcolor: 'lightGray', color: 'gray', borderRadius: '5px' }} />}
                  {loadingPhoto && <CircularProgress
                    size={30}
                    sx={{
                      color: 'white',
                      position: 'absolute',
                      top: '50%',
                      left: '30%',
                      marginTop: '-12px',
                      marginLeft: '-12px',
                    }}
                  />
                  }

                  {
                    <Grid item sx={{ display: 'flex', position: 'absolute' }} >
                      <InputLabel htmlFor="file" sx={{ my: '-50px' }} >
                        {logo ? <AddAPhotoOutlinedIcon fontSize="1.8 rem" sx={{ mx: '30px', zIndex: 1000, color: 'gray' }} />
                          :
                          <>
                            <AddAPhotoOutlinedIcon fontSize="1.8 rem" sx={{ mx: '30px', zIndex: 1000, cursor: 'pointer', color: 'inherit' }} />
                            <input type="file" id="file" accept='.jpg, .jpeg, .png' onChange={handleClickAddPhoto} style={{ display: 'none' }} />
                          </>
                        }
                      </InputLabel>
                      {logo ? <DeleteOutlinedIcon fontSize="1.8 rem" onClick={handleClickDeletePhoto} sx={{ my: '-50px', mx: '70px', zIndex: 1000, cursor: 'pointer', color: 'inherit' }} />
                        :
                        <DeleteOutlinedIcon fontSize="1.8 rem" sx={{ my: '-50px', mx: '70px', zIndex: 1000, color: 'darkGray' }} />
                      }
                    </Grid>
                  }
                </Grid>

                <Controls.Input
                  name="name"
                  label="Exam Name Abbr"
                  value={values?.name}
                  onChange={handleInputChange}
                  error={errors.name}
                  required={true}
                />

                <Controls.Input
                  name="expansion"
                  label="Exam Full Name"
                  value={values?.expansion}
                  onChange={handleInputChange}
                  error={errors.expansion}
                  required={true}
                />

                <Controls.Rgroup
                  name="board"
                  label="Select Board"
                  value={values?.board}
                  onChange={handleInputChange}
                  items={examBoard}
                  disabled={true}
                />

                {
                  (values.board === "National Level" || values.board === "State Level") &&
                <Controls.Input
                  name="place"
                  label="Country"
                  value={values?.place}
                  onChange={handleInputChange}
                  required={true}
                  disabled={true}
                />
                }

                {/* <Controls.Countries
                  name="place"
                  label="Select Country"
                  values={values}
                  setValues={setValues}                                   
                  required={true}
                  defaultValue={values.place}
                  disabled={true}
                  disableClearable={true}
                /> */}

                {/* {
                  values.board === "State Level" &&
                  <Controls.Input
                    name="place"
                    label="Country"
                    value={values?.place}
                    onChange={handleInputChange}
                    required={true}
                    disabled={true}
                  />                 
                } */}

                <Controls.Input
                  name="sectorId"
                  label="Field"
                  value={values?.sectorId}
                  onChange={handleInputChange}
                  required={true}
                  disabled={true}
                />
                <Controls.Input
                  name="eligibilityShort"
                  label="Eligibility Short"
                  value={values?.eligibilityShort}
                  onChange={handleInputChange}
                // required={true}
                // disabled={true}                  
                />
                <Controls.Input
                  name="eligibility"
                  label="Eligibility"
                  value={values?.eligibility}
                  onChange={handleInputChange}
                // required={true}
                // disabled={true}                  
                />
                <Controls.Input
                  name="regDate"
                  label="Registration Date"
                  value={values.regDate}
                  onChange={handleInputChange}
                  error={errors.regDate}
                // required={true}
                />

                <Controls.Input
                  name="examDate"
                  label="Exam Date"
                  value={values.examDate}
                  onChange={handleInputChange}
                  error={errors.examDate}
                // required={true}
                />

                <Controls.TextareaAutosizeBox
                  name="desc"
                  label="Description *"
                  placeholder="Enter Description"
                  minRows={4}
                  maxRows={7}
                  value={values?.desc}
                  onChange={handleInputChange}
                />
                <Controls.TextareaAutosizeBox
                  name="info"
                  label="Date & Details"
                  placeholder="Details"
                  minRows={4}
                  maxRows={7}
                  value={values.info}
                  onChange={handleInputChange}
                />
              </Grid>

              <Grid item md={8} sx={{ display: 'flex', flexDirection: 'column', p: '10px', }} >

                <Box sx={{ my: '10px' }}>
                  <Typography>
                    Select Eligible Courses
                  </Typography>
                </Box>

                <Controls.AutoComplete
                  name="courseType"
                  label="Select Course Category"
                  size='small'
                  listFontSize='14px'
                  labelFontSize='12px'
                  inputFontSize='12px'
                  optionsData={optionsData}
                  setOptionsData={setOptionsData}
                  values={values}
                  setValues={setValues}
                  handleFetch={handleFetch}
                  disableClearable={true}
                />

                <Box sx={{ my: '5px' }}>
                  <List dense sx={{ width: '100%', bgcolor: 'background.paper', columnCount: { xs: '1', sm: '2', md: '3' } }}>
                    {course && course.map((value) => {
                      const labelId = `checkbox-list-label-${value.name}`;

                      return (
                        <ListItem
                          key={value.id}
                          disablePadding
                        >
                          <ListItemButton dense>
                            <ListItemIcon>
                              {Object.keys(courseState).length !== 0 &&
                                <Controls.ChkboxValues
                                  name={value.id}
                                  checkedItems={checkedItems}
                                  setCheckedItems={setCheckedItems}
                                  checked={courseState[value.id]}
                                  // label="Government Owned"
                                  value={value.id}
                                  onChange={handleCheckCourses}
                                />
                              }
                              {Object.keys(courseState).length === 0 &&
                                <Controls.ChkboxValues
                                  name={value.id}
                                  checkedItems={checkedItems}
                                  setCheckedItems={setCheckedItems}
                                  checked={courseState[value.id]}
                                  // label="Government Owned"
                                  value={value.id}
                                  onChange={handleCheckCourses}
                                />
                              }
                            </ListItemIcon>
                            <ListItemText id={value.id} primary={value.name} />
                          </ListItemButton>
                        </ListItem>
                      );
                    })}
                  </List>

                </Box>

              </Grid>

            </Grid>

          </DialogContent>
          <DialogActions sx={{ bgcolor: '#darkslateGray' }}>
            <Controls.Btn
              // name="close"
              text="Close"
              color="error"
              variant="outlined"
              onClick={handleClose}
            />
            <Controls.Btn
              // name="close"
              text="Update"
              color="success"
              variant="outlined"
              type="submit"
            // onClick={handleClickSubmit}
            />
          </DialogActions>
          {
            uploadPhoto && <UploadPicModal open={openPhoto} handleClose={handlePhotoClose} handleSubmit={handleClickSubmitPhoto} previewSource={image} />
          }
        </FormAdmin>

      </Dialog>
    </div>
  );
}
