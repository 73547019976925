import { Box, Card, CardMedia, Grid, Paper, Typography } from '@mui/material'
import React from 'react'
import { GoogleLogin } from '@react-oauth/google';
import { useState, useEffect } from 'react';
import Loggedin from './Loggedin';
import Home from './Home';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'
import { login, logout, reset } from '../features/userAuth/authSlice';
import Jobs from './Jobs';
import LoginComponent from './widgets/LoginComponent';

function Login() {  

  const dispatch = useDispatch()

  const { user, userId, isLoading, isError, isSuccess, message, status } = useSelector(
    (state) => state.auth
  )
 
  const navigate = useNavigate();
  // const location = useLocation();
  

  // const handleFailure = (result) => {
  //   console.log(result);
  // };

  useEffect(() => {
    if (isError) {
      console.log(message)
    }
    if (status === "active") {
      navigate('/')      
    }
    // status === "active" && navigate('/')
    dispatch(reset()) 
      
  }, [reset, status, isError, isSuccess, message, navigate, dispatch])

  // socket?.on('connect', () => {
  //       console.log("socket connted")
  //     })

  // useEffect(() => {   
  //   socket?.on('connect', () => {
  //     console.log("socket connted")
  //   })
  // }, [socket])

  // const handleLogin = (googleData) => {
  //   // const userData = JSON.stringify({ token: googleData.tokenId });    
  //   const userData = JSON.stringify({ token: googleData.credential });
  //   dispatch(login(userData))  
  //   // console.log(userData)         
  // };


  // if (isLoading) {
  //   return console.log("Loading")
  // }

  const handleLogout = () => {
    dispatch(logout())
    navigate('/login');
  }

  

  return (

    <>
      {!user ? (
        <LoginComponent />
      ) :
        (user && status === "active") ? (
         
          
          <Jobs />
          
        ) : (
          <Loggedin onClick={handleLogout} data={user} />
        )}
    </>



//     <>
//       {!user ? (
//         <Paper elevation={0} square sx={{ bgcolor: 'background.default',  }}>
//         <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '94vh', backgroundImage: "url('/motivational.jpg')", backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
//           {/* <Typography variant="h3" color="text.dark"> Under Maintenence...</Typography> */}
//           <Card sx={{ display: 'flex', flexDirection: 'column', width: '300px', alignItems: 'center', justifyContent: 'center', m: ' 20px auto', height: '200px', backgroundColor: '#737d75', boxShadow: 13 }}>
//             <CardMedia
//             component="img"
//             // height="40"
//             image="/karpath.png"            
//             alt="Karpath"
//             sx={{ my: '20px', maxWidth: "150px", height: 'auto' }}
//             />

// {/* <Typography variant="h2" color="text.secondary" sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', fontSize: '1.25rem' }}>
// A Hub for All Career Needs!
// </Typography> */}
//             <GoogleLogin
//               // clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
//               buttonText="Sign in with Google"              
//               onSuccess={handleLogin}
//               // onFailure={handleFailure}
//               onError={handleFailure}
//               // cookiePolicy={'single_host_origin'}
//             // isSignedIn={true}
//             ></GoogleLogin>
//           </Card>
//         </Box>
//         <Grid sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>        
//         <Box sx={{ bgcolor: 'inherit', mb: '10px', mt: '5px', color: 'text.secondary', fontSize: '12px', fontWeignt: 400 }}>
//         &#169; 2020-2022. All Rights Reserved. Karpath Infotech Pvt Ltd.  1st Floor, A Square Building, Kochi, Kerala, IN.
//           </Box>
//         </Grid>
//         </Paper>
//       ) :
//         (user && status === "active") ? (
//           // navigate('/')
//           <Jobs />
//         ) : (
//           <Loggedin onClick={handleLogout} data={user} />
//         )}
//     </>
  )
}

export default Login