import * as React from 'react';
// import React from 'react'
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import styled from 'styled-components';
import { DataGrid, GridToolbarQuickFilter, GridLinkOperator, gridClasses } from '@mui/x-data-grid';
import { Typography, Box, Button, IconButton } from '@mui/material';
import moment from 'moment';
import { grey } from '@mui/material/colors';
import { Edit } from '@mui/icons-material';
import { axiosPrivate } from '../../api/axios';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import AdmissionEnquiryModalAdmin from '../../admin/modals/AdmissionEquiryModalAdmin';
import { useParams } from 'react-router-dom';


const NoData = styled.div`
display: flex;
max-height: 450px;
align-items: center;
justify-content: center;
font-size: 24px;
padding-top: 40px;
// color: darkslategray;
`
function QuickSearchToolbar() {
  return (
    <Box
      sx={{
        p: 0.5,
        pb: 0,
      }}
    >
      <GridToolbarQuickFilter
        quickFilterParser={(searchInput) =>
          searchInput
            .split(',')
            .map((value) => value.trim())
            .filter((value) => value !== '')
        }
      />
    </Box>
  );
}

export default function TableOrgEnquiry() {

    useAxiosPrivate()
    const {orgId} = useParams()
  
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [enquiry, setEnquiry] = React.useState([])
    const [selectedRow, setSelectedRow] = React.useState({})
    const [open, setOpen] = React.useState(false);
    const [institution, setInstitution] = React.useState([])
    const [course, setCourse] = React.useState([])
    const [selectInstitution, setSelectInstitution] = React.useState("");
    const [selectCourse, setSelectCourse] = React.useState("");
    const [loading, setLoading] = React.useState(false)  
    const [openModal, setOpenModal] = React.useState(false);
    const [openRevertModal, setOpenRevertModal] = React.useState(false);
    const [openRefuteModal, setOpenRefuteModal] = React.useState(false);
    const [errors, setErrors] = React.useState({});
    const [pageSize, setPageSize] = React.useState(10);
    const [rowId, setRowId] = React.useState(null);
    const [rowData, setRowData] = React.useState({})
    
    const handleFetch = async () => {
      try {
        setLoading(true)
        const res = await axiosPrivate.get(`/api/org/enquiry/${orgId}/admission_enquiry/fetch_all`)
        if (res) {
          setLoading(false)
          const data = res.data.data
          flatData(data)
          setEnquiry(data)
        }
      } catch (err) {
        console.error(err);
      }
    }
    console.log(enquiry) 
   
    let flattenedData = [];
    const flatData = (equiryData) => {    
      equiryData.forEach((item) => { 
        console.log(item)          
        item.orgName = item.org.name
        item.firstName = item.user ? item.user.firstName : "" 
        // item.read = item._id.toString() === selectedRow._id.toString() ? true : item.isRead    
        delete item.org     
        delete item.user
        flattenedData.push(item)          
      })
    }
   
   
    React.useEffect(() => {    
      handleFetch()
      // handleFetchInstitution()
      // handleFetchCourse()
    }, [])
  
    const handleClick = (event, selectedRow) => {
      // setPage(newPage);
    };
  
  
    const columns = React.useMemo(
      () => [
        // {
        //   field: 'edit',
        //   headerName: 'Edit',
        //   type: 'actions',
        //   width: 50,
        //   renderCell: (params) => <IconButton
        //   onClick = {(event) => {
        //     handleClick(event, selectedRow)
        //   }}
        //   ><Edit /> </IconButton>,
        // },
        // {
        //   field: 'edit',
        //   headerName: 'Edit',
        //   type: 'actions',
        //   width: 50,
        //   renderCell: (params) => <OrgActions {...{params} }  setOpen={setOpen}  />
        // },
        {
          field: 'name',
          headerName: 'Name',
          width: 200,        
        },
        {
          field: 'firstName',
          headerName: 'First Name',
          width: 200,        
        },
        // {
        //   field: 'orgName',
        //   headerName: 'Org Name',
        //   width: 300,        
        // }, 
        
        {
          field: 'email',
          headerName: 'Email',
          width: 150,        
        },     
       
        {
          field: 'createdAt',
          headerName: 'Created At',
          width: 200,
          renderCell: (params) =>
            moment(params.row.createdAt).format('YYYY-MM-DD HH:MM:SS'),
        },
        {
          field: 'isRead',
          headerName: 'Read',
          width: 150,        
        }, 
        { field: '_id', hide: true },       
       
      ],
      [rowId]
    );
  
   
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
    };
  
    // console.log(selectedRow)
  
    // const selectedRowClick = (e) => {
    //   e.preventDefault();
    //   setPage(newPage);
    // };
    
    const handleClose = () => {
      setOpen(false);
    };
    const handleNestedClose = () => {
      setOpenModal(false);
      setOpenRevertModal(false);
      setOpenRefuteModal(false);
    };
  
    return (
      <Box sx={{ width: '75vw', height: 475, }}>
    {
      loading ? <Typography>Loading...</Typography> : enquiry.length !== 0 ?
      <>
          {/* <Typography
        variant="h5"
        component="h5"
        sx={{ textAlign: 'center', my: 1 }}
      >
        Admission Enquiries
      </Typography> */}
      <DataGrid
      // {...user}
        columns={columns}
        getRowId={(row) => row._id }
        // getRowId={(row) => row.id }
        rowsPerPageOptions={[10, 25, 50, 100]}
        // rows={
        //   isAdmin(currentUser)
        //     ? rooms
        //     : rooms.filter((room) => room.uid === currentUser.id)
        // }
        rows={enquiry}        
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        onSelectionModelChange={(id) => {
          const selectedId = new Set(id);
          const selectedRowData = enquiry.filter((row) => selectedId.has(row._id.toString()));
          // const selectedOrgId = selectedRowData[0]._id
          setSelectedRow(selectedRowData)
          // console.log(selectedOrgId)
          // handleFetchOrgById(selectedOrgId)
          setOpen(true)
        } }
        
        sx={{ '& .MuiDataGrid-columnHeaders': { bgcolor: 'background.paper' }, '& .MuiDataGrid-footerContainer': { bgcolor: 'background.paper' } }}
        initialState={{
          // ...user.initialState,
          // pagination,
          filter: {
            filterModel: {
              items: [],
              quickFilterLogicOperator: GridLinkOperator.Or,
            },
          },          
        }}
        components={{ Toolbar: QuickSearchToolbar }}
      />  
      { open && <AdmissionEnquiryModalAdmin 
          // handleFetch={handleFetch} 
          open={open} 
          setOpen={setOpen} 
          handleClose={handleClose} 
          selectedRow={selectedRow[0]}          
          /> }    
         </> : 
    <Typography>No Data Remaining!!!</Typography>
    } 
             
    </Box>
    
  );
}
