import { Box, Card, CardHeader } from '@mui/material'
import React from 'react'
import { axiosPrivate } from '../../api/axios'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'

export const AdminPageDetails = () => {
  useAxiosPrivate()

  const handleFetch = async () => {              
    try {
      const res = await axiosPrivate.get('/api/org/designation/fetch')                
      console.log(res)
    } catch (err) {
      console.error(err);
    }
  }

  return (
    <Box>
    <Card onClick={handleFetch} sx={{ maxWidth: '250px', height: '200px', cursor: 'pointer'}}>
        "Admin Requests "
        
        jfgfkdj
    </Card>
</Box>
  )
}
