import { Box } from "@mui/material";
import React, { useRef, useState } from "react";
import {
  FileUploadContainer,
  FormField,
  DragDropText,
  UploadFileBtn,
  FilePreviewContainer,
  ImagePreview,
  PreviewContainer,
  PreviewList,
  FileMetaData,
  RemoveFileIcon,
  InputLabel
} from "./FileUpload.styles";

const KILO_BYTES_PER_BYTE = 1000;

const convertNestedObjectToArray = (nestedObj) =>
  Object.keys(nestedObj).map((key) => nestedObj[key]);

const convertBytesToKB = (bytes) => Math.round(bytes / KILO_BYTES_PER_BYTE);

const FileUpload = ({ files, setFiles, label, updateFilesCb, maxNoFiles, maxFileSizeInBytes, setFileError,  ...otherProps }) => {

  const fileInputField = useRef(null);
  // const [files, setFiles] = useState({});

  const handleUploadBtnClick = () => {
    fileInputField.current.click();
  };

  const addNewFiles = (newFiles) => {
    const filesArray = convertNestedObjectToArray(files);
    for (let file of newFiles) {
      if (filesArray.length > maxNoFiles) {
        alert('You cannot upload more files than recommended 1')
        return { ...files }
      }      
      if (file.size <= maxFileSizeInBytes) {
        if (!otherProps.multiple) {
          return { file };
        }
        files[file.name] = file;
        return { ...files };
      } alert('File size is greater than 250kb') 
    }
    return { ...files };    
  };

  const callUpdateFilesCb = (files) => {
    const filesAsArray = convertNestedObjectToArray(files);
    updateFilesCb(filesAsArray);
  };

  const handleNewFileUpload = (e) => {
    const  {files: newFiles}  = e.target;
    // console.log(files)
    // for (let i = maxNoFiles; i > newFiles.length; i--) {
    //   if (newFiles.length && newFiles.length > maxNoFiles+1) {          
    //       return alert('You cannot upload more files than recommended 2')
    //     }
        let updatedFiles = addNewFiles(newFiles);
        setFiles(updatedFiles);
        setFileError(false)
        callUpdateFilesCb(updatedFiles);

        console.log(files)
        return {files}        
    // } return       
  };

  const removeFile = (fileName) => {
    delete files[fileName];
    setFiles({ ...files });
    callUpdateFilesCb({ ...files });
  };
  
  return (
    <>
      <FileUploadContainer>        
        <DragDropText>Drag and drop or upload your SSLC, 10th certs to verify name and age. Max size 250kb.</DragDropText>
        <UploadFileBtn type="button" onClick={handleUploadBtnClick}>
          <i className="fas fa-file-upload" />
          <span> Upload  {otherProps.multiple ? "files" : "a file"}</span>
        </UploadFileBtn>
        <FormField
          type="file"
          ref={fileInputField}
          onChange={handleNewFileUpload}
          title=""
          value=""         
          {...otherProps}          
        />
      </FileUploadContainer>
      <FilePreviewContainer>
        {/* <span>To Upload</span> */}
        <PreviewList>
          {Object.keys(files).map((fileName, index) => {
            let file = files[fileName];
            let isImageFile = file.type.split("/")[0] === "image";
            return (
              <PreviewContainer key={fileName}>
                <div>
                  {isImageFile && (
                    <ImagePreview
                      src={URL.createObjectURL(file)}
                      alt={`file preview ${index}`}
                    />
                  )}
                  <FileMetaData isImageFile={isImageFile}>
                    <span>{file.name}</span>
                    <aside>
                      <span>{convertBytesToKB(file.size)} kb</span>
                      <RemoveFileIcon
                        className="fas fa-trash-alt"
                        onClick={() => removeFile(fileName)}
                      />
                    </aside>
                  </FileMetaData>
                </div>
              </PreviewContainer>
            );
          })}
        </PreviewList>
      </FilePreviewContainer>
    </>
  );    
};

export default FileUpload;



// import { Box } from "@mui/material";
// import React, { useRef, useState } from "react";
// import {
//   FileUploadContainer,
//   FormField,
//   DragDropText,
//   UploadFileBtn,
//   FilePreviewContainer,
//   ImagePreview,
//   PreviewContainer,
//   PreviewList,
//   FileMetaData,
//   RemoveFileIcon,
//   InputLabel
// } from "./FileUpload.styles";

// const KILO_BYTES_PER_BYTE = 1000;

// const convertNestedObjectToArray = (nestedObj) =>
//   Object.keys(nestedObj).map((key) => nestedObj[key]);

// const convertBytesToKB = (bytes) => Math.round(bytes / KILO_BYTES_PER_BYTE);

// const FileUpload = ({ files, setFiles, label, updateFilesCb, maxNoFiles, maxFileSizeInBytes, setFileError,  ...otherProps }) => {

//   const fileInputField = useRef(null);
//   // const [files, setFiles] = useState({});

//   const handleUploadBtnClick = () => {
//     fileInputField.current.click();
//   };

//   const addNewFiles = (newFiles) => {
//     const filesArray = convertNestedObjectToArray(files);
//     for (let file of newFiles) {
//       if (filesArray.length > maxNoFiles) {
//         alert('You cannot upload more files than recommended 1')
//         return { ...files }
//       }      
//       if (file.size <= maxFileSizeInBytes) {
//         if (!otherProps.multiple) {
//           return { file };
//         }
//         files[file.name] = file;
//         return { ...files };
//       } alert('File size is greater than 250kb') 
//     }
//     return { ...files };    
//   };

//   const callUpdateFilesCb = (files) => {
//     const filesAsArray = convertNestedObjectToArray(files);
//     updateFilesCb(filesAsArray);
//   };

//   const handleNewFileUpload = (e) => {
//     const  {files: newFiles}  = e.target;
//     // console.log(files)
//     // for (let i = maxNoFiles; i > newFiles.length; i--) {
//     //   if (newFiles.length && newFiles.length > maxNoFiles+1) {          
//     //       return alert('You cannot upload more files than recommended 2')
//     //     }
//         let updatedFiles = addNewFiles(newFiles);
//         setFiles(updatedFiles);
//         setFileError(false)
//         callUpdateFilesCb(updatedFiles);

//         console.log(files)
//         return {files}        
//     // } return       
//   };

//   const removeFile = (fileName) => {
//     delete files[fileName];
//     setFiles({ ...files });
//     callUpdateFilesCb({ ...files });
//   };
  
//   return (
//     <>
//       <FileUploadContainer>        
//         <DragDropText>Drag and drop or upload your SSLC, 10th certs to verify name and age. Max size 250kb.</DragDropText>
//         <UploadFileBtn type="button" onClick={handleUploadBtnClick}>
//           <i className="fas fa-file-upload" />
//           <span> Upload  {otherProps.multiple ? "files" : "a file"}</span>
//         </UploadFileBtn>
//         <FormField
//           type="file"
//           ref={fileInputField}
//           onChange={handleNewFileUpload}
//           title=""
//           value=""         
//           {...otherProps}          
//         />
//       </FileUploadContainer>
//       <FilePreviewContainer>
//         {/* <span>To Upload</span> */}
//         <PreviewList>
//           {Object.keys(files).map((fileName, index) => {
//             let file = files[fileName];
//             let isImageFile = file.type.split("/")[0] === "image";
//             return (
//               <PreviewContainer key={fileName}>
//                 <div>
//                   {isImageFile && (
//                     <ImagePreview
//                       src={URL.createObjectURL(file)}
//                       alt={`file preview ${index}`}
//                     />
//                   )}
//                   <FileMetaData isImageFile={isImageFile}>
//                     <span>{file.name}</span>
//                     <aside>
//                       <span>{convertBytesToKB(file.size)} kb</span>
//                       <RemoveFileIcon
//                         className="fas fa-trash-alt"
//                         onClick={() => removeFile(fileName)}
//                       />
//                     </aside>
//                   </FileMetaData>
//                 </div>
//               </PreviewContainer>
//             );
//           })}
//         </PreviewList>
//       </FilePreviewContainer>
//     </>
//   );    
// };

// export default FileUpload;