// import React, { useEffect, useRef, useState } from 'react'
// import MUIRichTextEditor from "mui-rte";
// import {stateToHTML} from 'draft-js-export-html';
// import htmlToDraft from 'html-to-draftjs'
// import {ContentState, EditorState, convertToRaw, convertFromHTML, convertFromRaw} from 'draft-js'

// export default function MuiRte(props) {

//     const { name, label, values, setValues, disabled, error = null, required, multiline, minRows, maxRows,  } = props;
      
//     const [editorState, setEditorState] = useState()

//     const ref = useRef()

       
//     // const htmlToDraftBlocks = (html) => {
//     //     const blocksFromHtml = htmlToDraft(html)
//     //     const { contentBlocks, entityMap } = blocksFromHtml
//     //     const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap)        
//     //     const editorState = EditorState.createWithContent(contentState)       
//     //     // console.log(blocksFromHtml)
//     //     return editorState        
//     // }

//     // const blocksFromHtml = htmlToDraft(values.roles)

//     // console.log(blocksFromHtml)

//     // useEffect(() => {
//     //  setEditorState(htmlToDraftBlocks(values.roles))
//     // }, [])

//     // const data = convertFromHTML(values.roles)
//     // console.log(editorState)

//     // const data = stateToHTML(editorState.getCurrentContent())
//     const data = '<p>hello</p>'
    
//     let content     
//     const onChange = (value) => {
//         content = stateToHTML(value.getCurrentContent())
//         // content = convertToRaw(value.getCurrentContent())
//         console.log(content)
        
//         setValues({
//             ...values,
//             [name]: content,
//           })
//       };
//     console.log(data) 
//     // console.log(content)
      
//     return (      
//         <MUIRichTextEditor             
//             label={label}
//             id={name}
//             // ref={content}
//             // editorState={editorState}
//             defaultValue={content}
//             onChange={(value) => onChange(value)}
//             controls={["bold", "italic", "underline", "strikethrough", "numberList", "bulletList", "clear", "undo", "redo",]}
                      
//             />
         
//     )
// }


import React, { forwardRef, useEffect, useRef, useState } from 'react'
import MUIRichTextEditor from "mui-rte";
import {stateToHTML} from 'draft-js-export-html';
import htmlToDraft from 'html-to-draftjs'
import {ContentState, EditorState, convertToRaw, convertFromHTML, convertFromRaw} from 'draft-js'

const MuiRte = forwardRef((props, ref) => {

    const { name, label, values, setValues, disabled, error = null, required, setEditorState, content, save  } = props;
      
    // const [editorState, setEditorState] = useState()

    // const ref = useRef()

       
    // const htmlToDraftBlocks = (html) => {
    //     const blocksFromHtml = htmlToDraft(html)
    //     const { contentBlocks, entityMap } = blocksFromHtml
    //     const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap)        
    //     const editorState = EditorState.createWithContent(contentState)       
    //     // console.log(blocksFromHtml)
    //     return editorState        
    // }

    // const blocksFromHtml = htmlToDraft(values.roles)

    // console.log(blocksFromHtml)

    // useEffect(() => {
    //  setEditorState(htmlToDraftBlocks(values.roles))
    // }, [])

    // const data = convertFromHTML(values.roles)
    // console.log(editorState)

    // const data = stateToHTML(editorState.getCurrentContent())
   
    
    // let content     
    // const onChange = (value) => {
    //     content = stateToHTML(value.getCurrentContent())
    //     // content = convertToRaw(value.getCurrentContent())
    //     console.log(content)
        
    //     setValues({
    //         ...values,
    //         [name]: content,
    //       })
    //   };
    // console.log(data) 
    // console.log(content)
//     const sample = {"blocks":[{"key":"va2u","text":"time to edit","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}} 
//   const content = JSON.stringify(sample)

    // const save = (data) => {
    //     setEditorState(data)
    //     console.log(data);
    //   };

    //   const handleClick = () => {
    //     ref.current?.save()
    // }

      
    return ( 
             
        <MUIRichTextEditor             
            label={label}
            id={name}
            onSave={save}
            ref={ref}           
            defaultValue={content}
            // onChange={(value) => onChange(value)}
            controls={["bold", "italic", "underline", "strikethrough", "numberList", "bulletList", "clear", "undo", "redo",]}
                      
            />

           
    )
})

export default MuiRte