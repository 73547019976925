import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Autocomplete from '@mui/material/Autocomplete';
import { Box } from '@mui/material';
import { useRef } from 'react';

export default function AutoComplete(props) {
  const { name, label, size, error = null, listFontSize, labelFontSize, inputFontSize, values, setValues, optionsData, loading, handleFetch, defaultValue, multiple, disableClearable, required } = props;

  const refr = useRef()
  // console.log(values)
  // console.log(optionsData)
  
  return (
    <Stack sx={{ width: '100%', }}>
      {/* ml: '-17px', */}
      <Autocomplete
        ref={refr}
        name={name}
        // value={(values[name] !== null || values[name] !== undefined) ? values[name] : {}}
        // value={ values !== null ? values[0] : {} }
        value={ ( values !== null ||  values !== undefined || values[name] !== undefined || values[name] !== null) ? values[name] || values[0] : {} } //Considering Objects and Arrays
        id="designation-id"
        filterSelectedOptions
        multiple={multiple}
        disableClearable={disableClearable}
        defaultValue={defaultValue}
        loading={loading}
        loadingText="Loading..."
        noOptionsText= "No Options"
        options={optionsData}
        getOptionLabel={(optionsData) => `${optionsData.name}`}
        isOptionEqualToValue={(option, value) => option?.id === value?.id}
        onChange={(event, newValue) => {
          const name = refr.current.getAttribute("name")          
          setValues({
            ...values,
            [name]: newValue,
          })
        }}
        renderOption={(props, optionsData) => (
          <Box component='li' {...props} key={optionsData.id} sx={{ bgcolor: 'background.primary', fontSize: `${listFontSize}` }} >
            {optionsData.name}
          </Box>
        )}
        renderInput={(params) => (
          <TextField sx={{ display: 'flex', justifyContent: 'spaceBetween' }}
            {...params}
            name={name}
            label={label}
            size={size}
            InputLabelProps={{ style: { fontSize: `${labelFontSize}` } }}
            placeholder="Type to Search"
            required={required}
            // required={Object.keys(designationType).length === 0}
            InputProps={{
              ...params.InputProps,
              type: 'search',
              style: { fontSize: `${inputFontSize}` }
            }}
            onClick={handleFetch}
            {...(error && { error: true, helperText: error })}            
          />
        )}       
      />
    </Stack>
  );
}