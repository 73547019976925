import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import authService from './authService'

// Get user from localStorage
const user = JSON.parse(localStorage.getItem('user'))

const initialState = {
  status: user ? user.status : null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
  // user: user ? user : null,
  userId: user ? user.userId : null,
  userid: user ? user.userId : null,
  firstName: user ? user.firstName : null,
  lastName: user ? user.lastName : null,
  email: user ? user.email : null,
  phone: user ? user.phone : null,
  profile_pic: user ? user.profile_pic : null,
  designation: user ? user.designation : null,
  course: user ? user.course : null,
  courseId: user ? user.courseId : null,
  homeTownId: user ? user.homeTownId : null,
  district: user ? user.district : null,
  state: user ? user.state : null,
  country: user ? user.country : null,
  org: user ? user.org : null, 
  orgId: user ? user.orgId : null, 
  enabledCourses: user ? user.enabledCourses : null,
  enabledDesignations: user ? user.enabledDesignations : null,
  enabledPlaces: user ? user.enabledPlaces : null,
  languages: user ? user.languages : null,
  address: user ? user.address : null,
  references: user ? user.references : null,
  publications: user ? user.publications : null,
  skills: user ? user.skills : null,
  certifications: user ? user.certifications : null,
  maritalStatus: user ? user.maritalStatus : null,
  currentPlaceId: user ? user.currentPlaceId : null,
  currentDistrict: user ? user.currentDistrict : null,
  currentState: user ? user.currentState : null,
  currentCountry: user ? user.currentCountry : null,
  currentAddress: user ? user.currentAddress : null,
  bio: user ? user.bio : null,
  maritalStatus: user ? user.maritalStatus : null,
  dateOfBirth: user ? user.dateOfBirth : null ,
  isVerified: user ? user.isVerified : null,
  isPublic: user ? user.isPublic : null,
  subscription: user ? user.subscription : null,
  isTempAddrDefault: user ? user.isTempAddrDefault : null,
  userSlug: user? user.userSlug : null,
  // forbiddenStatus: true,
}

// Login user
export const login = createAsyncThunk('auth/login', async (user, thunkAPI) => {
  try {
    return await authService.login(user)
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

export const logout = createAsyncThunk('auth/logout', async (thunkAPI) => {
  try {
    await authService.logout()
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString()
    return thunkAPI.rejectWithValue(message)
  }
  
})

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false
      state.isSuccess = false
      state.isError = false
      state.message = ''
      // state.firstName = ''
      // state.email = ''
      // state.status = ''
      // state.profile_pic = ''
    },
    update: (state, action) => {  
      state.user = action.payload   
      state.status = action.payload.status
      state.firstName = action.payload.firstName
      state.lastName = action.payload.lastName
      state.email = action.payload.email
      state.homeTownId = action.payload.homeTownId
      state.phone = action.payload.phone
      state.userId = action.payload.userId
      state.userid = action.payload.userId
      state.district = action.payload.district
      state.state = action.payload.state
      state.country = action.payload.country
      state.isVerified = action.payload.isVerified
      state.profile_pic = action.payload.profile_pic
        state.designation = action.payload.designation
        state.course = action.payload.course
        state.courseId = action.payload.courseId        
        state.org = action.payload.org
        state.orgId = action.payload.orgId        
        state.enabledCourses = action.payload.enabledCourses
        state.enabledDesignations = action.payload.enabledDesignations
        state.enabledPlaces = action.payload.enabledPlaces
        state.languages = action.payload.languages
        state.address = action.payload.address
        state.references = action.payload.references
        state.publications = action.payload.publications
        state.skills = action.payload.skills
        state.certifications = action.payload.certifications
        state.maritalStatus = action.payload.maritalStatus
        state.currentPlaceId = action.payload.currentPlaceId
        state.currentDistrict = action.payload.currentDistrict
        state.currentState = action.payload.currentState
        state.currentCountry = action.payload.currentCountry
        state.currentAddress = action.payload.currentAddress
        state.bio = action.payload.bio
        state.subscription = action.payload.subscription
        state.isPublic = action.payload.isPublic
        state.maritalStatus = action.payload.maritalStatus
        state.dateOfBirth = action.payload.dateOfBirth
        state.userSlug = action.payload.userSlug        
    },
    updatePic: (state, action) => {      
      state.profile_pic = action.payload     
    }, 
    updateBio: (state, action) => {      
      state.bio = action.payload     
    },
    updatePhone: (state, action) => {      
      state.phone = action.payload     
    },
    updateMaritalStatus: (state, action) => {      
      state.maritalStatus = action.payload     
    },
    updateHomeTownId: (state, action) => {
      state.homeTownId = action.payload
    },
    updateDistrict: (state, action) => {
      state.district = action.payload
    },
    updateState: (state, action) => {
      state.state = action.payload
    },
    updateCountry: (state, action) => {
      state.country = action.payload
    },
    updateAddress: (state, action) => {
      state.address = action.payload
    },   
    updateCurrentPlaceId: (state, action) => {
      state.currentPlaceId = action.payload
    },
    updateCurrentDistrict: (state, action) => {
      state.currentDistrict = action.payload
    },
    updateCurrentState: (state, action) => {
      state.currentState = action.payload
    },
    updateCurrentCountry: (state, action) => {
      state.currentCountry = action.payload
    },
    updateCurrentAddress: (state, action) => {
      state.currentAddress = action.payload
    },  
    updateLanguage: (state, action) => {
      state.languages = action.payload
    },
    updateReference: (state, action) => {
      state.references = action.payload
    }, 
    updatePublication: (state, action) => {
      state.publications = action.payload
    },
    updateSkills: (state, action) => {
      state.skills = action.payload
    },
    updateCertifications: (state, action) => {
      state.certifications = action.payload
    },
    updateIsPublic: (state, action) => {
      state.isPublic = action.payload
    },
    updateSubscription: (state, action) => {
      state.subscription = action.payload
    },
    updateIsTempAddrDefault: (state, action) => {
      state.isTempAddrDefault = action.payload
    },
    // updateForbiddenStatus: (state, action) => {      
    //   state.forbiddenStatus = action.payload     
    // },
  },
  extraReducers: (builder) => {
    builder      
      .addCase(login.pending, (state) => {
        state.isLoading = true
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.user = action.payload
        state.status = action.payload.status 
        state.userId = action.payload.userId  
        state.userid = action.payload.userId     
        state.firstName = action.payload.firstName
        state.lastName = action.payload.lastName
        state.email = action.payload.email
        state.phone = action.payload.phone
        state.profile_pic = action.payload.profile_pic
        state.designation = action.payload.designation
        state.course = action.payload.course
        state.courseId = action.payload.courseId
        state.homeTownId = action.payload.homeTownId
        state.district = action.payload.district
        state.state = action.payload.state
        state.country = action.payload.country
        state.org = action.payload.org
        state.orgId = action.payload.orgId
        state.enabledCourses = action.payload.enabledCourses
        state.enabledDesignations = action.payload.enabledDesignations
        state.enabledPlaces = action.payload.enabledPlaces
        state.languages = action.payload.languages
        state.address = action.payload.address
        state.references = action.payload.references
        state.publications = action.payload.publications
        state.skills = action.payload.skills
        state.certifications = action.payload.certifications
        state.maritalStatus = action.payload.maritalStatus
        state.currentPlaceId = action.payload.currentPlaceId
        state.currentDistrict = action.payload.currentDistrict
        state.currentState = action.payload.currentState
        state.currentCountry = action.payload.currentCountry
        state.currentAddress = action.payload.currentAddress
        state.bio = action.payload.bio
        state.maritalStatus = action.payload.maritalStatus
        state.dateOfBirth = action.payload.dateOfBirth
        state.isVerified = action.payload.isVerified
        state.isPublic = action.payload.isPublic
        state.subscription = action.payload.subscription
        state.isTempAddrDefault = action.payload.isTempAddrDefault
        state.userSlug = action.payload.userSlug
        // state.forbiddenStatus = true
      })
      .addCase(login.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
        state.user = null
      })
      .addCase(logout.fulfilled, (state) => {
        
        state.user = null
        state.userId = null
        state.userid = null
        state.firstName = null
        state.lastName = null
        state.email = null
        state.phone = null
        state.profile_pic = null
        state.status = null
        state.designation = null
        state.course = null
        state.courseId = null
        state.homeTownId = null
        state.district = null
        state.state = null
        state.country = null
        state.org = null
        state.orgId = null
        state.enabledCourses = null
        state.enabledDesignations = null
        state.enabledPlaces = null
        state.languages = null
        state.address = null
        state.references = null
        state.publications = null
        state.skills = null
        state.certifications = null
        state.maritalStatus = null
        state.currentPlaceId = null
        state.currentDistrict = null
        state.currentState = null
        state.currentCountry = null
        state.currentAddress = null
        state.bio = null
        state.maritalStatus = null
        state.dateOfBirth = null
        state.isVerified = null
        state.isPublic = null
        state.subscription = null
        state.isTempAddrDefault = null
        state.userSlug = null
        // state.forbiddenStatus = null
        
      })
  },
})

export const { reset, update, updatePic, updateBio, updatePhone, updateMaritalStatus, 
  updateLanguage, updateHomeTownId, updateDistrict, updateState, 
  updateCountry, updateAddress, updateCurrentPlaceId, updateCurrentDistrict, 
  updateCurrentState, updateCurrentCountry, updateCurrentAddress, 
  updatePublication, updateReference, updateSkills, updateCertifications,
  updateIsPublic, updateSubscription, updateIsTempAddrDefault, updateForbiddenStatus } = authSlice.actions
export default authSlice.reducer