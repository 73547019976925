import { Box, Button, Card, CardMedia, TextField } from '@mui/material'
import React from 'react'
import Dashboard from './Dashboard'
import { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation, Navigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'
import { login, reset } from '../../features/admin/adminSlice';
import axios from '../../api/axios';

function AdminLogin() {

  const dispatch = useDispatch()
  const navigate = useNavigate();
  const location = useLocation();

  const [values, setValues] = useState({ username: '', password: '' });


  const { admin, isLoading, isError, isSuccess, message, status } = useSelector(
    (state) => state.admin
  )

  useEffect(() => {
    if (isError) {
      console.log(message)
    }
    // status === "active" && navigate('/admin/dashboard')    

    dispatch(reset())
  }, [status, isError, isSuccess, message, navigate, dispatch]);

  const handleChange = (e) => {
    e.preventDefault()
    const { name, value } = e.target
    setValues({
      ...values,
      [name]: value,
    })
  };

  const handleLogin = (e) => {
    e.preventDefault()
    dispatch(login(values))
  };  

  if (isLoading) {
    return console.log("Loading")
  }

  return (
    <>
      {!admin ? (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
          <Card sx={{ display: 'flex', flexDirection: 'column', width: '300px', alignItems: 'center', justifyContent: 'center', m: ' 20px auto', height: 'auto' }}>
            <CardMedia
              component="img"
              // height="45"
              image="/karpath.png"
              alt="Karpath"
              sx={{ my: '20px', maxWidth: "150px", height: 'auto' }}
            />
            <TextField
              name='username'
              onChange={handleChange}
              placeholder="Username"
              label="Username"
              value={values.username}
              sx={{ my: '10px' }}
            />

            <TextField
              name='password'
              onChange={handleChange}
              placeholder="Password"
              label="Password"
              type="password"
              value={values.password}
              sx={{ my: '10px' }}
            />

            <Button
              variant="contained"
              size="large"
              color="primary"
              onClick={handleLogin}
              sx={{ my: '20px' }}
            >
              Login
            </Button>

          </Card>
        </Box>
      ) :
      (admin && status === "active") && (        
         <Navigate to="/admin/dashboard" />                            
        )}
    </>
  )
}

export default AdminLogin