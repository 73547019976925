import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import orgService from "./orgService";

const org = JSON.parse(localStorage.getItem('org'))

const initialState = {
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
  orgId: org ? org.orgId : null,
  orgName: org ? org.orgName : null,
  logo: org ? org.logo : null,
  pic: org ? org.pic : null,
  activeMembers: org ? org.activeMembers : null,
  students: org ? org.students : null,
  followers: org ? org.followers : null,
  sectorName: org ? org.sectorName : null,
  sectorId: org ? org.sectorId: null,
  isEduInstitution: org ? org.isEduInstitution : null,
  city: org ? org.city : null,
  state: org ? org.state : null,
  country: org ? org.country : null,
  locationId: org ? org.locationId : null,
  isModerator: org ? org.isModerator : null,
  desc: org ? org.desc : null,
  isAdministrator: org ? org.isAdministrator : null,
  isHr: org ? org.isHr : null,
  subscription: org ? org.subscription : null,
  noOfModerators: org ? org.noOfModerators : null,
  orgCreator: org ? org.creatorUser : null,
  street: org ?  org.street : null,
        area: org ?  org.area : null,
        pin: org ?  org.pin : null,
        website: org ?  org.website : null,
        email: org ?  org.email : null,
        phone: org ?  org.phone : null,        
        isGovt: org ?  org.isGovt : null,
        orgSlug: org ? org.orgSlug : null ,
}

// Fetch Org
export const fetchOrg = createAsyncThunk('org/fetch', async (org, thunkAPI) => {
  try {
    return await orgService.fetchOrg(org)
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

export const orgSlice = createSlice({
  name: 'org',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false
      state.isSuccess = false
      state.isError = false
      state.message = ''
      state.orgName = ''
      state.logo = ''
      state.city = ''
      state.state = ''
      state.country = ''
      state.sectorName = ''
      state.sectorId = ''
      
      // state.status = ''
      // state.profile_pic = ''
    },
    updateLogo: (state, action) => {
      state.logo = action.payload
    },
    updatePic: (state, action) => {
      state.pic = action.payload
    },
    updateDesc: (state, action) => {
      state.desc = action.payload
    },
    addFollowers: (state, action) => {      
      state.followers = state.followers.concat(action.payload)  
    }, 
    removeFollowers: (state, action) => {      
      state.followers = state.followers.filter(i => i !== action.payload) 
    },
    /* MODERATORS, SUBSCRIPTION, ETC NEEDS TO BE ADDED     */
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchOrg.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchOrg.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.orgId = action.payload.orgId
        state.orgName = action.payload.orgName
        state.pic = action.payload.pic
        state.logo = action.payload.logo
        state.activeMembers = action.payload.activeMembers
        state.students = action.payload.students
        state.followers = action.payload.followers
        state.sectorName = action.payload.sectorName
        state.sectorId = action.payload.sectorId
        state.isEduInstitution = action.payload.isEduInstitution
        state.city = action.payload.city
        state.state = action.payload.state
        state.country = action.payload.country
        state.locationId = action.payload.locationId
        state.isModerator = action.payload.isModerator
        state.desc = action.payload.desc
        state.isHr = action.payload.isHr
        state.isAdministrator = action.payload.isAdministrator
        state.subscription = action.payload.subscription
        state.noOfModerators = action.payload.noOfModerators
        state.orgCreator = action.payload.orgCreator
        state.street = action.payload.street
        state.area = action.payload.area
        state.pin = action.payload.pin
        state.website = action.payload.website
        state.email = action.payload.email
        state.phone = action.payload.phone        
        state.isGovt = action.payload.isGovt
        state.orgSlug = action.payload.orgSlug
      })
      .addCase(fetchOrg.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
        state.orgId = null
        state.orgName = null
        state.pic = null
        state.logo = null
        state.activeMembers = null
        state.students = null
        state.sectorName = null
        state.sectorId = null
        state.isEduInstitution = null
        state.city = null
        state.state = null
        state.country = null
        state.locationId = null
        state.isModerator = null
        state.desc = null
        state.isHr = null
        state.isAdministrator = null
        state.subscription = null
        state.noOfModerators = null
        state.orgCreator = null
        state.street = null
        state.area = null
        state.pin = null
        state.website = null
        state.email = null
        state.phone = null        
        state.isGovt = null
        state.orgSlug = null
      })

  },
})

export const { reset, updateLogo, updatePic, updateDesc, addFollowers, removeFollowers } = orgSlice.actions
export default orgSlice.reducer