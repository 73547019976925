import PublicIcon from '@mui/icons-material/Public';
import { CircularProgress, Card, Grid, Typography, Box } from '@mui/material'
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import { Link, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { axiosPrivate } from '../../api/axios';
import { fetchExam } from '../../features/exam/examSlice';

const ProfileImg = styled.img`
  border-radius: 5px;  
  width: 45px;
  height: 45px;
  object-fit: cover;
`;

function CompetitiveExamSidebar() {

    useAxiosPrivate();
    const dispatch = useDispatch();
    const navigate = useNavigate();
  
    const { userId } = useSelector(
      (state) => state.auth
    )
  
    const [examFetched, setExamFetched] = useState([]);
    const [loading, setLoading] = useState(false)
    const [keyValue, setKeyValue] = useState("")

    const fetchRecommendedExams = async () => {
        // e.preventDedault() 
        try {
          // setLoading(true)
          const res = await axiosPrivate.get(`/api/org/fetchexam`)
          if (res.status === 200) {
            // setLoading(false)
            // const data = res.data.orgPost
            // setOrgPost(data)
            const data = res.data.data
            setExamFetched(data)
          }
        } catch (err) {
          console.error(err);
        }
      };

      useEffect(() => {
        fetchRecommendedExams()
      }, [])
    
      // const shuffled = orgFetched.sort(() => 0.5 - Math.random());
      let selected = examFetched.slice(0, 4);

    let follow;
        return (
            <Card sx={{ my: '10px', p: '5px' }}>
            <Typography variant='h6' sx={{ mx: '3px', mb: '5px', fontSize: '14px', fontWeight: '500' }} > Competitive Exams</Typography>
            <Grid container spacing={0.5} sx={{ mx: '3px', display: 'flex', flexDirection: 'column', justifyContent: 'spaceEvenly' }}>
                {/* <Grid item sx={{ mx: '3px', my: '5px', display: 'flex' }}>
                    <Box component={Link} to='/competitiveexam' sx={{display: 'flex', textDecoration: 'none', color:'inherit' }}>
                    <PublicIcon sx={{ mr: '3px', width: '45px', height: '45px', bgcolor: 'lightGray', color: 'gray', borderRadius: '5px'  }} />
                    <Box sx={{ m: '3px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                        <Typography variant='h6' sx={{ fontSize: '12px', fontWeight: '400', maxWidth: {xs:'150px', sm: '150px', md:'150px', lg: '230px'}, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} > CSIR NET </Typography>
                        <Typography variant='h6' sx={{ fontSize: '9px', fontWeight: '200'}} > 5.7k Followers </Typography>
                    </Box>
                    </Box>
                    <Box sx={{ flexGrow: 1 }} />
                    {!follow ? ( <Box sx={{ m: '3px', display: 'flex',alignItems: 'center', justifyContent: 'center' }}>
                    <DoneOutlineIcon sx={{ mr: '5px', cursor: 'pointer', color: 'green'}}/>
                    </Box> ) : (
                       <Box sx={{ m: '3px', display: 'flex',alignItems: 'center', justifyContent: 'center' }}>
                       <DoneOutlineIcon sx={{ mr: '5px', cursor: 'pointer', color: 'darkGray'}}/>
                       </Box> 
                    )}
                </Grid> */}

{examFetched && selected.map((element) => <Box key={element.id}  >
          <Grid item sx={{ mx: '3px', my: '5px', display: 'flex' }} >
            <Box onClick={(e) => {
              e.preventDefault()                            
              navigate(`/exam/${element.id}`)
              dispatch(fetchExam(element.id)) 
              console.log(element)
            }}
              sx={{ display: 'flex', textDecoration: 'none', color: 'inherit', cursor: 'pointer' }}>
              {element.logo !== "" ? <ProfileImg src={element.logo} alt='Logo' /> : <PublicIcon sx={{ mr: '3px', width: '45px', height: '45px', bgcolor: 'lightGray', color: 'gray', borderRadius: '5px'  }} />}
              <Box sx={{ m: '3px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                <Typography variant='h6' sx={{ fontSize: '12px', fontWeight: '400', maxWidth: { xs: '150px', sm: '150px', md: '150px', lg: '230px' }, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} > {element.name} </Typography>
                <Typography variant='h6' sx={{ fontSize: '9px', fontWeight: '200' }} > {element.followers.length} Followers </Typography>
              </Box>
            </Box>

            <Box sx={{ flexGrow: 1 }} />
            {/* {!follow ? (<Box sx={{ m: '3px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                        <DoneOutlineIcon sx={{ mr: '5px', cursor: 'pointer', color: 'green' }} />
                                                </Box>) : (
                                                        <Box sx={{ m: '3px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                                <DoneOutlineIcon sx={{ mr: '5px', cursor: 'pointer', color: 'darkGray' }} />
                                                        </Box>
                                                )} */}

            {element.followers.includes(userId) ? (<Box sx={{ m: '3px', display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'relative', }}>
              <DoneOutlineIcon onClick={
                async () => {
                  let followersArray = element.followers;
                  const examId = element.id
                  console.log(followersArray)
                  try {
                    setKeyValue(examId)
                    setLoading(true)
                    const res = await axiosPrivate.put(`/api/org/exam/${examId}/unfollow`)
                    if (res) {
                      setLoading(false)
                      setKeyValue("")
                      // orgArray.pop()
                      const filtered = followersArray.indexOf(userId)
                      // console.log(filtered)
                      if (filtered !== -1) {
                        followersArray.splice(filtered, 1)
                      }
                      console.log(followersArray)
                      // const data = res.data.data        
                    }
                  } catch (err) {
                    setLoading(false)
                    setKeyValue("")
                    console.error(err);
                  }
                }

              } sx={{ mr: '4px', cursor: 'pointer', color: 'orange', }} />
              {(loading && keyValue === element.id) && <CircularProgress
                // key ={element._id}
                size={35}
                sx={{
                  color: 'orange',
                  position: 'absolute',
                  // top: '50%',
                  // left: '50%',
                  // marginTop: '-12px',
                  // marginLeft: '-12px',
                }}
              />
              }
            </Box>) : (
              <Box sx={{ m: '3px', display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'relative', }}>
                <DoneOutlineIcon onClick={
                  async () => {
                    let followersArray = element.followers;
                    const examId = element.id
                    console.log(followersArray)
                    try {
                      setKeyValue(examId)
                      setLoading(true)
                      const res = await axiosPrivate.put(`/api/org/exam/${examId}/follow`)
                      if (res) {
                        setLoading(false)
                        setKeyValue("")
                        followersArray.push(userId)
                        console.log(res)
                        // const data = res.data.data        
                      }
                    } catch (err) {
                      setLoading(false)
                      setKeyValue("")
                      console.error(err);
                    }
                  }
                } sx={{ mr: '4px', cursor: 'pointer', color: 'darkGray' }} />
                {(loading && keyValue === element.id) && <CircularProgress
                  // key ={element._id}
                  size={35}
                  sx={{
                    color: 'orange',
                    position: 'absolute',
                    // top: '50%',
                    // left: '50%',
                    // marginTop: '-12px',
                    // marginLeft: '-12px',
                  }}
                />
                }
              </Box>
            )}
          </Grid>
        </Box>
        )
        }   

            </Grid>

            <Box sx={{ mt: '3px', display: 'flex', flexDirection: 'column', alignItems: 'center', color: 'green', justifyContent: 'center'  }}>

            <Typography variant='h6' color='text.warning' component={Link} to='/praveen/following' sx={{ mx: '3px', mb: '5px', fontSize: '10px', fontWeight: '200', cursor: 'pointer', textDecoration: 'none' }} > Show All</Typography>
                {/* <KeyboardDoubleArrowDownIcon sx={{fontSize: '15px', fontWeight: '200', cursor: 'pointer'}} /> */}
            </Box>

        </Card>
        )
}

export default CompetitiveExamSidebar