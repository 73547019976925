import { Box, Card, CardMedia, Dialog, DialogContent, Typography } from '@mui/material'
import React from 'react'
import { styled as styledMui } from '@mui/material/styles';
import { GoogleLogin } from '@react-oauth/google';
import SecurityIcon from '@mui/icons-material/Security';

const BootstrapDialog = styledMui(Dialog)(({ theme }) => ({

    '& .MuiDialogContent-root': {
        padding: theme.spacing(0),
        // backgroundColor: 'beige',
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(0),       
        // backgroundColor: 'beige',
    },
}));

export default function LoginModal({open, handleClose, handleLogin, handleFailure}) {
  return (
    <BootstrapDialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
        >
            <DialogContent >                      
          <Card sx={{ display: 'flex', flexDirection: 'column', width: '300px', alignItems: 'center', justifyContent: 'center', height: '250px', backgroundColor: '#737d75', boxShadow: 13 }}>
            <CardMedia
            component="img"            
            image="/karpath.png"            
            alt="Karpath"
            sx={{ my: '30px', maxWidth: "150px", height: 'auto' }}
            />


            <GoogleLogin
             
              buttonText="Sign in with Google"              
              onSuccess={handleLogin}              
              onError={handleFailure}
              theme='filled_black'
              
            ></GoogleLogin>
             
            <Typography variant='subtitle2'  sx={{mt: '20px', display:'flex', alignItems: 'center'}}>
                          <SecurityIcon fontSize='small' />&nbsp;  Privacy Protected
                        </Typography>

                        
          </Card>        
            </DialogContent>            

        </BootstrapDialog>
  )
}
