import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Autocomplete from '@mui/material/Autocomplete';
// import places from '../../country-state-district.json'
import { Box } from '@mui/material';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { axiosPrivate } from '../../api/axios';
import { useEffect, useRef, useState } from 'react';

export default function States(props) {
  const { label, name, disableClearable, values, setValues, defaultValue, size, listFontSize, labelFontSize, inputFontSize, disabled  } = props;

  const [states, setStates] = useState([]);
  const [loading, setLoading] = useState(false)
  const [state, setState] = useState(null);
  
  useAxiosPrivate() 
  const refr = useRef() 
  const country = values.country || values.place;
   
  const handleFetchStates = async () => {
    try {
    //   setLoading(true)
      const res = await axiosPrivate.get(`/api/org/places/country/states/fetch?country=${country.id}`)
      if (res) {
        // setLoading(false)
        const data = res.data.data
        setStates(data)
        setState(data?.filter((item) => defaultValue === item.id)) 
      }
    } catch (err) {
      console.error(err);
    }
  }
  console.log(states)

  // useEffect(() => {
  //   handleFetchStates()
  // }, [])
  
  return (
    <Stack sx={{  width: '100%', }}>
      <Autocomplete
        id="places-id"
        ref={refr}
        name={name}
        disabled={disabled}
        disableClearable={disableClearable}
        // value={ state !== null ? state[0] : states }
        loading={loading}
        loadingText="Loading..."
        noOptionsText= "Choose Country First"
        options={states}
        getOptionLabel={(states) => `${states.state}`}
        isOptionEqualToValue={(option, value) => option?.id === value?.id}
        onChange={(event, newValue) => {
          const name = refr.current.getAttribute("name")          
          setValues({
            ...values,
            [name]: newValue,
          })
        }}
        renderOption={(props, states) => (
          <Box component='li' {...props} key={states.id} sx={{bgcolor: 'background.primary', fontSize: `${listFontSize}`}} >           
              {states.state}        
          </Box>
        )}
        renderInput={(params) => (
          <TextField sx={{ display: 'flex', justifyContent: 'spaceBetween' }}
            {...params}
            label={label}
            size={size}
            InputLabelProps={{ style: { fontSize: `${labelFontSize}` } }}
            placeholder="Type to Search"            
            InputProps={{
              ...params.InputProps,
              type: 'search',
              style: { fontSize: `${inputFontSize}` }
            }}
            onClick={handleFetchStates}
          />
        )}
      />
    </Stack>

  );
}