import { Backdrop, Card, CardActions, CircularProgress, FormControl, FormHelperText, Grid, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { axiosPrivate } from '../../api/axios'
import Controls from '../../components/controls/Controls'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import { FormAdmin, UseFormAdmin } from './UseFormAdmin'

const examBoard = [
    { id: 'State Level', title: 'State Level' },
    { id: 'National Level', title: 'National Level' },
    { id: 'Internationally', title: 'International Level' },
]

export const RegisterCompetitiveExamComponent = (props) => {

    useAxiosPrivate()

    const [endpoint, setEndpoint] = useState("")
    const [submitted, setSubmitted] = useState(false)
    const [loadingProgress, setLoadingProgress] = useState(false)

    const initialValues = {
        name: "",
        expansion: "",
        desc: "",
        courses: null,
        board: "National Level",
        place: null,
        sectorId: null,
        eligibilityShort: "",
        eligibility: "",
        regDate: "",
        examDate: "",
        info: "",
    }


    //   const updateUploadedFiles = (files) =>
    //     setUserCerts({ ...userCerts, certs: files });


    const validate = (fieldValues = values) => {
        let temp = { ...errors }
        if ('name' in fieldValues)
            temp.name = fieldValues.name ? "" : "Competitive Exam name required."

        // if ('description' in fieldValues)
        //     temp.description = fieldValues.description ? "" : "Pin/Zip required."

        setErrors({
            ...temp
        })
        // console.log(temp)

        if ((fieldValues = values))
            return Object.values(temp).every(x => x === "")
    }

    const validateRevert = (fieldValues = values) => {
        let temp = { ...errors }
        setErrors({
            ...temp
        })

        if ((fieldValues = values))
            return Object.values(temp).every(x => x === "")
    }

    const {
        values,
        setValues,
        files,
        setFiles,
        errors,
        setErrors,
        optionsData,
        setOptionsData,
        country,
        setCountry,
        state,
        setState,
        loading,
        setLoading,
        handleInputChange,
        resetForm,
    } = UseFormAdmin(initialValues, true, validate,);

    let coursesId = []
    if (values.courses) values.courses.map((item) => coursesId.push(item.id))

    console.log(values)

    const handleFetchCourse = async () => {
        try {
            setLoading(true)
            setEndpoint("course")
            if (endpoint === "" || endpoint !== "course") {
                setOptionsData([])
            }
            const res = await axiosPrivate.get('/api/org/courses/fetch_all')
            if (res) {
                setLoading(false)
                const data = res.data.data
                setOptionsData(data)
            }
        } catch (err) {
            console.error(err);
            setLoading(false)
        }
    }

    const handleFetchExam = async () => {
        try {
            setLoading(true)
            setEndpoint("exam")
            if (endpoint === "" || endpoint !== "exam") {
                setOptionsData([])
            }
            const res = await axiosPrivate.get('/api/org/exam_field/fetch')
            if (res) {
                setLoading(false)
                const data = res.data.data
                setOptionsData(data)
            }
        } catch (err) {
            console.error(err);
            setLoading(false)
        }
    }
    // console.log(courseMultiple)


    const examInfo = {
        name: values.name,
        expansion: values.expansion,
        desc: values.desc,
        board: values.board,
        place: values.place?.id,
        courses: JSON.stringify(coursesId),
        sectorId: values.sectorId?.id,
        eligibility: values.eligibility,
        eligibilityShort: values.eligibilityShort,
        regDate: values.regDate,
        examDate: values.examDate,
        info: values.info,
    }

    console.log(examInfo)

    // const orgId = examInfo.institution;


    const handleClickSubmit = async (e) => {
        e.preventDefault()
        console.log('submit clicked')
        if (validate()) {
            // setSubmitted(true)
            setLoadingProgress(true)
            const formData = new FormData()

            for (let key in examInfo) {
                formData.append(key, examInfo[key])
            }
            console.log('running')

            try {
                const res = await axiosPrivate.post(`/api/user/exam/register`, formData);
                if (!res) return
                if (res.status === 201) {
                    setSubmitted(true)
                    resetForm()
                }
                setLoadingProgress(false)
                // console.log(res)

            } catch (err) {
                console.error(err);
                setLoadingProgress(false)
                setSubmitted(false)
            }
        }
    }


    return (
        <Card sx={{ bgcolor: 'background.paper', width: '100%', height: '86vh', overflowX: 'hidden', overflowY: 'auto' }}>
            <FormAdmin onSubmit={handleClickSubmit} id="course-register-form" >

                <Grid container spacing={5} sx={{ px: '3px', py: '15px', }} >
                    <Grid item md={6} sx={{
                        display: 'flex', flexDirection: 'column',
                        '& .MuiTextField-root': { m: 1, width: '100%' },
                    }} >

                        <Typography variant="h6" component="div" sx={{ px: '7px', py: '3px' }}>
                            Add Designation Details
                        </Typography>

                        <Controls.Input
                            name="name"
                            label="Name in Abbr"
                            value={values.name}
                            onChange={handleInputChange}
                            error={errors.name}
                            required={true}
                        />

                        <Controls.Input
                            name="expansion"
                            label="Competitive Full Name"
                            value={values.expansion}
                            onChange={handleInputChange}
                            error={errors.expansion}
                            required={true}
                        />

                        <Controls.Rgroup
                            name="board"
                            label="Select Board"
                            value={values.board}
                            onChange={handleInputChange}
                            items={examBoard}
                            required={true}
                        // error={errors.homeTown}
                        />

                        {
                           (values.board === "State Level" || values.board === "National Level") &&
                        <Controls.Countries
                            name="place"
                            label="Select Country"
                            values={values}
                            setValues={setValues}
                            // error={errors.jobType}
                            country={country}
                            setCountry={setCountry}
                            required={true}
                        />
                        }


                        {
                            values.board === "State Level" &&
                            <Controls.States
                                name="place"
                                label="Select State"
                                values={values}
                                setValues={setValues}
                                // error={errors.jobType}
                                state={state}
                                setState={setState}
                                // country={values.place}
                                required={true}
                            />
                        }

                        {/* <Controls.AutoComplete
                            name="courses"
                            label="Eligible Courses"
                            //   size='small'
                            // listFontSize='14px'
                            // labelFontSize='12px'
                            // inputFontSize='12px'
                            optionsData={optionsData}
                            setOptionsData={setOptionsData}
                            handleFetch={handleFetchCourse}
                            values={values}
                            setValues={setValues}
                            disableClearable={false}
                            loading={loading}
                            required={false}
                            multiple={true}
                        /> */}

                        <Controls.AutoComplete
                            name="sectorId"
                            label="Field"
                            //   size='small'
                            // listFontSize='14px'
                            // labelFontSize='12px'
                            // inputFontSize='12px'
                            optionsData={optionsData}
                            setOptionsData={setOptionsData}
                            handleFetch={handleFetchExam}
                            values={values}
                            setValues={setValues}
                            disableClearable={false}
                            loading={loading}
                            required={true}
                        />

                        <Controls.Input
                            name="eligibilityShort"
                            label="Eligibility Short Desc"
                            value={values.eligibilityShort}
                            onChange={handleInputChange}
                            error={errors.eligibilityShort}
                        // required={true}
                        />

                        <Controls.Input
                            name="eligibility"
                            label="Eligibility Criteria"
                            value={values.eligibility}
                            onChange={handleInputChange}
                            error={errors.eligibility}
                        // required={true}
                        />

                        <Controls.Input
                            name="regDate"
                            label="Registration Date"
                            value={values.regDate}
                            onChange={handleInputChange}
                            error={errors.regDate}
                        // required={true}
                        />

                        <Controls.Input
                            name="examDate"
                            label="Exam Date"
                            value={values.examDate}
                            onChange={handleInputChange}
                            error={errors.examDate}
                        // required={true}
                        />

                        <Controls.TextareaAutosizeBox
                            name="desc"
                            label="Description *"
                            placeholder="Enter Description"
                            minRows={4}
                            maxRows={7}
                            value={values.desc}
                            onChange={handleInputChange}
                        />

                        <Controls.TextareaAutosizeBox
                            name="info"
                            label="Date & Details"
                            placeholder="Details"
                            minRows={4}
                            maxRows={7}
                            value={values.info}
                            onChange={handleInputChange}
                        />

                        <Grid item gap={5} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mt: '20px' }}>

                            <Box component={Link} to='/admin/dashboard'>
                                <Controls.Btn
                                    // name="close"
                                    text="Close"
                                    color="error"
                                    variant="outlined"
                                //   onClick={handleClose}
                                />
                            </Box>

                            <Controls.Btn
                                type="submit"
                                text="Enter"
                                color="success"
                                variant="outlined"
                                form="course-register-form"
                            //   onClick={clickOpenModal}
                            />

                        </Grid>
                    </Grid>

                    <Grid item md={6} sx={{
                        display: 'flex', flexDirection: 'column',
                        '& .MuiTextField-root': { m: 1, width: '90%' },
                    }} >

                        <Typography variant="subtitile1" component="div" sx={{ px: '7px', py: '10px' }}>
                            Similar Courses
                        </Typography>

                        {/* <Grid item gap={5} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mt: '30px' }}>

              <Box component={Link} to='/admin/dashboard'>
                <Controls.Btn
                  // name="close"
                  text="Close"
                  color="error"
                  variant="outlined"
                //   onClick={handleClose}
                />
              </Box>
              <Controls.Btn
                type="submit"
                text="Enter"
                color="success"
                variant="outlined"
                form="org-register-form"
              //   onClick={clickOpenModal}
              />
            </Grid> */}

                    </Grid>

                </Grid>
                {loadingProgress && (
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={loadingProgress}
                    // onClick={handleClose}
                    >
                        <CircularProgress
                            size={24}
                            sx={{
                                color: 'inherit',
                                // position: 'absolute',
                                top: '50%',
                                left: '50%',
                                marginTop: '-12px',
                                marginLeft: '-12px',
                            }}
                        />
                    </Backdrop>
                )}
            </FormAdmin>
        </Card>
    )
}
