import * as React from 'react';
// import { styled, alpha } from '@mui/material/styles';
import { AppBar, Avatar, Button, Dialog, Divider, ListItemIcon, ListItemText } from '@mui/material';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import HomeRepairServiceOutlinedIcon from '@mui/icons-material/HomeRepairServiceOutlined';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import PostAddOutlinedIcon from '@mui/icons-material/PostAddOutlined';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import PowerSettingsNewOutlinedIcon from '@mui/icons-material/PowerSettingsNewOutlined';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import {io} from 'socket.io-client'
import CreatePost from '../../pages/modals/post/CreatePost';
import { useSelector, useDispatch } from 'react-redux'
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { logout, } from '../../features/userAuth/authSlice';
import styled from 'styled-components';
import { reset } from '../../features/user/userSlice';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { axiosPrivate } from '../../api/axios';
import moment from 'moment';
import FeedOutlinedIcon from '@mui/icons-material/FeedOutlined';
import MailOutlinedIcon from '@mui/icons-material/MailOutlined';
import DomainAddIcon from '@mui/icons-material/DomainAdd';

const ProfileImg = styled.img`
  border-radius: 50%;  
  width: 25px;
  height: 25px;
  object-fit: cover;
`;

export default function NavBar() {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  // useAxiosPrivate()
  const socket = React.useRef()

  const { profile_pic, userId, user, status, userSlug } = useSelector(
    (state) => state.auth)

  const theme = useSelector((state) => state.theme);
  // console.log(theme)
  // let isUnReadArray = [];
  // let notificationsAllRead = false

  // console.log(location)

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [notificationAnchorEl, setNotificationAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [isUnReadArray, setIsUnReadArray] = React.useState([]);
  const [notifications, setNotifications] = React.useState([])
  const [notificationsAllRead, setNotificationsAllRead] = React.useState(false)

  const isMenuOpen = Boolean(anchorEl);
  const isNotificationOpen = Boolean(notificationAnchorEl);

  
  React.useEffect(() => {      
      fetchNotifications()           
  }, [])

  React.useEffect(() => {
    notificationsState()
  }, [notifications])

   
  const fetchNotifications = async () => {
    // e.preventDedault() 
    try {     
      const res = await axiosPrivate.get(`/api/user/notifications/fetch`)
      if (res) {       
        const data = res.data.data
        setNotifications(data)                    
        }
    } catch (err) {
      console.error(err);
    }
  };

  
  const notificationsState = () => {
    const unRead = notifications.filter((item) => !item.isRead)
   
    unRead?.length === 0 ? setNotificationsAllRead(true) : setNotificationsAllRead(false)
   
    const unReadId = unRead?.map((item) => item._id).flat(1) 
    setIsUnReadArray(unReadId)     
  };

  // console.log(notificationsAllRead)
  // console.log(isUnReadArray)
       
  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setNotificationAnchorEl(null);
  };

  const handleNotifMenuItemClose = () => {
    setNotificationAnchorEl(null);
  };

  const handleNotifMenuItemClick = (item) => {
    console.log(item)
    setNotificationAnchorEl(null);    
    if (isUnReadArray.includes(item._id)) {
      updateNotifications(item._id)      
    }
    if (item.postId === undefined) {     
      navigate(`/user/${item.sender.slug}/${item.sender._id}`)
    }
    if (!(item.postId === undefined)) {
    navigate(`/job/${item.postId}`)
    }
  };

  const handleNotificationsOpen = (event) => {
    setNotificationAnchorEl(event.currentTarget);
  };

  const handleClickOpen = () => {
    setOpen(true);

  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    setOpen(false);
  };

  const revertProfileClick = () => {
    console.log('clicked')
    dispatch(reset())
  };

  const handleProfileClick = () => {
    setAnchorEl(null);
    dispatch(reset())
    navigate(`/user/${userSlug}/${userId}`)
  };

  const handleLogout = () => {
    setNotificationAnchorEl(null);
    setAnchorEl(null);
    dispatch(logout())
    // setTimeout(() => {
    //   navigate('/login');  
    //   console.log('logging out')

    // }, 500);        
  }

  const updateNotifications = async (notificationId) => {

    try {
      const res = await axiosPrivate.put(`/api/user/notifications/${notificationId}/update`)
      if (res) {
        const filtered = isUnReadArray.indexOf(notificationId)
        let arr = isUnReadArray
        // console.log(filtered)
        if (filtered !== -1) {
          arr.splice(filtered, 1)
        }
        setIsUnReadArray(arr)
        console.log(res)
      }
    } catch (err) {
      console.error(err);
    }
  };

  const updateAllNotifications = async () => {
    if (!notificationsAllRead) {
      try {     
        const res = await axiosPrivate.put(`/api/user/notifications/fetch/update_all`)
        if (res) {
          setNotificationsAllRead(true)
          setIsUnReadArray([])
          console.log(res)                    
          }
      } catch (err) {
        console.error(err);
      }
    }   
  };
   
  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleProfileClick}  >
        <ListItemIcon>
          <PersonOutlinedIcon fontSize='small' color='inherit' />
        </ListItemIcon>
        <ListItemText> Profile</ListItemText>
      </MenuItem>
     
      <Divider />      
      <MenuItem disabled onClick={handleMenuClose} component={Link} to="/help" >
        <ListItemIcon>
          <HelpOutlineIcon fontSize='small' color='inherit' />
        </ListItemIcon>
        <ListItemText> Help</ListItemText>
      </MenuItem>
      <MenuItem onClick={handleMenuClose} component={Link} to="/myaccount" >
        <ListItemIcon>
          <SettingsOutlinedIcon fontSize='small' color='inherit' />
        </ListItemIcon>
        <ListItemText>Account Settings</ListItemText>
      </MenuItem>
      <MenuItem onClick={handleMenuClose} component={Link} to="/org/create" >
        <ListItemIcon>
          <DomainAddIcon fontSize='small' color='inherit' />
        </ListItemIcon>
        <ListItemText>Add Organization</ListItemText>
      </MenuItem>
      <MenuItem onClick={handleLogout}>
        <ListItemIcon>
          <PowerSettingsNewOutlinedIcon fontSize='small' color='inherit' />
        </ListItemIcon>
        <ListItemText> Sign Out</ListItemText>
      </MenuItem>
    </Menu>
  );

  const notificationsDropdownId = 'notifications-dropdown';
  const renderNotifications = (
    <Menu
      anchorEl={notificationAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={notificationsDropdownId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isNotificationOpen}
      onClose={handleNotifMenuItemClose}
    >
      {/* <Box sx={{ position: 'fixed', zIndex: '999', bgcolor: 'background.main', }}> */}
      <Box sx={{ display: 'flex',width: '320px', px: '10px', pb: '3px',  }} >
        <Typography onClick={updateAllNotifications} sx={{ fontSize: '13px', fontWeight: '400', cursor: 'pointer'  }} >Mark All as Read</Typography>
        <Box sx={{ flexGrow: 1 }} />
        <Typography onClick={handleNotifMenuItemClose} sx={{ fontSize: '13px', fontWeight: '400', cursor: 'pointer' }} >Close</Typography>
      </Box>
      <Divider />
      {/* </Box> */}
      {/* <Divider sx={{mt: '25px',}} /> */}
      
      { notifications.length === 0 ? 
      <Typography variant='body2' 
      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}}>No Notifications</Typography> 
      : 
      notifications.map((item) => <MenuItem divider key={item._id} 
      onClick={()=>handleNotifMenuItemClick(item)}  
      sx={{ backgroundColor: `${isUnReadArray?.includes(item._id) ? 'background.primary' : 'background.secondary'}` }} >
      {/* <ListItemIcon >
        <Avatar fontSize='small' color='inherit' sx={{ width: '30px', height: '30px', fontSize: '12px' }} />
      </ListItemIcon> */}
      <ListItemText> <Typography variant='body2' sx={{ fontSize: '11px', fontWeight: 300, maxWidth: '300px', overflow: 'hidden', whiteSpace: 'nowrap',}} >{item.body}</Typography></ListItemText>
      <Typography variant='body2' sx={{ fontSize: '9px' }} >  - {moment(item.createdAt).fromNow()}.</Typography>
    </MenuItem> )         
       } 
       </Menu>
  );

  return (   
        <>
          <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static" color='primary' sx={{ zIndex: 10}} >
              <Toolbar>
                {/* <IconButton component={Link} to='/'
              size="large"
              edge="start"
              color="inherit"
              aria-label="open drawer"
              sx={{ mr: 2 }}              
            > */}
                <Box component={Link} to='/' onClick={revertProfileClick} sx={{ cursor: 'pointer' }}>
                  <img src='/logo.png' alt='Karpath' width={'33px'} height={'auto'} />
                </Box>
                {/* <MenuIcon /> */}
                {/* </IconButton> */}

                <Box sx={{ flexGrow: 1 }} />
                <Box sx={{ display: { xs: 'none', sm: 'flex' } }}>
                  {/* <Tabs  >
                <Tab icon={<HomeOutlinedIcon />} label="Home" iconPosition="start" component={Link} to="/" />
                <Tab  icon={<HomeRepairServiceOutlinedIcon />} label="Job" iconPosition="start" component={Link} to="/job" />
                <Tab  icon={<SchoolOutlinedIcon />} label="Career" iconPosition="start" component={Link} to="/career" />
               
              </Tabs> */}
                  <Button component={Link} to="/" onClick={revertProfileClick} sx={{ mx: '4px', my: '7px', }} startIcon={<HomeRepairServiceOutlinedIcon />}> Job
                  </Button>
                  <Button component={Link} to="/feed" onClick={revertProfileClick} sx={{ mx: '4px', my: '7px', }} startIcon={<FeedOutlinedIcon />}> Feed
                  </Button>
                  <Button component={Link} to="/career" onClick={revertProfileClick} sx={{ mx: '4px', my: '7px', }} startIcon={<SchoolOutlinedIcon />}> Career
                  </Button>

                </Box>



                <Box sx={{ flexGrow: 1 }} />
                <Box sx={{ display: { xs: 'flex' } }}>
                  <IconButton
                    size="large"
                    edge="end"
                    color="inherit"
                    component={Link}
                    to="/search"
                  >
                    <SearchIcon />
                  </IconButton>
                  </Box>

                  {/* <Box sx={{ display: { xs: 'none', sm: 'flex' } }}> */}
                  <Box sx={{ display: 'flex' }}>
                  <IconButton
                    size="large"
                    aria-label="show 17 new notifications"
                    color="inherit"
                    aria-controls={notificationsDropdownId}
                    aria-haspopup="true"
                    onClick={handleNotificationsOpen}
                  >
                    <Badge badgeContent={isUnReadArray.length === 0 ? null : isUnReadArray.length} color="error">
                      <NotificationsOutlinedIcon />
                    </Badge>
                  </IconButton>
                  </Box>
                

                {/* <Box sx={{ display: { xs: 'none', sm: 'flex' } }}>
                  <IconButton size="large" aria-label="show 4 new mails" color="inherit">
                    <Badge badgeContent={4} color="error">
                      <MessageOutlinedIcon />
                    </Badge>
                  </IconButton>
                </Box> */}

                <Box sx={{ display: { xs: 'flex' } }}>
                  <IconButton
                    size="large"
                    edge="end"
                    aria-label="account of current user"
                    aria-controls={menuId}
                    aria-haspopup="true"
                    onClick={handleProfileMenuOpen}
                    color="inherit"
                  >
                    {profile_pic ? <ProfileImg src={profile_pic} alt='profile_picture' /> : <AccountCircleOutlinedIcon />}
                  </IconButton>
                </Box>
              </Toolbar>
            </AppBar>
            {/* {renderMobileMenu} */}
            {renderMenu}
            {renderNotifications}
          </Box>

          <Box sx={{ flexGrow: 1 }}>
            <AppBar position="fixed" color="inherit" sx={{ top: 'auto', bottom: 0, display: { xs: 'flex', sm: 'none' } }}>
              <Toolbar >

                {
                  location.pathname === '/' ? 
                  <IconButton size="large" edge="start" aria-label="show 4 new mails" color="inherit" component={Link} to="/" onClick={revertProfileClick} sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}} >
              <HomeOutlinedIcon color='warning' />
              <Typography color='text.warning' variant='h5' sx={{variant: 'h5', fontSize: '11px', fontWeight: '400'}}>
                    Home
                  </Typography>
            </IconButton>
            :
            <IconButton size="large" edge="start" aria-label="show 4 new mails" color="inherit" component={Link} to="/" onClick={revertProfileClick} sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}} >
              <HomeOutlinedIcon />
              <Typography variant='h5' sx={{variant: 'h5', fontSize: '11px', fontWeight: '400'}}>
                    Home
                  </Typography>
            </IconButton>
                }                   

                <Box sx={{ flexGrow: 1 }} />

                {
                  location.pathname === '/feed' ?
                  <IconButton size="large" edge="start" aria-label="show 4 new mails" color="inherit" component={Link} to="/feed" onClick={revertProfileClick} sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}} >
                  <FeedOutlinedIcon color='warning' />
                  <Typography color='text.warning' variant='h5' sx={{variant: 'h5', fontSize: '11px', fontWeight: '400'}}>
                    Updates
                  </Typography>
                </IconButton>
                :
                <IconButton size="large" edge="start" aria-label="show 4 new mails" color="inherit" component={Link} to="/feed" onClick={revertProfileClick} sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}} >
                  <FeedOutlinedIcon />
                  <Typography variant='h5' sx={{variant: 'h5', fontSize: '11px', fontWeight: '400'}}>
                    Updates
                  </Typography>
                </IconButton>
                }
               
                <Box sx={{ flexGrow: 1 }} />

                {
                  location.pathname === '/career' ?
                  <IconButton
                  size="small"
                  aria-label="show 17 new notifications"
                  color="inherit"                  
                  component={Link} to="/career" onClick={revertProfileClick}
                  sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}
                >
                  <SchoolOutlinedIcon color='warning'  />
                  <Typography color='text.warning' variant='h5' sx={{variant: 'h5', fontSize: '11px', fontWeight: '400'}}>
                    Career
                  </Typography>
                </IconButton>
                :
                <IconButton
                  size="small"
                  aria-label="show 17 new notifications"
                  color="inherit"                  
                  component={Link} to="/career" onClick={revertProfileClick}
                  sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}
                >
                  <SchoolOutlinedIcon  />
                  <Typography variant='h5' sx={{variant: 'h5', fontSize: '11px', fontWeight: '400'}}>
                    Career
                  </Typography>
                </IconButton>
                }
                

                <Box sx={{ flexGrow: 1 }} />

                {
                  location.pathname === '/user/inbox' ?
                  <IconButton              
                  size="large"
                  sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}
                  color="inherit"
                  component={Link} to="/user/inbox" onClick={revertProfileClick}
                >
                  <MailOutlinedIcon color='warning' />
                  <Typography color='text.warning' variant='h5' sx={{variant: 'h5', fontSize: '11px', fontWeight: '400'}}>
                    Inbox
                  </Typography>
                </IconButton>
                :
                <IconButton              
                  size="large"
                  sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}
                  color="inherit"
                  component={Link} to="/user/inbox" onClick={revertProfileClick}
                >
                  <MailOutlinedIcon />
                  <Typography variant='h5' sx={{variant: 'h5', fontSize: '11px', fontWeight: '400'}}>
                    Inbox
                  </Typography>
                </IconButton>
                }
              
                
              </Toolbar>
            </AppBar>
          </Box>
        </>      
  );
}
