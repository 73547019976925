import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import userService from './userService'

// Get user from localStorage
const user = JSON.parse(localStorage.getItem('userSuggest'))

const initialState = {
//   status: user ? user.status : null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
//   user: user ? user : null,
  user_id: user ? user._id : null,
  firstName: user ? user.firstName : null,
  lastName: user ? user.lastName : null,
  // email: user ? user.email : null,
  profile_pic: user ? user.profile_pic : null,
  designation: user ? user.designation : null,
  course: user ? user.course : null,
  org: user ? user.org : null, 
  orgId: user ? user.orgId : null,
  isVerified: user ? user.isVerified : false,
  isPublic: user ? user.isPublic : false 
  
}

// userFetch user
export const userFetch = createAsyncThunk('user/userFetch', async (user, thunkAPI) => {
  try {
    return await userService.userFetch(user)
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false
      state.isSuccess = false
      state.isError = false
      state.message = ''
      state.user_id = null  
      state.firstName = ''
      state.lastName = ''
      state.designation = ''
      state.org = ''
      state.orgId = ''
      state.course = ''
      state.profile_pic = ''
      state.isVerified = null
      state.isPublic = null
      
    },
    // update: (state, action) => {
    //   state.isLoading = false
    //   state.isSuccess = false
    //   state.isError = false
    //   state.message = ''
    //   state.firstName = ''
    //   state.email = ''
    //   state.status = ''
    //   state.profile_pic = action.payload
    // },      
  },
  extraReducers: (builder) => {
    builder      
      .addCase(userFetch.pending, (state) => {
        state.isLoading = true
      })
      .addCase(userFetch.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.status = action.payload.status 
        state.user_id = action.payload.user_id       
        state.firstName = action.payload.firstName
        state.lastName = action.payload.lastName
        state.email = action.payload.email
        state.profile_pic = action.payload.profile_pic
        state.designation = action.payload.designation
        state.course = action.payload.course
        state.org = action.payload.org
        state.orgId = action.payload.orgId
        state.isVerified = action.payload.isVerified
        state.isPublic = action.payload.isPublic
       
        // state.user = action.payload
      })
      .addCase(userFetch.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
        // state.user = null
      })      
  },
})

export const { reset } = userSlice.actions
export default userSlice.reducer