import React, { useEffect, useState } from 'react'

export function UseFormAdmin(initialValues, validateOnChange = false, validate, validateRevert, selectedRow, initialItemState ) {
  
    const [values, setValues] = useState(initialValues);
    const [errors, setErrors] = useState({});
    const [files, setFiles] = useState({});  
    // const [address, setAddress] = useState("");
    // const [branchAddress, setBranchAddress] = useState("");
    // const [sector, setSector] = useState("");

    const [optionsData, setOptionsData] = React.useState([]);
    const [country, setCountry] = React.useState("");
    const [state, setState] = React.useState("");
    const [loading, setLoading] = useState(false)
    const [checkedItems, setCheckedItems] = React.useState([]);
           

    const handleInputChange = e => {        
        const { name, value } = e.target
        setValues({
            ...values,
            [name]: value,
        }) 
        
        if (validateOnChange) {
            validate({ [name]: value})
            // validateRevert({ [name]: value})  //Errors notifications will work only if turned off           
            console.log(value)    
        }             
    }  
    
    const handleChkboxItems = (event) => {               
        let updatedList = [...checkedItems];   
        if (event.target.checked) {
          updatedList = [...checkedItems, event.target.value];
        } else {
          updatedList.splice(checkedItems.indexOf(event.target.value), 1);
        }
        setCheckedItems(updatedList);        
    }       

    useEffect(() => {
      setValues(initialValues)     
    }, [selectedRow])

    const resetForm = () => {
        setValues(initialValues)             
        setErrors({})       
    }
    
    // console.log(values)  
    // console.log(initialValues) 
    // console.log(initialValues.homeTown)   
   
    return {
        values,
        setValues,
        errors,
        setErrors,
        files,
        setFiles,

        optionsData,
        setOptionsData,
        country,
        setCountry,
        state,
        setState,
        loading,
        setLoading,
        resetForm,
        checkedItems,
        setCheckedItems,        
        handleInputChange, 
        handleChkboxItems,            
    }
}


export function FormAdmin(props) {
    
    const { children, ...other } = props;
    return (
        <form style={{ width: '100%',}} autoComplete="off" {...other}>
        {props.children}
    </form>
    )
}
