import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Controls from '../../components/controls/Controls';
// import { Form, UseForm } from '../../components/UseForm';
import { Backdrop, Box, CardMedia, CircularProgress, FormHelperText, Grid, IconButton, Link, Typography } from '@mui/material';
import { FormAdmin, UseFormAdmin } from '../components/UseFormAdmin';
import ImageSlider from '../components/ImageSlider';
import CloseIcon from '@mui/icons-material/Close';

import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import VerifiedModal from './alertModal/VerifiedModal';
import RevertModal from './alertModal/RevertModal';
import RefuteModal from './alertModal/RefuteModal';
import { axiosPrivate } from '../../api/axios';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import AcceptModal from './alertModal/AcceptModal';
import RejectModal from './alertModal/RejectModal';
import { useEffect } from 'react';
import { useState } from 'react';
// import { Link } from 'react-router-dom';

// const ITEM_HEIGHT = 48;
// const ITEM_PADDING_TOP = 8;
// const MenuProps = {
//   PaperProps: {
//     style: {
//       maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
//       // width: 250,
//     },
//   },
// };

// const message = [
//   'Please upload your 10th/SSLC certificate.',
//   'Please upload your Aadhar.',
//   'Please upload your National ID card.',
//   'Please upload your 10th/SSLC certificate & Aadhar.',
//   'Please upload your 10th/SSLC certificate & National ID card.',
//   'Your files are not clearly visible. Please upoad again.',
// ];

const options = [
  { id: "migrated", name: "Migrated" },
  { id: "completed", name: "Completed" },
  { id: "dropout", name: "Dropped Out" },
];

const genderItems = [
  { id: 'male', title: 'Male' },
  { id: 'female', title: 'Female' },
  { id: 'other', title: 'Other' },
]

// function getStyles(message, levelName, theme) {
//   return {
//     fontWeight:
//       levelName.indexOf(message) === -1
//         ? theme.typography.fontWeightRegular
//         : theme.typography.fontWeightMedium,
//   };
// }

export default function MemberRequestModal(props) {

  const [loadingCerts, setLoadingCerts] = useState(false)
  const [securedURL, setSecuredURL] = useState()

  const {
    handleFetch,
    open,
    setOpen,
    handleClose,
    selectedRow,

    values,
    setValues,
    files,
    setFiles,
    optionsData,
    setOptionsData,
    designation,
    course,
    handleFetchCourse,
    handleFetchDesignation,
    selectDesignation,
    setSelectDesignation,
    selectCourse,
    setSelectCourse,
    loading,
    setLoading,
    // reasonMessage,
    // setReasonMessage,
    openModal,
    setOpenModal,
    openRevertModal,
    setOpenRevertModal,
    openRefuteModal,
    setOpenRefuteModal,
    handleNestedClose,
    errors,
    setErrors,
    validate,
    validateRevert,
    handleInputChange,
  } = props

  console.log(values)

  useAxiosPrivate()
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));


  const clickOpenModal = async (e) => {
    e.preventDefault()
    setOpenModal(true)
  }

  const clickOpenRefuteModal = async (e) => {
    e.preventDefault()
    setOpenRefuteModal(true)
  }
  const requestId = selectedRow._id
  const level = values.level

  const handleClickAccept = async (e) => {
    e.preventDefault()
    try {
      console.log('running submission')
      setLoading(true)
      const res = await axiosPrivate.put(`/api/org/member_request/${selectedRow._id}/accept`, {values});
      if (!res) return
      if (res.data) {
        handleFetch()
        setOpenModal(false)
        setLoading(false)
        setOpen(false)
        console.log(res.data)
      }
    } catch (err) {
      console.error(err);
    }
  }

  const handleClickReject = async (e) => {
    e.preventDefault()
    try {
      console.log('running reject')
      setLoading(true)
      const res = await axiosPrivate.get(`/api/org/member_request/${selectedRow._id}/decline`);
      if (!res) return
      if (res.data) {
        handleFetch()
        setOpenModal(false)
        setLoading(false)
        setOpen(false)
        console.log(res.data)
      }

    } catch (err) {
      console.error(err);
    }
  }

  const handleFetchCerts = async () => {
    // e.preventDefault()
    try {      
      setLoadingCerts(true)
      const res = await axiosPrivate.get(`/api/org/member_request/${selectedRow._id}/fetch_certs`);
      if (!res) return
      if (res.status === 200) {
        setLoadingCerts(false)
        setSecuredURL(res.data.data.url)        
        // console.log(res.data.data.url)
      }
    } catch (err) {
      console.error(err);
      setLoadingCerts(false)
    }
  }

  useEffect(() => {
    setSecuredURL()
    handleFetchCerts()
  }, [selectedRow._id])
  

  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      // sx={{zIndex: 1700}}
      // sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: 1080, height: { xs: 'auto', md: '100vh' } }}
      >
        <DialogTitle id="responsive-dialog-title" sx={{ bgcolor: 'background.paper', display: 'flex' }}>
          {`Member Request from ${selectedRow.firstName} ${selectedRow.lastName}`}
          <Box sx={{ flexGrow: 1 }} />
          <IconButton onClick={handleClose} >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ bgcolor: 'background.default', pt: "70px" }} >

          <Grid container spacing={2} sx={{ px: '3px', py: '15px' }} >
            <Grid item md={4} sx={{
              display: 'flex', flexDirection: 'column',
              '& .MuiTextField-root': { m: 1, width: '90%' },
            }} >
              {/* <Typography variant="subtitle1" component="div" sx={{ display: 'flex', alignItems: 'center', p: 0.5 }}>
                Name :<Typography variant="body2" component="div" sx={{ pl: 0.5 }}>{selectedRow?.firstName} {selectedRow?.lastName}</Typography>
              </Typography> */}

              <Controls.Input
                name="firstName"
                label="First Name"
                value={values.firstName}
                onChange={handleInputChange}
                error={errors.firstName}
                required={true}
              />
              <Controls.Input
                name="lastName"
                label="Last Name"
                value={values.lastName}
                onChange={handleInputChange}
                error={errors.lastName}
                required={true}
              />

              <Controls.DPicker
                name="dateOfBirth"
                label="Date Of Birth *"
                value={values.dateOfBirth}
                onChange={handleInputChange}
                error={errors.dateOfBirth}
              />

              <Controls.Rgroup
                name="gender"
                label="Gender *"
                value={values.gender}
                onChange={handleInputChange}
                size="small"
                fontSize="13px"
                items={genderItems}
                error={errors.gender}
              />


              <Typography variant="subtitle1" component="div" sx={{ display: 'flex', alignItems: 'center', p: 0.5 }}>
                Org :<Typography variant="body2" component="div" sx={{ pl: 0.5 }}>{selectedRow?.orgName}</Typography>
              </Typography>

              {selectedRow?.designationName && <Typography variant="subtitle1" component="div" sx={{ display: 'flex', alignItems: 'center', p: 0.5 }}>
                Designation :<Typography variant="body2" component="div" sx={{ pl: 0.5 }}>{selectedRow?.designationName}</Typography>
              </Typography>}

              {selectedRow?.courseName && <Typography variant="subtitle1" component="div" sx={{ display: 'flex', alignItems: 'center', p: 0.5 }}>
                Course :<Typography variant="body2" component="div" sx={{ pl: 0.5 }}>{selectedRow?.courseName}</Typography>
              </Typography>}

              {
                selectedRow?.designationName &&
              <Typography variant="subtitle1" component="div" sx={{ display: 'flex', alignItems: 'center', p: 0.5 }}>
                Level :<Typography variant="body2" component="div" sx={{ pl: 0.5 }}>{selectedRow?.submittedLevel ? selectedRow?.submittedLevel : selectedRow?.level}</Typography>
              </Typography>
              }

{
                (selectedRow?.courseName && !selectedRow?.submittedLevel) &&
              <Typography variant="subtitle1" component="div" sx={{ display: 'flex', alignItems: 'center', p: 0.5 }}>
                Level :<Typography variant="body2" component="div" sx={{ pl: 0.5 }}>{selectedRow?.level}</Typography>
              </Typography>
              }

              {
                (selectedRow?.courseName && selectedRow?.submittedLevel) && 
                <Controls.Select
                name="level"
                label="Current Status"
                  size='small'
                listFontSize='14px'
                labelFontSize='12px'
                inputFontSize='12px'
                // error={error}
                required={true}
                options={options}
                value={values.level}
                onChange={handleInputChange}
                loading={loading}
              />
               } 

              <Controls.DPicker
                name="joinedDate"
                label="Joined Date *"
                value={values.joinedDate}
                onChange={handleInputChange}
                error={errors.joinedDate}
              />

              {(selectedRow?.level?.includes("worked") ||
                selectedRow?.level?.includes("migrated") ||
                selectedRow?.level?.includes("completed") ||
                selectedRow?.level?.includes("dropout") ||
                selectedRow?.submittedLevel?.includes("worked") ||
                selectedRow?.submittedLevel?.includes("migrated") ||
                selectedRow?.submittedLevel?.includes("completed") ||
                selectedRow?.submittedLevel?.includes("dropout")) &&
                <Controls.DPicker
                  name="leftDate"
                  label="Left Date *"
                  value={values.leftDate}
                  onChange={handleInputChange}
                  error={errors.leftDate}
                />
              }
            </Grid>

            <Grid item md={8} sx={{ display: 'flex', justifyContent: 'center', p: '10px' }} >
              <ImageSlider certs={selectedRow.certs} securedURL={securedURL} />
            </Grid>
          </Grid>

        </DialogContent>
        <DialogActions sx={{ bgcolor: '#darkslateGray' }}>
          <Controls.Btn
            // name="close"
            text="Close"
            color="error"
            variant="outlined"
            onClick={handleClose}
          />
          <Controls.Btn
            // type="submit"
            // name="refute"
            text="Reject"
            color="error"
            variant="outlined"
            onClick={clickOpenRefuteModal}
          />

          <Controls.Btn
            // type="submit"
            // name="verified"
            text="Accept"
            color="success"
            variant="outlined"
            onClick={clickOpenModal}
          />

          <AcceptModal openModal={openModal} handleClickAccept={handleClickAccept} handleNestedClose={handleNestedClose} loading={loading} />
          <RejectModal openRefuteModal={openRefuteModal} handleClickReject={handleClickReject} handleNestedClose={handleNestedClose} loading={loading} />

        </DialogActions>
      </Dialog>
    </div>
  );

}
