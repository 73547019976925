import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import AddAPhotoOutlinedIcon from '@mui/icons-material/AddAPhotoOutlined';
import { Dialog, DialogActions, DialogContent } from '@mui/material';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 350,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function UploadPicModal({ open, handleClose, handleSubmit, previewSource }) {
  
  return (
    
    <Dialog
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition           
      >
         <DialogContent sx={{ bgcolor: 'background.main', minWidth: '300px', height: '65vh', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', zIndex: 1600, overflowX: 'hidden', }}>
          {/* <Box sx={style}> */}
            <img
              src={previewSource}
              alt="chosen"
              style={{ width: 'auto', height: '50vh', objectFit: 'contain' }}
            />
            {/* <DialogActions> */}
            <Box sx={{ display: 'flex', mt: '5px' }}>
              <Button color="warning" variant="outlined" onClick={handleClose} sx={{ m: '5px', textTransform: 'none', fontSize: '11px' }} >  Cancel
              </Button>
              <Button color="warning" variant="outlined" onClick={handleSubmit} sx={{ m: '5px', textTransform: 'none', fontSize: '11px' }}>  Upload
              </Button>
            </Box>
            {/* </DialogActions> */}

          {/* </Box> */}
        </DialogContent>
      </Dialog>
    
  );
}
