import * as React from 'react';
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import DraftsIcon from '@mui/icons-material/Drafts';
import SendIcon from '@mui/icons-material/Send';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StarBorder from '@mui/icons-material/StarBorder';
import { Divider } from '@mui/material';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import SupervisorAccountOutlinedIcon from '@mui/icons-material/SupervisorAccountOutlined';
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import { Box } from '@mui/system';
import EngineeringOutlinedIcon from '@mui/icons-material/EngineeringOutlined';
import CorporateFareOutlinedIcon from '@mui/icons-material/CorporateFareOutlined';
import RoomPreferencesOutlinedIcon from '@mui/icons-material/RoomPreferencesOutlined';
import { Link } from 'react-router-dom';

export default function SidePanelList() {
  const [openUser, setOpenUser] = React.useState(true);
  const [openEnquiry, setOpenEnquiry] = React.useState(true)
  const [openOrg, setOpenOrg] = React.useState(false);
  const [openCourse, setOpenCourse] = React.useState(false);
  const [openDesignation, setOpenDesignation] = React.useState(false);
  const [openCompetitiveExam, setOpenCompetitiveExam] = React.useState(false);

  const handleUserClick = () => {
    setOpenUser(!openUser);
  };

  const handleOrgClick = () => {
    setOpenOrg(!openOrg);
  };

  const handleCourseClick = () => {
    setOpenCourse(!openCourse);
  };

  const handleDesignationClick = () => {
    setOpenDesignation(!openDesignation);
  };

  const handleCompetitveExamClick = () => {
    setOpenCompetitiveExam(!openCompetitiveExam);
  };

  return (
    <Box sx={{display: 'flex', flexDirection: 'column', height: '100%'}} >
    <Divider component="li" sx={{borderColor: 'white' }} />
    <List
      sx={{ 
        '& .MuiListItemText-primary': {
          fontSize: '13px',
          fontWeight: 500,
          },
        width: '100%', maxWidth: 230 }}
      component="nav"
      aria-labelledby="nested-list-subheader"       
    
    >         
      <ListItemButton component={Link} to="/admin/dashboard">
        <ListItemIcon>
          <DashboardOutlinedIcon sx={{color:'white'}} />
        </ListItemIcon>
        <ListItemText primary="Dashboard" />
      </ListItemButton>

      <ListItemButton onClick={handleUserClick}>
        <ListItemIcon>
          <SupervisorAccountOutlinedIcon sx={{color:'white'}} />
        </ListItemIcon>
        <ListItemText primary="User Info" />
        {openUser ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={openUser} timeout="auto" unmountOnExit>
        <List component="div" disablePadding >
          <ListItemButton component={Link} to="/admin/user-request" sx={{ pl: 4 }}>
            <ListItemIcon>
              <GroupAddOutlinedIcon sx={{color:'white'}} />
            </ListItemIcon>
            <ListItemText primary="User Requests"  />
          </ListItemButton>
        </List>
      </Collapse>
      <Collapse in={openUser} timeout="auto" unmountOnExit>
        <List component="div" disablePadding >
          <ListItemButton component={Link} to="/admin/member-request" sx={{ pl: 4 }}>
            <ListItemIcon>
              <EngineeringOutlinedIcon sx={{color:'white'}} />
            </ListItemIcon>
            <ListItemText primary="Member Requests" />
          </ListItemButton>
        </List>
      </Collapse>
      <Collapse in={openEnquiry} timeout="auto" unmountOnExit>
        <List component="div" disablePadding >
          <ListItemButton component={Link} to="/admin/admission-enquiry" sx={{ pl: 4 }}>
            <ListItemIcon>
              <EngineeringOutlinedIcon sx={{color:'white'}} />
            </ListItemIcon>
            <ListItemText primary="Admission Enquiry" />
          </ListItemButton>
        </List>
      </Collapse>

      <ListItemButton onClick={handleOrgClick}>
        <ListItemIcon>
          <CorporateFareOutlinedIcon sx={{color:'white'}} />
        </ListItemIcon>
        <ListItemText primary="Org Info" />
        {openOrg ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={openOrg} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
        <ListItemButton component={Link} to="/admin/register-org" sx={{ pl: 4 }}>
            <ListItemIcon>
              <RoomPreferencesOutlinedIcon sx={{color:'white'}} />
            </ListItemIcon>
            <ListItemText primary="Register Orgs" />
          </ListItemButton>
          <ListItemButton component={Link} to="/admin/org-list" sx={{ pl: 4 }}>
            <ListItemIcon>
              <RoomPreferencesOutlinedIcon sx={{color:'white'}} />
            </ListItemIcon>
            <ListItemText primary="Manage Orgs" />
          </ListItemButton>          
          <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon>
              <RoomPreferencesOutlinedIcon sx={{color:'white'}} />
            </ListItemIcon>
            <ListItemText primary="Org Requests" />
          </ListItemButton>
        </List>
      </Collapse>

      <ListItemButton onClick={handleCourseClick}>
        <ListItemIcon>
          <CorporateFareOutlinedIcon sx={{color:'white'}} />
        </ListItemIcon>
        <ListItemText primary="Course" />
        {openCourse ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={openCourse} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
         <ListItemButton component={Link} to="/admin/register-course" sx={{ pl: 4 }}>
            <ListItemIcon>
              <RoomPreferencesOutlinedIcon sx={{color:'white'}} />
            </ListItemIcon>
            <ListItemText primary="Register Course" />
          </ListItemButton>
          <ListItemButton component={Link} to="/admin/course-list" sx={{ pl: 4 }}>
            <ListItemIcon>
              <RoomPreferencesOutlinedIcon sx={{color:'white'}} />
            </ListItemIcon>
            <ListItemText primary="Manage Course" />
          </ListItemButton>          
        </List>
      </Collapse>

      <ListItemButton onClick={handleDesignationClick}>
        <ListItemIcon>
          <CorporateFareOutlinedIcon sx={{color:'white'}} />
        </ListItemIcon>
        <ListItemText primary="Designation" />
        {openDesignation ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={openDesignation} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemButton component={Link} to="/admin/register-designation" sx={{ pl: 4 }}>
            <ListItemIcon>
              <RoomPreferencesOutlinedIcon sx={{color:'white'}} />
            </ListItemIcon>
            <ListItemText primary="Register Designation" />
          </ListItemButton>
          <ListItemButton component={Link} to="/admin/designation-list" sx={{ pl: 4 }}>
            <ListItemIcon>
              <RoomPreferencesOutlinedIcon sx={{color:'white'}} />
            </ListItemIcon>
            <ListItemText primary="Manage Designation" />
          </ListItemButton>          
        </List>
      </Collapse>

      <ListItemButton onClick={handleCompetitveExamClick}>
        <ListItemIcon>
          <CorporateFareOutlinedIcon sx={{color:'white'}} />
        </ListItemIcon>
        <ListItemText primary="Competitive Exam" />
        {openCompetitiveExam ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={openCompetitiveExam} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemButton component={Link} to="/admin/register-competitive-exam" sx={{ pl: 4 }}>
            <ListItemIcon>
              <RoomPreferencesOutlinedIcon sx={{color:'white'}} />
            </ListItemIcon>
            <ListItemText primary="Register Exam" />
          </ListItemButton>
          <ListItemButton component={Link} to="/admin/competitive-exam-list" sx={{ pl: 4 }}>
            <ListItemIcon>
              <RoomPreferencesOutlinedIcon sx={{color:'white'}} />
            </ListItemIcon>
            <ListItemText primary="Manage Exam" />
          </ListItemButton>          
        </List>
      </Collapse>

    </List>

    <Box sx={{ display:'flex', height: '100%', alignItems: 'end', justifyContent: 'center'}}>
      Account Name
    </Box>
   
    </Box>
  );
}
