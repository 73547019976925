import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Autocomplete from '@mui/material/Autocomplete';
// import places from '../../country-state-district.json'
import { Box } from '@mui/material';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { axiosPrivate } from '../../api/axios';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

export default function PlacesAddBranches(props) {
  const { label, name, values, setValues, size, listFontSize, labelFontSize, inputFontSize, multiple, disableClearable, required  } = props;
  
  useAxiosPrivate()
  const refr = useRef()
 
  const [placesData, setPlacesData] = useState([])  
  const [loading, setLoading] = useState(false)   

  const countries = placesData.map((item) => item.states)
  const city = countries.map((item) => item.map((element) => element.districts)).flat(2)
    
  const handleFetch = async () => {
    try {
      setLoading(true)
      const res = await axiosPrivate.get('/api/org/places/fetch')
      if (res) {
        setLoading(false)
        const data = res.data.data.countries        
        setPlacesData(data)        
      }
    } catch (err) {
      console.error(err);
    }
  } 
  
  return (
    <Stack sx={{ width: '100%', }}>
      {/* ml: '-17px', */}
      <Autocomplete
        id="places-id"
        ref={refr}
        name={name}
        multiple={multiple}
        filterSelectedOptions
        disableClearable={disableClearable}
        loading={loading}
        loadingText="Loading..."
        noOptionsText= "No Options"
        options={city}
        getOptionLabel={(city) => `${city.district}`}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        onChange={(event, newValue) => {
          const name = refr.current.getAttribute("name")          
          setValues({
            ...values,
            [name]: newValue,
          })
        }}
        renderOption={(props, city) => (
          <Box component='li' {...props} key={city.id} sx={{bgcolor: 'background.primary', fontSize: `${listFontSize}`}} >
            <Box >
              {city.district}
            </Box>
            <Box sx={{ color: 'text.secondary', fontSize: '12px' }} >
              , {city.state}, {city.id.slice(0, 2)}
            </Box>

          </Box>
        )}
        renderInput={(params) => (
          <TextField sx={{ display: 'flex', justifyContent: 'spaceBetween' }}
            {...params}
            // label={address.length === 0 ? label : label + " *"}
            label={label}
            size={size}
            InputLabelProps={{ style: { fontSize: `${labelFontSize}` } }}
            // required={address.length === 0}
            required={required}
            InputProps={{
              ...params.InputProps,
              type: 'search',
              style: { fontSize: `${inputFontSize}` }
            }}
            onClick={handleFetch}
          />
        )}
      />
    </Stack>

  );
}