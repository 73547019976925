import React, { useCallback, useRef, useState } from 'react'
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import { Avatar, CardHeader, CardMedia, CircularProgress, Grid, IconButton, LinearProgress, Menu, MenuItem, Skeleton, Typography } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ShareIcon from '@mui/icons-material/Share';
import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined';
import CorporateFareTwoToneIcon from '@mui/icons-material/CorporateFareTwoTone';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import JobCardModal from '../modals/JobCardModal';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { axiosPrivate } from '../../api/axios';
import { fetchOrg } from '../../features/org/orgSlice';
import moment from 'moment'
import Chip from '@mui/material/Chip';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import BookmarkOutlinedIcon from '@mui/icons-material/BookmarkOutlined';
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import NoPostFeed from './NoPostFeed';

const OrgLogo = styled.img`
  border-radius: 50%;  
  width: 40px;
  height: 40px;
  object-fit: cover;
`;

const JobCardFeed = () => {
  useAxiosPrivate()

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { userId } = useSelector(
    (state) => state.auth
  )
  //   const { profile_pic, firstName, lastName, designation } = useSelector(
  //     (state) => state.auth
  //   )

  const [open, setOpen] = React.useState(false);  
  const [newsFeed, setNewsFeed] = React.useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [postId, setPostId] = React.useState();
  const [loadingFeeds, setLoadingFeeds] = React.useState(true)
  const [element, setElement] = React.useState()
  const [loading, setLoading] = React.useState(false)
  const [disabled, setDisabled] = React.useState(false)
  const [bookmarkLoading, setBookmarkLoading] = React.useState(false)
  const [keyValue, setKeyValue] = React.useState("")
  const [jobModalClicked, setJobModalClicked] = React.useState(false);
  const [skip, setSkip] = React.useState(0);
  const [hasMore, setHasMore] = useState(false)


  const isMenuOpen = Boolean(anchorEl);

  const millisec = Date.now()
  const date = new Date(millisec)

  // const handleMoreVertMenuOpen = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleSubmit = () => {
    setOpen(false);
  };

  const handleClickOpen = (item) => {
    setOpen(true);
    setElement(item)
    setJobModalClicked(true)
    // console.log(item)

  };

  const handleClose = () => {
    setOpen(false);
    // setEditOpen(false)
  };

  const copyLink = (postId) => {
    navigator.clipboard.writeText(`${process.env.REACT_APP_URL}/job/${postId}`)
    setAnchorEl(null);
  };

  const observer = useRef()
  const lastFeedRef = useCallback(node => {
    if (loadingFeeds) return
    if (observer.current) observer.current.disconnect()
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore) {
        setSkip(skip => skip + 7)
      }
    })
    if (node) observer.current.observe(node)
  }, [loadingFeeds, hasMore])

  const fetchNewsFeed = async () => {
    // e.preventDedault() 
    try {
      // setLoadingFeeds(true)
      const res = await axiosPrivate.get(`/api/post/jobfeed?skip=${skip}`)
      if (res.status === 200) {
        setLoadingFeeds(false)
        // const data = res.data.orgPost
        // setOrgPost(data)
        const data = res.data.feeds
        setNewsFeed(prevFeed => {
          return [...new Set([...prevFeed, ...data])]
        })
        setLoadingFeeds(false)
        data.length > 0 ? setHasMore(true) : setHasMore(false)
        console.log(data)
      }
      if (res.status === 201) {
        setNewsFeed([])
        setLoadingFeeds(false)
      }
    } catch (err) {
      console.error(err);
      setLoadingFeeds(false)
    }
  };

  React.useEffect(() => {
    fetchNewsFeed()
  }, [skip])
  
  const moreVertId = 'more-vert-menu';

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={element?._id}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >

      {element?.org.activeMembers.includes(userId) && <MenuItem disabled sx={{ padding: '3px 16px 3px 16px' }}  >
        <Typography sx={{ fontSize: '13px', fontWeight: 250, }}> Unfollow {element?.org.name} </Typography>
      </MenuItem>}

      {(!element?.org.activeMembers.includes(userId) && element?.org.followers.includes(userId)) && <MenuItem disabled={disabled} sx={{ padding: '3px 16px 3px 16px' }} onClick={async () => {
        const orgId = element?.org._id
        let followersArray = element?.org.followers;
        try {
          setLoading(true)
          setDisabled(true)
          const res = await axiosPrivate.put(`/api/org/${orgId}/unfollow`)
          if (res.status === 201) {
            setAnchorEl(null)
            setLoading(false)
            setDisabled(false)
            const filtered = followersArray.indexOf(userId)
            if (filtered !== -1) {
              followersArray.splice(filtered, 1)
            }
            // console.log(followersArray)
            // const data = res.data.data        
          }
        } catch (err) {
          setAnchorEl(null)
          console.error(err);
          setLoading(false)
          setDisabled(false)
        }
      }
      }  >
        <Typography sx={{ fontSize: '13px', fontWeight: 250 }}> Unfollow {element?.org.name} </Typography>
      </MenuItem>}

      {(!element?.org.activeMembers.includes(userId) && !element?.org.followers.includes(userId)) && <MenuItem disabled={disabled} sx={{ padding: '3px 16px 3px 16px' }} onClick={async () => {
        const orgId = element?.org._id
        let followersArray = element?.org.followers;
        try {
          setLoading(true)
          setDisabled(true)
          const res = await axiosPrivate.put(`/api/org/${orgId}/follow`)
          if (res.status === 201) {
            setAnchorEl(null)
            setLoading(false)
            setDisabled(false)
            followersArray.push(userId)
            console.log(res)
            // const data = res.data.data        
          }
        } catch (err) {
          setAnchorEl(null)
          console.error(err);
          setLoading(false)
          setDisabled(false)
        }
      }
      }  >

        <Typography sx={{ fontSize: '13px', fontWeight: 250 }}> Follow {element?.org.name} </Typography>
      </MenuItem>}

      <MenuItem sx={{ padding: '3px 16px 3px 16px', position: 'relative' }} onClick={() => copyLink(postId)} >
        <Typography sx={{ fontSize: '13px', fontWeight: 250 }}> Copy Link</Typography>

        {loading && <CircularProgress
          // key ={element._id}
          size={25}
          sx={{
            color: 'orange',
            position: 'absolute',
            top: '10%',
            left: '40%',
            // marginTop: '-12px',
            // marginLeft: '-12px',
          }}
        />
        }
      </MenuItem>

      <MenuItem sx={{ padding: '3px 16px 3px 16px' }} onClick={handleMenuClose} >
        <Typography sx={{ fontSize: '13px', fontWeight: 250 }}> Report</Typography>
      </MenuItem>

    </Menu>
  )

  const LoadingSkeleton = () => {
    return (
      <Card sx={{ my: '10px', p: '3px' }}>
      <CardHeader
        avatar={          
            <Skeleton animation="wave" variant="circular" width={40} height={40} />           
        }       
        title={         
            <Skeleton
              animation="wave"
              height={10}
              width="80%"
              style={{ marginBottom: 6 }}
            />         
        }
        subheader={          
            <Skeleton animation="wave" height={10} width="40%" />        
        }
      />     

      <CardContent>      
      <Skeleton animation="wave" height={40} width="80%" style={{ marginBottom: 6 }} />
            <Skeleton animation="wave" height={10} variant="rectangular" width="70%" style={{ marginBottom: 10 }} />  
            <Skeleton animation="wave" height={30} width="60%" style={{ marginBottom: 7 }} />
            <Skeleton animation="wave" height={30} width="60%" style={{ marginBottom: 7 }} />
            <Skeleton animation="wave" height={20} width="50%" style={{ marginBottom: 7 }} />            
            <Skeleton animation="wave" height={10} width="30%" style={{ marginBottom: 7 }} /> 
      </CardContent>
    </Card>
    )
  }

 
  return (
    <>
      {loadingFeeds ? 
      <>
      <LoadingSkeleton />
      <LoadingSkeleton />
      <LoadingSkeleton />
      <LoadingSkeleton />
      </>

       : newsFeed && newsFeed.length !== 0 ? newsFeed.map((element) => <Box key={element._id} ref={lastFeedRef} >
        <Card sx={{ my: '10px', p: '3px' }}>
          <CardHeader sx={{ p: '7px' }}
            avatar={element.org?.logo !== "" ? <OrgLogo src={element.org?.logo} alt='L' /> :
              <CorporateFareTwoToneIcon variant="rounded" sx={{ width: '45px', height: '45px', bgcolor: 'lightGray', color: 'gray', borderRadius: '5px' }} />
            }
            action={
              <>
              { (date.toISOString() < element?.extendedDate) &&
              <>
              {
                !element.saved.includes(userId) ? 
                <IconButton
                aria-label="bookmark"  
                disabled={bookmarkLoading && keyValue === element._id && true}              
                onClick={async(event) => {
                  const jobId = element._id
                  // console.log(element.salary === "")
                  setElement(element)                  
                  setPostId(element._id)
                  let savedArray = element.saved;
                  // console.log(postId)
                  try {
                    setKeyValue(element._id)
                    setBookmarkLoading(true)
                    const res = await axiosPrivate.put(`/api/post/${jobId}/add`)
                    if (res.status === 201) {
                      setBookmarkLoading(false)
                      setKeyValue("")
                     savedArray.push(userId)    
                    }
                  } catch (err) {
                    setBookmarkLoading(false)
                    setKeyValue("")
                    console.error(err);
                  }
                }}> 
              <BookmarkBorderOutlinedIcon />
              </IconButton>
                :
                <IconButton
                aria-label="bookmark" 
                disabled={bookmarkLoading && keyValue === element._id && true}                                            
                onClick={async(event) => {
                  const jobId = element._id
                  // console.log(element)
                  setElement(element)                  
                  setPostId(element._id)
                  let savedArray = element.saved;
                  try {
                    setKeyValue(element._id)
                    setBookmarkLoading(true)
                    const res = await axiosPrivate.put(`/api/post/${jobId}/remove`)
                    if (res.status === 201) {
                      setBookmarkLoading(false)
                      setKeyValue("")
                      const filtered = savedArray.indexOf(userId)
                      if (filtered !== -1) {
                        savedArray.splice(filtered, 1)
                      }
                      // console.log(savedArray)
                    }
                  } catch (err) {
                    setBookmarkLoading(false)
                    setKeyValue("")
                    console.error(err);
                  }
                }}> 
              <BookmarkOutlinedIcon color="text.secondary"  />
              </IconButton>
              }
               </>
            }

              <IconButton
                aria-label="settings"
                aria-controls={moreVertId}
                aria-haspopup="true"
                onClick={(event) => {
                  // console.log(element)
                  setElement(element)
                  setAnchorEl(event.currentTarget);
                  setPostId(element._id)
                }}>                  
                <MoreVertIcon />
              </IconButton>
                </>
            }
            titleTypographyProps={{ variant: 'h5', fontSize: '12px', fontWeight: '400', overflow: 'hidden', whiteSpace: 'nowrap'  }}
            title={<Box sx={{ maxWidth: '200px', cursor: 'pointer' }} onClick={() => {              
              navigate(`/org/${element.org._id}`)
            }}> {element.org.name} </Box>}
            subheader={<div>
              <Typography variant='h6' sx={{ fontSize: '10px', fontWeight: '400' }} > {element.org.sector.name} </Typography>
              <Typography variant='h6' sx={{ fontSize: '10px', fontWeight: '300', lineHeight: '0.5rem' }} >Posted on {moment(element.createdAt).format("DD-MM-YYYY")}.</Typography></div>
            }
          />
          {renderMenu}

          <CardContent onClick={(event) => handleClickOpen(element)} sx={{ cursor: 'pointer' }} >

              <Typography variant="h2" color="text.secondary" sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', fontSize: '1.25rem' }}>
                {element.designation.name}&nbsp;
                <Typography variant="body2" color="text.secondary" sx={{ fontSize: '11px', fontStyle: 'italic' }}>
                  {element.experience}
                </Typography>
              </Typography>


            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <PlaceOutlinedIcon fontSize='12px' />
              <Typography variant="h6" color="text.secondary" sx={{ display: 'flex', flexWrap: 'wrap', fontSize: '12px', }} >
                {element.location.slice(0, 2).map((item, index) => <Box key={item.id} sx={{ mx: '3px' }}>
                  {item.district}, {item.state}
                </Box>)}
                {
                (element.location.length - 2 > 0) && <Box> + {element.location.length - 2}</Box>                
              }
              </Typography>
              {/* {
                (element.location.length - 2 > 0) &&
                <Typography variant="h6" color="text.secondary" sx={{ display: 'flex', flexWrap: 'wrap', fontSize: '12px', }} >
                  + {element.location.length - 2}
                </Typography>
              } */}
            </Box>


            {/* <Typography variant="h6" color="text.secondary" sx={{ display: 'flex', fontSize: '12px', }} >
              {element.designation.courses.map((item) => <Box key={item.name} sx={{ mx: '6px'}}>
                {item.name}
              </Box> )}
            </Typography> */}
            <Grid gap sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', m: '7px' }}>
              <Chip label={element.jobType} />
              <Chip label={element.jobMode} />
              {
                element.gender !== "Any" && <Chip label={element.gender + " Only"} />
              }

            </Grid>

            <Grid gap sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', my: '10px' }}>
              {element.salary !== "" && 
              <Chip label={element.salary} variant="outlined" />
              }
               {
                element.vacancies !== 0 &&
              <Chip label={element.vacancies + " Vacancy(s)"} variant="outlined" />
              }
            </Grid>

            <Grid gap sx={{ display: 'flex', alignItems: 'center', my: '10px' }}>
              {
                (element?.extendedDate === element?.lastDate) ? 
              <Typography variant="body2" color="text.secondary" >
                Last Date: {moment(element.lastDate).format("DD MMM YYYY")}.
              </Typography>
              :
              <Typography variant="body2" color="text.secondary" >
              Extended Date: {moment(element.extendedDate).format("DD MMM YYYY")}.
            </Typography>
              }
              {
                (date.toISOString() > element?.extendedDate) &&
              <Chip label="expired" variant="outlined" color='error' />
              }

            </Grid>
            {
              (element.applied_candidates.length <= 10) ?
              <Typography variant="body2" color="text.secondary" sx={{ fontSize: '11px', fontStyle: 'italic' }} >
              Be in the First 10 Applied.
            </Typography> 
            :
            <Typography variant="body2" color="text.secondary" sx={{ fontSize: '11px', fontStyle: 'italic' }} >
              {element.applied_candidates.length} Applied
            </Typography> 
            } 
           
          </CardContent>
        </Card>
      </Box>
      )
        :
        <NoPostFeed />

      }
      {
        jobModalClicked &&
      <JobCardModal onClose={handleClose} onSubmit={handleSubmit} open={open} element={element}
      bookmarkLoading={bookmarkLoading}
      setBookmarkLoading={setBookmarkLoading}
      keyValue={keyValue}
      setKeyValue={setKeyValue}
      userId={userId}
      />
      }

      {
        hasMore && 
        <Box sx={{ width: '100%' ,display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <CircularProgress color="inherit" size={25}  />
    </Box>
      }

    </>
  )
}

export default JobCardFeed