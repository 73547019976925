import React from 'react'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import TextField from '@mui/material/TextField';
import dayjs from 'dayjs';
import { format } from 'date-fns'

export default function DPicker(props) {

    const { name, label, value, error = null, onChange, disabled, disableFuture, disablePast } = props

    const convertToDefEventPara = (name, value) => ({
        target: {
            name, value
        }
    })

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
                label={label}
                format="dd/MM/yyyy"
                name={name}
                // value={dayjs(value)}                
                value={value}
                disabled={disabled}
                disableFuture={disableFuture}
                disablePast={disablePast}
                onChange={date => onChange(convertToDefEventPara(name, date))}
                renderInput={(props) => <TextField {...props} sx={{width: "100%", }}
                {...(error && { error: true, helperText: error })}
                />}
               
            />
          </LocalizationProvider>
    )
}