import { Box, Button, Card, CardMedia, TextField } from '@mui/material'
import React from 'react'
import { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation, Navigate, useParams } from 'react-router-dom';
import axios from '../api/axios';
import ResumeForPdf from '../pages/ResumeForPdf';


function PupLogin() {

    const {userId} = useParams()

    // const dispatch = useDispatch()
    const navigate = useNavigate();
    // const location = useLocation();

    const [values, setValues] = useState({ userName: '', password: '' });
    const [data, setData] = useState();
    const [self, setSelf] = React.useState();
    const [resumeDetails, setResumeDetails] = React.useState();

    const handleChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target
        setValues({
            ...values,
            [name]: value,
        })
    };

    const pupLogin = async (e) => {
        e.preventDefault()
        console.log(values)
        const res = await axios.post(`/api/auth/pup_login/${userId}`, values,
            {
                headers: { 'Content-Type': 'application/json' },
                withCredentials: true
            });
            if (res.status == 201)            
            setData(res.data.data)
        //    fetchSelf()
        return res.data.data
    }

    // const fetchSelf = async () => {
    //     // e.preventDedault() 
    //     try {
    //         // setLoading(true)
    //         const res = await axios.get(`/api/user/puppeteer/resume/630f46549722e9ef9d198c32`)
    //         if (res.status == 200) {  
    //             const data = res.data.data
    //             setSelf(data)
    //             setResumeDetails(res.data.resumeDetails)
                
    //         }
    //     } catch (err) {
    //         console.error(err);
    //     }
    // };

    useEffect(() => {
        data && data.status === "active" && navigate(`/resume/${userId}`)
    }, [data])
    

    return (
        <> 
        {
            data && data.status === "active" ? 

            <ResumeForPdf /> 
            :

            <>
            <TextField
                name='userName'
                onChange={handleChange}
                placeholder="Username"
                label="Username"
                value={values.userName}
                sx={{ my: '10px' }}
            />

            <TextField
                name='password'
                onChange={handleChange}
                placeholder="Password"
                label="Password"
                type="password"
                value={values.password}
                sx={{ my: '10px' }}
            />

<button
                
                type='submit'                
                onClick={pupLogin}
                sx={{ my: '20px' }}
            >
                Login
            </button>
            </>

        }
             
            
            

            {/* <Button
                variant="contained"
                size="large"
                color="primary"
                onClick={pupLogin}
                sx={{ my: '20px' }}
            >
                Login
            </Button> */}
        </>
    )
}

export default PupLogin