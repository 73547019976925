import Input from "./Input";
import Rgroup from "./Rgroup";
import DPicker from "./DPicker";
import Chkbox from "./Chkbox";
import ChkboxValues from "./ChkboxValues";
import Btn from "./Btn";
import FileUpload from "./FileUpload";
import FileUploadJoin from "./FileUploadJoin";
import Places from "./Places";
import TextareaAutosizeBox from "./TextareaAutosizeBox";
import States from "./States";
import Countries from "./Countries";
import AutoComplete from "./AutoComplete";
import Select from "./Select";
import MuiRte from "./MuiRte";
import PlacesAddBranches from "./PlacesAddBranches";

const Controls = {
    Input,
    Rgroup,
    Chkbox,
    ChkboxValues,
    DPicker,
    FileUpload,
    FileUploadJoin,
    Btn,
    Places,
    PlacesAddBranches,   
    Countries,
    States,
    AutoComplete,
    Select,
    TextareaAutosizeBox,
    MuiRte,
}

export default Controls