import axios from '../api/axios';

const useRefreshToken = () => {
    // try {
        const refresh = async () => {
            const res = await axios.get('/api/auth/refresh', {
                withCredentials: true
            })
        }
        return refresh; 
    // } catch (error) {
    //    console.log(error) 
    // }
    
};

export default useRefreshToken;