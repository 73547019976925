import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Controls from '../../components/controls/Controls';
// import { Form, UseForm } from '../../components/UseForm';
import { Backdrop, Box, CardMedia, CircularProgress, FormHelperText, Grid, IconButton, Link, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { FormAdmin, UseFormAdmin } from '../components/UseFormAdmin';
import ImageSlider from '../components/ImageSlider';
import CloseIcon from '@mui/icons-material/Close';
import AddModeratorOutlinedIcon from '@mui/icons-material/AddModeratorOutlined';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import VerifiedModal from './alertModal/VerifiedModal';
import RevertModal from './alertModal/RevertModal';
import RefuteModal from './alertModal/RefuteModal';
import { axiosPrivate } from '../../api/axios';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';

// import { Link } from 'react-router-dom';
import durations from '../../duration.json'

const examBoard = [
  { id: 'state', title: 'State' },
  { id: 'central', title: 'Central' },
]

export default function DesignationSelectModal(props) {

  const {
    // handleFetch,
    open,
    setOpen,
    handleClose,
    selectedRow,
  } = props
  // const rowData = selectedRow[0]

  useAxiosPrivate()
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [loading, setLoading] = React.useState(false)
  const [course, setCourse] = React.useState([])
  const [enabledCourses, setEnabledCourses] = React.useState([])

  // console.log(selectedRow)
  //   const duration = durations.duration

  //   const selectedDuration = duration.filter((item) => selectedRow?.duration === item.id)[0]

  const initialValues = {
    name: selectedRow?.name,
    designationType: selectedRow?.type,
    description: selectedRow?.desc,
    eligibleCourses: selectedRow?.courses,
    alternateNames: selectedRow?.alternateNames,
  }
  //   console.log(initialValues)

  const validate = (fieldValues = values) => {
    let temp = { ...errors }
    if ('name' in fieldValues)
      temp.name = fieldValues.name ? "" : "Competitive Exam name required."

    // if ('description' in fieldValues)
    //     temp.description = fieldValues.description ? "" : "Pin/Zip required."

    setErrors({
      ...temp
    })
    // console.log(temp)

    if ((fieldValues = values))
      return Object.values(temp).every(x => x === "")
  }

  const validateRevert = (fieldValues = values) => {
    let temp = { ...errors }
    setErrors({
      ...temp
    })

    if ((fieldValues = values))
      return Object.values(temp).every(x => x === "")
  }

  const {
    values,
    setValues,
    files,
    setFiles,
    errors,
    setErrors,
    optionsData,
    setOptionsData,
    checkedItems,
    setCheckedItems,
    country,
    setCountry,
    state,
    setState,
    handleInputChange,
  } = UseFormAdmin(initialValues, true, validate, validateRevert, selectedRow);

  //   console.log(values)

  const filteredCourses = course.map((item) => item.id).filter(item => !enabledCourses.includes(item))

  let initialCourseState = {}

  // const updateCourses = (enabledCourses) => { 
  for (const item of filteredCourses) {
    initialCourseState[item] = false
  }
  for (const item of enabledCourses) {
    initialCourseState[item] = true
  }
  // }

  const [courseState, setCourseState] = React.useState(initialCourseState);

  //   console.log(courseState)

  const handleCheckCourses = async (event) => {
    setCourseState({
      ...courseState,
      [event.target.name]: event.target.checked,
    });
    let updatedList = [...checkedItems];
    if (event.target.checked) {
      updatedList = [...checkedItems, event.target.value];
    } else {
      updatedList.splice(checkedItems.indexOf(event.target.value), 1);
    }
    setCheckedItems(updatedList);
  };
  // console.log(values)

  const designationId = selectedRow?._id

  const handleFetchCheckedCourse = async () => {
    try {
      // setLoading(true)
      setEnabledCourses([])
      setCourse([])
      const res = await axiosPrivate.get(`/api/org/designation/${designationId}/fetch_courses`)
      if (res) {
        // setLoading(false)        
        const data = res.data.data
        setEnabledCourses(data.map((item) => item.id))
        setCourse(data)
        // console.log(res.data)
      }
    } catch (err) {
      console.error(err);
    }
  }
  // console.log(values)

  const handleFetch = async () => {
    try {
      setLoading(true)
      setOptionsData([])
      // setValues(null)
      const res = await axiosPrivate.get('/api/org/course_type/fetch')
      if (res) {
        setLoading(false)
        const data = res.data.data
        setOptionsData(data)
      }
    } catch (err) {
      console.error(err);
    }
  }

  const handleFetchCourseTypeCourses = async () => {
    try {
      // setLoading(true)
      const res = await axiosPrivate.post(`api/org/course_type/courses/fetch?courseType=${values.courseType.id}`);
      if (!res) return

      if (res.status === 200) {
        // setLoading(false)
        const data = res.data
        setCourse(data)
      }
    } catch (err) {
      console.error(err);
    }
  }
  console.log(values)

  const designationInfo = {
    name: values.name,
    type: values.designationType,
    alternateNames: values.alternateNames.toString(),
    desc: values.description,
    courses: JSON.stringify(checkedItems),
    enabledCourses: JSON.stringify(enabledCourses)
  }
  console.log(designationInfo)

  const handleClickSubmit = async (e) => {
    e.preventDefault()
    try {
      setLoading(true)
      const formData = new FormData()
      for (let key in designationInfo) {
        formData.append(key, designationInfo[key])
      }
      const res = await axiosPrivate.put(`/api/user/designation/${designationId}/update`, formData)
      if (res.status === 200) {
        setLoading(false)
        console.log(res.data)
      }
    } catch (err) {
      console.error(err);
    }
  }
  // console.log(enabledCourses)

  React.useEffect(() => {
    setCourseState(initialCourseState)
    setCheckedItems(enabledCourses)
  }, [enabledCourses])


  React.useEffect(() => {
    handleFetchCheckedCourse()
    // setCheckedItems(enabledCourses)       
  }, [selectedRow])

  React.useEffect(() => {
    if (values?.courseType?.id.slice(0, 2) === "CT") handleFetchCourseTypeCourses();
  }, [values])


  return (
    <div>
      <Dialog
        // fullScreen={fullScreen}

        fullScreen
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      // sx={{zIndex: 1700}}
      // sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: 1080, height: { xs: 'auto', md: '100vh' } }}
      >
        <DialogTitle id="responsive-dialog-title" sx={{ bgcolor: 'background.paper', display: 'flex', position: "fixed", width: "97%", zIndex: 1600 }}>
          {/* {`User Data of ${selectedRow.given_name} ${selectedRow.family_name}`} */}
          {`Manage ${selectedRow?.name}`}
          <Box sx={{ flexGrow: 1 }} />
          <IconButton onClick={handleClose} >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <FormAdmin onSubmit={handleClickSubmit} id="hook-form" >
          <DialogContent sx={{ bgcolor: 'background.default', pt: "70px" }} >

            <Grid container spacing={2} sx={{ px: '3px', py: '15px' }} >
              <Grid item md={4} sx={{
                display: 'flex', flexDirection: 'column',
                '& .MuiTextField-root': { m: 1, width: '90%' },
              }} >

                <Controls.Input
                  name="name"
                  label="Designation Name"
                  value={values?.name}
                  onChange={handleInputChange}
                  error={errors.name}
                  required={true}
                />

                <Controls.Input
                  name="alternateNames"
                  label="Alternate Names Seperated with Comma"
                  placeholder="eg. Teacher,Asst Prof Physics,Lecturer"
                  minRows={3}
                  // maxRows={7}
                  multiline={true}
                  value={values?.alternateNames}
                  onChange={handleInputChange}
                />

                <Controls.Input
                  name="name"
                  label="Designation Category"
                  value={values?.designationType}
                  onChange={handleInputChange}
                  error={errors.name}
                  disabled={true}
                />


                <Controls.TextareaAutosizeBox
                  name="description"
                  label="Description *"
                  placeholder="Enter Description"
                  minRows={4}
                  maxRows={7}
                  value={values?.description}
                  onChange={handleInputChange}
                />
              </Grid>

              <Grid item md={8} sx={{ display: 'flex', flexDirection: 'column', p: '10px', }} >

                <Box sx={{ my: '10px' }}>
                  <Typography>
                    Select Eligible Courses
                  </Typography>
                </Box>

                <Controls.AutoComplete
                  name="courseType"
                  label="Select Course Category"
                  size='small'
                  listFontSize='14px'
                  labelFontSize='12px'
                  inputFontSize='12px'
                  optionsData={optionsData}
                  setOptionsData={setOptionsData}
                  values={values}
                  setValues={setValues}
                  handleFetch={handleFetch}
                  disableClearable={true}
                />

                <Box sx={{ my: '5px' }}>
                  <List dense sx={{ width: '100%', bgcolor: 'background.paper', columnCount: { xs: '1', sm: '2', md: '3' } }}>
                    {course && course.map((value) => {
                      const labelId = `checkbox-list-label-${value.name}`;

                      return (
                        <ListItem
                          key={value.id}
                          disablePadding
                        >
                          <ListItemButton dense>
                            <ListItemIcon>
                              {Object.keys(courseState).length !== 0 &&
                                <Controls.ChkboxValues
                                  name={value.id}
                                  checkedItems={checkedItems}
                                  setCheckedItems={setCheckedItems}
                                  checked={courseState[value.id]}
                                  // label="Government Owned"
                                  value={value.id}
                                  onChange={handleCheckCourses}
                                />
                              }
                              {Object.keys(courseState).length === 0 &&
                                <Controls.ChkboxValues
                                  name={value.id}
                                  checkedItems={checkedItems}
                                  setCheckedItems={setCheckedItems}
                                  checked={courseState[value.id]}
                                  // label="Government Owned"
                                  value={value.id}
                                  onChange={handleCheckCourses}
                                />
                              }
                            </ListItemIcon>
                            <ListItemText id={value.id} primary={value.name} />
                          </ListItemButton>
                        </ListItem>
                      );
                    })}
                  </List>

                </Box>

              </Grid>

            </Grid>

          </DialogContent>
          <DialogActions sx={{ bgcolor: '#darkslateGray' }}>
            <Controls.Btn
              // name="close"
              text="Close"
              color="error"
              variant="outlined"
              onClick={handleClose}
            />
            <Controls.Btn
              // name="close"
              text="Update"
              color="success"
              variant="outlined"
              onClick={handleClickSubmit}
            />
          </DialogActions>
        </FormAdmin>

      </Dialog>
    </div>
  );
}
