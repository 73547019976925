import * as React from 'react';
import { FormControl, FormControlLabel, Checkbox, Box } from '@mui/material';

export default function Chkbox(props) {

  const { name, label, value, required, defaultChecked, onChange } = props;
  
  const convertToDefEventPara = (name, value) => ({
    target: {
      name, value
    }
  })

  return (
    <FormControl>
      <FormControlLabel
        control={<Checkbox
        size='small'
          name={name}
          color="primary"
          checked={value}
          required={required}          
          onChange={e => onChange(convertToDefEventPara(name, e.target.checked))}
        />}
        label={label}
      />
    </FormControl>
  );
}