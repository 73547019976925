import * as React from 'react';
import NavBar from '../components/nav/NavBar'
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import { Avatar, Backdrop, Button, CardHeader, CardMedia, Chip, CircularProgress, Divider, IconButton, Input, ListItemAvatar, Typography } from '@mui/material';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { axiosPrivate } from '../api/axios';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import DisabledByDefaultOutlinedIcon from '@mui/icons-material/DisabledByDefaultOutlined';
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { Form, UseForm } from '../components/UseForm';
import Controls from '../components/controls/Controls';
import { updateLanguage, updateAddress, updatePublication, updateReference, updateSkills, updateBio, updateMaritalStatus, updateHomeTownId, updateDistrict, updateState, updateCountry, updateCurrentAddress, updateCurrentDistrict, updateCurrentState, updateCurrentCountry, updateCurrentPlaceId, update, updateCertifications, updatePhone } from '../features/userAuth/authSlice';
import { Link } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const levelItems = [
  { id: 1, title: 'Basic' },
  { id: 2, title: 'Intermediate' },
  { id: 3, title: 'Conversational' },
  { id: 4, title: 'Proficient' },
  { id: 5, title: 'Fluent' },
]

const maritalStatusItems = [
  { id: 1, title: 'Unmarried' },
  { id: 2, title: 'Married' },
  { id: 3, title: 'Divorced' },
  { id: 4, title: 'Widow' },
  { id: 5, title: 'Widower' },
]

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function EditProfile() {

  useAxiosPrivate()
  const dispatch = useDispatch();

  const { userId, userSlug, isVerified, bio, phone, maritalStatus, languages, address, references, publications, skills, district, state, country, homeTownId, currentPlaceId, currentAddress, currentDistrict, currentState, currentCountry, certifications } = useSelector(
    (state) => state.auth
  )

  let addedLanguages = [];

  for (let i = 0; i < languages?.length; i++) {
    addedLanguages.push(
      {
        name: languages[i]?.name,
        proficiency: languages[i]?.proficiency
      })
  }

  let addedReferences = [];

  for (let i = 0; i < references?.length; i++) {
    addedReferences.push(
      {
        name: references[i]?.name,
        details: references[i]?.details,
        phone: references[i]?.phone,
        email: references[i]?.email,
      })
  }

  let addedPublications = [];

  for (let i = 0; i < publications?.length; i++) {
    addedPublications.push(
      {
        authors: publications[i]?.authors,
        title: publications[i]?.title,
        journal: publications[i]?.journal,
        volume: publications[i]?.volume,
      })
  }

  let addedSkills = [];

  for (let i = 0; i < skills?.length; i++) {
    addedSkills.push(
      {
        name: skills[i]?.name,
      })
  }

  let addedCertifications = [];

  for (let i = 0; i < certifications?.length; i++) {
    addedCertifications.push(
      {
        name: certifications[i]?.name,
        institute: certifications[i]?.institute,
        year: certifications[i]?.year,
      })
  }

  const initialValues = {
    bio: bio,
    maritalStatus: maritalStatus === undefined ? "" : maritalStatus,
    homeTownId: homeTownId,
    phone: phone === undefined ? "" : phone,
    street: address?.street === "" ? "" : address.street,
    locality: address?.locality === "" ? "" : address.locality,
    pin: address?.pin === "" ? "" : address.pin,
    district: district,
    state: state,
    country: country,
    currentPlaceId: currentPlaceId,
    currentStreet: currentAddress?.street === "" ? "" : currentAddress?.street,
    currentLocality: currentAddress?.locality === "" ? "" : currentAddress?.locality,
    currentPin: currentAddress?.pin === "" ? "" : currentAddress?.pin,
    currentDistrict: currentDistrict === undefined ? "" : currentDistrict,
    currentState: currentState === undefined ? "" : currentState,
    currentCountry: currentCountry === undefined ? "" : currentCountry,
    languages: languages.length === 0 ? [{ name: "", proficiency: 3 }] : addedLanguages,
    references: references.length === 0 ? [{ name: "", details: "", phone: "", email: "" }] : addedReferences,
    publications: publications.length === 0 ? [{ authors: "", title: "", journal: "", volume: "" }] : addedPublications,
    skills: skills?.length === 0 ? [{ name: "" }] : addedSkills,
    certifications: certifications?.length === 0 ? [{ name: "", institute: "", year: "" }] : addedCertifications,
  }

  const {
    values,
    setValues,
    errors,
    setErrors,
    // country,
    // setCountry,
    // state,
    // setState,
    checkedItems,
    setCheckedItems,
    handleInputChange,
    handleChkboxItems,
    handleChkboxDesignation,

  } = UseForm(initialValues);

  const [expanded, setExpanded] = React.useState(false);
  const [loading, setLoading] = React.useState(false)
  const [saveBio, setSaveBio] = React.useState(false)
  const [saveMaritalStatus, setSaveMaritalStatus] = React.useState(false)
  const [savePhone, setSavePhone] = React.useState(false)
  const [saveLanguage, setSaveLanguage] = React.useState(false)
  const [saveAddress, setSaveAddress] = React.useState(false)
  const [saveCurrentAddress, setSaveCurrentAddress] = React.useState(false)
  const [saveSkill, setSaveSkill] = React.useState(false)
  const [skill, setSkill] = React.useState(initialValues.skills)
  const [saveReference, setSaveReference] = React.useState(false)
  const [savePublication, setSavePublication] = React.useState(false)
  const [language, setLanguage] = React.useState(initialValues.languages);
  const [reference, setReference] = React.useState(initialValues.references)
  const [publication, setPublication] = React.useState(initialValues.publications);
  const [saveCertification, setSaveCertification] = React.useState(false)
  const [certification, setCertification] = React.useState(initialValues.certifications)
  const [pdfUpdate, setPdfUpdate] = React.useState(false)
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [severity, setSeverity] = React.useState();
  const [message, setMessage] = React.useState("");
  // console.log(initialValues)

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
    setMessage("")
  };


  const handleLanguageChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...language];
    // console.log(list)
    list[index][name] = value;
    setLanguage(list);
    setSaveLanguage(true)
  };

  const handleLanguageAdd = () => {
    setLanguage([...language, { name: "", proficiency: 3 }]);
  };

  const handleLanguageRemove = (index) => {
    const list = [...language];
    list.splice(index, 1);
    setLanguage(list);
    setSaveLanguage(true)
  };

  const handleReferenceChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...reference];
    // console.log(list)
    list[index][name] = value;
    setReference(list);
    setSaveReference(true)
  };
  // console.log(language)
  // console.log(values.languages)

  const handleReferenceAdd = () => {
    setReference([...reference, { name: "", details: "", phone: "", email: "" }]);
  };

  const handleReferenceRemove = (index) => {
    const list = [...reference];
    list.splice(index, 1);
    setReference(list);
    setSaveReference(true)
  };

  const handlePublicationChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...publication];
    // console.log(list)
    list[index][name] = value;
    setPublication(list);
    setSavePublication(true)
  };
  // console.log(language)
  // console.log(values.languages)

  const handlePublicationAdd = () => {
    setPublication([...publication, { authors: "", title: "", journal: "", volume: "" }]);
  };

  const handlePublicationRemove = (index) => {
    const list = [...publication];
    list.splice(index, 1);
    setPublication(list);
    setSavePublication(true)
  };

  const handleSkillChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...skill];
    // console.log(list)
    list[index][name] = value;
    setSkill(list);
    setSaveSkill(true)
  };
  // console.log(language)
  // console.log(values.languages)

  const handleSkillAdd = () => {
    setSkill([...skill, { name: "" }]);
  };

  const handleSkillRemove = (index) => {
    const list = [...skill];
    list.splice(index, 1);
    setSkill(list);
    setSaveSkill(true)
  };

  const handleCertificationChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...certification];
    // console.log(list)
    list[index][name] = value;
    setCertification(list);
    setSaveCertification(true)
  };
  // console.log(language)
  // console.log(values.languages)

  const handleCertificationAdd = () => {
    setCertification([...certification, { name: "", institute: "", year: "" }]);
  };

  const handleCertificationRemove = (index) => {
    const list = [...certification];
    list.splice(index, 1);
    setCertification(list);
    setSaveCertification(true)
  };

  const fetchSelf = async () => {
    try {
      const res = await axiosPrivate.get(`/api/user/self`)
      if (res) {
        const data = res.data.data
        dispatch(update(data))
        // const profileUpdate = JSON.parse(localStorage.getItem('user'))
        // profileUpdate = data
        localStorage.setItem('user', JSON.stringify(data))
        // console.log(data)        
      }
    } catch (err) {
      console.error(err);
    }
  };

  const bioInfo = {
    bio: values.bio,
  }

  const handleSubmitBio = async (e) => {
    e.preventDefault()
    setLoading(true)
    try {
      const formData = new FormData()
      for (let key in bioInfo) {
        formData.append(key, bioInfo[key])
      }
      const res = await axiosPrivate.put('/api/user/bio/update', formData);
      if (!res) return
      if (res.status === 200) {
        const data = res.data.data;
        dispatch(updateBio(data))
        const profileUpdate = JSON.parse(localStorage.getItem('user'))
        profileUpdate.bio = data
        localStorage.setItem('user', JSON.stringify(profileUpdate))
        setLoading(false)
        setSaveBio(false)
        console.log(data)
      }

    } catch (err) {
      console.error(err);
      setLoading(false)
    }
  }

  const maritalStatusInfo = {
    maritalStatus: values.maritalStatus,
  }

  const handleSubmitMaritalStatus = async (e) => {
    e.preventDefault()
    setLoading(true)
    try {
      const formData = new FormData()
      for (let key in maritalStatusInfo) {
        formData.append(key, maritalStatusInfo[key])
      }
      const res = await axiosPrivate.put('/api/user/marital_status/update', formData);
      if (!res) return
      if (res.status === 200) {
        const data = res.data.data;
        dispatch(updateMaritalStatus(data))
        const profileUpdate = JSON.parse(localStorage.getItem('user'))
        profileUpdate.maritalStatus = data
        localStorage.setItem('user', JSON.stringify(profileUpdate))
        setLoading(false)
        setSaveMaritalStatus(false)
        console.log(data)
      }

    } catch (err) {
      console.error(err);
      setLoading(false)
    }
  }

  const phoneInfo = {
    phone: values.phone,
  }

  const handleSubmitPhone = async (e) => {
    e.preventDefault()
    setLoading(true)
    try {
      const formData = new FormData()
      for (let key in phoneInfo) {
        formData.append(key, phoneInfo[key])
      }
      const res = await axiosPrivate.put('/api/user/phone/update', formData);
      if (!res) return
      if (res.status === 200) {
        const data = res.data.data;
        dispatch(updatePhone(data))
        const profileUpdate = JSON.parse(localStorage.getItem('user'))
        profileUpdate.phone = data
        localStorage.setItem('user', JSON.stringify(profileUpdate))
        setLoading(false)
        setSavePhone(false)
        console.log(data)
      }

    } catch (err) {
      console.error(err);
      setLoading(false)
    }
  }

  const languagesInfo = {
    languages: JSON.stringify(language),

  }

  const handleSubmitLanguages = async (e) => {
    e.preventDefault()
    setLoading(true)
    try {
      const formData = new FormData()
      for (let key in languagesInfo) {
        formData.append(key, languagesInfo[key])
      }
      const res = await axiosPrivate.put('/api/user/languages/update', formData);
      if (!res) return
      if (res.status === 200) {
        const data = res.data;
        dispatch(updateLanguage(language))
        const profileUpdate = JSON.parse(localStorage.getItem('user'))
        profileUpdate.languages = language
        localStorage.setItem('user', JSON.stringify(profileUpdate))
        setLoading(false)
        setSaveLanguage(false)
        console.log(data)
      }

    } catch (err) {
      console.error(err);
      setLoading(false)
    }
  }

  const addressInfo = {
    street: values.street,
    locality: values.locality,
    pin: values.pin,
    homeTownId: values.homeTownId?.id === undefined ? homeTownId : values.homeTownId?.id
  }

  const city = {
    district: values.homeTownId?.id === undefined ? district : values.homeTownId?.district,
    state: values.homeTownId?.id === undefined ? state : values.homeTownId?.state,
    country: values.homeTownId?.id === undefined ? country : values.homeTownId?.country,
  }

  const handleSubmitAddress = async (e) => {
    e.preventDefault()
    setLoading(true)
    try {
      const formData = new FormData()
      for (let key in addressInfo) {
        formData.append(key, addressInfo[key])
      }
      const res = await axiosPrivate.put('/api/user/address/update', formData);
      if (!res) return
      if (res.status === 200) {
        const data = res.data.data;
        setSaveAddress(false)
        dispatch(updateAddress(data))
        dispatch(updateHomeTownId(addressInfo.homeTownId))
        dispatch(updateDistrict(city.district))
        dispatch(updateState(city.state))
        dispatch(updateCountry(city.country))
        const profileUpdate = JSON.parse(localStorage.getItem('user'))
        profileUpdate.address = data
        profileUpdate.homeTownId = addressInfo.homeTownId
        profileUpdate.district = city.district
        profileUpdate.state = city.state
        profileUpdate.country = city.country
        localStorage.setItem('user', JSON.stringify(profileUpdate))
        setLoading(false)
        console.log(data)
      }

    } catch (err) {
      console.error(err);
      setLoading(false)
    }
  }

  const currentAddressInfo = {
    street: values.currentStreet,
    locality: values.currentLocality,
    pin: values.currentPin,
    currentPlaceId: values.currentPlaceId?.id === undefined ? currentPlaceId : values.currentPlaceId?.id
  }

  const currentCity = {
    district: values.currentPlaceId?.id === undefined ? currentDistrict : values.currentPlaceId?.district,
    state: values.currentPlaceId?.id === undefined ? currentState : values.currentPlaceId?.state,
    country: values.currentPlaceId?.id === undefined ? currentCountry : values.currentPlaceId?.country,
  }
  // console.log(currentCity.state)

  const handleSubmitCurrentAddress = async (e) => {
    e.preventDefault()
    setLoading(true)
    try {
      const formData = new FormData()
      for (let key in currentAddressInfo) {
        formData.append(key, currentAddressInfo[key])
      }
      const res = await axiosPrivate.put('/api/user/current_address/update', formData);
      if (!res) return
      if (res.status === 200) {
        const data = res.data.data;
        setSaveCurrentAddress(false)
        dispatch(updateCurrentAddress(data))
        dispatch(updateCurrentPlaceId(currentAddressInfo.currentPlaceId))
        dispatch(updateCurrentDistrict(currentCity.district))
        dispatch(updateCurrentState(currentCity.state))
        dispatch(updateCurrentCountry(currentCity.country))
        const profileUpdate = JSON.parse(localStorage.getItem('user'))
        profileUpdate.currentAddress = data
        profileUpdate.currentPlaceId = currentAddressInfo.currentPlaceId
        profileUpdate.currentDistrict = currentCity.district
        profileUpdate.currentState = currentCity.state
        profileUpdate.currentCountry = currentCity.country
        localStorage.setItem('user', JSON.stringify(profileUpdate))
        setLoading(false)
        console.log(data)
      }

    } catch (err) {
      console.error(err);
      setLoading(false)
    }
  }

  const referencesInfo = {
    references: JSON.stringify(reference),
  }
  // console.log(reference)

  const handleSubmitReferences = async (e) => {
    e.preventDefault()
    setLoading(true)
    try {
      const formData = new FormData()
      for (let key in referencesInfo) {
        formData.append(key, referencesInfo[key])
      }
      const res = await axiosPrivate.put('/api/user/references/update', formData);
      if (!res) return
      if (res.status === 200) {
        const data = res.data;
        dispatch(updateReference(reference))
        const profileUpdate = JSON.parse(localStorage.getItem('user'))
        profileUpdate.references = reference
        localStorage.setItem('user', JSON.stringify(profileUpdate))
        setLoading(false)
        setSaveReference(false)
        console.log(data)
      }

    } catch (err) {
      console.error(err);
      setLoading(false)
    }
  }

  const publicationsInfo = {
    publications: JSON.stringify(publication),
  }

  const handleSubmitPublications = async (e) => {
    e.preventDefault()
    setLoading(true)
    try {
      const formData = new FormData()
      for (let key in publicationsInfo) {
        formData.append(key, publicationsInfo[key])
      }
      const res = await axiosPrivate.put('/api/user/publications/update', formData);
      if (!res) return
      if (res.status === 200) {
        const data = res.data;
        dispatch(updatePublication(publication))
        const profileUpdate = JSON.parse(localStorage.getItem('user'))
        profileUpdate.publications = publication
        localStorage.setItem('user', JSON.stringify(profileUpdate))
        setLoading(false)
        setSavePublication(false)
        console.log(data)
      }

    } catch (err) {
      console.error(err);
      setLoading(false)
    }
  }

  const skillsInfo = {
    skills: JSON.stringify(skill),

  }

  const handleSubmitSkills = async (e) => {
    e.preventDefault()
    setLoading(true)
    try {
      const formData = new FormData()
      for (let key in skillsInfo) {
        formData.append(key, skillsInfo[key])
      }
      const res = await axiosPrivate.put('/api/user/skills/update', formData);
      if (!res) return
      if (res.status === 200) {
        const data = res.data;
        dispatch(updateSkills(skill))
        const profileUpdate = JSON.parse(localStorage.getItem('user'))
        profileUpdate.skills = skill
        localStorage.setItem('user', JSON.stringify(profileUpdate))
        setLoading(false)
        setSaveSkill(false)
        console.log(data)
      }

    } catch (err) {
      console.error(err);
      setLoading(false)
    }
  }

  const certificationsInfo = {
    certifications: JSON.stringify(certification),

  }

  const handleSubmitCertifications = async (e) => {
    e.preventDefault()
    setLoading(true)
    try {
      const formData = new FormData()
      for (let key in certificationsInfo) {
        formData.append(key, certificationsInfo[key])
      }
      const res = await axiosPrivate.put('/api/user/certifications/update', formData);
      if (!res) return
      if (res.status === 200) {
        const data = res.data;
        dispatch(updateCertifications(certification))
        const profileUpdate = JSON.parse(localStorage.getItem('user'))
        profileUpdate.certifications = certification
        localStorage.setItem('user', JSON.stringify(profileUpdate))
        setLoading(false)
        setSaveCertification(false)
        console.log(data)
      }

    } catch (err) {
      console.error(err);
      setLoading(false)
    }
  }

  const handleUpdatePdf = async () => {
    try { 
      setMessage("")
      if (isVerified) {
        setPdfUpdate(true)     
        const res = await axiosPrivate.get(`/api/user/user/generate/generate_resume`)
        if (res.status === 200) {  
          console.log({success: true}) 
          setMessage("PDF of Resume Updated Successfully!")
          setSeverity("success")
        setOpenSnackbar(true)
          setPdfUpdate(false)                                
        }
      } else {
        setMessage("Please Add a Course!")
        setSeverity("info")
        setOpenSnackbar(true)
      }
    } catch (err) {
      console.error(err);
      setPdfUpdate(false)           
    }
  };

  React.useEffect(() => {
    fetchSelf()
  }, [])

  React.useEffect(() => {
    if (values.bio !== initialValues.bio) {
      setSaveBio(true)
    }
  }, [bioInfo])

  React.useEffect(() => {
    if (values.phone !== initialValues.phone) {
      setSavePhone(true)
    }
  }, [phoneInfo])

  React.useEffect(() => {
    if (values.maritalStatus !== initialValues.maritalStatus) {
      setSaveMaritalStatus(true)
    }
  }, [maritalStatusInfo])

  React.useEffect(() => {
    if (values.street !== initialValues.street || values.locality !== initialValues.locality || values.pin !== initialValues.pin || district !== city.district) {
      setSaveAddress(true)
    }
  }, [values.street, values.locality, values.pin, values.homeTownId])

  React.useEffect(() => {
    if (values.currentStreet !== initialValues.currentStreet || values.currentLocality !== initialValues.currentLocality || values.currentPin !== initialValues.currentPin || currentDistrict !== currentCity.district) {
      setSaveCurrentAddress(true)
    }
  }, [values.currentStreet, values.currentLocality, values.currentPin, values.currentPlaceId])

  React.useEffect(() => {
    if (values.name !== initialValues.name) {
      setSaveSkill(true)
    }
  }, [skillsInfo])

  React.useEffect(() => {
    if (values.name !== initialValues.name || values.institute !== initialValues.institute || values.year !== initialValues.year) {
      setSaveCertification(true)
    }
  }, [certificationsInfo])

  console.log(values)
  // console.log(currentState)
  // console.log(currentCity.state)

  return (

    <Paper elevation={0} sx={{ bgcolor: 'background.default', minHeight: '100vh', paddingBottom: '60px' }} >
      <NavBar />
      <Card sx={{ bgcolor: 'background.paper', m: '1px', p: '1px', }} >
        <Grid container spacing={2} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }} >
          <Grid item sx={{display: 'flex', mt: '6px' }}>
            <Typography variant='h5' >
              Edit Your Resume
            </Typography>                       
          </Grid>

          <Grid item gap sx={{ width: '90%' }} >
            <Divider textAlign="left" sx={{ my: '10px', }}>
              <Chip label="BIO" />
            </Divider>
            <Controls.TextareaAutosizeBox
              name="bio"
              // size='small'
              fullWidth={true}
              // labelFontSize='12px'
              // inputFontSize='12px'
              minRows={5}
              maxRows={7}
              multiline={true}
              label="Enter Bio"
              value={values.bio}
              onChange={handleInputChange}
            // error={errors.lastName}
            />
            <Grid sx={{ my: '5px', display: 'flex', alignItems: 'center' }}>
              <Box sx={{ flexGrow: 1 }} />
              {saveBio && <Button onClick={handleSubmitBio} variant='outlined' color='primary'
                sx={{ textTransform: 'none' }}
              >Save</Button>}
            </Grid>
          </Grid>

          <Grid item gap sx={{ width: '90%', }} >
            <Divider textAlign="left" sx={{ my: '10px', }}>
              <Chip label="PHONE NUMBER" />
            </Divider>
            <Controls.Input
              name="phone"
              size='small'
              fullWidth={true}
              // labelFontSize='12px'
              // inputFontSize='12px'
              // minRows={3}
              // maxRows={5}
              // multiline={true}
              label="Phone Number"
              value={values.phone}
              onChange={handleInputChange}
            // error={errors.lastName}
            />
            <Grid sx={{ my: '5px', display: 'flex', alignItems: 'center', alignItems: 'center', justifyContent: 'center' }}>
              <Box sx={{ flexGrow: 1 }} />
              {savePhone && <Button onClick={handleSubmitPhone} variant='outlined' color='primary'
                sx={{ textTransform: 'none' }}
              >Save</Button>}
            </Grid>
          </Grid>

          <Grid item gap sx={{ width: '90%', }} >
            <Divider textAlign="left" sx={{ my: '10px', }}>
              <Chip label="MARITAL STATUS" />
            </Divider>
            <Controls.Rgroup
              name="maritalStatus"
              // label="Level"
              value={values.maritalStatus}
              fontSize='13px'
              size="small"
              onChange={handleInputChange}
              items={maritalStatusItems}
            // error={errors.homeTown}
            />
            <Grid sx={{ my: '5px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Box sx={{ flexGrow: 1 }} />
              {saveMaritalStatus && <Button onClick={handleSubmitMaritalStatus} variant='outlined' color='primary'
                sx={{ textTransform: 'none' }}
              >Save</Button>}
            </Grid>
          </Grid>

          <Grid item gap sx={{ width: '90%', }} >
            <Divider textAlign="left" sx={{ my: '10px', }} >
              <Chip label="PERMANENT ADDRESS" />
            </Divider>
          </Grid>
          <Grid item gap sx={{ width: '90%', display: 'flex', flexDirection: { xs: 'column', sm: 'row', md: 'row', lg: 'row' }, alignItems: 'center', justifyContent: 'center' }} >
            <Controls.Input
              name="street"
              size='small'
              fullWidth={true}
              // labelFontSize='12px'
              // inputFontSize='12px'
              label="Steet"
              value={values.street}
              onChange={handleInputChange}
            // error={errors.lastName}
            />
            <Controls.Input
              name="locality"
              size='small'
              fullWidth={true}
              // labelFontSize='12px'
              // inputFontSize='12px'
              label="Locality"
              value={values.locality}
              onChange={handleInputChange}
            // error={errors.lastName}
            />
            <Controls.Input
              name="pin"
              size='small'
              fullWidth={true}
              // labelFontSize='12px'
              // inputFontSize='12px'
              label="Pin"
              value={values.pin}
              onChange={handleInputChange}
            // error={errors.lastName}
            />
          </Grid>
          <Grid item gap sx={{ width: '90%', display: 'flex', flexDirection: { xs: 'column', sm: 'row', md: 'row', lg: 'row' }, alignItems: 'center', justifyContent: 'center' }} >
            <Controls.Input
              name="district"
              size='small'
              fullWidth={true}
              // labelFontSize='12px'
              // inputFontSize='12px'
              disabled={true}
              label="District"
              value={values.homeTownId?.district === undefined ? values.district : values.homeTownId?.district}
            // onChange={handleInputChange}
            // error={errors.lastName}
            />
            <Controls.Input
              name="state"
              size='small'
              fullWidth={true}
              // labelFontSize='12px'
              // inputFontSize='12px'
              disabled={true}
              label="State"
              value={values.homeTownId?.state === undefined ? values.state : values.homeTownId?.state}
            // onChange={handleInputChange}
            // error={errors.lastName}
            />
            <Controls.Input
              name="country"
              size='small'
              fullWidth={true}
              // labelFontSize='12px'
              // inputFontSize='12px'
              disabled={true}
              label="Country"
              value={values.homeTownId?.country === undefined ? values.country : values.homeTownId?.country}
            // onChange={handleInputChange}
            // error={errors.lastName}
            />
          </Grid>
          <Grid item sx={{ width: '90%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Controls.Places
              name="homeTownId"
              label="Change City/District"
              size='small'
              fullWidth={true}
              // listFontSize='14px'
              // labelFontSize='12px'
              // inputFontSize='12px'
              values={values}
              setValues={setValues}
              multiple={false}
              disableClearable={true}
            />
          </Grid>
          <Grid sx={{ my: '5px', display: 'flex', width: '90%', }}>
            <Box sx={{ flexGrow: 1 }} />
            {saveAddress && <Button onClick={handleSubmitAddress} variant='outlined' color='primary'
              sx={{ textTransform: 'none' }}
            >Save</Button>}
          </Grid>

          <Grid item gap sx={{ width: '90%', }} >
            <Divider textAlign="left" sx={{ my: '10px', }} >
              <Chip label="CURRENT/TEMPORARY ADDRESS" />
            </Divider>
          </Grid>
          <Grid item gap sx={{ width: '90%', display: 'flex', flexDirection: { xs: 'column', sm: 'row', md: 'row', lg: 'row' }, alignItems: 'center', justifyContent: 'center' }} >
            <Controls.Input
              name="currentStreet"
              size='small'
              fullWidth={true}
              // labelFontSize='12px'
              // inputFontSize='12px'
              label="Steet"
              value={values.currentStreet}
              onChange={handleInputChange}
            // error={errors.lastName}
            />
            <Controls.Input
              name="currentLocality"
              size='small'
              fullWidth={true}
              // labelFontSize='12px'
              // inputFontSize='12px'
              label="Locality"
              value={values.currentLocality}
              onChange={handleInputChange}
            // error={errors.lastName}
            />
            <Controls.Input
              name="currentPin"
              size='small'
              fullWidth={true}
              // labelFontSize='12px'
              // inputFontSize='12px'
              label="Pin"
              value={values.currentPin}
              onChange={handleInputChange}
            // error={errors.lastName}
            />
          </Grid>
          <Grid item gap sx={{ width: '90%', display: 'flex', flexDirection: { xs: 'column', sm: 'row', md: 'row', lg: 'row' }, alignItems: 'center', justifyContent: 'center' }} >
            <Controls.Input
              name="currentDistrict"
              size='small'
              fullWidth={true}
              // labelFontSize='12px'
              // inputFontSize='12px'
              disabled={true}
              label="District"
              value={values.currentPlaceId?.district === undefined ? values.currentDistrict : values.currentPlaceId?.district}
            // onChange={handleInputChange}
            // error={errors.lastName}
            />
            <Controls.Input
              name="state"
              size='small'
              fullWidth={true}
              // labelFontSize='12px'
              // inputFontSize='12px'
              disabled={true}
              label="State"
              value={values.currentPlaceId?.state === undefined ? values.currentState : values.currentPlaceId?.state}
            // onChange={handleInputChange}
            // error={errors.lastName}
            />
            <Controls.Input
              name="country"
              size='small'
              fullWidth={true}
              // labelFontSize='12px'
              // inputFontSize='12px'
              disabled={true}
              label="Country"
              value={values.currentPlaceId?.country === undefined ? values.currentCountry : values.currentPlaceId?.country}
            // onChange={handleInputChange}
            // error={errors.lastName}
            />
          </Grid>
          <Grid item sx={{ width: '90%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Controls.Places
              name="currentPlaceId"
              label="Change City/District"
              size='small'
              fullWidth={true}
              // listFontSize='14px'
              // labelFontSize='12px'
              // inputFontSize='12px'
              values={values}
              setValues={setValues}
              multiple={false}
              disableClearable={true}
            />
          </Grid>
          <Grid sx={{ my: '5px', display: 'flex', width: '90%', }}>
            <Box sx={{ flexGrow: 1 }} />
            {saveCurrentAddress && <Button onClick={handleSubmitCurrentAddress} variant='outlined' color='primary'
              sx={{ textTransform: 'none' }}
            >Save</Button>}
          </Grid>

          <Grid item gap sx={{ width: '90%', }} >
            <Divider textAlign="left" sx={{ my: '10px', }} >
              <Chip label="LANGUAGES" />
            </Divider>
          </Grid>
          {
            language && language.map((item, index) => <Grid item gap key={index} sx={{ width: '90%', display: 'flex', alignItems: 'center' }}>
              <Grid item gap sx={{ display: 'flex', flexDirection: 'column',  }}>
              <Controls.Input
                name="name"
                size="small"
                // fullWidth={true}
                // label="Language"
                placeholder="Enter a Language"
                value={item.name}
                onChange={(e) => handleLanguageChange(e, index)}
              // error={errors.lastName}
              />
              <Controls.Rgroup
                name="proficiency"
                // label="Level"
                value={item.proficiency}
                fontSize='10px'
                size="small"
                onChange={(e) => handleLanguageChange(e, index)}
                items={levelItems}
              // error={errors.homeTown}
              />
              </Grid>
              {
                language.length !== 1 && (
                  <DisabledByDefaultOutlinedIcon onClick={() => handleLanguageRemove(index)} sx={{ color: 'red', cursor: 'pointer' }} />
                )
              }
              {
                language.length - 1 === index && language.length < 5 && (
                  <AddBoxOutlinedIcon onClick={handleLanguageAdd} sx={{ color: 'lightgreen', cursor: 'pointer' }} />
                )
              }
            </Grid>
            )}
          <Grid sx={{ my: '5px', display: 'flex', alignItems: 'center', width: '90%', }}>
            <Box sx={{ flexGrow: 1 }} />
            {saveLanguage && <Button onClick={handleSubmitLanguages} variant='outlined' color='primary'
              sx={{ textTransform: 'none' }}
            >Save</Button>}
          </Grid>

          <Grid item gap sx={{ width: '90%', }} >
            <Divider textAlign="left" sx={{ my: '10px', }} >
              <Chip label="REFERENCES" />
            </Divider>
          </Grid>
          {
            reference && reference.map((item, index) => <Grid item gap key={index} sx={{ width: '90%', display: 'flex', flexDirection: { xs: 'column', sm: 'row', md: 'row', lg: 'row' }, alignItems: 'center', justifyContent: 'center' }}>
              <Controls.Input
                name="name"
                size='small'
                fullWidth={true}
                // label="Language"
                placeholder="Name"
                value={item.name}
                onChange={(e) => handleReferenceChange(e, index)}
              // error={errors.lastName}
              />
              <Controls.Input
                name="details"
                size='small'
                fullWidth={true}
                // label="Language"
                placeholder="Designation & Company"
                value={item.details}
                onChange={(e) => handleReferenceChange(e, index)}
              // error={errors.lastName}
              />
              <Controls.Input
                name="phone"
                size='small'
                fullWidth={true}
                // label="Language"
                placeholder="Phone"
                value={item.phone}
                onChange={(e) => handleReferenceChange(e, index)}
              // error={errors.lastName}
              />
              <Grid sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Controls.Input
                  name="email"
                  size='small'
                  fullWidth={true}
                  // label="Language"
                  placeholder="Email"
                  value={item.email}
                  onChange={(e) => handleReferenceChange(e, index)}
                // error={errors.lastName}
                />
                {
                  reference.length !== 1 && (
                    <DisabledByDefaultOutlinedIcon onClick={() => handleReferenceRemove(index)} sx={{ color: 'red', cursor: 'pointer' }} />
                  )
                }
                {
                  reference.length - 1 === index && reference.length < 2 && (
                    <AddBoxOutlinedIcon onClick={handleReferenceAdd} sx={{ color: 'lightgreen', cursor: 'pointer' }} />
                  )
                }
              </Grid>
            </Grid>
            )}
          <Grid sx={{ my: '5px', display: 'flex', alignItems: 'center', width: '90%', }}>
            <Box sx={{ flexGrow: 1 }} />
            {saveReference && <Button onClick={handleSubmitReferences} variant='outlined' color='primary'
              sx={{ textTransform: 'none' }}
            >Save</Button>}
          </Grid>

          <Grid item gap sx={{ width: '90%', }} >
            <Divider textAlign="left" sx={{ my: '10px', }} >
              <Chip label="PUBLICATIONS" />
            </Divider>
          </Grid>
          {
            publication && publication.map((item, index) => <Grid item gap key={index} sx={{ width: '90%', display: 'flex', flexDirection: { xs: 'column', sm: 'row', md: 'row', lg: 'row' }, alignItems: 'center', justifyContent: 'center' }}>
              <Controls.Input
                name="authors"
                size='small'
                fullWidth={true}
                // label="Language"
                placeholder="Author's Names"
                value={item.authors}
                onChange={(e) => handlePublicationChange(e, index)}
              // error={errors.lastName}
              />
              <Controls.Input
                name="title"
                size='small'
                fullWidth={true}
                // label="Language"
                placeholder="Title"
                value={item.title}
                onChange={(e) => handlePublicationChange(e, index)}
              // error={errors.lastName}
              />
              <Controls.Input
                name="journal"
                size='small'
                fullWidth={true}
                // label="Language"
                placeholder="Journal Name"
                value={item.journal}
                onChange={(e) => handlePublicationChange(e, index)}
              // error={errors.lastName}
              />
              <Grid sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Controls.Input
                  name="volume"
                  size='small'
                  fullWidth={true}
                  // label="Language"
                  placeholder="Volume, Page & Year"
                  value={item.volume}
                  onChange={(e) => handlePublicationChange(e, index)}
                // error={errors.lastName}
                />
                {
                  publication.length !== 1 && (
                    <DisabledByDefaultOutlinedIcon onClick={() => handlePublicationRemove(index)} sx={{ color: 'red', cursor: 'pointer' }} />
                  )
                }
                {
                  publication.length - 1 === index && publication.length < 5 && (
                    <AddBoxOutlinedIcon onClick={handlePublicationAdd} sx={{ color: 'lightgreen', cursor: 'pointer' }} />
                  )
                }
              </Grid>
            </Grid>
            )}

          <Grid sx={{ my: '5px', display: 'flex', alignItems: 'center', width: '90%', }}>
            <Box sx={{ flexGrow: 1 }} />
            {savePublication && <Button onClick={handleSubmitPublications} variant='outlined' color='primary'
              sx={{ textTransform: 'none' }}
            >Save</Button>}
          </Grid>

          <Grid item gap sx={{ width: '90%' }} >
            <Divider textAlign="left" sx={{ my: '10px', }} >
              <Chip label="CERTIFICATIONS" />
            </Divider>
          </Grid>
          {
            certification && certification.map((item, index) => <Grid item gap key={index} sx={{ width: '90%', display: 'flex', flexDirection: { xs: 'column', sm: 'row', md: 'row', lg: 'row' }, alignItems: 'center', justifyContent: 'center' }} >

              <Controls.Input
                name="name"
                size="small"
                fullWidth={true}
                // label="Language"
                placeholder="Certification Name"
                value={item.name}
                onChange={(e) => handleCertificationChange(e, index)}
              // error={errors.lastName}
              />
              <Controls.Input
                name="institute"
                size="small"
                fullWidth={true}
                // label="Language"
                placeholder="Institute"
                value={item.institute}
                onChange={(e) => handleCertificationChange(e, index)}
              // error={errors.lastName}
              />
              <Grid sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Controls.Input
                  name="year"
                  size="small"
                  fullWidth={true}
                  // label="Language"
                  placeholder="Year"
                  value={item.year}
                  onChange={(e) => handleCertificationChange(e, index)}
                // error={errors.lastName}
                />

                {
                  certification.length !== 1 && (
                    <DisabledByDefaultOutlinedIcon onClick={() => handleCertificationRemove(index)} sx={{ color: 'red', cursor: 'pointer' }} />
                  )
                }
                {
                  certification.length - 1 === index && certification.length < 8 && (
                    <AddBoxOutlinedIcon onClick={handleCertificationAdd} sx={{ color: 'lightgreen', cursor: 'pointer' }} />
                  )
                }
              </Grid>
            </Grid>
            )}

          <Grid sx={{ my: '5px', display: 'flex', alignItems: 'center', width: '90%', }}>
            <Box sx={{ flexGrow: 1 }} />
            {saveCertification && <Button onClick={handleSubmitCertifications} variant='outlined' color='primary'
              sx={{ textTransform: 'none' }}
            >Save</Button>}
          </Grid>

          <Grid item gap sx={{ width: '90%' }} >
            <Divider textAlign="left" sx={{ my: '10px', }} >
              <Chip label="SKILLS" />
            </Divider>
          </Grid>
          <Grid container spacing={2} sx={{ width: { xs: '100%', sm: '100%', md: '90%', lg: '90%' }, alignItems: 'center', justifyContent: 'center' }}>
            {
              skill && skill.map((item, index) => <Grid gap item key={index} xs={10} sm={5} md={4} xl={4} sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
                <Controls.Input
                  name="name"
                  size="small"
                  fullWidth={true}
                  // label="Language"
                  placeholder="Enter Skills"
                  value={item.name}
                  onChange={(e) => handleSkillChange(e, index)}
                // error={errors.lastName}
                />
                {
                  skill.length !== 1 && (
                    <DisabledByDefaultOutlinedIcon onClick={() => handleSkillRemove(index)} sx={{ color: 'red', cursor: 'pointer' }} />
                  )
                }
                {
                  skill.length - 1 === index && skill.length < 10 && (
                    <AddBoxOutlinedIcon onClick={handleSkillAdd} sx={{ color: 'lightgreen', cursor: 'pointer' }} />
                  )
                }
              </Grid>
              )}
          </Grid>
          <Grid sx={{ my: '5px', display: 'flex', alignItems: 'center', width: '90%', }}>
            <Box sx={{ flexGrow: 1 }} />
            {saveSkill && <Button onClick={handleSubmitSkills} variant='outlined' color='primary'
              sx={{ textTransform: 'none' }}
            >Save</Button>}
          </Grid>

          <Grid sx={{ m: '5px', display: 'flex', alignItems: 'center', width: '90%', }}>
            <Button variant="outlined" color='warning' component={Link} to={`/user/${userSlug}/${userId}`} sx={{ display: 'flex', textTransform: 'none', fontSize: '11px', mx: '4px', my: '5px', width: '100px' }} startIcon={<ArrowBackIcon fontSize="small" />}> Profile
            </Button>
            <Box sx={{ flexGrow: 1 }} />
            {
              pdfUpdate ? <Button variant="outlined" color='warning' disabled  sx={{ display: 'flex', textTransform: 'none', fontSize: '11px', mx: '4px', my: '5px', width: '120px' }} startIcon={<PictureAsPdfOutlinedIcon fontSize="small" />}> Updating..
              </Button>
              :
              <Button variant="outlined" color='warning' onClick={handleUpdatePdf} sx={{ display: 'flex', textTransform: 'none', fontSize: '11px', mx: '4px', my: '5px', width: '120px' }} startIcon={<PictureAsPdfOutlinedIcon fontSize="small" />}> Update PDF
            </Button>
            }
            
          </Grid>
        </Grid>

        {
          openSnackbar &&  
          <Snackbar open={openSnackbar} autoHideDuration={3000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity={severity} sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
        }

        {loading && (
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
          >
            <CircularProgress
              size={24}
              sx={{
                color: 'inherit',
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: '-12px',
                marginLeft: '-12px',
              }}
            />
          </Backdrop>)}
      </Card>
    </Paper>
  );
}
