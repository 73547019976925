import * as React from 'react';
import NavBar from '../components/nav/NavBar'
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import ProfileSidebar from './widgets/ProfileSidebar';
import OrgSuggestion from './widgets/OrgSuggestion';
import { useDispatch, useSelector } from 'react-redux';
import ProfileSidebarSelf from './widgets/ProfileSidebarSelf';
import JobCardDetail from './widgets/JobCardDetail';
import NavBarPublic from '../components/nav/NavBarPublic';
import { useLocation, useNavigate } from 'react-router-dom';
import { login, reset } from '../features/userAuth/authSlice';
import LoginModal from './modals/LoginModal';
import Loggedin from './Loggedin';
import AdsCard from './widgets/AdsCard';

export default function OrgJobPage() {

  const dispatch = useDispatch()

  const { user_id } = useSelector(
    (state) => state.user
  )

  const { user, userId, isLoading, isError, isSuccess, message, status } = useSelector(
    (state) => state.auth
  )

  const navigate = useNavigate();
  const location = useLocation();

  const [open, setOpen] = React.useState(false)

  const handleClose = (result) => {
    setOpen(false)
  };

  const handleFailure = (result) => {
    console.log(result);
    setOpen(false)
  };

  React.useEffect(() => {
    if (isError) {
      console.log(message)
      setOpen(false)
    }
    if (user && status === "inactive") {
      navigate('/login')
    }
    dispatch(reset())
  }, [reset, status, isError, isSuccess, message, navigate, dispatch])


  const handleLogin = (googleData) => {
    const userData = JSON.stringify({ token: googleData.credential });
    setOpen(false)
    dispatch(login(userData))
  };

  if (isLoading) {
    return console.log("Loading")
  }
  console.log(userId)

  return (
    <Paper elevation={0} sx={{ bgcolor: 'background.default', minHeight: '100vh', paddingBottom: '60px' }} >
      {
        (userId && status === "active") ? (
          <>
            <NavBar />
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={3} sx={{ justifyContent: 'center' }} >
                <Grid item xs={11} sm={10} md={6} sx={{ minWidth: '320px' }}>

                  <JobCardDetail />
                </Grid>

                <Grid item xs={3} sx={{ display: { xs: 'none', sm: 'none', md: 'block' }, minWidth: '280px' }} >
                  <ProfileSidebarSelf />

                  <AdsCard /> 
                  <OrgSuggestion />

                </Grid>
              </Grid>
            </Box>
          </>
        ) :  (userId && status === "inactive") ? <Loggedin data={user} /> : 
        (
          <>
        <NavBarPublic setOpen={setOpen} />
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={3} sx={{ justifyContent: 'center' }} >
            <Grid item xs={11} sm={10} md={6} sx={{ minWidth: '320px' }}>

              <JobCardDetail setOpen={setOpen} />
            </Grid>
            <Grid item xs={3} sx={{ display: { xs: 'none', sm: 'none', md: 'block' }, minWidth: '280px' }} >

              <Card sx={{ my: '10px' }} >
                Ads
              </Card>

            </Grid>
          </Grid>
        </Box>
      </>
        )
          }

      <LoginModal open={open} handleClose={handleClose} handleLogin={handleLogin} handleFailure={handleFailure} />
    </Paper>
  );
}
