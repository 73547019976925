import { AppBar, Box, Button, Container, Toolbar, Typography } from '@mui/material'

import React from 'react'

export default function NavBarLogin({setOpen}) {
  return (
    <AppBar position="static" color='transparent'>
      <Container maxWidth="xl">
        <Toolbar disableGutters>          
          {/* <Typography
            variant="h4"            
            noWrap
            // component="a"
            // href="#app-bar-with-responsive-menu"
            sx={{
              color:'text.warning',
              mr: 2,              
              fontFamily: 'monospace',
              fontWeight: 600,
              letterSpacing: '.1rem',
            //   color: 'inherit',
              textDecoration: 'none',
            }}
          >
            Karpath
          </Typography>         */}
          
         

          <Box sx={{ flexGrow: 1 }} />
            <Button onClick={() => setOpen(true)} variant='outlined' color='warning' sx={{mt: '3px', textDecoration: 'none'}}>
                Login
            </Button>
          
        </Toolbar>
      </Container>
    </AppBar>
  )
}
