import * as React from 'react';
import { FormControl, FormControlLabel, Checkbox, Box } from '@mui/material';

export default function ChkboxValues(props) {

  const { name, label, value, checked, setChecked, onChange } = props;
  // const [checked, setChecked] = React.useState([]);

//   const handleChkboxChange = (event) => {        
//     let updatedList = [...checked];   
//     if (event.target.checked) {
//       updatedList = [...checked, event.target.value];
//     } else {
//       updatedList.splice(checked.indexOf(event.target.value), 1);
//     }
//     setChecked(updatedList);
// }
  
  // const convertToDefEventPara = (name, value) => ({
  //   target: {
  //     name, value
  //   }
  // })
  // console.log(name)

  return (
    <FormControl>
      <FormControlLabel
        control={<Checkbox
        size='small'
          name={name}
          color="primary"
          value={value}
          checked={checked}
          onChange={onChange}
        />}
        label={label}
      />
    </FormControl>
  );
}