import React, { useState, useEffect } from 'react';
import Controls from "../components/controls/Controls";
import { Backdrop, Box, Button, Card, CardMedia, Grid, InputLabel, Paper } from '@mui/material';
import { UseForm, Form } from '../components/UseForm';
import axios, { axiosPrivate } from '../api/axios';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { useDispatch, useSelector } from 'react-redux';
import FileUpload from '../components/controls/FileUpload';
import CircularProgress from '@mui/material/CircularProgress';
// import places from '../country-state-district.json'
import { daysToWeeks } from 'date-fns';
import { update } from '../features/userAuth/authSlice';

const genderItems = [
  { id: 'male', title: 'Male' },
  { id: 'female', title: 'Female' },
  { id: 'other', title: 'Other' },
]


const maxFiles = 3;
const DEFAULT_MAX_FILE_SIZE_IN_BYTES = 250000;


function Loggedin(props) {

  const { onClick, data } = props

  // const info = JSON.parse(localStorage.getItem('info'))
  useAxiosPrivate()
  const dispatch = useDispatch();

  const [userCerts, setUserCerts] = useState({
    certs: []
  });
  // const [submitted, setSubmitted] = useState(info ? info.submitted : false)
  const [loading, setLoading] = useState(false)
  const [fileError, setFileError] = useState(false)

  const initialValues = {
    // id: 0,
    firstName: data?.given_name,
    lastName: data?.family_name,
    email: data?.email,
    phone: data?.phone,
    // firstName: user.data.given_name,
    // lastName: user.data.family_name,
    // email: user.data.email,
    // aadhar: '',
    homeTown: '',
    gender: '',
    dateOfBirth: null,
  }

  const updateUploadedFiles = (files) =>
    setUserCerts({ ...userCerts, certs: files });

  const validate = (fieldValues = values) => {
    console.log(fieldValues)
    let temp = { ...errors }
    if ('firstName' in fieldValues)
      temp.firstName = fieldValues.firstName ? "" : "First name without space is required."
    if ('lastName' in fieldValues)
      temp.lastName = fieldValues.lastName ? "" : "Last name with initial is required."
    if ('dateOfBirth' in fieldValues)
      temp.dateOfBirth = fieldValues.dateOfBirth ? "" : "Date of Birth is required."
    if ('gender' in fieldValues)
      temp.gender = fieldValues.gender ? "" : "Gender is required."
    if ('phone' in fieldValues)
      temp.phone = fieldValues.phone?.length === 10 ? "" : "Valid Mobile Number is required."
    // if ('aadhar' in fieldValues)
    //   temp.aadhar = fieldValues.aadhar.length === 12 ? "" : "Valid Aadhar Number is required."

    setErrors({
      ...temp
    })
    console.log(temp)

    if ((fieldValues = values))
      return Object.values(temp).every(x => x === "")
  }

  const {
    values,
    setValues,
    files,
    setFiles,
    errors,
    setErrors,
    address,
    setAddress,
    handleInputChange,
  } = UseForm(initialValues, true, validate);

  const userInfo = {
    given_name: values.firstName,
    family_name: values.lastName,
    email: values.email,
    phone: values.phone,
    // aadhar: values.aadhar,
    homeTownId: values.homeTown.id,
    // district: address.district,
    // state: address.state,
    // country: address.country,
    gender: values.gender,
    dateOfBirth: values.dateOfBirth,
  }

  console.log(userInfo)


  const handleSubmit = async (e) => {
    e.preventDefault()
    if (validate()) {
      // console.log(Object.keys(files).length === 0)
      // if (Object.keys(files).length === 0) return setFileError(true);
      // setSubmitted(true)
      setLoading(true)
      // console.log(files)
      const formData = new FormData()

      for (let key in userInfo) {
        formData.append(key, userInfo[key])
      }
      // for (let file of Object.keys(files)) {
      //   formData.append('files', files[file])
      // }

      console.log('running')

      try {
        // if (info) {

        //   if (info.infoMessage !== null) {
        //     const res = await axiosPrivate.put('/api/user/submit/update', formData);
        //     if (!res) return
        //     if (res.data) {
        //       // setSubmitted(true)
        //       localStorage.setItem('info', JSON.stringify(res.data.data));
        //     }
        //     if (res.status === 200) {
        //       setSubmitted(true)
        //     }
        //     setLoading(false)
        //     console.log(res)
        //   }
        // } else {
        const res = await axiosPrivate.put('/api/user/submit', formData);
        if (!res) return
        // if (res.data) {
        //   localStorage.setItem('user', JSON.stringify(res.data.data))
        // }
        if (res.status === 200) {
          localStorage.setItem('user', JSON.stringify(res.data.data))
          dispatch(update(res.data.data))
          handleFetchLocationJobs(res.data.data.homeTownId)
          // setSubmitted(true)
        }
        setLoading(false)
        console.log(res.data.data)
        // }

      } catch (err) {
        console.error(err);
        setLoading(false)
        // setSubmitted(false)
      }
    }
  }

  const handleFetchLocationJobs = async (homeTownId) => {
    try {
      // setLoading(true)
      const res = await axiosPrivate.get(`/api/post/jobs/${homeTownId}/fetch`)
      if (res === 201) {
        // setLoading(false)
        const data = res.data.data
        // setDesignation(data)
        // setJobs(data)
      }
    } catch (err) {
      console.error(err);
    }
  }

  const handleRefreshPage = async () => {
    window.location.reload()
  }

  return (

    <Paper elevation={0} square sx={{ bgcolor: 'background.default', display: 'flex', alignItems: 'center', justifyContent: 'center', height: { xs: 'auto', md: '100vh' }, overflowY: { xs: 'auto', md: 'hidden' } }}>

      <Form onSubmit={handleSubmit}>
        <Grid container
          sx={{
            '& .MuiGrid-grid-xs-6:nth-of-type(1)': { mb: '5px' },
            '& .MuiGrid-root:nth-of-type(2)': { mt: '0px' },

            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            alignItems: 'center',
            justifyContent: 'center',
            m: ' 20px auto',
            bgcolor: 'background.paper',
            overflow: 'hidden',
            borderRadius: '12px',
            boxShadow: 2,
            // height: '100 vh',
            maxWidth: { xs: 320, md: 720 },
          }}
        >
          {/* {info?.infoMessage && <Box sx={{ my: '10px', color: '#d64113' }}>
            {info.infoMessage}
          </Box>} */}

          <Grid item xs={12}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              // bgcolor: 'primary.main',
              alignItems: { xs: 'center', md: 'center' },
              mt: 3,
              height: '50px',
              maxWidth: { xs: 300, md: 700 },
            }}
          >
            <CardMedia
              component="img"
              // height="40"
              image="/karpath.png"
              alt="Karpath"
              onClick={handleRefreshPage}
              sx={{ maxWidth: "150px", height: 'auto', cursor: 'pointer' }}
            />
          </Grid>

          <Box
            sx={{
              '& .MuiTextField-root': { m: 1, width: '100%' },
              // '&  .MuiButton-root': { mx: '5px', mt: '10px' },
              // '& .MuiGrid-root:nth-of-type(2) > div:nth-of-type(4)': { fontSize: '12px', mt: '15px' },
              '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                display: 'none'
             },

              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Grid item xs={6}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: { xs: 'center', md: 'center' },
                m: 3,
                height: 'auto',
                minWidth: { xs: 280, md: 280 },
              }}
            >
              <Controls.Input
                name="firstName"
                label="First Name *"
                value={values.firstName}
                onChange={handleInputChange}
                error={errors.firstName}
              />
              <Controls.Input
                name="lastName"
                label="Last Name *"
                value={values.lastName}
                onChange={handleInputChange}
                error={errors.lastName}
              />

              <Controls.Input
                name="email"
                label="email"
                value={values.email}
                onChange={handleInputChange}
                disabled={true}
              />
              {/* <Controls.Input
                name="homeTown"
                label="Home Town"
                value={values.homeTown}
                onChange={handleInputChange}
                error={errors.homeTown}
              /> */}

              {/* <Controls.Input
                name="aadhar"
                label="ID/Aadhar Number *"
                // pattern="[0-9]*"
                value={values.aadhar}
                onChange={handleInputChange}
                error={errors.aadhar}
              /> */}
              <Controls.Input
                name="phone"
                label="Mobile Number *"
                // pattern="[0-9]*"
                value={values.phone}
                onChange={handleInputChange}
                error={errors.phone}
                type="number"
              />
            </Grid>

            <Grid item xs={6}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: { xs: 'center', md: 'center' },
                m: 3,
                height: 'auto',
                minWidth: { xs: 280, md: 280 },
              }}
            >

              <Box sx={{ ml: '-17px', width: '100%' }}>
                <Controls.Places
                  name="homeTown"
                  label="City/District"
                  // size='small'
                  // listFontSize='14px'
                  // labelFontSize='12px'
                  // inputFontSize='12px'
                  values={values}
                  setValues={setValues}
                  multiple={false}
                  disableClearable={true}
                  required={true}
                // onChange={handleInputChange}
                // error={errors.BranchesLocations}
                // onChange={handleInputChange}
                // error={errors.homeTown}
                // address={address}
                // setAddress={setAddress}
                // handleSelectPlace={handleSelectPlace}
                />
              </Box>

              <Controls.DPicker
                name="dateOfBirth"
                label="Date Of Birth *"
                value={values.dateOfBirth}
                onChange={handleInputChange}
                error={errors.dateOfBirth}
                disableFuture={true}
              />

              <Controls.Rgroup
                name="gender"
                label="Gender *"
                value={values.gender}
                onChange={handleInputChange}
                size="small"
                fontSize="13px"
                items={genderItems}
                error={errors.gender}
              />

              {/* <Box>
                <Controls.FileUpload
                  accept=".jpg,.png,.jpeg"
                  label="Upload your SSLC, 10th certs and Aadhar to verify name and age."
                  files={files}
                  setFiles={setFiles}
                  multiple
                  updateFilesCb={updateUploadedFiles}
                  maxNoFiles={maxFiles}
                  maxFileSizeInBytes={DEFAULT_MAX_FILE_SIZE_IN_BYTES}
                  setFileError={setFileError}
                />
                {fileError && <Box sx={{ fontSize: '14px', color: '#d64113' }}> * Please Upload a Certificate. </Box>}
              </Box> */}
              <div style={{ postition: 'relative' }}>
                <Controls.Btn
                  type="submit"
                  text="Create"
                  color="success"
                  size="medium"
                // disabled={submitted}
                />
                {loading && (
                  <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loading}
                  // onClick={handleClose}
                  >
                    <CircularProgress
                      size={24}
                      sx={{
                        color: 'inherit',
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        marginTop: '-12px',
                        marginLeft: '-12px',
                      }}
                    />
                  </Backdrop>
                )}
                {/* <Controls.Btn
                  text="Log Out"
                  color="success"
                  onClick={onClick}
                /> */}
              </div>
              <Box sx={{ mt: '12px', fontSize: '12px', fontWeignt: 400 }}>
                By creating, you accept our Terms and Conditions & Privacy Policy.
              </Box>
              {/* {submitted && <Box sx={{ my: '10px', color: '#4ee44e' }}>
                Success! Logout and login back later.
              </Box>
              } */}

            </Grid>
          </Box>
          <Box sx={{ mb: '5px', mt: '7px', color: 'text.secondary', fontSize: '9px', fontWeignt: 400 }}>
            Karpath Infotech Pvt Ltd.  1st Floor, A Square Building, Kochi, Kerala, IN.
          </Box>
        </Grid>
      </Form>


    </Paper>
  )
}

export default Loggedin
