import { Paper, Typography } from '@mui/material'
import React from 'react'
import NavBar from '../components/nav/NavBar'

export default function Inbox() {
  return (
    <Paper elevation={0} sx={{ bgcolor: 'background.default', minHeight: '100vh',  paddingBottom: '60px' }} >
         <NavBar />
         <Typography sx={{m: '10px'}}>
            Your Inbox is Empty!
         </Typography>

        </Paper>
  )
}

