import * as React from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { axiosPrivate } from '../../api/axios';
import '../../App.css';
import { PdfViewerComponent, Toolbar, Magnification, Navigation, LinkAnnotation, BookmarkView,
  ThumbnailView, Print, TextSelection, Annotation, TextSearch, FormFields, FormDesigner, PageOrganizer, Inject} from '@syncfusion/ej2-react-pdfviewer';

export default function ViewResumePdfModal({ open, handleClose, selectedRow, loading, setLoading, setAnchorEl, setPdfUrl, pdfUrl, orgId, selectedItem, setSelectedItem }) {

  const [loadingShortlist, setLoadingShortlist] = React.useState(false)
  const [loadingReject, setLoadingReject] = React.useState(false)
    
  const handleViewPdf = async () => {
    try {
    //   setLoading(true)
      // setPdfUrl()
    //   setAnchorEl(null);
      const filename = {
        filename: selectedRow.resumes[0].split('.com/').pop()
      }
      // console.log(filename)
      const res = await axiosPrivate.post(`/api/user/resume/${orgId}/fetch/view_resume`, filename)
      if (res.status === 200) {
        const data = res.data.data.url
        // setLoading(false)
        setPdfUrl(data)
      }
    } catch (err) {
      console.error(err);
    //   setLoading(false)
    }
  };

  const handleShortlist = async () => {
    try {
      setLoadingShortlist(true)
   
      const res = await axiosPrivate.put(`/api/post/${selectedItem._id}/${selectedRow._id}/shortlist`)
      if (res.status === 201) {
        const data = res.data.data
        setSelectedItem(data)
        setLoadingShortlist(false)      
      }
    } catch (err) {
      console.error(err);
      setLoadingShortlist(false)
    }
  };

  const handleUnShortlist = async () => {
    try {
      setLoadingShortlist(true)
   
      const res = await axiosPrivate.put(`/api/post/${selectedItem._id}/${selectedRow._id}/unshortlist`)
      if (res.status === 201) {
        const data = res.data.data
        setSelectedItem(data)   
        setLoadingShortlist(false)   
      }
    } catch (err) {
      console.error(err);
      setLoadingShortlist(false)
    }
  };

  const handleReject = async () => {
    try {
      setLoadingReject(true)
   
      const res = await axiosPrivate.put(`/api/post/${selectedItem._id}/${selectedRow._id}/reject`)
      if (res.status === 201) {
        const data = res.data.data
        setSelectedItem(data)  
        setLoadingReject(false)   
      }
    } catch (err) {
      console.error(err);
      setLoadingReject(false)
    }
  };

  const handleUnReject = async () => {
    try {
      setLoadingReject(true)
   
      const res = await axiosPrivate.put(`/api/post/${selectedItem._id}/${selectedRow._id}/unreject`)
      if (res.status === 201) {
        const data = res.data.data
        setSelectedItem(data)    
        setLoadingReject(false)  
      }
    } catch (err) {
      console.error(err);
      setLoadingReject(false)
    }
  };

  let viewer;

  React.useEffect(() => {
    handleViewPdf()
  }, [])

  return (

    <Dialog
      fullScreen
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}      
    >
      {/* <DialogTitle id="responsive-dialog-title" sx={{ bgcolor: 'background.paper', display: 'flex', position: "fixed", width: "100%", px:'10px', height: '20px', zIndex: 1600,  }}>
        {
          loading ? <Typography variant='subtitle2'>Loading..</Typography> : <Typography variant='subtitle2' sx={{ maxWidth: '350px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}></Typography>
        }
        <Box sx={{ flexGrow: 1 }} />
        <IconButton onClick={handleClose} sx={{ mr: '10px'}} >
          <CloseIcon />
        </IconButton>
      </DialogTitle> */}

      <DialogContent sx={{ mt: '5px', overflow: 'hidden'  }} >
      <div className='control-section' > 
      <PdfViewerComponent   
        ref={(scope) => { viewer = scope; }}     
        id="container"
        documentPath={pdfUrl}
        resourceUrl="https://cdn.syncfusion.com/ej2/23.1.40/dist/ej2-pdfviewer-lib"        
        style={{ 'height': '87vh',  }}>
         
         <Inject services={[ Toolbar, Magnification, Navigation, ThumbnailView, Print, TextSearch, PageOrganizer ]}/>

      </PdfViewerComponent>

      </div>        

      </DialogContent>
      <DialogActions sx={{ display: 'flex' }}>
            
                <Button variant="outlined" onClick={handleClose} color='warning' sx={{ m: '5px', textTransform: 'none', fontSize: '11px' }} >  Close
                </Button>
                {
                  loadingReject ? <Button variant="outlined" onClick={handleClose} disabled sx={{ m: '5px', textTransform: 'none', fontSize: '11px' }} >  Loading..
                  </Button> : (selectedItem?.rejected.includes(selectedRow._id)) ?
                  <Button variant="outlined" onClick={handleUnReject} color='success' sx={{ m: '5px', textTransform: 'none', fontSize: '11px' }} >  Unreject
                  </Button> :
                  <Button variant="outlined" onClick={handleReject} color='error' sx={{ m: '5px', textTransform: 'none', fontSize: '11px' }} >  Reject
                  </Button>
                } 
                {
                  loadingShortlist ? <Button variant="outlined" onClick={handleClose} disabled sx={{ m: '5px', textTransform: 'none', fontSize: '11px' }} >  Loading..
                  </Button> : (selectedItem?.shortlisted.includes(selectedRow._id)) ?
                  <Button variant="outlined" onClick={handleUnShortlist} color='error' sx={{ m: '5px', textTransform: 'none', fontSize: '11px' }} >  Unshortlist
                  </Button> :
                  <Button variant="outlined" onClick={handleShortlist} color='success' sx={{ m: '5px', textTransform: 'none', fontSize: '11px' }} >  Shortlist
                  </Button>
                }               
            </DialogActions>

    </Dialog>

  );
}
