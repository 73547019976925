import React from "react";
import axios from "../api/axios";
import { Paper, Box, ThemeProvider, Divider, Grid, Typography, createTheme,  } from "@mui/material";
import { useParams } from "react-router-dom";
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import HomeRepairServiceOutlinedIcon from '@mui/icons-material/HomeRepairServiceOutlined';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import CardMembershipOutlinedIcon from '@mui/icons-material/CardMembershipOutlined';
import moment from 'moment'
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import CircleIcon from '@mui/icons-material/Circle';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import RecommendIcon from '@mui/icons-material/Recommend';
import { styled as muiStyled } from '@mui/material/styles';
import Rating from '@mui/material/Rating';
import styled from "styled-components";

const StyledIcon = muiStyled(Rating)({
  '& .MuiRating-iconFilled': {
    color: '#ffff',
  },
  // '& .MuiRating-iconHover': {
  //   color: '#ff3d47',
  // },
});

const Photo = styled.img`
border-radius: 6px;
margin: 5px;
width: 200px;
height: 256px;
object-fit: cover;
`;


function ResumeForPdf() {
 
  const {userId} = useParams()
  // const userId = '630f46549722e9ef9d198c32'
  const theme = createTheme()
    
    const [self, setSelf] = React.useState();
    const [resumeDetails, setResumeDetails] = React.useState();
    console.log("first")

    const fetchSelf = async () => {
      // e.preventDedault() 
      try {
        console.log("2nd")
          // setLoading(true)
          const res = await axios.get(`/api/user/puppeteer/resume/${userId}`)
          if (res.status == 200) {  
              const data = res.data.data
              setSelf(data)
              setResumeDetails(res.data.resumeDetails)
          }
      } catch (err) {
          console.error(err);
      }
  };

  let relationship = ""

  {
    (() => {
      switch (self && self?.maritalStatus) {
        case 1:
          return relationship = "Unmarried"
        case 2:
          return relationship = "Married"
        case 3:
          return relationship = "Divorced"
        case 4:
          return relationship = "Widow"
        case 5:
          return relationship = "Widower"
      }
    })()
  }
  console.log(self)

  const courseDetails = self !== undefined && resumeDetails?.filter((item) => item.designationId === null && item.isInternship === false)
  const employementDetails = self !== undefined && resumeDetails?.filter((item) => item.courseId === null && item.isInternship === false)
  const internshipDetails = self !== undefined && resumeDetails?.filter((item) => item.courseId === null && item.isInternship === true)
  
    React.useEffect(() => {
      fetchSelf()
    }, [])
  
   // A4 SIZE ADJUSTED FOR PUPPETEER SCREEN
  
    return (
      <ThemeProvider theme={theme}>

          <Paper elevation={0} square={true} sx={{ height: '1540px', width: '1042px', }} > 

         <Grid container spacing={0.5} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center',  }} >
        <Grid item sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', mb: '20px' }} >
          <Typography sx={{ fontSize:'3vw', fontWeight: '600' }}> {self?.firstName.toUpperCase()} {self?.lastName.toUpperCase()} </Typography>
          <Typography sx={{ fontFamily: 'Antonio', fontSize:'2.1vw',  fontWeight: '300' }}> {self?.designation.toUpperCase()} </Typography>
        </Grid>

      </Grid>

      <Grid container spacing={1} sx={{ display: 'flex', alignContent: 'stretch', height: '91%',   }} >
        <Grid item xs={4} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100%', }} >

          <Paper variant='outlined' elevation={0} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', border: '1px', borderColor: "whitesmoke", width: '100%', height: '100%', backgroundColor: '#9aa1d6',  m: '5px' }}>

         { self?.profile_pic && <Photo src={self?.profile_pic} alt='Photo' style={{marginTop: '10px'}} />}

            <Typography sx={{ fontSize: '1.8vw', fontWeight: '600', mb: '5px', mt: '8px' }}> --ADDRESS-- </Typography>

            <Typography sx={{ fontSize: '1.6vw', }}> {self?.address?.street} </Typography>
            <Typography sx={{ fontSize: '1.6vw', mb: '3px' }}> {self?.address?.locality} - {self?.district} </Typography>
            <Typography sx={{ fontSize:'1.6vw',  mb: '3px' }}> {self?.state} - {self?.address?.pin} </Typography>
            <Typography sx={{ fontSize: '1.6vw',  }}> {self?.phone} </Typography>
            <Typography sx={{ fontSize: '1.6vw', }}> {self?.email} </Typography>


            <Typography sx={{ fontSize: '1.8vw', fontWeight: '600', mt: '15px', mb: '5px' }}> --DATE OF BIRTH-- </Typography>
            <Typography sx={{ fontSize: '1.8vw',  }}> {moment(self?.dateOfBirth).format("DD-MM-YYYY")} </Typography>



            <Typography sx={{ fontSize:'1.8vw',  fontWeight: '600', mt: '15px', mb: '5px' }}> --NATIONALITY-- </Typography>
            <Typography sx={{ fontSize: '1.8vw',  }}> {self?.country} </Typography>



            <Typography sx={{ fontSize:'1.8vw', fontWeight: '600', mt: '15px', mb: '5px' }}> --MARITAL STATUS-- </Typography>
            <Typography sx={{ fontSize: '1.6vw' }}> {relationship} </Typography>



            <Typography sx={{ fontSize:'1.8vw', fontWeight: '600', mt: '15px', mb: '5px' }}> --SKILLS-- </Typography>
            {
              self?.skills.length !== 0 && self?.skills.map((element) => <Box key={Math.random()} >
                <Typography sx={{ fontSize:'1.6vw'}}> {element.name} </Typography>
              </Box>
              )}


            <Typography sx={{ fontSize: '1.8vw', fontWeight: '600', mt: '15px', mb: '5px' }}> --LANGUAGES-- </Typography>
            {
              self?.languages.length !== 0 && self?.languages.map((element) => <Box key={Math.random()} >
                <Typography sx={{ fontSize:'1.6vw',  }}> {element.name} </Typography>
                <StyledIcon
                  // name="customized-color"
                  defaultValue={element.proficiency}
                  disabled
                  // getLabelText={(value) => `${value} Heart${value !== 1 ? 's' : ''}`}
                  precision={1}
                  icon={<CircleIcon fontSize="small" />}
                  emptyIcon={<CircleOutlinedIcon fontSize="small" />}
                />
              </Box>
              )}

          </Paper>

        </Grid>

        <Grid item xs={8} sx={{height: '100%',}}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <PersonOutlineIcon sx={{ fontSize: '2.5vw',mr: '5px' }} />
            <Typography sx={{ fontSize: '1.9vw',  fontWeight: '600' }}> PROFILE </Typography> </Box>
          <Divider sx={{ mb: '10px' }} />
          <Typography sx={{ fontSize: '1.7vw', ml: '15px', fontWeight: '400' }}>
            {self?.bio} </Typography>

          {
            self !== undefined && employementDetails.length !== 0 && <>
              <Box sx={{ display: 'flex', alignItems: 'center', mt: '20px' }}>
                <HomeRepairServiceOutlinedIcon sx={{ fontSize:'2.5vw', mr: '5px' }} />
                <Typography sx={{ fontSize: '1.9vw', fontWeight: '600' }}> EMPLOYMENT HISTORY </Typography> </Box>
              <Divider sx={{ mb: '10px' }} />
            </>
          }

          {
             self !== undefined && employementDetails.length !== 0 && employementDetails.map((element) => <Box key={element.id} sx={{ mt: '7px', ml: '15px' }}>
              <Typography sx={{ fontSize: '1.7vw', fontWeight: '400' }}> {element.receiverName} - {element.receiverLocation.district}  </Typography>
              <Typography sx={{ fontSize: '1.6vw', fontStyle: 'italic' }}> {element.designationName} | {moment(element.joinedDate).format("MMM YYYY")}  - {element.leftDate !== null ? moment(element.leftDate).format("MMM YYYY") : "Working"} </Typography>
            </Box>
            )
          }

          {
            self !== undefined && internshipDetails.length !== 0 && <>
              <Box sx={{ display: 'flex', alignItems: 'center', mt: '20px' }}>
                <CardMembershipOutlinedIcon sx={{ fontSize:'2.5vw', mr: '5px' }} />
                <Typography sx={{ fontSize:'1.9vw', fontWeight: '600' }}> INTERNSHIPS </Typography> </Box>
              <Divider sx={{ mb: '10px' }} />
            </>
          }
          {
            self !== undefined && internshipDetails.length !== 0 && internshipDetails.map((element) => <Box key={element.id} sx={{ mt: '7px', ml: '15px' }}>
              <Box sx={{ mt: '7px' }}>
                <Typography sx={{ fontSize: '1.7vw', fontWeight: '400'  }}> {element.receiverName} - {element.receiverLocation.district}  </Typography>
                <Typography sx={{ fontSize:'1.6vw',  fontStyle: 'italic' }}> {element.designationName} | {moment(element.joinedDate).format("MMM YYYY")}  - {element.leftDate !== null ? moment(element.leftDate).format("MMM YYYY") : "Awaiting Result"} </Typography>
              </Box>
            </Box>
            )
          }

          {
            self !== undefined && courseDetails.length !== 0 && <>
              <Box sx={{ display: 'flex', alignItems: 'center', mt: '20px' }}>
                <SchoolOutlinedIcon sx={{ fontSize: '2.5vw', mr: '5px' }} />
                <Typography sx={{ fontSize: '1.9vw', fontWeight: '600' }}> ACADEMIC HISTORY </Typography> </Box>
              <Divider sx={{ mb: '10px' }} />
            </>
          }

          {
            self !== undefined && courseDetails.length !== 0 && courseDetails.map((element) => <Box key={element.id} sx={{ mt: '7px', ml: '15px' }}>
              <Typography sx={{ fontSize:'1.7vw', fontWeight: '400' }}> {element.receiverName} - {element.receiverLocation.district}  </Typography>
              <Typography sx={{ fontSize: '1.6vw',  fontStyle: 'italic' }}> {element.courseName} | {moment(element.joinedDate).format("MMM YYYY")}  - {element.leftDate !== null ? moment(element.leftDate).format("MMM YYYY") : "Awaiting Result"} </Typography>
            </Box>
            )
          }

          {
            self !== undefined && self?.publications.length !== 0 && <>
              <Box sx={{ display: 'flex', alignItems: 'center', mt: '20px' }}>
                <HistoryEduIcon sx={{ fontSize:'2.5vw',  mr: '5px' }} />
                <Typography sx={{ fontSize: '1.9vw',  fontWeight: '600' }}> PUBLICATIONS </Typography> </Box>
              <Divider sx={{ mb: '10px' }} />
            </>
          }
          {
            self !== undefined && self?.publications.length !== 0 && self?.publications.map((element) => <Box key={element._id} sx={{ mt: '7px', ml: '15px' }}>
              <Box sx={{ overflowWrap: 'normal', mt: '7px' }}>
                <Typography sx={{ fontSize:'1.7vw', fontWeight: '400'  }}> {element.authors}. "{element.title}", {element.journal}, {element.volume}. </Typography>                
              </Box>
            </Box>
            )
          }

          {
            self?.certifications.length !== 0 && <>
              <Box sx={{ display: 'flex', alignItems: 'center', mt: '20px' }}>
                <CardMembershipOutlinedIcon sx={{ fontSize:'2.5vw',mr: '5px' }} />
                <Typography sx={{ fontSize:'1.9vw', fontWeight: '600' }}> CERTIFICATIONS </Typography> </Box>
              <Divider sx={{ mb: '10px' }} />
            </>
          }
          {
            self?.certifications.length !== 0 && self?.certifications.map((element) => <Box key={element._id} sx={{ mt: '7px', ml: '15px' }}>

              <Box sx={{ display: 'flex', mt: '7px' }}>
                <Typography sx={{ fontSize: '1.7vw', fontStyle: 'italic' }}> {element.name} - </Typography>
                <Typography sx={{ fontSize: '1.7vw' }}> &nbsp; {element.institute},   </Typography>
                <Typography sx={{ fontSize: '1.7vw',  }}> &nbsp; {element.year}.   </Typography>
              </Box>
            </Box>
            )
          }

          {
            self?.references.length !== 0 && <>
              <Box sx={{ display: 'flex', alignItems: 'center', mt: '20px' }}>
                <RecommendIcon sx={{ fontSize: '2.5vw', mr: '5px' }} />
                <Typography sx={{ fontSize: '1.9vw', fontWeight: '600' }}> REFERENCES </Typography> </Box>
              <Divider sx={{ mb: '10px' }} />
            </>
          }
          {
            self?.references.length !== 0 && self?.references.map((element) => <Box key={element._id} sx={{ mt: '7px', ml: '15px' }}>
              <Box sx={{ display: 'block', mt: '7px' }}>
                <Typography sx={{ fontSize: '1.7vw', fontWeight: '400' }} > {element.name} - {element.details}, </Typography>               
                <Typography sx={{ fontSize: '1.5vw', fontStyle: 'italic' }}> &nbsp; {element.email}, {element.phone}.   </Typography>
              </Box>
            </Box>
            )
          }
        </Grid>
      </Grid>       
      </Paper>      
           
      </ThemeProvider>     
    );
  }

  export default ResumeForPdf