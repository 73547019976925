import { Box } from '@mui/material'
import React from 'react'

export const Logo = () => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center',  cursor: 'pointer'}} >
        Logo
    </Box>
  )
}
