import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Autocomplete from '@mui/material/Autocomplete';
// import places from '../../country-state-district.json'
import { Box } from '@mui/material';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { axiosPrivate } from '../../api/axios';
import { useEffect, useRef, useState } from 'react';

export default function Countries(props) {
  const { label, name, disableClearable, values, setValues, size, listFontSize, labelFontSize, inputFontSize, required, defaultValue, disabled  } = props;

  const [countries, setCountries] = useState([]);
  const [loading, setLoading] = useState(false) 
  const [state, setState] = useState(null);
  
  useAxiosPrivate()  
  const refr = useRef()
   
  const handleFetchCountries = async () => {
    try {
    //   setLoading(true)
      const res = await axiosPrivate.get(`/api/org/places/countries/fetch`)
      if (res) {
        // setLoading(false)
        const data = res.data.data
        setCountries(data)
        setState(data?.filter((item) => defaultValue === item.id))        
      }
    } catch (err) {
      console.error(err);
    }
  } 
  console.log(countries)

  // useEffect(() => {
  //   handleFetchCountries()
  // }, [])
  
    
  return (
    <Stack sx={{  width: '100%', }}>
      <Autocomplete
        id="places-id"
        ref={refr}
        name={name}
        disabled={disabled}
        disableClearable={disableClearable}
        // value={ state !== null ? state[0] : countries }       
        loading={loading}
        loadingText="Loading..."
        noOptionsText= "No Options"
        options={countries}
        getOptionLabel={(countries) => `${countries.country}`}
        isOptionEqualToValue={(option, value) => option?.id === value?.id}
        onChange={(event, newValue) => {
          const name = refr.current.getAttribute("name")          
          setValues({
            ...values,
            [name]: newValue,
          })
        }}
        renderOption={(props, countries) => (
          <Box component='li' {...props} key={countries.id} sx={{bgcolor: 'background.primary', fontSize: `${listFontSize}`}} >
           
              {countries.country}
           
          </Box>
        )}
        renderInput={(params) => (
          <TextField sx={{ display: 'flex', justifyContent: 'spaceBetween' }}
            {...params}            
            label={label}
            size={size}
            InputLabelProps={{ style: { fontSize: `${labelFontSize}` } }}
            // required={address.length === 0}
            required={required}
            InputProps={{
              ...params.InputProps,
              type: 'search',
              style: { fontSize: `${inputFontSize}` }
            }}
       
            onClick={handleFetchCountries}
          />
        )}
      />
    </Stack>

  );
}