import { Box, Card, CardHeader } from '@mui/material'
import React from 'react'

export const AdminMemberRequest = () => {
  return (
    <Box>
        <Card sx={{ bgcolor: 'inherit', maxWidth: '400px'}}>
            <CardHeader 
            title="User Requests User Requests User Requests User Requests User Requests User Requests User Requests"
            />
            jfgfkdj
        </Card>
    </Box>
  )
}
