import { Box, Button, Card, CardMedia, Chip, Divider, Grid, Paper, Typography } from '@mui/material'
import { GoogleLogin } from '@react-oauth/google'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { login, reset } from '../../features/userAuth/authSlice'
import LoginModal from '../modals/LoginModal'
import NavBarLogin from '../../components/nav/NavBarLogin'
import SchoolIcon from '@mui/icons-material/School';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import { Helmet } from 'react-helmet-async';

export default function LoginComponent() {

    const dispatch = useDispatch()

    const { user, userId, isLoading, isError, isSuccess, message, status } = useSelector(
        (state) => state.auth
    )

    const navigate = useNavigate();
    const location = useLocation();

    const [open, setOpen] = useState(false)

    const handleClose = (result) => {
        setOpen(false)
    };

    const handleFailure = (result) => {
        console.log(result);
    };

    useEffect(() => {
        if (isError) {
            console.log(message)
        }
        if (status === "active") {
            navigate('/')
        }
        // status === "active" && navigate('/')
        dispatch(reset())

    }, [reset, status, isError, isSuccess, message, navigate, dispatch])

    // socket?.on('connect', () => {
    //       console.log("socket connted")
    //     })

    // useEffect(() => {   
    //   socket?.on('connect', () => {
    //     console.log("socket connted")
    //   })
    // }, [socket])

    const handleLogin = (googleData) => {
        // const userData = JSON.stringify({ token: googleData.tokenId });    
        const userData = JSON.stringify({ token: googleData.credential });
        dispatch(login(userData))
        // console.log(userData)         
    };


    if (isLoading) {
        return console.log("Loading")
    }

    return (
        <Paper elevation={0} square sx={{ bgcolor: 'background.dark', }}>
            <Helmet>
          <title>Karpath - Lead to Excellence</title>
          <meta name="description" content="AI Integrated Social Media Platform for All Your Career Needs" />
          <meta property="og:title" content="Karpath - Lead to Excellence" />         
        </Helmet>
            <Grid container spacing={1} sx={{position: "relative", display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
            <NavBarLogin setOpen={setOpen} />
                <Grid item xs={12} sx={{  mt: '-66px', width: '100%', height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                   <Grid sx={{ width: '100%', height: '100vh', background: 'linear-gradient(0deg, rgba(92,15,15,1) 53%, rgba(6,18,82,1) 90%)', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                   <img 
                   src="/karpath.png"
                   width= '260px'
                   height='auto'
                //    style={{ }}                   
                   />
                   
                   <Typography variant='h4' align='center' sx={{ mx: '15px', mt:'80px', mb:'30px', lineHeight: '1.8em', fontWeight: '800', fontStyle: 'bold', textShadow: '2px 4px 7px rgba(0, 0, 0, 0.7)' }} >
                        AI-Powered Social Media Platform for All Your Career Needs
                    </Typography>
                    <Button color='success' variant='contained' onClick={() => setOpen(true)} sx={{ m: '15px', width: '145px', boxShadow: '2px 4px 7px rgba(0, 0, 0, 0.7)', textShadow: '2px 2px 3px rgba(0, 0, 0, 0.3)'}}>
                        Get Started
                    </Button>
                   </Grid>                      
                </Grid>
                {/* <Grid item gap sx={{mt: '-7px', pb: '6px', px: '6px', bgcolor: 'background.darkMaroon', display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', flexDirection: { xs: 'column', sm: 'row'} }}>
                    <Typography variant='h6' sx={{ m: '7px', px: '3px'}} >
                        AI Integrated Social Media Platform for  All Your Career Needs
                    </Typography>
                    <Button variant='contained' onClick={() => setOpen(true)} sx={{ m: '7px', width: '145px'}}>
                        Get Started
                    </Button>
                </Grid> */}

                <Grid item rowGap={4} columnGap={2} sx={{ width: '100%', bgcolor: 'background.default', display: 'flex', alignItems: 'center', justifyContent: 'space-evenly',  flexDirection: { xs: 'column', sm: 'column', md: 'row'} }}>
                    <Grid item rowGap={4} columnGap={2} sx={{ mt: '25px',  pb: '25px', width: '100%', bgcolor: 'background.default', display: 'flex', alignItems: 'center', justifyContent: 'space-evenly',  flexDirection: { xs: 'column', sm: 'column', md: 'row'} }}>
                                  
                    <Paper elevation={6} sx={{ bgcolor: '#1a5c0a', height: '300px', width: '300px', display: 'flex', alignItems: 'center', justifyContent: 'center',flexDirection: 'column'}}>
                        <HomeRepairServiceIcon color='warning'  sx={{ fontSize: '100px', }} />
                        <Typography sx={{ fontFamily: 'ui-rounded',}}>
                        Browse Elligible Jobs
                        </Typography>
                        <Typography sx={{ fontFamily: 'ui-rounded',}}>
                        Equip Yourself with Latest Trends
                        </Typography>
                        <Typography sx={{ fontFamily: 'ui-rounded',}}>
                        Connect with Companies & HRMs
                        </Typography> 
                        <Button variant='contained' color='info' component={Link} to='/jobs' sx={{ textTransform: 'none', mt: '15px', width: '200px'}}>Find Jobs</Button>                       
                    </Paper>

                    <Paper elevation={6}  sx={{ bgcolor: '#73178c',  width: '300px', height: '300px', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
                        <SchoolIcon color='warning' sx={{ fontSize: '100px'}} />
                        <Typography sx={{ fontFamily: 'ui-rounded',}}>
                        Compare Courses with Opportunities
                        </Typography>
                        <Typography sx={{ fontFamily: 'ui-rounded',}}>
                        Find Courses & Colleges
                        </Typography>
                        <Typography sx={{ fontFamily: 'ui-rounded',}}>
                        Enroll in Premium Institutions
                        </Typography>
                        <Button variant='contained' color='primary' component={Link} to='/institutions' sx={{ textTransform: 'none', mt: '15px', width: '200px'}}>Find Institutions</Button>  
                    </Paper>  

                    <Paper elevation={6}  sx={{  bgcolor: '#2952ad', height: '300px',  width: '300px', display: 'flex', alignItems: 'center', justifyContent: 'center',flexDirection: 'column'}}>
                        <NotificationsActiveIcon color='warning' sx={{ fontSize: '100px'}} />
                        <Typography sx={{ fontFamily: 'ui-rounded',}}>
                        Get Competitive Exams & Jobs Alert
                        </Typography>
                        <Typography sx={{ fontFamily: 'ui-rounded',}}>
                        Set Your Preferences
                        </Typography>
                        <Typography sx={{ fontFamily: 'ui-rounded',}}>
                        Never Miss an Opportunity
                        </Typography>
                        <Button variant='contained' color='secondary' component={Link} to='/exams' sx={{ textTransform: 'none', mt: '15px', width: '200px'}}>Find Exams</Button>  
                    </Paper>
                    </Grid>                    
                </Grid>

                <Grid item gap sx={{ bgcolor: 'background.main', display: 'flex', alignItems: 'center', width: '100%', flexDirection: { xs: 'column', sm: 'row'} }} >
                    <Grid item xs={12} md={4} sx={{ ml: '20px', width: '100%', }}>
                        <Typography variant='h6' color='text.pearl' sx={{ fontFamily: 'monospace',}}>
                            Office:
                        </Typography>
                        <Box sx={{height: '1px'}}></Box>
                        <Typography variant='body1' color='text.pearl' sx={{fontSize: '14px'}}>
                            Karpath Infotech Pvt Ltd.
                        </Typography>
                        <Typography variant='body2' color='text.pearl' sx={{fontSize: '13px'}}>
                        1st Floor, A Square Building,  
                        </Typography>                                             
                        <Typography variant='body2' color='text.pearl' sx={{fontSize: '13px'}}>
                            Kuzhivellipady, Kochi,
                        </Typography>
                        <Typography variant='body2' color='text.pearl' sx={{fontSize: '13px'}}>
                           Kerala - 683561.
                        </Typography>    
                        <Box sx={{height: '10px'}}></Box>                   
                        <Typography variant='body2' color='text.pearl' sx={{fontSize: '12px', fontStyle: 'italic'}}>
                            email: info@karpath.com
                        </Typography>
                        <Typography variant='body2' color='text.pearl' sx={{fontSize: '12px', fontStyle: 'italic'}}>
                            CIN: U72900KL2022PTC077820
                        </Typography>

                    </Grid>
                    <Divider orientation="vertical" variant='fullWidth' flexItem color='text.primary' />

                    <Grid item md={8} gap={2} sx={{mb: '10px', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', }}>                    
                        <Typography variant='body2' onClick={() => setOpen(true)} sx={{ cursor: 'pointer', color: 'text.info'}}>
                            Login
                        </Typography>
                        <Divider orientation="vertical" variant='fullWidth' flexItem color='text.info' />
                        <Typography variant='body2' component={Link} to='/page/terms-conditions' sx={{ cursor: 'pointer', textDecoration: 'none', color: 'text.info'}}>
                            Terms & Conditions
                        </Typography>
                        <Divider orientation="vertical" variant='fullWidth' flexItem color='text.info' />
                        <Typography variant='body2' component={Link} to='/page/privacy-policy' sx={{ cursor: 'pointer', textDecoration: 'none', color: 'text.info'}}>
                            Privacy Policy
                        </Typography>
                    </Grid>
                </Grid>

                <Grid item sx={{ bgcolor: 'inherit', mb: '10px', mt: '5px', color: 'text.secondary', fontSize: '12px', fontWeignt: 400 }}>
                    &#169; 2024. Karpath Infotech Pvt Ltd. All Rights Reserved.
                </Grid>
            </Grid>
            <LoginModal open={open} handleClose={handleClose} handleLogin={handleLogin} handleFailure={handleFailure} />
        </Paper>
    )
}



// import { Box, Button, Card, CardMedia, Chip, Divider, Grid, Paper, Typography } from '@mui/material'
// import { GoogleLogin } from '@react-oauth/google'
// import React, { useEffect, useState } from 'react'
// import { useDispatch, useSelector } from 'react-redux'
// import { Link, useLocation, useNavigate } from 'react-router-dom'
// import { login, reset } from '../../features/userAuth/authSlice'
// import LoginModal from '../modals/LoginModal'
// import NavBarLogin from '../../components/nav/NavBarLogin'
// import SchoolIcon from '@mui/icons-material/School';
// import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
// import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';

// export default function LoginComponent() {

//     const dispatch = useDispatch()

//     const { user, userId, isLoading, isError, isSuccess, message, status } = useSelector(
//         (state) => state.auth
//     )

//     const navigate = useNavigate();
//     const location = useLocation();

//     const [open, setOpen] = useState(false)

//     const handleClose = (result) => {
//         setOpen(false)
//     };

//     const handleFailure = (result) => {
//         console.log(result);
//     };

//     useEffect(() => {
//         if (isError) {
//             console.log(message)
//         }
//         if (status === "active") {
//             navigate('/')
//         }
//         // status === "active" && navigate('/')
//         dispatch(reset())

//     }, [reset, status, isError, isSuccess, message, navigate, dispatch])

//     // socket?.on('connect', () => {
//     //       console.log("socket connted")
//     //     })

//     // useEffect(() => {   
//     //   socket?.on('connect', () => {
//     //     console.log("socket connted")
//     //   })
//     // }, [socket])

//     const handleLogin = (googleData) => {
//         // const userData = JSON.stringify({ token: googleData.tokenId });    
//         const userData = JSON.stringify({ token: googleData.credential });
//         dispatch(login(userData))
//         // console.log(userData)         
//     };


//     if (isLoading) {
//         return console.log("Loading")
//     }

//     return (
//         <Paper elevation={0} square sx={{ bgcolor: 'background.dark', }}>
//             <Grid container spacing={1} sx={{position: "relative", display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
//             <NavBarLogin setOpen={setOpen} />
//                 <Grid item xs={12} sx={{  mt: '-66px', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
//                     {/* <Grid > */}
//                     {/* <Card> */}
//                         {/* <div style={{ position: "relative" }}> */}
//                     <CardMedia
//             component="img"            
//             image="/home.png"            
//             alt="Student"
//             sx={{  minWidth: "300px", width: '100%', height: 'auto', backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}
//             />
//             {/* <Card style={{fontSize: '20px', fontWeight: 700, position: "absolute", 
//             color: "lightGreen", maxWidth: '300px', 
//             top:{ xs: 230, sm: 230, md: 250 }, 
//             opacity: '65%',
//             // zIndex: -1,
//             left: '40%',  transform: "translateX(-50%)",}}>
//                 <Typography>
//                  Empower Your Career!
//                 </Typography>
//                  </Card> */}
//                     {/* </div> */}

//                     {/* </Card> */}
           
//                 </Grid>
//                 <Grid item gap sx={{mt: '-7px', pb: '6px', px: '6px', bgcolor: 'background.darkMaroon', display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', flexDirection: { xs: 'column', sm: 'row'} }}>
//                     <Typography variant='h6' sx={{ m: '7px', px: '3px'}} >
//                         AI Integrated Social Media Platform for  All Your Career Needs
//                     </Typography>
//                     <Button variant='contained' onClick={() => setOpen(true)} sx={{ m: '7px', width: '145px'}}>
//                         Get Started
//                     </Button>
//                 </Grid>

//                 <Grid item gap={2} sx={{ pb: '12px', width: '100%', bgcolor: 'background.default', display: 'flex', alignItems: 'center', justifyContent: 'space-evenly',  flexDirection: { xs: 'column', sm: 'column', md: 'row'} }}>
//                     <Paper elevation={4}  sx={{ bgcolor: '#73178c',  width: '300px', height: '300px', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
//                         <SchoolIcon color='warning' sx={{ fontSize: '130px'}} />
//                         <Typography sx={{ fontFamily: 'ui-rounded',}}>
//                         Compare Courses with Opportunities
//                         </Typography>
//                         <Typography sx={{ fontFamily: 'ui-rounded',}}>
//                         Find Courses & Colleges
//                         </Typography>
//                         <Typography sx={{ fontFamily: 'ui-rounded',}}>
//                         Enroll in Premium Institutions
//                         </Typography>
//                         <Button variant='outlined' color='inherit' component={Link} to='/institutions' sx={{ textTransform: 'none', mt: '15px', width: '200px'}}>Find Institutions</Button>  
//                     </Paper>                    

//                     <Paper elevation={4} sx={{ bgcolor: '#1a5c0a', height: '300px', width: '300px', display: 'flex', alignItems: 'center', justifyContent: 'center',flexDirection: 'column'}}>
//                         <HomeRepairServiceIcon color='warning'  sx={{ fontSize: '130px'}} />
//                         <Typography sx={{ fontFamily: 'ui-rounded',}}>
//                         Browse Elligible Jobs
//                         </Typography>
//                         <Typography sx={{ fontFamily: 'ui-rounded',}}>
//                         Equip Yourself with Latest Trends
//                         </Typography>
//                         <Typography sx={{ fontFamily: 'ui-rounded',}}>
//                         Connect with Companies & HRMs
//                         </Typography> 
//                         <Button variant='outlined' color='inherit' component={Link} to='/jobs' sx={{ textTransform: 'none', mt: '15px', width: '200px'}}>Find Jobs</Button>                       
//                     </Paper>

//                     <Paper elevation={4}  sx={{  bgcolor: '#2952ad', height: '300px',  width: '300px', display: 'flex', alignItems: 'center', justifyContent: 'center',flexDirection: 'column'}}>
//                         <NotificationsActiveIcon color='warning' sx={{ fontSize: '130px'}} />
//                         <Typography sx={{ fontFamily: 'ui-rounded',}}>
//                         Get Competitive Exams & Jobs Alert
//                         </Typography>
//                         <Typography sx={{ fontFamily: 'ui-rounded',}}>
//                         Set Your Preferences
//                         </Typography>
//                         <Typography sx={{ fontFamily: 'ui-rounded',}}>
//                         Never Miss an Opportunity
//                         </Typography>
//                         <Button variant='outlined' color='inherit' component={Link} to='/exams' sx={{ textTransform: 'none', mt: '15px', width: '200px'}}>Find Exams</Button>  
//                     </Paper>

//                 </Grid>

//                 <Grid item gap sx={{ bgcolor: 'background.main', display: 'flex', alignItems: 'center', width: '100%', flexDirection: { xs: 'column', sm: 'row'} }} >
//                     <Grid item xs={12} md={4} sx={{ ml: '20px', width: '100%', }}>
//                         <Typography variant='h6' color='text.pearl' sx={{ fontFamily: 'monospace',}}>
//                             Contact:
//                         </Typography>
//                         <Box sx={{height: '1px'}}></Box>
//                         <Typography variant='body1' color='text.pearl' sx={{fontSize: '14px'}}>
//                             Karpath Infotech Pvt Ltd.
//                         </Typography>
//                         <Typography variant='body2' color='text.pearl' sx={{fontSize: '13px'}}>
//                         1st Floor, A Square Building,  
//                         </Typography>                                             
//                         <Typography variant='body2' color='text.pearl' sx={{fontSize: '13px'}}>
//                             Kuzhivellipady, Kochi,
//                         </Typography>
//                         <Typography variant='body2' color='text.pearl' sx={{fontSize: '13px'}}>
//                            Kerala - 683561.
//                         </Typography>    
//                         <Box sx={{height: '10px'}}></Box>                   
//                         <Typography variant='body2' color='text.pearl' sx={{fontSize: '12px', fontStyle: 'italic'}}>
//                             email: info@karpath.com
//                         </Typography>
//                         <Typography variant='body2' color='text.pearl' sx={{fontSize: '12px', fontStyle: 'italic'}}>
//                             CIN: U72900KL2022PTC077820
//                         </Typography>

//                     </Grid>
//                     <Divider orientation="vertical" variant='fullWidth' flexItem color='text.primary' />

//                     <Grid item md={8} gap={2} sx={{mb: '10px', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', }}>                    
//                         <Typography variant='body2' onClick={() => setOpen(true)} sx={{ cursor: 'pointer', color: 'text.info'}}>
//                             Login
//                         </Typography>
//                         <Divider orientation="vertical" variant='fullWidth' flexItem color='text.info' />
//                         <Typography variant='body2' component={Link} to='/page/terms-conditions' sx={{ cursor: 'pointer', textDecoration: 'none', color: 'text.info'}}>
//                             Terms & Conditions
//                         </Typography>
//                         <Divider orientation="vertical" variant='fullWidth' flexItem color='text.info' />
//                         <Typography variant='body2' component={Link} to='/page/privacy-policy' sx={{ cursor: 'pointer', textDecoration: 'none', color: 'text.info'}}>
//                             Privacy Policy
//                         </Typography>
//                     </Grid>
//                 </Grid>

//                 <Grid item sx={{ bgcolor: 'inherit', mb: '10px', mt: '5px', color: 'text.secondary', fontSize: '12px', fontWeignt: 400 }}>
//                     &#169; 2024. Karpath Infotech Pvt Ltd. All Rights Reserved.
//                 </Grid>
//             </Grid>
//             <LoginModal open={open} handleClose={handleClose} handleLogin={handleLogin} handleFailure={handleFailure} />
//         </Paper>
//     )
// }
