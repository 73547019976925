import { Box, Button, DialogActions, DialogContent, Divider, Grid, IconButton, InputLabel, TextareaAutosize, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
// import { PostContext } from "./Context";
import InsertPhotoOutlinedIcon from '@mui/icons-material/InsertPhotoOutlined';
import VideoFileOutlinedIcon from '@mui/icons-material/VideoFileOutlined';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import Controls from "../../../components/controls/Controls";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { axiosPrivate } from "../../../api/axios";
import { useSelector } from "react-redux";

export function CreateSimplePost(props) {
  // const { switchJobPost } = useContext(PostContext);
  const { onSubmit, 
    values, setValues, handleInputChange, 
    files, 
    setFiles,
    setOpen,
    resetForm,
    setSubmitLoading,
    setSeverity,
    setOpenSnackbar, 
   } = props

   const DEFAULT_MAX_FILE_SIZE_IN_BYTES = process.env.REACT_APP_DEFAULT_MAX_FILE_SIZE_PIC_IN_BYTES;
   const MIN_DIMENSION = process.env.REACT_APP_MIN_DIMENSION;

   const { orgId,  locationId,  } = useSelector(
    (state) => state.org
  )

  const ref = React.useRef()

  const [error, setError] = useState("")
  const [imageData, setImageData] = useState("")

  let description
  const save = (data) => {
    description = data
    console.log(data)
  };
  
  const handleClickAddPic = (e) => {  
    const fileData = e.target.files[0];
    setFiles({images:fileData})
    setOpen(true)
    if (fileData.size > DEFAULT_MAX_FILE_SIZE_IN_BYTES) return setError("Image must be less than 5mb.");    
    if (!fileData) return;     
  
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      const imageElement = new Image();
      const imageUrl = reader.result?.toString() || "";      
      imageElement.src = imageUrl;
  
      imageElement.addEventListener("load", (e) => {
        if (error) setError("");        
        const { naturalWidth, naturalHeight } = e.currentTarget;
        if (naturalWidth < MIN_DIMENSION || naturalHeight < MIN_DIMENSION) {
          setError("Image must be at least 150 x 150 pixels.");
          return setImageData("");
        }
      });
      setImageData(imageUrl);
    });
    reader.readAsDataURL(fileData);
  };

  const handleSubmit = async () => {
    ref.current?.save()
    setSubmitLoading(true)
    const postInfo = {
      title: values.title,
      description: undefined ? "" : description,
    }
    const formData = new FormData()

    for (let key in postInfo) {
      formData.append(key, postInfo[key])
    }
    for (let file of Object.keys(files)) {
      formData.append('files', files[file])
    }

    try {
      const res = await axiosPrivate.post(`api/post/${orgId}/orgpost`, formData);
      if (!res) return

      if (res.status === 201) {
        // setSubmitted(true)
        setOpen(false);
        resetForm()
        // console.log(res.data.post)
        // postFeed.unshift(res.data.post)
        // setOrgPost(postFeed)
        setSubmitLoading(false)
        setSeverity("success")
        setOpenSnackbar(true)
      }
      //   setLoading(false)
      // console.log(orgPost)            

    } catch (err) {
      console.error(err);
      setSubmitLoading(false)
      setSeverity("error")
      setOpenSnackbar(true)
      // setLoading(false)
      // setSubmitted(false)
    }
  };

  console.log(files)
 
  return (
    <>
      <DialogContent dividers>
        <Box display="flex" flexDirection="column" gap >
          <Controls.Input
            name="title"
            label="Enter Title *"
            //  placeholder="Enter Title"
            //  maxRows={2}
            value={values.title}
            onChange={handleInputChange}
          />
          {
            imageData &&
            <img
              src={imageData}
              alt="chosen"
              style={{ width: '100%', mt: '6px', height: 'auto' }}
            />
          }
          <Divider sx={{ mb: -1 }} />

          <Box sx={{ mb: '15px' }}>
            <Controls.MuiRte
              name="description"
              label="Enter Post Description"
              values={values}
              setValues={setValues}
              ref={ref}
              // content={content}
              save={save}
            />
          </Box>

        </Box>
      </DialogContent>

      <DialogActions>
        <IconButton> 
        <InputLabel htmlFor="file" sx={{ mb: '-7px' }} >
                <InsertPhotoOutlinedIcon fontSize="medium" color="warning" /> 
            <input type="file" id="file" accept='.jpg, .jpeg, .png' onChange={handleClickAddPic} style={{ display: 'none' }} />
               
            </InputLabel>          
          </IconButton>
        {/* <IconButton> <VideoFileOutlinedIcon fontSize="medium" sx={{ color: 'green' }} /> </IconButton> */}

        <Box sx={{ flexGrow: 1 }} />

        <Button variant="outlined" onClick={handleSubmit} sx={{ color: 'orange', borderColor: 'orange', textTransform: 'none', fontSize: '11px' }} startIcon={<SendOutlinedIcon fontSize="small" />}>  Post
        </Button>
      </DialogActions>

    </>
  );
}