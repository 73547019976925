import { CircularProgress, Card, Grid, Typography, Box, Button } from '@mui/material'
import CorporateFareTwoToneIcon from '@mui/icons-material/CorporateFareTwoTone';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { axiosPrivate } from '../../api/axios';
import { useDispatch, useSelector } from 'react-redux';
import { fetchOrg } from '../../features/org/orgSlice';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import styled from 'styled-components';
import { userFetch } from '../../features/user/userSlice';

const ProfileImg = styled.img`
  border-radius: 5px;  
  width: 45px;
  height: 45px;
  object-fit: cover;
`;

function PeopleSuggestion() {
    useAxiosPrivate();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { userId } = useParams()

    // const { userId } = useSelector(
    //     (state) => state.auth
    // )

    const [connectionFetch, setConnectionFetch] = useState([]);
    const [loading, setLoading] = useState(false)
    const [keyValue, setKeyValue] = useState("")
    const [connectionSentFetch, setConnectionSentFetch] = useState([]);

    const fetchConnections = async () => {
        // e.preventDedault() 
        try {
            // setLoading(true)
            const res = await axiosPrivate.get(`/api/user/user/recommended_users/${userId}`)
            if (res) {
                // setLoading(false)
                // const data = res.data.orgPost
                // setOrgPost(data)
                const data = res.data.users
                setConnectionFetch(data)


            }
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        fetchConnections()        
    }, [userId])

    useEffect(() => {
        fetchConnections()
        fetchSentConnectionRequest()
    }, [])

    const fetchSentConnectionRequest = async () => {
        // e.preventDedault() 
        try {
            // setLoading(true)
            const res = await axiosPrivate.get(`/api/user/connection_request/sent`)
            if (res) {
                // setLoading(false)
                // const data = res.data.orgPost
                // setOrgPost(data)
                const data = res.data.connections
                setConnectionSentFetch(data)


            }
        } catch (err) {
            console.error(err);
        }
    };

    const fetchRequestId = async (connectionsId) => {
       
        try {
            // setLoading(true)
            const filterValue = connectionSentFetch.connections.filter(item => 
               item.receiver._id === connectionsId)                
               const requestObj = Object.assign({}, ...filterValue)
               const requestId = requestObj._id
               
               const res = await axiosPrivate.get(`/api/user/connection_request/${requestId}/cancel`)
            if (res.status === 200) {               
                const data = res.data.connections
                fetchSentConnectionRequest()                
            }    
            
        } catch (err) {
            console.error(err);
        }
        setLoading(false)
    };

    // const shuffled = orgFetched.sort(() => 0.5 - Math.random());
    let selected = connectionFetch.slice(0, 8);
    // console.log(connectionSentFetch)
    // console.log(connectionFetch)

    return (
        
        <Card sx={{ my: '10px', p: '5px' }}>
            <Typography variant='h6' sx={{ mx: '3px', mb: '5px', fontSize: '14px', fontWeight: '500' }} > People You May Know</Typography>
            <Grid container spacing={0.5} sx={{ mx: '3px', display: 'flex', flexDirection: 'column', justifyContent: 'spaceEvenly' }}>
                {(connectionFetch.length !== 0) && selected.map((element) => <Box key={element.id}  >
                    <Grid item sx={{ mx: '3px', my: '5px', display: 'flex' }} >
                        <Box onClick={(e) => {
                            let user_id = element.id
                            // e.preventDefault()
                            console.log(element)
                            // dispatch(fetchOrg(element.id))
                            // dispatch(userFetch(user_id))
                            navigate(`/user/${element.slug}/${element.id}`)
                        }}
                            sx={{ display: 'flex', textDecoration: 'none', color: 'inherit', cursor: 'pointer' }}>                                
                                {element.profile_pic !== "" ? <ProfileImg src={element.profile_pic} alt='profile_picture' /> : <PersonOutlinedIcon variant="rounded" sx={{ width: '45px', height: '45px', bgcolor: 'lightGray', color: 'gray', borderRadius: '5px' }} />}
                            {/* <CorporateFareTwoToneIcon variant="rounded" sx={{ width: '45px', height: 'auto', bgcolor: 'lightGray', color: 'gray', borderRadius: '5px', cursor: 'pointer' }} /> */}
                            <Box sx={{ m: '3px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                <Typography variant='h6' sx={{ fontSize: '12px', fontWeight: '400', maxWidth: { xs: '150px', sm: '150px', md: '150px', lg: '230px' }, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} > {element.firstName} </Typography>
                                <Typography variant='h6' sx={{ fontSize: '9px', fontWeight: '100' }} > {element.designation} </Typography>
                            </Box>
                        </Box>

                        <Box sx={{ flexGrow: 1 }} />
                        
                        {connectionSentFetch?.connectionData?.receiverId.includes(element.id) ? (<Box sx={{ m: '3px', display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'relative', }}>
                            <Button variant='outlined' sx={{ color: 'darkGray', borderColor: 'darkGray', textTransform: 'none', fontSize: '9px', mr: '4px', width: '50px' }}
                            onClick={
                                async () => {
                                    // let followersArray = element.connections;
                                    const connectionsId = element.id                                    
                                    try {
                                        setKeyValue(connectionsId)
                                        setLoading(true)
                                        fetchRequestId(connectionsId)
                                                                           
                                    } catch (err) {
                                        setLoading(false)
                                        setKeyValue("")
                                        console.error(err);
                                    }

                                }
                            }  >Cancel</Button>
                            {(loading && keyValue === element.id) && <CircularProgress
                                // key ={element._id}
                                size={20}
                                sx={{
                                    color: 'orange',
                                    position: 'absolute',
                                    // top: '50%',
                                    // left: '50%',
                                    // marginTop: '-12px',
                                    // marginLeft: '-12px',
                                }}
                            />
                            }
                        </Box>) : (
                            <Box sx={{ m: '3px', display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'relative', }}>
                                <Button variant='outlined' sx={{ color: 'orange', borderColor: 'orange', textTransform: 'none', fontSize: '9px', mr: '4px', width: '50px' }}
                                 onClick={
                                    async () => {
                                        // let followersArray = element.connections;
                                        const connectionsId = element.id
                                        // console.log(followersArray)
                                        try {
                                            setKeyValue(connectionsId)
                                            setLoading(true)
                                            const res = await axiosPrivate.get(`/api/user/connection_request/${connectionsId}/send`)
                                            if (res) {                                                
                                                fetchSentConnectionRequest()
                                                setLoading(false)
                                                // setKeyValue(connectionsId)
                                                // followersArray.push(userId)
                                                // console.log(res)
                                                // const data = res.data.data        
                                            }
                                        } catch (err) {
                                            setLoading(false)
                                            setKeyValue("")
                                            console.error(err);
                                        }

                                    }
                                }  >Connect</Button>
                                {(loading && keyValue === element.id) && <CircularProgress
                                    // key ={element._id}
                                    size={20}
                                    sx={{
                                        color: 'orange',
                                        position: 'absolute',
                                        // top: '50%',
                                        // left: '50%',
                                        // marginTop: '-12px',
                                        // marginLeft: '-12px',
                                    }}
                                />
                                }
                            </Box>
                        )}



                    </Grid>
                </Box>
                )
                }



            </Grid >           
        </Card >
    )
}

export default PeopleSuggestion