// import React from 'react';
// import ReactDOM from 'react-dom';
// // import './index.css';
// import App from './App';
// // import { store } from './app/store';
// // import { Provider } from 'react-redux';


// ReactDOM.render(
//   <React.StrictMode>
//     {/* <Provider store={store}> */}
//       <App />
//     {/* </Provider> */}
//   </React.StrictMode>,
//   document.getElementById('root')
// );

import React from 'react';
import App from './App';
import { createRoot } from 'react-dom/client';
import { store } from './store/store';
import { Provider } from 'react-redux';
import { GoogleOAuthProvider } from '@react-oauth/google';
import {registerLicense} from '@syncfusion/ej2-base';

const container = document.getElementById('app');
const root = createRoot(container);

registerLicense('ORg4AjUWIQA/Gnt2UFhhQlJBfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTX5Xd0xiWH1WcH1dQGJc')
// const {theme} = ToggleMode()

root.render(
  <Provider store={store}>
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID} >   

      <App />

      </GoogleOAuthProvider>    
  </Provider>
);
