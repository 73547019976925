import { Box, Grid } from '@mui/material'
import React from 'react'
import { AdminMemberRequest } from '../components/AdminMemberRequest'
import AdminNav from '../components/AdminNav'
import { AdminNotifications } from '../components/AdminNotifications'
import { AdminPageDetails } from '../components/AdminPageDetails'
import { Logo } from '../components/Logo'
import SidePanelList from '../components/SidePanelList'
import { UserRequest } from '../components/UserRequest'

const Dashboard = () => {

  return (
    <>      
      <Box sx={{
        bgcolor: '#051145 ',
         display: 'flex', flexDirection: 'column', color: '#f7faf5', alignContent: 'stretch',
        px: '7px', py: '10px', height: '97vh', width: '230px', position: 'fixed'
      }}>
        <Logo />
        <SidePanelList />
      </Box>
      
      <Box sx={{ ml: '242px', height: '100vh',overflow: 'scroll'}}> 
        <AdminNav />

        <Grid container spacing={3} sx={{ p: '10px'   }}>
          <Grid item gap={2}  md={9} xl={9} sx={{ display: 'flex', justifyContent: 'spaceEvenly', minWidth: '320px' }}>
            <Box >
              <UserRequest />
            </Box>
            <AdminMemberRequest />                            
          </Grid>

          <Grid item md={3} xl={3} >
            
              <AdminNotifications />                     
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default Dashboard