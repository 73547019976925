import React, { forwardRef, useState } from "react";
import { styled } from '@mui/material/styles';
import { CreateSimplePost } from "./CreateSimplePost";
import { PostContext } from "../../../store/Context";
import { CreateJob } from "./CreateJob";
import { Button, Box, Typography, DialogTitle, IconButton, Grid, Dialog, Backdrop, CircularProgress } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({

  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
    // backgroundColor: 'beige',
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
    // backgroundColor: 'beige',
  },
}));

export default function CreatePost(props) {
  const { onClose, onSubmit, jobSubmit, open, handleInputChange, errors, values, setValues, loading, optionsData, setOptionsData, save,
    files,
    setFiles,
    setOpen,
    resetForm,
    setLoading,
    setSeverity,
    setOpenSnackbar,
  } = props
  const [active, setActive] = useState("simplePost");
 
  const switchSimplePost = () => {
    setActive("simplePost");
  };
  const switchJobPost = () => {
    setActive("jobPost");
  };
  const contextValue = { switchSimplePost, switchJobPost };
  
  return (   
    <PostContext.Provider value={contextValue}>  
    <BootstrapDialog
            onClose={onClose}
            aria-labelledby="customized-dialog-title"
            open={open}>   

        {active === "simplePost" && (          
            <Grid container sx={{ display: "flex", alignItems: 'center', pr: '5px', pl: '10px', }} >
              <Typography>
                Create a Post <Button variant="outlined" onClick={switchJobPost} sx={{color: 'orange', borderColor:'orange', textTransform: 'none', fontSize: '11px' }} startIcon={<WorkOutlineOutlinedIcon fontSize="small" />}> Toggle Jobpost
                </Button>
              </Typography>
              <Box sx={{ flexGrow: 1 }} />
              <IconButton onClick={onClose} >
                <CloseIcon />
              </IconButton>
            </Grid>       
        )}
        {active === "jobPost" && (       
            <Grid container sx={{ display: "flex", alignItems: 'center', pr: '5px', pl: '10px', }} >
              <Typography>
                Create A Job <Button variant="outlined" onClick={switchSimplePost} sx={{color: 'orange', borderColor:'orange', textTransform: 'none', fontSize: '11px' }} startIcon={<ArticleOutlinedIcon fontSize="small" />}>  Normal Post
                </Button>
              </Typography>
              <Box sx={{ flexGrow: 1 }} />
              <IconButton onClick={onClose} >
                <CloseIcon />
              </IconButton>
            </Grid>        
        )}
        {active === "simplePost" && <CreateSimplePost onSubmit={onSubmit} values={values} setValues={setValues} 
        handleInputChange={handleInputChange} 
        setOpen={setOpen}
         resetForm={resetForm}
         setSubmitLoading={setLoading}
         setSeverity={setSeverity}
         setOpenSnackbar={setOpenSnackbar} 
         files={files}
         setFiles={setFiles} 
          />}

        {active === "jobPost" && <CreateJob
        jobSubmit={jobSubmit}
        handleInputChange={handleInputChange}
        errors={errors}        
        values={values}
        setValues={setValues}
        optionsData={optionsData}
         setOptionsData={setOptionsData}  
         setOpen={setOpen}
         resetForm={resetForm}
         setSubmitLoading={setLoading}
         setSeverity={setSeverity}
         setOpenSnackbar={setOpenSnackbar}     
        />}

{loading && (
                  <Backdrop
                  sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                  open={loading}                  
                >
                  <CircularProgress
                    size={24}
                    sx={{
                      color: 'inherit',
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      marginTop: '-12px',
                      marginLeft: '-12px',
                    }}
                  />
                  </Backdrop> ) }
        
        </BootstrapDialog>         
    </PostContext.Provider>
   
  );
}

