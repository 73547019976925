import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import ReactCrop, { centerCrop, makeAspectCrop, Crop, PixelCrop, convertToPixelCrop } from 'react-image-crop'
import { Dialog, DialogActions, DialogContent, Typography } from '@mui/material';
import { styled as styledMui } from '@mui/material/styles';
// import {  onImageLoad } from '../../components/CenterAspectCrop';
import SetCanvasPreview from '../../components/SetCanvasPreview';
import { UrlToFile } from '../../components/UrlToFile';


const BootstrapDialog = styledMui(Dialog)(({ theme }) => ({

    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
        // backgroundColor: 'beige',
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
        // backgroundColor: 'beige',
    },
}));

export default function UploadPhotoModal({ open, handleClose, fileName, optimizedFile, setFile, handleSubmit, setCanvasPreview, crop, setCrop, imgSrc, error }) {

    const imgRef = React.useRef(null);
    const previewCanvasRef = React.useRef(null);    

    const ASPECT_RATIO = process.env.REACT_APP_ASPECT_RATIO_PHOTO;
    const MIN_DIMENSION = process.env.REACT_APP_MIN_DIMENSION;

    const [completedCrop, setCompletedCrop] = React.useState(null);

    // console.log(crop)

    // const cropImage = () => {
        React.useEffect(() => {
            // setCanvasImage(imgRef.current, previewCanvasRef.current, completedCrop);
            SetCanvasPreview(
                imgRef.current, // HTMLImageElement
                previewCanvasRef.current, // HTMLCanvasElement
                // convertToPixelCrop(
                //     crop,
                //     imgRef.current.width,
                //     imgRef.current.height
                // ), 
                completedCrop
            );
            if (previewCanvasRef.current) {
                const dataUrl = previewCanvasRef.current.toDataURL('image/png', 90);
                // console.log(dataUrl)
                UrlToFile(dataUrl, fileName, setFile)
            }
          }, [completedCrop]);

        // const new_image = document.createElement('img')        
       
    // }

    const onImageLoad = (e) => {
        // console.log(e)
        const { width, height } = e.currentTarget;
        const cropWidthInPercent = (MIN_DIMENSION / width) * 100;
      
        const crop = makeAspectCrop(
          {
            unit: "%",
            width: cropWidthInPercent,
          },
          ASPECT_RATIO,
          width ,
          height
        );        
        const centeredCrop = centerCrop(crop, width, height);
        setCrop(centeredCrop);
      };

    return (

        <BootstrapDialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
        >

            <DialogContent >
                { error && <Typography color='text.error'>{error}</Typography>}
                {imgSrc && (
                    <Box className="flex flex-col items-center">
                        <ReactCrop
                            crop={crop}
                            onChange={(pixelCrop, percentCrop) => setCrop(percentCrop)}
                            // circularCrop
                            // keepSelection
                            aspect={ASPECT_RATIO}
                            // minHeight={100}
                            maxWidth={350}
                            minWidth={MIN_DIMENSION}
                            onComplete={(c) => setCompletedCrop(c)}
                        >
                            <img
                                ref={imgRef}
                                src={imgSrc}
                                alt="Upload"
                                style={{ maxHeight: "70vh" }}
                                onLoad={onImageLoad}
                            />
                        </ReactCrop>                                          
                    </Box>
                )}
                {crop && (
                    <canvas
                        ref={previewCanvasRef}                        
                        style={{
                            display: "none",
                            border: "1px solid black",
                            objectFit: "contain",
                            width: 150,
                            height: 150,
                        }}
                    />
                )}

            </DialogContent>
            <DialogActions sx={{ display: 'flex' }}>
                {
                    !error && 
                <Button variant="outlined" onClick={handleSubmit}  color='warning' sx={{ m: '5px', textTransform: 'none', fontSize: '11px' }}>  Upload
                </Button>
                }
                <Button variant="outlined" onClick={handleClose} color='warning' sx={{ m: '5px', textTransform: 'none', fontSize: '11px' }} >  Cancel
                </Button>
            </DialogActions>

        </BootstrapDialog>

    );
}
