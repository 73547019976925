import { Navigate } from "react-router-dom";
import axios from "../../api/axios";

const API_URL = '/api/auth/';

// Login admin
const login = async (adminData) => {
    console.log(adminData)
  const res = await axios.post(API_URL + 'admin_login', adminData,
  {
    headers: { 'Content-Type': 'application/json' },
    withCredentials: true
  });

  if (res.data) {    
    localStorage.setItem('admin', JSON.stringify(res.data.data));    
  }    
  return res.data.data
}

// Logout admin
const logout = async () => {
  const res = await axios.post('/api/auth/admin_logout');
  localStorage.removeItem('admin');  
  }

const adminService = {  
  logout,
  login,
}

export default adminService