import React, { useCallback, useRef, useState } from 'react'
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import { Avatar, CardHeader, CardMedia, CircularProgress, IconButton, Menu, MenuItem, Skeleton, Typography } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ShareIcon from '@mui/icons-material/Share';
import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined';
import CorporateFareTwoToneIcon from '@mui/icons-material/CorporateFareTwoTone';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import JobCardModal from '../modals/JobCardModal';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { axiosPrivate } from '../../api/axios';
import { fetchOrg } from '../../features/org/orgSlice';
import Collapse from '@mui/material/Collapse';
import moment from 'moment'
import {stateToHTML} from 'draft-js-export-html';
import { convertFromRaw} from 'draft-js'
import ReactHtmlParser from 'react-html-parser'
import NoPostFeed from './NoPostFeed';
import ViewPostImageModal from '../modals/ViewPostImageModal';

const OrgLogo = styled.img`
  border-radius: 50%;  
  width: 40px;
  height: 40px;
  object-fit: contain;
`;

const PostCard = () => {
  useAxiosPrivate()

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { userId } = useSelector(
    (state) => state.auth
  )

  const [open, setOpen] = React.useState(false);
  const [newsFeed, setNewsFeed] = React.useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [postId, setPostId] = React.useState();
  const [loadingFeeds, setLoadingFeeds] = React.useState(true)
  const [element, setElement] = React.useState()
  const [loading, setLoading] = React.useState(false)
  const [disabled, setDisabled] = React.useState(false)
  const [expanded, setExpanded] = React.useState(false);
  const [keyValue, setKeyValue] = React.useState("")
  const [skip, setSkip] = React.useState(0);
  const [hasMore, setHasMore] = useState(false)
 
  const isMenuOpen = Boolean(anchorEl);

  let htmlContent
  if (element !== undefined && element?.description !== "") {
    const content = JSON.parse(element?.description)
    // const htmlcontent = '<p> Hello </p>'
    const convertedContent = convertFromRaw(content)
    htmlContent = stateToHTML(convertedContent)
    console.log(htmlContent)
  }

  const handleExpandClick = (element) => {
    setExpanded(!expanded);
    setElement(element)
    setKeyValue(element._id)
    // console.log(element)
  };

  const handleMoreVertMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);

  };

  const handleClickImageOpen = (element) => {
    setElement(element)
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const copyLink = (postId) => {
    navigator.clipboard.writeText(`${process.env.REACT_APP_URL}/post/${postId}`)
    setAnchorEl(null);
  };

  const observer = useRef()
  const lastFeedRef = useCallback(node => {
    if (loadingFeeds) return
    if (observer.current) observer.current.disconnect()
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore) {
        setSkip(skip => skip + 7)
      }
    })
    if (node) observer.current.observe(node)
  }, [loadingFeeds, hasMore])

  const fetchNewsFeed = async (e) => {
    // e.preventDedault() 
    try {
      // setLoadingFeeds(true)
      const res = await axiosPrivate.get(`/api/post/newsfeed?skip=${skip}`)
      if (res.status === 200) {
        // const data = res.data.orgPost
        // setOrgPost(data)
        const data = res.data.Feeds
        setNewsFeed(prevFeed => {
          return [...new Set([...prevFeed, ...data])]
        })
        setLoadingFeeds(false)
         data.length > 0 ? setHasMore(true) : setHasMore(false) 
        console.log(data)
      }
      if (res.status === 201) {
        setNewsFeed([])
        setLoadingFeeds(false)
      }
    } catch (err) {
      console.error(err);
      setLoadingFeeds(false)
    }
  };
 
  React.useEffect(() => {
    fetchNewsFeed()
  }, [skip])

  // let employee = ""  
  // if (element?.org[0].activeMembers?.length === 0 || element?.org[0].activeMembers?.length <= 100) {
  //   employee = "1-100 Employees"
  // }
  //  else if (element?.org[0].activeMembers?.length === 101 || element?.org[0].activeMembers?.length <= 1000) {
  //   employee = "100-1k Employees"
  // }
  //  else if (element?.org[0].activeMembers?.length === 1001 || element?.org[0].activeMembers?.length <= 5000) {
  //   employee = "1k-5k Employees"
  // }
  //  else if (element?.org[0].activeMembers?.length === 5001 || element?.org[0].activeMembers?.length <= 10000) {
  //   employee = "5k-10k Employees"
  // }
  //  else if (element?.org[0].activeMembers?.length > 10000 ) {
  //   employee = "10k+ Employees"
  //  }


  // const moreVertId = 'more-vert-menu'; 

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={element?._id}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >

      {element?.org[0].activeMembers.includes(userId) && <MenuItem disabled sx={{ padding: '3px 16px 3px 16px' }}  >
        <Typography sx={{ fontSize: '13px', fontWeight: 250, }}> Unfollow {element?.org[0].name} </Typography>
      </MenuItem>}

      {(!element?.org[0].activeMembers.includes(userId) && element?.org[0].followers.includes(userId)) && <MenuItem disabled={disabled} sx={{ padding: '3px 16px 3px 16px' }} onClick={async () => {
        const orgId = element?.org[0]._id
        let followersArray = element?.org[0].followers;
        try {
          setLoading(true)
          setDisabled(true)
          const res = await axiosPrivate.put(`/api/org/${orgId}/unfollow`)
          if (res.status === 201) {
            setAnchorEl(null)
            setLoading(false)  
            setDisabled(false)          
            const filtered = followersArray.indexOf(userId)
            if (filtered !== -1) {
              followersArray.splice(filtered, 1)
            }
            console.log(followersArray)
            // const data = res.data.data        
          }
        } catch (err) {
          setAnchorEl(null)         
          console.error(err);
          setLoading(false) 
          setDisabled(false)
        }
      }
      }  >
        <Typography sx={{ fontSize: '13px', fontWeight: 250 }}> Unfollow {element?.org[0].name} </Typography>
      </MenuItem>}

      {(!element?.org[0].activeMembers.includes(userId) && !element?.org[0].followers.includes(userId)) && <MenuItem disabled={disabled} sx={{ padding: '3px 16px 3px 16px' }} onClick={async () => {
        const orgId = element?.org[0]._id
        let followersArray = element?.org[0].followers;
        try {
          setLoading(true)
          setDisabled(true)
          const res = await axiosPrivate.put(`/api/org/${orgId}/follow`)
          if (res.status === 201) {
            setAnchorEl(null)
            setLoading(false) 
            setDisabled(false)          
            followersArray.push(userId)
            console.log(res)
            // const data = res.data.data        
          }
        } catch (err) {
          setAnchorEl(null)
          console.error(err);
          setLoading(false)  
          setDisabled(false)       
        }
      }
      }  >
        <Typography sx={{ fontSize: '13px', fontWeight: 250 }}> Follow {element?.org[0].name} </Typography>
      </MenuItem>}

      <MenuItem sx={{ padding: '3px 16px 3px 16px' }} onClick={() => copyLink(postId)} >
        <Typography sx={{ fontSize: '13px', fontWeight: 250 }}> Copy Link</Typography>

        {loading && <CircularProgress
                            // key ={element._id}
                            size={25}
                            sx={{
                                color: 'orange',
                                position: 'absolute',
                                top: '10%',
                                left: '40%',
                                // marginTop: '-12px',
                                // marginLeft: '-12px',
                            }}
                        />
                        }
      </MenuItem>
      <MenuItem sx={{ padding: '3px 16px 3px 16px' }} onClick={handleMenuClose}  >
        <Typography sx={{ fontSize: '13px', fontWeight: 250 }}> Report</Typography>
      </MenuItem>
    </Menu>
  )

  const LoadingSkeleton = () => {
    return (
      <Card sx={{ my: '10px', p: '3px' }}>
      <CardHeader
        avatar={          
            <Skeleton animation="wave" variant="circular" width={40} height={40} />           
        }       
        title={         
            <Skeleton
              animation="wave"
              height={10}
              width="80%"
              style={{ marginBottom: 6 }}
            />         
        }
        subheader={          
            <Skeleton animation="wave" height={10} width="40%" />        
        }
      /> 
      <CardContent> 
       <Skeleton animation="wave" height={30} width="70%" style={{ marginBottom: 7 }} /> 
      </CardContent>           
      <Skeleton animation="wave" variant="rectangular" height={230} width="100%" style={{ marginBottom: 6 }} />           
      <CardContent> 
       <Skeleton animation="wave" height={10} width="20%" /> 
      </CardContent> 
    </Card>
    )
  }

  return (
    <>
      {loadingFeeds ? 
      <>
      <LoadingSkeleton />
      <LoadingSkeleton />
      <LoadingSkeleton />
      <LoadingSkeleton />
      </>

       : newsFeed && newsFeed.length !== 0 ? newsFeed.map((element) => <Box key={element._id} ref={lastFeedRef}>
        <Card sx={{ my: '10px', p: '3px' }}>
          <CardHeader sx={{ p: '7px' }}

            avatar={element?.org[0].logo !== "" ? <OrgLogo src={element?.org[0].logo} alt='L' /> :
              <CorporateFareTwoToneIcon variant="rounded" sx={{ width: '45px', height: '45px', bgcolor: 'lightGray', color: 'gray', borderRadius: '5px' }} />
            }
            action={
              <IconButton
                aria-label="settings"
                aria-controls={element?._id}
                aria-haspopup="true"
                onClick={(event) => {
                  setElement(element)
                  setAnchorEl(event.currentTarget);
                  setPostId(element?._id)
                }}>
                <MoreVertIcon />

              </IconButton>
            }
            titleTypographyProps={{ variant: 'h5', fontSize: '13px', fontWeight: '400' }}
            title={<Box sx={{ maxWidth: '200px', cursor: 'pointer' }} onClick={() => {              
              navigate(`/org/${element.org[0]._id}`)
            }}> {element.org[0].name} </Box>}
            subheader={<div>
              {/* <Typography variant='h6' sx={{ fontSize: '9px', fontWeight: '300' }} > {element.org[0].activeMembers.length} Employees </Typography> */}
              <Typography variant='h6' sx={{ fontSize: '9px', fontWeight: '200', lineHeight: '0.5rem' }} >{moment(element.createdAt).fromNow()}.</Typography></div>
            }
          />
          {renderMenu}
          <CardContent >
            <Typography variant="h6" component="div" color="text.secondary" sx={{ display: 'flex', alignItems: 'center' }}>
              {element.title}              
            </Typography>
            </CardContent>

            {
              element.images.length !==0  &&
              <CardMedia
            component="img"
            height="300"
            // image="/great.jpg"
            src={element.images[0].url}
            alt={`${element.name}-image-post`}
            onClick={() => handleClickImageOpen(element)}
          />
            }          

            <CardContent>
            {expanded && keyValue === element._id &&  <Collapse in={expanded} timeout="auto" unmountOnExit>
            <Typography variant="body2" color="text.secondary" >
              {ReactHtmlParser(htmlContent)}
            </Typography>
              </Collapse>     }  

            {expanded && keyValue === element._id ?
              <Typography variant="body2" color="text.info" onClick={(event) => handleExpandClick(element)} sx={{ fontSize: '12px', cursor: 'pointer', pt: '10px' }} >
                COLLAPSE
              </Typography>
              :
              <Typography variant="body2" color="text.secondary" onClick={(event) => handleExpandClick(element)} sx={{ fontSize: '12px', cursor: 'pointer' }} >
                Show More...
              </Typography>
            }
          </CardContent>
        
        </Card>
      </Box>
      ) :
        <NoPostFeed />
      }
      {
        hasMore && 
        <Box sx={{ width: '100%' ,display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <CircularProgress color="inherit" size={25}  />
    </Box>
      }
      {
            open && <ViewPostImageModal open={open} handleClose={handleClose} url={element.images[0].url} />
          }
        
    </>
  )
}

export default PostCard