import { Button, Card, CardContent, CardHeader, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { Link } from 'react-router-dom'
import { axiosPrivate } from '../../api/axios'


export const UserRequest = () => {

  const handleUpdateJobFeeds = async() => {
    try {
      const res = await axiosPrivate.get(`/api/user/admin/update/update_job_feeds`);
      if (!res) return
      if (res.status === 200)
        console.log(res.data.data)
            
    } catch (error) {
      console.log(error)
    }
  }
  
  return (
    <Box>
        <Card sx={{ bgcolor: 'inherit', maxWidth: '350px'}}>
            <CardHeader 
            title="Hard Update Job Feeds "
            />
            <CardContent sx={{ py: '5px' }} >
                {/* <Typography component={Link} to="/admin/user-request" variant="body1"  color="text.secondary" sx={{ display: 'flex', alignItems: 'center', fontSize: '12px', lineHeight: '10px', fontWeight: 400, cursor: 'pointer' }}>
                  some descrition here about the post....
                </Typography> */}
                <Button variant='contained' onClick={handleUpdateJobFeeds}>
                  Update Job Feeds
                </Button>
              </CardContent>
        </Card>
    </Box>
  )
}
