import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Controls from '../../components/controls/Controls';
// import { Form, UseForm } from '../../components/UseForm';
import { Backdrop, Box, CardMedia, CircularProgress, FormHelperText, Grid, IconButton, Link, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { FormAdmin, UseFormAdmin } from '../components/UseFormAdmin';
import ImageSlider from '../components/ImageSlider';
import CloseIcon from '@mui/icons-material/Close';
import AddModeratorOutlinedIcon from '@mui/icons-material/AddModeratorOutlined';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import VerifiedModal from './alertModal/VerifiedModal';
import RevertModal from './alertModal/RevertModal';
import RefuteModal from './alertModal/RefuteModal';
import { axiosPrivate } from '../../api/axios';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import ModeratorModalAdmin from './ModeratorModalAdmin';
import DesignationModalAdmin from './DesignationModalAdmin';
import CourseModalAdmin from './CourseModalAdmin';
// import { Link } from 'react-router-dom';
import durations from '../../duration.json'

const mode = [
  {
      "id": "Regular",
      "name": "Regular"
  },

  {
      "id": "Online",
      "name": "Online"
  },

  {
      "id": "Distance",
      "name": "Distance"
  }
]

export default function CourseSelectModal(props) {

  const {
    // handleFetch,
    open,
    setOpen,
    handleClose,
    selectedRow,
  } = props
  // const rowData = selectedRow[0]

  useAxiosPrivate()
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [loading, setLoading] = React.useState(false)
  const [endpoint, setEndpoint] = React.useState("")
  const [furtherCourse, setFurtherCourse] = React.useState([])
  const [enabledFurtherCourses, setEnabledFurtherCourses] = React.useState([])
  const [designation, setDesignation] = React.useState([])
  const [checkedDesignations, setCheckedDesignations] = React.useState([])
  const [enabledDesignations, setEnabledDesignations] = React.useState([])
  const [competitiveExam, setCompetitiveExam] = React.useState([])
  const [checkedCompetitiveExams, setCheckedCompetitiveExams] = React.useState([])
  const [enabledCompetitiveExams, setEnabledCompetitiveExams] = React.useState([])

  // console.log(selectedRow)
  const duration = durations.duration

  const selectedDuration = duration.filter((item) => selectedRow?.duration === item.id)[0]
  const selectedMode = mode.filter((item) => selectedRow?.mode === item.id)[0]

  console.log(selectedMode)

  const initialValues = {
    name: selectedRow?.name,
    courseType: selectedRow?.type,
    program: selectedRow?.program,
    duration: selectedDuration,
    mode: selectedMode,
    isFullTime: selectedRow?.isFullTime,
    specialization: selectedRow.specialization,
    description: selectedRow?.desc,
    // eligibleCourses: selectedRow?.eligibleCourses,
    furtherCourses: selectedRow?.furtherCourses,
    designations: selectedRow?.designations,
    competitiveExams: selectedRow?.competitiveExams,
  }
  // console.log(initialValues)

  const validate = (fieldValues = values) => {
    let temp = { ...errors }
    if ('name' in fieldValues)
      temp.name = fieldValues.name ? "" : "Competitive Exam name required."

    // if ('description' in fieldValues)
    //     temp.description = fieldValues.description ? "" : "Pin/Zip required."

    setErrors({
      ...temp
    })
    // console.log(temp)

    if ((fieldValues = values))
      return Object.values(temp).every(x => x === "")
  }

  const validateRevert = (fieldValues = values) => {
    let temp = { ...errors }
    setErrors({
      ...temp
    })

    if ((fieldValues = values))
      return Object.values(temp).every(x => x === "")
  }

  const {
    values,
    setValues,
    files,
    setFiles,
    errors,
    setErrors,
    optionsData,
    setOptionsData,
    checkedItems,
    setCheckedItems,
    country,
    setCountry,
    state,
    setState,
    handleInputChange,
  } = UseFormAdmin(initialValues, true, validate, validateRevert, selectedRow);

  console.log(values)

  const filteredFurtherCourses = furtherCourse.map((item) => item.id).filter(item => !enabledFurtherCourses.includes(item))

  let initialFurtherCourseState = {}

  // const updateCourses = (enabledCourses) => { 
  for (const item of filteredFurtherCourses) {
    initialFurtherCourseState[item] = false
  }
  for (const item of enabledFurtherCourses) {
    initialFurtherCourseState[item] = true
  }
  // }

  const [furtherCourseState, setFurtherCourseState] = React.useState(initialFurtherCourseState);


  const handleCheckFurtherCourses = async (event) => {
    setFurtherCourseState({
      ...furtherCourseState,
      [event.target.name]: event.target.checked,
    });
    let updatedList = [...checkedItems];
    if (event.target.checked) {
      updatedList = [...checkedItems, event.target.value];
    } else {
      updatedList.splice(checkedItems.indexOf(event.target.value), 1);
    }
    setCheckedItems(updatedList);
  };

  const filteredDesignations = designation.map((item) => item.id).filter(item => !enabledDesignations.includes(item))

  let initialDesignationState = {}

  // const updateCourses = (enabledCourses) => { 
  for (const item of filteredDesignations) {
    initialDesignationState[item] = false
  }
  for (const item of enabledDesignations) {
    initialDesignationState[item] = true
  }
  // }

  const [designationState, setDesignationState] = React.useState(initialDesignationState);


  const handleCheckDesignations = async (event) => {
    setDesignationState({
      ...designationState,
      [event.target.name]: event.target.checked,
    });
    let updatedList = [...checkedDesignations];
    if (event.target.checked) {
      updatedList = [...checkedDesignations, event.target.value];
    } else {
      updatedList.splice(checkedDesignations.indexOf(event.target.value), 1);
    }
    setCheckedDesignations(updatedList);
  };

  const filteredCompetitiveExams = competitiveExam.map((item) => item.id).filter(item => !enabledCompetitiveExams.includes(item))

  let initialCompetitiveExamState = {}

  // const updateCourses = (enabledCourses) => { 
  for (const item of filteredCompetitiveExams) {
    initialCompetitiveExamState[item] = false
  }
  for (const item of enabledCompetitiveExams) {
    initialCompetitiveExamState[item] = true
  }
  // }

  const [competitiveExamState, setCompetitiveExamState] = React.useState(initialCompetitiveExamState);

  // console.log(filteredCompetitiveExams)

  const handleCheckCompetitiveExams = async (event) => {
    setCompetitiveExamState({
      ...competitiveExamState,
      [event.target.name]: event.target.checked,
    });
    let updatedList = [...checkedCompetitiveExams];
    if (event.target.checked) {
      updatedList = [...checkedCompetitiveExams, event.target.value];
    } else {
      updatedList.splice(checkedCompetitiveExams.indexOf(event.target.value), 1);
    }
    setCheckedCompetitiveExams(updatedList);
  };
  // console.log(competitiveExamState)

  const courseId = selectedRow?._id

  const handleFetchCheckedFurtherCourse = async () => {
    try {
      // setLoading(true)
      setEnabledFurtherCourses([])
      setFurtherCourse([])
      const res = await axiosPrivate.get(`/api/org/further_courses/${courseId}/fetch`)
      if (res) {
        // setLoading(false)        
        const data = res.data.data
        setEnabledFurtherCourses(data.map((item) => item.id))
        setFurtherCourse(data)
        // console.log(res.data)
      }
    } catch (err) {
      console.error(err);
    }
  }

  const handleFetchCourseType = async () => {
    try {
      setLoading(true)
      setOptionsData([])
      // setValues(null)
      const res = await axiosPrivate.get('/api/org/course_type/fetch')
      if (res) {
        setLoading(false)
        const data = res.data.data
        setOptionsData(data)
      }
    } catch (err) {
      console.error(err);
    }
  }

  const handleFetchDesignationType = async () => {
    try {
      setLoading(true)
      setOptionsData([])
      // setValues(null)
      const res = await axiosPrivate.get('/api/org/designation_type/fetch')
      if (res) {
        setLoading(false)
        const data = res.data.data
        setOptionsData(data)
      }
    } catch (err) {
      console.error(err);
    }
  }

  const handleFetchCourseTypeCourses = async () => {
    try {
      // setLoading(true)
      const res = await axiosPrivate.post(`api/org/course_type/courses/fetch?courseType=${values.courseTypeFurther.id}`);
      if (!res) return

      if (res.status === 200) {
        // setLoading(false)
        const data = res.data
        setFurtherCourse(data)
      }
    } catch (err) {
      console.error(err);
    }
  }

  const handleFetchDesignationTypeDesignations = async () => {
    try {
      // setLoading(true)
      const res = await axiosPrivate.post(`api/org/designation_type/designations/fetch?designationType=${values.designationType.id}`);
      if (!res) return

      if (res.status === 200) {
        // setLoading(false)
        const data = res.data
        setDesignation(data)
      }
    } catch (err) {
      console.error(err);
    }
  }

  const handleFetchCheckedDesignation = async () => {
    try {
      // setLoading(true)
      setEnabledDesignations([])
      setDesignation([])
      const res = await axiosPrivate.get(`/api/org/designations/${courseId}/fetch`)
      if (res) {
        // setLoading(false)        
        const data = res.data.data
        setEnabledDesignations(data.map((item) => item.id))
        setDesignation(data)
        // console.log(res.data)
      }
    } catch (err) {
      console.error(err);
    }
  }

  const handleFetchCheckedCompetitiveExam = async () => {
    try {
      // setLoading(true)
      setEnabledCompetitiveExams([])
      const res = await axiosPrivate.get(`/api/org/competitive_exam/${courseId}/fetch`)
      if (res) {
        // setLoading(false)        
        const data = res.data.data
        setEnabledCompetitiveExams(data.map((item) => item.id))
        // console.log(res.data)
      }
    } catch (err) {
      console.error(err);
    }
  }

  const handleFetchCompetitiveExam = async () => {
    try {
      // setLoading(true) 
      setCompetitiveExam([])
      const res = await axiosPrivate.get(`/api/org/competitive_exam/${courseId}/fetch`)
      if (res) {
        // setLoading(false)        
        const data = res.data.data
        setCompetitiveExam(data)
        // console.log(data)
      }
    } catch (err) {
      console.error(err);
    }
  }


  const handleFetchDuration = async () => {
    setOptionsData([])
    setOptionsData(duration)
    setEndpoint("duration")
  }

  const handleFetchMode = async () => {
    setOptionsData([])
    setOptionsData(mode)
    setEndpoint("mode")
  }

  const courseInfo = {
    name: values.name,
    type: values.courseType,
    program: values.program,
    specialization: values.specialization,
    duration: values.duration?.id,
    mode: values.mode?.id,
    desc: values.description,
    isFullTime: values.isFullTime,
    // eligibleCourses: selectedRow?.eligibleCourses,
    furtherCourses: JSON.stringify(checkedItems),
    designations: JSON.stringify(checkedDesignations),
    enabledDesignations: JSON.stringify(enabledDesignations),
    competitiveExams: JSON.stringify(checkedCompetitiveExams),
    enabledCompetitiveExams: JSON.stringify(enabledCompetitiveExams),

  }
  // console.log(courseInfo)

  const handleClickSubmit = async (e) => {
    e.preventDefault()
    try {
      setLoading(true)
      const formData = new FormData()
      for (let key in courseInfo) {
        formData.append(key, courseInfo[key])
      }
      const res = await axiosPrivate.put(`/api/user/course/${courseId}/update`, formData)
      if (res.status === 200) {
        setLoading(false)
        console.log(res.data)
      }
    } catch (err) {
      console.error(err);
    }
  }
  // console.log(designation)

  React.useEffect(() => {
    setFurtherCourseState(initialFurtherCourseState)
    setCheckedItems(enabledFurtherCourses)
  }, [enabledFurtherCourses])

  React.useEffect(() => {
    setDesignationState(initialDesignationState)
    setCheckedDesignations(enabledDesignations)
  }, [enabledDesignations])

  React.useEffect(() => {
    setCompetitiveExamState(initialCompetitiveExamState)
    setCheckedCompetitiveExams(enabledCompetitiveExams)
  }, [enabledCompetitiveExams])


  React.useEffect(() => {
    handleFetchCheckedFurtherCourse()
    handleFetchCheckedDesignation()
    handleFetchCheckedCompetitiveExam()
    handleFetchCompetitiveExam()
    // setCheckedItems(enabledCourses)       
  }, [selectedRow])

  React.useEffect(() => {
    if (values?.courseTypeFurther?.id.slice(0, 2) === "CT") handleFetchCourseTypeCourses();
    if (values?.designationType?.id.slice(0, 2) === "DT") handleFetchDesignationTypeDesignations();
  }, [values])

  // const locationData = selectedRow?.locationId

  return (
    <div>
      <Dialog
        // fullScreen={fullScreen}

        fullScreen
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      // sx={{zIndex: 1700}}
      // sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: 1080, height: { xs: 'auto', md: '100vh' } }}
      >
        <DialogTitle id="responsive-dialog-title" sx={{ bgcolor: 'background.paper', display: 'flex', position: "fixed", width: "97%", zIndex: 1600 }}>
          {/* {`User Data of ${selectedRow.given_name} ${selectedRow.family_name}`} */}
          {`Manage ${selectedRow?.name}`}
          <Box sx={{ flexGrow: 1 }} />
          <IconButton onClick={handleClose} >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <FormAdmin onSubmit={handleClickSubmit} id="hook-form" >
          <DialogContent sx={{ bgcolor: 'background.default', pt: "70px" }} >

            <Grid container spacing={2} sx={{ px: '3px', py: '15px' }} >
              <Grid item md={4} sx={{
                display: 'flex', flexDirection: 'column',
                '& .MuiTextField-root': { m: 1, width: '90%' },
              }} >

                <Controls.Input
                  name="name"
                  label="Competetive Exam Name"
                  value={values?.name}
                  onChange={handleInputChange}
                  error={errors.name}
                  required={true}
                />

                <Controls.Input
                  name="specialization"
                  label="Specialization"
                  value={values?.specialization}
                  onChange={handleInputChange}
                  error={errors.specialization}
                />

                <Controls.Input
                  name="courseType"
                  label="Course Type *"
                  value={values?.courseType}
                  onChange={handleInputChange}
                  disabled={true}
                  error={errors.courseType}
                />

                <Controls.AutoComplete
                  name="duration"
                  label="Select Course Duration"
                  // size='small'
                  // listFontSize='14px'
                  // labelFontSize='12px'
                  // inputFontSize='12px'
                  optionsData={optionsData}
                  setOptionsData={setOptionsData}
                  values={values}
                  setValues={setValues}
                  handleFetch={handleFetchDuration}
                  disableClearable={true}
                />

<Controls.Input
                  name="program"
                  label="Course Program *"
                  value={values?.program}
                  onChange={handleInputChange}
                  disabled={true}
                  error={errors.program}
                />

<Controls.AutoComplete
                  name="mode"
                  label="Select Course Duration"
                  // size='small'
                  // listFontSize='14px'
                  // labelFontSize='12px'
                  // inputFontSize='12px'
                  optionsData={optionsData}
                  setOptionsData={setOptionsData}
                  values={values}
                  setValues={setValues}
                  handleFetch={handleFetchMode}
                  disableClearable={true}
                />

<Box sx={{ pl: '17px' }}>
                            <Controls.Chkbox
                                name="isFullTime"
                                label="Full Time"
                                value={values.isFullTime}
                                onChange={handleInputChange}
                            />
                        </Box>


                <Controls.TextareaAutosizeBox
                  name="description"
                  label="Description *"
                  placeholder="Enter Description"
                  minRows={4}
                  maxRows={7}
                  value={values?.description}
                  onChange={handleInputChange}
                />
              </Grid>

              <Grid item md={8} sx={{ display: 'flex', flexDirection: 'column', p: '10px', }} >

                <Box sx={{ my: '10px' }}>
                  <Typography>
                    Select Further Courses
                  </Typography>
                </Box>

                <Controls.AutoComplete
                  name="courseTypeFurther"
                  label="Select Course Category"
                  size='small'
                  listFontSize='14px'
                  labelFontSize='12px'
                  inputFontSize='12px'
                  optionsData={optionsData}
                  setOptionsData={setOptionsData}
                  values={values}
                  setValues={setValues}
                  handleFetch={handleFetchCourseType}
                  disableClearable={true}
                />



                <Box sx={{ my: '5px' }}>
                  <List dense sx={{ width: '100%', bgcolor: 'background.paper', columnCount: { xs: '1', sm: '2', md: '3' } }}>
                    {furtherCourse && furtherCourse.map((value) => {
                      const labelId = `checkbox-list-label-${value.name}`;

                      return (
                        <ListItem
                          key={value.id}
                          disablePadding
                        >
                          <ListItemButton dense>
                            <ListItemIcon>
                              {Object.keys(furtherCourseState).length !== 0 &&
                                <Controls.ChkboxValues
                                  name={value.id}
                                  checkedItems={checkedItems}
                                  setCheckedItems={setCheckedItems}
                                  checked={furtherCourseState[value.id]}
                                  // label="Government Owned"
                                  value={value.id}
                                  onChange={handleCheckFurtherCourses}
                                />
                              }
                              {Object.keys(furtherCourseState).length === 0 &&
                                <Controls.ChkboxValues
                                  name={value.id}
                                  checkedItems={checkedItems}
                                  setCheckedItems={setCheckedItems}
                                  checked={furtherCourseState[value.id]}
                                  // label="Government Owned"
                                  value={value.id}
                                  onChange={handleCheckFurtherCourses}
                                />
                              }
                            </ListItemIcon>
                            <ListItemText id={value.id} primary={value.name} />
                          </ListItemButton>
                        </ListItem>
                      );
                    })}
                  </List>

                </Box>

                <Box sx={{ my: '10px' }}>
                  <Typography>
                    Select Eligible Designations
                  </Typography>
                </Box>

                <Controls.AutoComplete
                  name="designationType"
                  label="Select Designation Category"
                  size='small'
                  listFontSize='14px'
                  labelFontSize='12px'
                  inputFontSize='12px'
                  optionsData={optionsData}
                  setOptionsData={setOptionsData}
                  values={values}
                  setValues={setValues}
                  handleFetch={handleFetchDesignationType}
                  disableClearable={true}
                />



                <Box sx={{ my: '5px' }}>
                  <List dense sx={{ width: '100%', bgcolor: 'background.paper', columnCount: { xs: '1', sm: '2', md: '3' } }}>
                    {designation && designation.map((value) => {
                      const labelId = `checkbox-list-label-${value.name}`;

                      return (
                        <ListItem
                          key={value.id}
                          disablePadding
                        >
                          <ListItemButton dense>
                            <ListItemIcon>
                              {Object.keys(designationState).length !== 0 &&
                                <Controls.ChkboxValues
                                  name={value.id}
                                  checkedItems={checkedDesignations}
                                  setCheckedItems={setCheckedDesignations}
                                  checked={designationState[value.id]}
                                  // label="Government Owned"
                                  value={value.id}
                                  onChange={handleCheckDesignations}
                                />
                              }
                              {Object.keys(designationState).length === 0 &&
                                <Controls.ChkboxValues
                                  name={value.id}
                                  checkedItems={checkedDesignations}
                                  setCheckedItems={setCheckedDesignations}
                                  checked={designationState[value.id]}
                                  // label="Government Owned"
                                  value={value.id}
                                  onChange={handleCheckDesignations}
                                />
                              }
                            </ListItemIcon>
                            <ListItemText id={value.id} primary={value.name} />
                          </ListItemButton>
                        </ListItem>
                      );
                    })}
                  </List>

                </Box>

                <Box sx={{ my: '10px' }}>
                  <Typography>
                    Select Competitive Exams
                  </Typography>
                </Box>


                <Box sx={{ my: '5px' }}>
                  <List dense sx={{ width: '100%', bgcolor: 'background.paper', columnCount: { xs: '1', sm: '2', md: '3' } }}>
                    {competitiveExam && competitiveExam.map((value) => {
                      const labelId = `checkbox-list-label-${value.name}`;

                      return (
                        <ListItem
                          key={value.id}
                          disablePadding
                        >
                          <ListItemButton dense>
                            <ListItemIcon>
                              {Object.keys(competitiveExamState).length !== 0 &&
                                <Controls.ChkboxValues
                                  name={value.id}
                                  checkedItems={checkedCompetitiveExams}
                                  setCheckedItems={setCheckedCompetitiveExams}
                                  checked={competitiveExamState[value.id]}
                                  // label="Government Owned"
                                  value={value.id}
                                  onChange={handleCheckCompetitiveExams}
                                />
                              }
                              {Object.keys(competitiveExamState).length === 0 &&
                                <Controls.ChkboxValues
                                  name={value.id}
                                  checkedItems={checkedCompetitiveExams}
                                  setCheckedItems={setCheckedCompetitiveExams}
                                  checked={competitiveExamState[value.id]}
                                  // label="Government Owned"
                                  value={value.id}
                                  onChange={handleCheckCompetitiveExams}
                                />
                              }
                            </ListItemIcon>
                            <ListItemText id={value.id} primary={value.name} />
                          </ListItemButton>
                        </ListItem>
                      );
                    })}
                  </List>

                </Box>

              </Grid>

            </Grid>

          </DialogContent>
          <DialogActions sx={{ bgcolor: '#darkslateGray' }}>
            <Controls.Btn
              // name="close"
              text="Close"
              color="error"
              variant="outlined"
              onClick={handleClose}
            />
            <Controls.Btn
              // name="close"
              text="Update"
              color="success"
              variant="outlined"
              onClick={handleClickSubmit}
            />
          </DialogActions>
        </FormAdmin>

      </Dialog>
    </div>
  );
}
