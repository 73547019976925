import { createTheme } from "@mui/material";
import MUIRichTextEditor from "mui-rte";

export const lightTheme = createTheme({
  palette: {
    mode: "light",
    background: {
      paper: "#f2f2f2",
    },
    text: {
      primary: "#11111",      
    },
  },
});

export const darkTheme = createTheme({
  palette: {
    mode: "dark",
    background: {
      paper: "#171717",
      default: "#544C4A",             //"#444444"
      main: "#2e2d2d",
      primary: "#464a46",
      secondary: "#666e66",
      darkGreen: "#063b03",
      darkMaroon: "#5c0f0f"     
    },
    text: {
      primary: "#ffffffd9",
      secondary: "#ffffffb3",
      tertiary: "#FFFFFF",
      pearl: "#bfbeba",
      info: "#4fc3f7",
      warning: "#ffa500",
      success: "lightGreen",
      alternate: "#899499",
      dark: "#555555",
      darkMaroon: "#5c0f0f"      
    },
  },
});

Object.assign(darkTheme, {
  overrides: {
      MUIRichTextEditor: {
          root: {
              marginTop: 10,
              width: "100%"
          },
          editor: {
              // borderBottom: "1px solid gray" ,
              height: '200px',
              maxHeight: '200px',
              overflow: 'auto',
          }
      }
  }
})
