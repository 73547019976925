// import * as React from 'react';
import NavBar from '../components/nav/NavBar'

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import { Avatar, CardHeader, CardMedia, IconButton, Typography } from '@mui/material';
import ProfileSidebar from './widgets/ProfileSidebar';
import { useState, useEffect } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ShareIcon from '@mui/icons-material/Share';
import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import PostCard from './widgets/PostCard';
import Styled from '@emotion/styled';
import Input from '@mui/material/Input';
import AddPost from './widgets/AddPost';
import CompetitiveExamSidebar from './widgets/CompetitiveExamSidebar';
import AdsCard from './widgets/AdsCard'
import AddUserPost from './widgets/AddUserPost';
import OrgSuggestion from './widgets/OrgSuggestion';
import PeopleSuggestion from './widgets/PeopleSuggestion';
import { useSelector } from 'react-redux';
import ProfileSidebarSelf from './widgets/ProfileSidebarSelf';
import { Helmet } from 'react-helmet-async';

export default function Home() {  
  
  // const {user_id} = useSelector(
  //   (state) => state.user
  // )

  // const {userId} = useSelector(
  //   (state) => state.auth
  // )
 
  return (
    <Paper elevation={0} sx={{ bgcolor: 'background.default', minHeight: '100vh',  paddingBottom: '60px' }} >
      <Helmet>
          <title>Karpath - Lead to Excellence</title>
          <meta name="description" content="AI Integrated Social Media Platform for All Your Career Needs" />
          <meta property="og:title" content="Karpath - Lead to Excellence" />         
        </Helmet>
      <NavBar />
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={3} sx={{ justifyContent: 'center' }} >
          <Grid item xs={11} sm={10} md={6} sx={{ minWidth: '320px' }} >
            {/* <AddUserPost /> */}
            <PostCard />
          </Grid>

          <Grid item xs={3} sx={{ display: { xs: 'none', sm: 'none', md: 'block' }, minWidth: '280px', my: '10px' }} >
           <ProfileSidebarSelf /> 

<AdsCard />
           
              {/* <OrgSuggestion /> */}
              
           
            {/* <PeopleSuggestion /> */}
           
            {/* <CompetitiveExamSidebar /> */}
          
            

          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
}
