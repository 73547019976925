import React from 'react'
import { Box, TextareaAutosize } from '@mui/material';

export default function TextareaAutosizeBox(props) {

    const { name, placeholder, label, value, disabled, maxRows, minRows, fullwidth, error = null, onChange } = props;
    
    return (
        <Box sx={{  width:'100%' }} >
            <TextareaAutosize 
            style={{ width: "99%", backgroundColor: 'inherit', color: "inherit",
            // '& textarea::placeholder': {  //Not working
            //     color: 'text.secondary'
            // },           
         }} 
                              
                label={label}
                placeholder={placeholder}
                name={name}
                value={value}
                fullwidth={fullwidth}
                minRows={minRows}
                maxRows={maxRows}
                onChange={onChange}
                disabled={disabled}
                {...(error && { helpertext: error })}
            />
        </Box>
    )
}