import { configureStore } from "@reduxjs/toolkit";
import adminReducer from "../features/admin/adminSlice";
import authReducer from "../features/userAuth/authSlice";
import orgReducer from "../features/org/orgSlice";
import themeReducer from "../features/theme/themeSlice";
import userReducer from "../features/user/userSlice";
import examReducer from "../features/exam/examSlice";

export const store = configureStore({
  reducer: {
    admin: adminReducer,
    auth: authReducer,
    org: orgReducer,
    theme: themeReducer,    
    user: userReducer,
    exam: examReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      // serializableCheck: {
      //   // Ignore these action types
      //   ignoredActions: ['auth/update', 'org/updateLogo'],
      //   // Ignore these field paths in all actions
      //   // ignoredActionPaths: ['meta.arg', 'payload.timestamp'],
      //   // Ignore these paths in the state
      //   // ignoredPaths: ['items.dates'],
      // }, 
    }),
})