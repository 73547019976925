import React from 'react'
import AppBar from '@mui/material/AppBar';
import { Box, Button, IconButton, Toolbar, Typography } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import { Link } from 'react-router-dom';

const NavBarPublic = ({setOpen}) => {

  return (
    <AppBar position="sticky" color='primary' sx={{ zIndex: 10}} >
        <Toolbar>
       <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: 'flex',
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            KARPATH
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          {/* <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          >
            <LockIcon />
          </IconButton> */}
          {/* <Button variant="outlined" color='warning' component={Link} to="/login" onClick={() => setOpen(true)} sx={{textTransform: 'none'   }} startIcon={<LockIcon fontSize="small" />}> Login
          </Button>           */}
          <Button variant="outlined" color='warning' onClick={() => setOpen(true)} sx={{textTransform: 'none'   }} startIcon={<LockIcon fontSize="small" />}> Login
          </Button> 
          </Toolbar>
    </AppBar>
  )
}

export default NavBarPublic