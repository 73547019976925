import { Navigate } from "react-router-dom";
import { axiosPrivate } from "../../api/axios";


const API_URL = '/api/user/';

const userFetch = async (user_id) => {
  console.log(user_id)
  const res = await axiosPrivate.get(API_URL + `${user_id}`); 
  
  if (res.data) {    
    localStorage.setItem('userSuggest', JSON.stringify(res.data.data));    
  }
  return res.data.data
}
const userService = {  
    userFetch,
}

export default userService