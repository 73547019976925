import * as React from 'react';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
// import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';
import { Avatar, CardHeader, CardMedia, IconButton, Typography, Modal, Box, Input, Card, Button, Backdrop, CircularProgress } from '@mui/material';
import CreatePost from '../modals/post/CreatePost';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import CorporateFareTwoToneIcon from '@mui/icons-material/CorporateFareTwoTone';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { axiosPrivate } from '../../api/axios';
import { Form, UseForm } from '../../components/UseForm';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const OrgLogo = styled.img`
  border-radius: 50%;  
  width: 30px;
  height: 30px;
  object-fit: cover;
`;

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function AddOrgPost() {
  useAxiosPrivate(); 

  const ref = React.useRef()

  const { logo, orgName, pic, activeMembers, orgId, sectorName, isEduInstitution, isModerator, city, state, country, locationId } = useSelector(
    (state) => state.org
  )

  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false)
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [severity, setSeverity] = React.useState();
  const [postId, setPostId] = React.useState();  

  const date = new Date();

   const initialValues = {
    designation: null,
    experience: { name: "0-1 Year"},
    jobType: { name: "Full Time"},
    jobMode: { name: "Onsite"},
    lastDate: new Date().setDate(date.getDate() + 30),
    extendedDate: new Date().setDate(date.getDate() + 30),
    salary: "",
    gender: "Any",
    title: "",
    description: "", 
    roles: "",
    location: [],   
    includeHQ: true,
    vacancies: 1,
    url:"",
  }

  // let postFeed = orgPost;
  // let jobFeed = jobPost; 
 
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  
  const {
    values,
    setValues,    
    files,
    setFiles,
    errors,
    setErrors,   
    handleInputChange,
    resetForm,
    optionsData,
    setOptionsData,    
  } = UseForm(initialValues);

  const urlQuery = new Date().getTime()
  const logo_updated = logo + `?${urlQuery}`
   
  return (
    
    <Card sx={{display: { xs: 'block', sm:'block' }, my: '10px', p: '3px' }} >
      <Form>
      
      <Box sx={{ display: 'flex', m: '3px', height: '40px', alignItems: 'center', gap: '10px' }}>
      {logo ? <OrgLogo src={logo_updated} alt='L' /> : <CorporateFareTwoToneIcon variant="rounded" sx={{ width: '30px', height: '30px', bgcolor: 'lightGray', color: 'gray', borderRadius: '5px' }} /> }
      
        <div style={{ width: '100%' }}>
          <Typography variant="body2" component="div" sx={{ fontSize: '12px', color: 'GrayText' }} onClick={handleClickOpen}>
            Add your post here...
          </Typography>
          <hr style={{ width: '97%', border: '0.05rem dotted', color: 'Grey' }} />
        </div>
      </Box> 
      
        <CreatePost
         onClose={handleClose}
          // onSubmit={handleSubmit}
          //  jobSubmit={handleJobSubmit}
            open={open}
             handleInputChange={handleInputChange}  
             files={files}
    setFiles={setFiles}    
         loading={loading}
         values={values}
         setValues={setValues}
         optionsData={optionsData}
         setOptionsData={setOptionsData} 
         setOpen={setOpen}
         resetForm={resetForm}
         setLoading={setLoading}
         setSeverity={setSeverity}
         setOpenSnackbar={setOpenSnackbar}        
        />
        </Form>  

        {
          openSnackbar &&  
          <Snackbar open={openSnackbar} autoHideDuration={3000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity={severity} sx={{ width: '100%' }}>
          Posted Successfully!
        </Alert>
      </Snackbar>
        }

    </Card >
  )
}

export default AddOrgPost