import * as React from 'react';
import NavBar from '../components/nav/NavBar'

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import ProfileSidebar from './widgets/ProfileSidebar';
import ProfileSidebarSelf from './widgets/ProfileSidebarSelf';
import JobCardFeed from './widgets/JobCardFeed';
import { useDispatch, useSelector } from 'react-redux';
import CompetitiveExamSidebar from './widgets/CompetitiveExamSidebar';
import AdsCard from './widgets/AdsCard';
import { update } from '../features/userAuth/authSlice';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { axiosPrivate } from '../api/axios';
import { Helmet } from 'react-helmet-async';


// const Input = Styled.input`
// type="text";
// placeholder='Add your post here';
// width: 90%;
// borderRadius: 30px;
// &:focus: none;`;


// const Item = styled(Paper)(({ theme }) => ({
//   backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
//   ...theme.typography.body2,
//   padding: theme.spacing(3),
//   textAlign: 'center',
//   color: theme.palette.text.secondary,
// }));

export default function Jobs() {

  useAxiosPrivate();

  const dispatch = useDispatch();
 
  //   const {user_id} = useSelector(
  //   (state) => state.user
  // )

  // const {userId} = useSelector(
  //   (state) => state.auth
  // )

  const fetchSelf = async () => {
    try {
      const res = await axiosPrivate.get(`/api/user/self`)
      if (res) {
        const data = res.data.data
        dispatch(update(data))
        // const profileUpdate = JSON.parse(localStorage.getItem('user'))
        // profileUpdate = data
        localStorage.setItem('user', JSON.stringify(data))
        // console.log(data)        
      }
    } catch (err) {
      console.error(err);
    }
  };

  React.useEffect(() => {
    fetchSelf()
  }, [])


  
  return (
    
    <Paper elevation={0} sx={{ bgcolor: 'background.default', minHeight: '100vh',  paddingBottom: '60px' }} >
      <Helmet>
          <title>Karpath - Lead to Excellence</title>
          <meta name="description" content="AI Integrated Social Media Platform for All Your Career Needs" />
          <meta property="og:title" content="Karpath - Lead to Excellence" />         
        </Helmet>
      <NavBar />
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={3} sx={{ justifyContent: 'center' }} >
          <Grid item xs={11} sm={10} md={6} sx={{ minWidth: '320px' }}>                     
            <JobCardFeed />
          </Grid>

          <Grid item xs={3} sx={{ display: { xs: 'none', sm: 'none', md: 'block' }, minWidth: '280px', my: '10px' }} >
                   
                <ProfileSidebarSelf />              
              {/* <OrgSuggestion /> */}
              <AdsCard />  
            {/* <CompetitiveExamSidebar /> */}
            

          </Grid>
        </Grid>
      </Box>
    </Paper>
    
  );
}
