import React from 'react'
import { TextField } from '@mui/material';

export default function Input(props) {

    const { name, type, label, value, disabled, error = null, onChange, size, required, multiline, minRows, maxRows, placeholder, fullWidth } = props;
    
    return (
        <TextField
            variant="outlined"
            label={label}
            name={name}
            type={type}
            value={value}
            size={size}
            fullWidth={fullWidth}
            onChange={onChange}
            disabled={disabled}
            required={required}
            multiline={multiline}
            minRows={minRows}
            maxRows={maxRows} 
            placeholder={placeholder}          
            {...(error && { error: true, helperText: error })}            
                     
        />
    )
}