import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import AddAPhotoOutlinedIcon from '@mui/icons-material/AddAPhotoOutlined';
import { CircularProgress, DialogActions, DialogContent, DialogTitle, Divider, FormControl, Grid, IconButton, Input, MenuItem, Select } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import WorkIcon from '@mui/icons-material/Work';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import { Link, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { axiosPrivate } from '../../api/axios';

export default function ModeratorModalAdmin({ orgDetails, moderator, moderatorModal, handleCloseModerator,}) {

  const { orgId } = useParams()
  // useAxiosPrivate();  

//   const [anchorEl, setAnchorEl] = React.useState(null);
  // const [value, setValue] = React.useState(1);
  const [loading, setLoading] = React.useState(false)
  const [keyValue, setKeyValue] = React.useState("")
  const [query, setQuery] = React.useState("");
  const [employees, setEmployees] = React.useState([])
    
  // const handleChange = (event) => {
  //   setValue(event.target.value);
  // };  
//   const open = Boolean(anchorEl);
  // const orgId = orgDetails?.org._id;
  // console.log(selectedRow)

  const handleFetchMembers = async () => {
    try {
      setLoading(true)
      const res = await axiosPrivate.get(`/api/org/${orgId}/active_members/fetch`)
      if (res) {
        setLoading(false)
        const data = res.data.data
        setEmployees(data)
        // console.log(data)
      }
    } catch (err) {
      console.error(err);
    }
  }

  const handleSearch = (e) => {    
    console.log(query)
    if (query.length > 0 ) {
      const filterBySearch = employees.filter((item) => {
        if (item.firstName.toLowerCase().includes(query) || item.lastName.toLowerCase().includes(query)) return item;
      })
      setEmployees(filterBySearch)
    }
    if (query.length === 0) handleFetchMembers()       
  }
console.log(employees)

  React.useEffect(() => {     
    if (query.length === 0 || query.length > 0) handleSearch();
  }, [query])
       
  return (

    <Dialog
      open={moderatorModal}
      onClose={handleCloseModerator}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title" sx={{ bgcolor: 'background.paper', minWidth: 250, height: 30, display: 'flex', alignItems: 'center', zIndex: 1600, fontSize: 16 }}>
        {`Add/Remove Admins `}
        <Box sx={{ flexGrow: 1 }} />
        <IconButton size="small" onClick={handleCloseModerator} >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ bgcolor: 'background.default', minWidth: 250, height: '65vh', display: 'flex', flexDirection: 'column', zIndex: 1600 }}>

        <Box sx={{ display: 'flex', alignItems: 'center', mb: '7px', }}>
        <Input type='text' placeholder='Search' onChange={(e) => setQuery(e.target.value.toLowerCase())} ></Input>

          {/* <FormControl sx={{ m: 1, minWidth: 120, fontSize: '10px' }} size="small">
            <Select
              // labelId="demo-select-small"
              id="infoId"
              value={value}
              onChange={handleChange}
              sx={{ fontSize: '10px' }}
            >
              <MenuItem value={1} sx={{ fontSize: '12px' }}>All Courses</MenuItem>
              <MenuItem value={2} sx={{ fontSize: '12px' }}>Arts and Science</MenuItem>
              <MenuItem value={3} sx={{ fontSize: '12px' }}>Engineering</MenuItem>
              <MenuItem value={4} sx={{ fontSize: '12px' }}>Medical</MenuItem>
            </Select>
          </FormControl> */}
        </Box>
        <Divider sx={{mb: 1}} />
        
        <Grid container spacing={0.5} sx={{ mx: '1px', pr: '1px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          {employees.map((element) => <Box key={element._id}> 
            
            <Grid  item gap={2} sx={{ mx: '3px', mb: '15px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Box component={Link} to='#' sx={{ display: 'flex', alignItems: 'center', textDecoration: 'none', color: 'inherit', cursor: 'pointer' }}>
              {/* <WorkIcon variant="rounded" sx={{ mr: '5px', width: '60px', height: 'auto', bgcolor: 'lightGray', color: 'gray', borderRadius: '5px', }} /> */}
              <Box>
                <Typography variant='h6' sx={{ fontSize: '11px', fontWeight: '500', cursor: 'pointer', maxWidth: { xs: '200px', sm: '300px', md: '200px', lg: '350px' }, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} > {element.firstName} {element.lastName} </Typography>
                <Typography variant='h6' sx={{ fontSize: '10px', fontWeight: '200', }} > {element.recentCourse}</Typography>
                <Typography variant='h6' sx={{ fontSize: '10px', fontWeight: '200', }} > {element.recentDesignation}</Typography>
              </Box>
            </Box>            

            <Box sx={{ flexGrow: 1 }} />
            {orgDetails?.org.moderators.includes(element._id) ?  (<Box sx={{ m: '3px', display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'relative' }}>
              <DoneOutlineIcon onClick={ 
                  async() => {
                    let orgArray = orgDetails?.org.moderators;
                    const moderatorId = element._id 
                     console.log(orgArray)                   
                    try {
                      setKeyValue(moderatorId)
                      setLoading(true)
                      const res = await axiosPrivate.put(`/api/org/org_request/${orgId}/${moderatorId}/remove_moderator`)
                      if (res) {                        
                        setLoading(false)
                        setKeyValue("")
                        // orgArray.pop()
                        const filtered = orgArray.indexOf(moderatorId)
                        if (filtered !== -1) {
                          orgArray.splice(filtered, 1)
                        }
                        console.log(orgArray)                                
                      }
                    } catch (err) {
                      setLoading(false)
                        setKeyValue("")
                      console.error(err);
                    }                     
                  }
                } sx={{ mr: '4px', cursor: 'pointer', color: 'orange' }} />
                {(loading && keyValue === element._id) && <CircularProgress 
                // key ={element._id}
              size={35}
              sx={{
                color: 'orange',
                position: 'absolute',               
              }} />
            }
            </Box>) : (
              <Box sx={{ m: '3px', display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'relative' }}>
                <DoneOutlineIcon onClick={ 
                  async() => {
                    let orgArray = orgDetails?.org.moderators;
                    const moderatorId = element._id 
                    console.log(orgArray)
                    try {
                      setKeyValue(moderatorId)
                      setLoading(true)
                      const res = await axiosPrivate.put(`/api/org/org_request/${orgId}/${moderatorId}/add_moderator`)
                      if (res) {
                        setLoading(false)
                        setKeyValue("")
                        orgArray.push(moderatorId)
                        console.log(res) 
                        console.log(orgArray)                            
                      }
                    } catch (err) {
                      setLoading(false)
                        setKeyValue("")
                      console.error(err);
                    }                     
                  }
                } sx={{ mr: '4px', cursor: 'pointer', color: 'darkGray' }} />
                {(loading && keyValue === element._id) && <CircularProgress                 
              size={35}
              sx={{
                color: 'orange',
                position: 'absolute',               
              }} />
            }
              </Box>
            )}
          </Grid>
            <Divider />
            </Box >
           )}
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
