import { useDispatch } from "react-redux";
import { Navigate } from "react-router-dom";
import axios from "../../api/axios";
import { userFetch } from "../user/userSlice";

const API_URL = '/api/auth/';

// Login user
const login = async (googleData) => {  
  const res = await axios.post(API_URL + 'login', googleData,
    {
      headers: { 'Content-Type': 'application/json' },
      withCredentials: true
    });

  if (res.data) {
    console.log(res.data.data)
    localStorage.setItem('user', JSON.stringify(res.data.data));    
  }
  // if (res.data.info) {
  //   if (Object.keys(res.data.info).length !== 0) {
  //     localStorage.setItem('info', JSON.stringify(res.data.info));
  //   }
  // }
  return res.data.data
}

// Logout user
const logout = async () => { 
  localStorage.removeItem('user'); //auth user
  localStorage.removeItem('org');
  localStorage.removeItem('info');
  localStorage.removeItem('exam');  
  localStorage.removeItem('userSuggest'); //clicked user
  const res = await axios.post('/api/auth/logout');
}

const authService = {
  logout,
  login,
}

export default authService