import React from 'react'
import OrgNavBar from '../components/nav/OrgNavBar'
import { Box, Grid, Paper, Tabs, Typography } from '@mui/material'
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
// import TabPanel from '@mui/lab/TabPanel';
import { useSelector } from 'react-redux';
import TableOrgMemberRequest from './widgets/TableOrgMemberRequest';
import TableJobDetails from './widgets/TableJobDetails';
import TableJobApplications from './widgets/TableJobApplications';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Link, useNavigate } from 'react-router-dom';
import TableOrgEnquiry from './widgets/TableOrgEnquiry';
import TableActiveMembers from './widgets/TableActiveMembers';
import TableStudents from './widgets/TableStudents';
import { axiosPrivate } from '../api/axios';
import useAxiosPrivate from '../hooks/useAxiosPrivate';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ px: 2 }}>
          {/* <Typography> */}
          {children}
          {/* </Typography> */}
        </Box>
      )}
    </div>
  );
}

//   TabPanel.propTypes = {
//     children: PropTypes.node,
//     index: PropTypes.number.isRequired,
//     value: PropTypes.number.isRequired,
//   };

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}


const OrgSettings = () => {

  useAxiosPrivate()
  const navigate = useNavigate()

  const { logo, orgName, pic, activeMembers, sectorName, isEduInstitution, subscription, isModerator, followers, city, state, country } = useSelector(
    (state) => state.org
  )

  const [value, setValue] = React.useState(0);
  const [jobDetailsClicked, setJobDetailsClicked] = React.useState(false);
  const [job, setJob] = React.useState()
  const [applicants, setApplicants] = React.useState()
  const [loading, setLoading] = React.useState(false)

  const handleFetchJobDetails = async (jobId) => {
    console.log(jobId)
    try {
      setLoading(true)
      const res = await axiosPrivate.get(`/api/post/job/${jobId}`)
      if (res) {
        setLoading(false)
        const data = res.data.data
        // console.log(data)        
        setApplicants(data.applied_candidates)       
        setJob(data)
      }
    } catch (err) {
      console.error(err);
    }
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClickBack = () => {
    navigate(-1)
  }

  return (
    <Paper elevation={0} sx={{ bgcolor: 'background.default', minHeight: '100vh', paddingBottom: '60px' }} >
      <OrgNavBar />
      {/* <Box sx={{ flexGrow: 1 }}> */}
      <Grid container  sx={{ mt: '6px', justifyContent: 'center', width: '100%' }} >
        <Grid item xs={11} sm={11} md={11} sx={{ minWidth: '320px', maxWidth: '100%', }}>
          <Box
            sx={{ flexGrow: 1, bgcolor: 'background.main', display: 'flex', height: '87vh', overflowY: 'hidden', }}
          >
            <Tabs
              orientation="vertical"
              variant="scrollable"
              value={value}
              onChange={handleChange}
              aria-label="Vertical tabs example"
              sx={{ borderRight: 1, borderColor: 'divider', justifyItems: 'left' }}
            >
              {
                jobDetailsClicked ?
                  <Tab icon={<ArrowBackIcon fontSize='small' />} iconPosition="start" label="Jobs" {...a11yProps(0)} onClick={() => { setJobDetailsClicked(false) }} sx={{ textTransform: 'none' }} />
                  :
                  <Tab label="Job Applications" {...a11yProps(0)} sx={{ textTransform: 'none' }} />
              }
              <Tab label="Member Requests" {...a11yProps(1)} sx={{ textTransform: 'none' }} />
              <Tab label="Active Members" {...a11yProps(2)} sx={{ textTransform: 'none' }} />
              <Tab label="Org Settings" {...a11yProps(3)} sx={{ textTransform: 'none' }} />
              {isEduInstitution && <Tab label="Admission Enquiry" {...a11yProps(4)} sx={{ textTransform: 'none' }} />}
              {isEduInstitution && <Tab label="Active Students" {...a11yProps(5)} sx={{ textTransform: 'none' }} />}              
              <Tab onClick={handleClickBack} icon={<ArrowBackIcon fontSize='small' />} iconPosition="start" label="Org Profile" {...a11yProps(6)} sx={{ textTransform: 'none' }} />
            </Tabs>
            <TabPanel value={value} index={0}>
              {
                jobDetailsClicked ? <TableJobApplications selectedItem={job} setSelectedItem={setJob} loading={loading} setLoading={setLoading} applicants={applicants} /> : <TableJobDetails setJobDetailsClicked={setJobDetailsClicked} jobDetailsClicked={jobDetailsClicked} handleFetchJobDetails={handleFetchJobDetails} />
              }
            </TabPanel>
            <TabPanel value={value} index={1}>
              <TableOrgMemberRequest />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <TableActiveMembers />
            </TabPanel>
            <TabPanel value={value} index={3}>
            <Typography sx={{ my: 1.5 }} color="text.secondary" variant="subtitle2" component="div">
              Subcription: {subscription}
            </Typography>
            </TabPanel>    
            <TabPanel value={value} index={4}>
            <TableOrgEnquiry />
            </TabPanel>
            <TabPanel value={value} index={5}>
              <TableStudents />
            </TabPanel>
                    
          </Box>

        </Grid>
      </Grid>
      {/* </Box> */}
    </Paper>
  )
}

export default OrgSettings