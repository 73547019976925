import * as React from 'react';
import { Box, CardMedia, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export default function ViewPostImageModal({ open, handleClose, url }) {

  return (

    <Dialog
      fullScreen
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}      
    >        
      <DialogTitle id="responsive-dialog-title" sx={{ bgcolor: 'background.paper', display: 'flex', alignContent: 'flexEnd', position: "fixed", px: '10px', zIndex: 1600,  }}>        
        <Box sx={{ flexGrow: 1 }} />
        <IconButton onClick={handleClose} >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <CardMedia
            component="img"                      
            src={url}
            alt="Image Post"
            sx={{pt: "30px", objectFit: 'contain', minWidth: "300px", height: '90vh',}}
          />

      {/* <DialogContent sx={{ mt: '45px', overflow: 'hidden'  }} >     
      </DialogContent>      */}

    </Dialog>

  );
}
