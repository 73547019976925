import React from 'react'
import { FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, FormHelperText } from '@mui/material';

export default function Rgroup(props) {

    const { name, size, labelFontSize, label, value, error = null, onChange, items, required, disabled, fontSize } = props;

    return (
        <FormControl sx={{px: '7px', }}
        {...(error && { error: true })}
        >
            <FormLabel>{label}</FormLabel>
            <RadioGroup row
                name={name}
                value={value}
                onChange={onChange}
                // size={size} 
                required={required}
                                                                         
                >
                {
                    items.map(
                        item => (
                            <FormControlLabel key={item.id} value={item.id} disabled = {disabled} control={<Radio size={size} />} label={item.title} 
                            sx={{'& .css-ahj2mt-MuiTypography-root': { fontSize: `${fontSize}`}}}
                            />
                        )
                    )
                }
                
            </RadioGroup>
            <FormHelperText>{error}</FormHelperText>
        </FormControl>
    )
}