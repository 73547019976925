import React from 'react'
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import { Alert, Avatar, Backdrop, Button, CardHeader, CardMedia, Chip, CircularProgress, Divider, Grid, IconButton, Menu, MenuItem, Skeleton, Snackbar, Typography, getTableSortLabelUtilityClass } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ShareIcon from '@mui/icons-material/Share';
import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { fetchOrg } from '../../features/org/orgSlice';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { axiosPrivate } from '../../api/axios';
import CorporateFareTwoToneIcon from '@mui/icons-material/CorporateFareTwoTone';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import moment from 'moment'
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import ReactHtmlParser from 'react-html-parser'
import parse from 'html-react-parser'
import BookmarkOutlinedIcon from '@mui/icons-material/BookmarkOutlined';
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import { stateToHTML } from 'draft-js-export-html';
import { convertFromRaw } from 'draft-js'
import { Helmet } from 'react-helmet-async';

const OrgLogo = styled.img`
  border-radius: 50%;  
  width: 40px;
  height: 40px;
  object-fit: cover;
`;

const JobCardDetail = ({ setOpen }) => {

  const { jobId } = useParams()
  useAxiosPrivate()

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { userId } = useSelector(
    (state) => state.auth
  )

  const [clicked, setClicked] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [post, setPost] = React.useState(null);
  const [loading, setLoading] = React.useState(false)
  const [loadingApply, setLoadingApply] = React.useState(false)
  const [disabled, setDisabled] = React.useState(false)
  const [loadingFeeds, setLoadingFeeds] = React.useState(false)
  const [bookmarkLoading, setBookmarkLoading] = React.useState(false)
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [severity, setSeverity] = React.useState();
  const [applied, setApplied] = React.useState([]);
  const [message, setMessage] = React.useState();

  const isMenuOpen = Boolean(anchorEl);

  const millisec = Date.now()
  const date = new Date(millisec)

  console.log(userId)

  let htmlContent
  if (post !== null && post.roles !== "") {
    const content = JSON.parse(post?.roles)

    // const htmlcontent = '<p> Hello </p>'
    const convertedContent = convertFromRaw(content)

    htmlContent = stateToHTML(convertedContent)
    console.log(htmlContent)
  }


  const handleMoreVertMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);

  };

  const copyLink = () => {
    navigator.clipboard.writeText(`${process.env.REACT_APP_URL}/job/${jobId}`)
    setAnchorEl(null);
  };

  const fetchJobPost = async () => {
    // e.preventDedault() 
    try {
      setLoadingFeeds(true)
      const res = await axiosPrivate.get(`/api/post/job/${jobId}`)
      if (res) {
        const data = res.data.data
        setPost(data)
        setLoadingFeeds(false)
        console.log(data)
      }
    } catch (err) {
      console.error(err);
      setLoadingFeeds(false)
    }
  };

  const handleApplyJob = async () => {
    try {
      if (post.url !== "") {
        setLoadingApply(true)
        const res = await axiosPrivate.put(`/api/post/${jobId}/apply`)
        if (res.status === 201) {
          window.open(`${post.url}`, '_blank', 'noopener noreferrer')
          setLoadingApply(false)
          setApplied([...applied, jobId])
        }
      } else {
        setLoadingApply(true)
        // setLoading(true)
        const res = await axiosPrivate.put(`/api/post/${jobId}/apply`)
        if (res.status === 201) {
          setLoadingApply(false)
          setApplied([...applied, jobId])
          setMessage(res.data)
          setSeverity("success")
          setOpenSnackbar(true)
        }
      }
    } catch (err) {
      console.error(err);
      setLoadingApply(false)
      setMessage(err.response.data)
      setSeverity("warning")
      setOpenSnackbar(true)
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  React.useEffect(() => {
    fetchJobPost()
  }, [jobId])

  const moreVertId = 'more-vert-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={post?._id}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >

      {post?.org.activeMembers.includes(userId) && <MenuItem disabled sx={{ padding: '3px 16px 3px 16px' }}  >
        <Typography sx={{ fontSize: '13px', fontWeight: 250, color: 'grey' }}> Unfollow {post?.org.name} </Typography>
      </MenuItem>}

      {(!post?.org.activeMembers.includes(userId) && post?.org.followers.includes(userId)) && <MenuItem disabled={disabled} sx={{ padding: '3px 16px 3px 16px' }} onClick={async () => {
        const orgId = post?.org._id
        let followersArray = post?.org.followers;
        try {
          setLoading(true)
          setDisabled(true)
          const res = await axiosPrivate.put(`/api/org/${post.org._id}/unfollow`)
          if (res.status === 201) {
            setAnchorEl(null)
            setLoading(false)
            setDisabled(false)
            const filtered = followersArray.indexOf(userId)
            if (filtered !== -1) {
              followersArray.splice(filtered, 1)
            }
            console.log(followersArray)
            // const data = res.data.data        
          }
        } catch (err) {
          setAnchorEl(null)
          console.error(err);
          setLoading(false)
          setDisabled(false)
        }
      }
      }  >
        <Typography sx={{ fontSize: '13px', fontWeight: 250 }}> Unfollow {post?.org.name} </Typography>
      </MenuItem>}

      {(!post?.org.activeMembers.includes(userId) && !post?.org.followers.includes(userId)) && <MenuItem disabled={disabled} sx={{ padding: '3px 16px 3px 16px' }} onClick={async () => {
        const orgId = post?.org._id
        let followersArray = post?.org.followers;
        try {
          setLoading(true)
          setDisabled(true)
          const res = await axiosPrivate.put(`/api/org/${orgId}/follow`)
          if (res.status === 201) {
            setAnchorEl(null)
            setLoading(false)
            setDisabled(false)
            followersArray.push(userId)
            console.log(res)
            // const data = res.data.data        
          }
        } catch (err) {
          setAnchorEl(null)
          console.error(err);
          setLoading(false)
          setDisabled(false)
        }
      }
      }  >
        <Typography sx={{ fontSize: '13px', fontWeight: 250 }}> Follow {post?.org.name} </Typography>
      </MenuItem>}

      <MenuItem sx={{ padding: '3px 16px 3px 16px' }} onClick={copyLink} >
        <Typography sx={{ fontSize: '13px', fontWeight: 250 }}> Copy Link</Typography>
        {loading && <CircularProgress
          // key ={element._id}
          size={25}
          sx={{
            color: 'orange',
            position: 'absolute',
            top: '10%',
            left: '40%',
            // marginTop: '-12px',
            // marginLeft: '-12px',
          }}
        />
        }
      </MenuItem>
      <MenuItem sx={{ padding: '3px 16px 3px 16px' }} onClick={handleMenuClose} component={Link} to="/" >
        <Typography sx={{ fontSize: '13px', fontWeight: 250 }}> Report</Typography>
      </MenuItem>
    </Menu>
  )

  const LoadingSkeleton = () => {
    return (
      <Card sx={{ my: '10px', p: '3px' }}>
        <CardHeader
          avatar={
            <Skeleton animation="wave" variant="circular" width={40} height={40} />
          }
          title={
            <Skeleton
              animation="wave"
              height={10}
              width="80%"
              style={{ marginBottom: 6 }}
            />
          }
          subheader={
            <Skeleton animation="wave" height={10} width="40%" />
          }
        />
        <CardContent>
          <Skeleton animation="wave" height={30} width="70%" style={{ marginBottom: 7 }} />
        </CardContent>
        <Skeleton animation="wave" variant="rectangular" height={230} width="100%" style={{ marginBottom: 6 }} />
        <CardContent>
          <Skeleton animation="wave" height={10} width="20%" />
        </CardContent>
      </Card>
    )
  }


  return (
    <>
      {loadingFeeds ? <LoadingSkeleton /> : post && <Card sx={{ my: '10px', p: '3px' }}>
        <Helmet>
          <title>{post.designation.name}</title>
          {/* <meta name="description" content={(htmlContent).split(" ").splice(0, 20).join(" ")} /> */}
          <meta name="description" content={`${post.org.name} has vacancies for the post of ${post.designation.name}. Interested candidate can apply for the job.`} />
          <meta property="og:title" content={post.designation.name} />                  
        </Helmet>
        {/* <Box key={Math.random()}> */}
        <CardHeader sx={{ p: '7px' }}

          avatar={post.org.logo !== "" ? <OrgLogo src={post.org.logo} alt='L' /> :
            <CorporateFareTwoToneIcon variant="rounded" sx={{ width: '45px', height: '45px', bgcolor: 'lightGray', color: 'gray', borderRadius: '5px' }} />
          }

          action={userId &&
            <IconButton
              aria-label="settings"
              aria-controls={post._id}
              aria-haspopup="true"
              onClick={(event) => {
                // setElement(post)
                setAnchorEl(event.currentTarget);
                // setPostId(post._id)
              }}>
              <MoreVertIcon />
            </IconButton>
          }
          titleTypographyProps={{ variant: 'h5', fontSize: '12px', fontWeight: '400', overflow: 'hidden', whiteSpace: 'nowrap' }}
          title={userId !== null ? <Box sx={{ maxWidth: '200px', cursor: 'pointer' }} onClick={() => {
            // dispatch(fetchOrg(post.org._id))
            navigate(`/org/${post.org._id}`)
          }}> {post.org.name} </Box>
            :
            <Box sx={{ maxWidth: '200px', cursor: 'pointer' }} onClick={() => {
              navigate(`/org/${post.org._id}/${post.org.slug}/about`)
            }} > {post.org.name} </Box>}
          subheader={<div>
            {/* <Typography variant='h6' sx={{ fontSize: '9px', fontWeight: '300' }} > {post.org.activeMembers.length} Employees </Typography> */}
            <Typography variant='h6' sx={{ fontSize: '10px', fontWeight: '300', lineHeight: '0.5rem' }} >Posted on {moment(post.createdAt).format("DD-MM-YYYY")}.</Typography></div>
          }
        />
        {userId && renderMenu}


        <CardContent >
          <Typography variant="h2" color="text.secondary" sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', fontSize: '1.25rem' }}>
            {post.designation.name}&nbsp;
            <Typography variant="body2" color="text.secondary" sx={{ fontSize: '11px', fontStyle: 'italic' }}>
              {post.experience}
            </Typography>
          </Typography>

          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <PlaceOutlinedIcon fontSize='12px' />
            <Typography variant="h6" color="text.secondary" sx={{ display: 'flex', flexWrap: 'wrap', fontSize: '12px', }} >
              {post.location.slice(0, 10).map((item, index) => <Box key={item.id} sx={{ mx: '3px' }}>
                {item.district}, {item.state}
              </Box>)}
              {
                (post.location.length - 5 > 0) && <Box> + {post.location.length - 5}</Box>
              }
            </Typography>
            {/* {
                (post.location.length - 10 > 0) &&
                <Typography variant="h6" color="text.secondary" sx={{ display: 'flex', flexWrap: 'wrap', fontSize: '12px', }} >
                  + {post.location.length - 2}
                </Typography>
              } */}

          </Box>

          <Grid gap sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', m: '7px' }}>
            <Chip label={post.jobType} />
            <Chip label={post.jobMode} />
            {
              post.gender !== "Any" && <Chip label={post.gender + " Only"} />
            }

          </Grid>

          <Grid gap sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', my: '10px' }}>
            {post.salary !== "" &&
              <Chip label={post.salary} variant="outlined" />
            }
            {
              post.vacancies !== 0 &&
              <Chip label={post.vacancies + " Vacancy(s)"} variant="outlined" />
            }
          </Grid>


          {/* <Divider sx={{ my: '10px'}} /> */}


          {/* <Grid gap sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', my: '10px' }}>
            {post.designation.courses.slice(0, 3).map((item) => <Box key={item.name} sx={{ mx: '6px' }}>
              <Chip label={item.name} variant="outlined" color="success" />              
            </Box>)}
            {
                (post.designation.courses.length - 3 > 0) &&
                <Chip label={ "+ " + (post.designation.courses.length - 3) + " Others"} variant="outlined" color="success" />
              }
          </Grid> */}

          <Typography variant="body2" color="text.secondary" sx={{ mt: '15px' }} >
            {ReactHtmlParser(htmlContent)}
          </Typography>

          <Grid gap sx={{ display: 'flex', alignItems: 'center', my: '10px' }}>
            {
              (post?.extendedDate === post?.lastDate) ?
                <Typography variant="body2" color="text.secondary" >
                  Last Date: {moment(post.lastDate).format("DD MMM YYYY")}.
                </Typography>
                :
                <Typography variant="body2" color="text.secondary" >
                  Extended Date: {moment(post.extendedDate).format("DD MMM YYYY")}.
                </Typography>
            }
            {
              (date.toISOString() > post?.extendedDate) &&
              <Chip label="expired" variant="outlined" color='error' />
            }

          </Grid>
          {
            (post.applied_candidates.length <= 10) ?
              <Typography variant="body2" color="text.secondary" sx={{ fontSize: '11px', fontStyle: 'italic' }} >
                Be in the First 10 Applied.
              </Typography>
              :
              <Typography variant="body2" color="text.secondary" sx={{ fontSize: '11px', fontStyle: 'italic' }} >
                {post.applied_candidates.length} Applied
              </Typography>
          }

        </CardContent>

        {
          (date.toISOString() < post?.extendedDate) &&
          <CardActions >
            {userId === null ? <Button color="warning" variant="outlined" onClick={() => setOpen(true)} sx={{ textTransform: 'none', fontSize: '11px' }} startIcon={<BookmarkBorderOutlinedIcon fontSize="small" />}>  Save </Button>
              :
              !post.saved.includes(userId) ?
                <Button color="warning" variant="outlined"
                  disabled={bookmarkLoading && true}
                  onClick={async (event) => {
                    const jobId = post._id
                    console.log(post)
                    let savedArray = post.saved;
                    try {
                      setBookmarkLoading(true)
                      const res = await axiosPrivate.put(`/api/post/${jobId}/add`)
                      if (res.status === 201) {
                        setBookmarkLoading(false)
                        savedArray.push(userId)
                      }
                    } catch (err) {
                      setBookmarkLoading(false)
                      console.error(err);
                    }
                  }}
                  sx={{ textTransform: 'none', fontSize: '11px' }} startIcon={<BookmarkBorderOutlinedIcon fontSize="small" />}>  Save
                </Button>
                :
                <Button color="warning" variant="outlined"
                  disabled={bookmarkLoading && true}
                  onClick={async (event) => {
                    const jobId = post._id
                    console.log(post)
                    let savedArray = post.saved;
                    try {
                      setBookmarkLoading(true)
                      const res = await axiosPrivate.put(`/api/post/${jobId}/remove`)
                      if (res.status === 201) {
                        setBookmarkLoading(false)
                        const filtered = savedArray.indexOf(userId)
                        if (filtered !== -1) {
                          savedArray.splice(filtered, 1)
                        }
                        console.log(savedArray)
                      }
                    } catch (err) {
                      setBookmarkLoading(false)
                      console.error(err);
                    }
                  }}
                  sx={{ textTransform: 'none', fontSize: '11px' }} startIcon={<BookmarkIcon fontSize="small" />}>  Remove
                </Button>
            }
            <Box sx={{ flexGrow: 1 }} />
            {userId === null ? <Button color="warning" variant="outlined" onClick={() => setOpen(true)} sx={{ textTransform: 'none', fontSize: '11px' }} startIcon={<SendOutlinedIcon fontSize="small" />}>  Apply
            </Button> :
              post.applied_candidates.includes(userId) || applied.includes(jobId) || clicked ?
                <Button color="warning" variant="outlined" disabled sx={{ textTransform: 'none', fontSize: '11px' }} startIcon={<SendOutlinedIcon fontSize="small" />}>  Applied
                </Button> :
                <Button color="warning" variant="outlined" onClick={handleApplyJob} sx={{ textTransform: 'none', fontSize: '11px' }} startIcon={<SendOutlinedIcon fontSize="small" />}>  Apply
                </Button>}
          </CardActions>}

        {loadingApply && (
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loadingApply}
          >
            <CircularProgress
              size={24}
              sx={{
                color: 'inherit',
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: '-12px',
                marginLeft: '-12px',
              }}
            />
          </Backdrop>)}

      </Card>}
      {
        openSnackbar &&
        <Snackbar open={openSnackbar} autoHideDuration={3000} onClose={handleSnackbarClose}>
          <Alert onClose={handleSnackbarClose} severity={severity} sx={{ width: '100%' }}>
            {message}
          </Alert>
        </Snackbar>
      }
    </>
  )
}

export default JobCardDetail