import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import Controls from '../../../components/controls/Controls';
import { Backdrop, Box, CircularProgress } from '@mui/material';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

export default function AcceptModal({openModal, handleClickAccept, handleNestedClose, loading }) {

  return (
   
    <Dialog
        open={openModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleNestedClose}
        aria-describedby="alert-dialog-slide-description"
        sx={{zIndex: 1800}}
      >
        <DialogTitle sx={{bgcolor: "background.paper"}}>{"Notice Required!"}</DialogTitle>
        <DialogContent sx={{bgcolor: "background.paper"}} >
          <DialogContentText id="alert-dialog-slide-description">
            Are you sure. Do You want to continue?
            This action adds the user permanently to the database.
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{bgcolor: "background.paper"}}type="submit">
        <Button autoFocus onClick={handleNestedClose}>Cancel</Button>
        <Button
                  type="button"                   
                  form="hook-form"                                                               
                  onClick={handleClickAccept}
               >
               Continue</Button>
               {loading && (
                  <Backdrop
                  sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                  open={loading}                  
                >
                  <CircularProgress
                    size={24}
                    sx={{
                      color: 'inherit',
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      marginTop: '-12px',
                      marginLeft: '-12px',
                    }}
                  />
                  </Backdrop> ) }
       
          
        </DialogActions>
      </Dialog>
     
  );
}
