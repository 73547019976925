import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import AddAPhotoOutlinedIcon from '@mui/icons-material/AddAPhotoOutlined';
import { CircularProgress, DialogActions, DialogContent, DialogTitle, Divider, FormControl, Grid, IconButton, MenuItem, Select } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import WorkIcon from '@mui/icons-material/Work';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import { Link, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { axiosPrivate } from '../../api/axios';
import Controls from '../../components/controls/Controls';

export default function DesignationModalAdmin({ orgDetails, designation, designationModal, handleCloseDesignation, values, setValues, optionsData, setOptionsData, handleInputChange }) {

  useAxiosPrivate();  
  const {orgId} = useParams()

//   const [anchorEl, setAnchorEl] = React.useState(null);
  // const [value, setValue] = React.useState(1);
  const [loading, setLoading] = React.useState(false)
  const [keyValue, setKeyValue] = React.useState("")
    
  // const handleChange = (event) => {
  //   setValue(event.target.value);
  // };
  

  // const orgId = orgDetails?._id;
  

  const handleFetch = async () => {
    try {
      setLoading(true)
      setOptionsData([])
      // setValues(null)
      const res = await axiosPrivate.get('/api/org/designation_type/fetch')
      if (res) {
        setLoading(false)
        const data = res.data.data
        setOptionsData(data)
      }
    } catch (err) {
      console.error(err);
    }
  }
  console.log(designation)
       
  return (
    <Dialog
      open={designationModal}
      onClose={handleCloseDesignation}
      aria-labelledby="responsive-dialog-title"      
    >
      <DialogTitle id="responsive-dialog-title" sx={{ bgcolor: 'background.paper', minWidth: 250, height: 30, display: 'flex', alignItems: 'center', zIndex: 1600, fontSize: 16 }}>
        {`Add/Remove Designations `}
        <Box sx={{ flexGrow: 1 }} />
        <IconButton size="small" onClick={handleCloseDesignation} >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ bgcolor: 'background.default', minWidth: 250, height: '65vh', display: 'flex', flexDirection: 'column', zIndex: 1600, overflowX: 'hidden', }}>

      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', my: '7px', }}>
          <Typography variant='h6' sx={{ mr: '7px', fontSize: '12px', fontWeight: '400', }} > Filter By</Typography>

          {/* <FormControl sx={{ m: 1, minWidth: 120, fontSize: '10px' }} size="small">
            <Select
              // labelId="demo-select-small"
              id="infoId"
              value={value}
              onChange={handleChange}
              sx={{ fontSize: '10px' }}
            >
              <MenuItem value={1} sx={{ fontSize: '12px' }}>All Courses</MenuItem>
              <MenuItem value={2} sx={{ fontSize: '12px' }}>Arts and Science</MenuItem>
              <MenuItem value={3} sx={{ fontSize: '12px' }}>Engineering</MenuItem>
              <MenuItem value={4} sx={{ fontSize: '12px' }}>Medical</MenuItem>
            </Select>
          </FormControl> */}

          <Box sx={{ width: '75%', }} >
            <Controls.AutoComplete
              name="designationType"
              label="Select Designation Category"
              size='small'
              listFontSize='14px'
              labelFontSize='12px'
              inputFontSize='12px'
              optionsData={optionsData}
              setOptionsData={setOptionsData}
              values={values}
              setValues={setValues}
              handleFetch={handleFetch}
              // onChange={handleInputChange}
              disableClearable={true}
            />
          </Box>

        </Box>
        <Divider sx={{ mb: 1 }} />

        <Grid container spacing={0.5} sx={{ mx: '1px', pr: '1px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          {designation !== "No designations found" ? designation.map((element) => <Box key={element.id}>

            <Grid item gap={2} sx={{ mx: '3px', mb: '15px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Box component={Link} to='#' sx={{ display: 'flex', alignItems: 'center', textDecoration: 'none', color: 'inherit', cursor: 'pointer' }}>
                {/* <WorkIcon variant="rounded" sx={{ mr: '5px', width: '60px', height: 'auto', bgcolor: 'lightGray', color: 'gray', borderRadius: '5px', }} /> */}
                <Box>
                  <Typography variant='h6' sx={{ fontSize: '11px', fontWeight: '500', cursor: 'pointer', maxWidth: { xs: '200px', sm: '300px', md: '200px', lg: '350px' }, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} > {element.name} </Typography>
                {/* <Typography variant='h6' sx={{ fontSize: '10px', fontWeight: '200', }} > {element.activeMembers.length} People </Typography> */}
                <Typography variant='h6' sx={{ fontSize: '10px', fontWeight: '200', }} > {element.orgs.length} Orgs </Typography>
                </Box>
              </Box>
             

              <Box sx={{ flexGrow: 1 }} />
              {element.orgs.includes(orgId) ? (<Box sx={{ m: '3px', display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'relative' }}>
                <DoneOutlineIcon onClick={
                  async () => {
                    let orgArray = element.orgs;
                    const designationId = element.id
                    console.log(orgArray)
                    try {
                      setKeyValue(designationId)
                      setLoading(true)
                      const res = await axiosPrivate.put(`/api/org/${orgId}/${designationId}/remove_designation`)
                      if (res) {
                        setLoading(false)
                        setKeyValue("")
                        // orgArray.pop()
                        const filtered = orgArray.indexOf(orgId)
                        if (filtered !== -1) {
                          orgArray.splice(filtered, 1)
                        }
                        console.log(orgArray)
                        // const data = res.data.data        
                      }
                    } catch (err) {
                      setLoading(false)
                      setKeyValue("")
                      console.error(err);
                    }

                  }
                } sx={{ mr: '4px', cursor: 'pointer', color: 'orange' }} />
                {(loading && keyValue === element.id) && <CircularProgress
                  // key ={element._id}
                  size={35}
                  sx={{
                    color: 'orange',
                    position: 'absolute',
                    // top: '50%',
                    // left: '50%',
                    // marginTop: '-12px',
                    // marginLeft: '-12px',
                  }}
                />
                }
              </Box>) : (
                <Box sx={{ m: '3px', display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'relative' }}>
                  <DoneOutlineIcon onClick={
                    async () => {
                      let orgArray = element.orgs;
                      const designationId = element.id
                      // console.log(orgArray)
                      try {
                        setKeyValue(designationId)
                        setLoading(true)
                        const res = await axiosPrivate.put(`/api/org/${orgId}/${designationId}/add_designation`)
                        if (res) {
                          setLoading(false)
                          setKeyValue("")
                          orgArray.push(orgId)
                          console.log(res)
                          // const data = res.data.data        
                        }
                      } catch (err) {
                        setLoading(false)
                        setKeyValue("")
                        console.error(err);
                      }

                    }
                  } sx={{ mr: '4px', cursor: 'pointer', color: 'darkGray' }} />
                  {(loading && keyValue === element.id) && <CircularProgress
                    // key ={element._id}
                    size={35}
                    sx={{
                      color: 'orange',
                      position: 'absolute',
                      // top: '50%',
                      // left: '50%',
                      // marginTop: '-12px',
                      // marginLeft: '-12px',
                    }}
                  />
                  }
                </Box>
              )}
            </Grid>
            <Divider />
          </Box >

          ) : <Typography> No Designations</Typography>
          }
        </Grid>
      </DialogContent>

    </Dialog>

  );
}