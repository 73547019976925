import { Box, Card, CardContent, Grid, Typography } from '@mui/material'
import React, { useEffect, useRef } from 'react'

function NoPostFeed() {

  const videoEl = useRef(null);

  const attemptPlay = () => {
    videoEl &&
      videoEl.current &&
      videoEl.current.play().catch(error => {
        console.error("Error attempting to play", error);
      });
  };

  useEffect(() => {
    attemptPlay();
  }, []);


  return (
    <Grid>
<Card sx={{ minWidth: 275, height: 'auto', my: '10px', p: '3px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <CardContent>
      <Typography variant="body2" sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', mb: '2px'}}>
        Add Courses in Your Resume
        </Typography>
      <video
          style={{ maxWidth: "100%", height: 'auto', margin: "0 auto" }}
          playsInline
          loop
          muted
          controls
          alt="Add a Course"
          src="https://karpath.s3.ap-south-1.amazonaws.com/clips/Add+course.mp4"
          ref={videoEl}
        />
      </CardContent>      
    </Card> 

    <Card sx={{ minWidth: 275, height: 250, my: '10px', p: '3px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <CardContent>
        <Typography variant="body2" sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        OPPORTUNITIES ARE NEVER MISSED!!
        </Typography>        
        <Typography sx={{ mb: 1.5 }} color="text.secondary">
          Follow Some Companies or Institutions to get their Updates.
        </Typography>
      </CardContent>      
    </Card> 

    <Card sx={{ minWidth: 275, height: 250, my: '10px', p: '3px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
    <CardContent>        
        <Typography sx={{ mb: 1.5 }} color="text.secondary">
          Advertisement
        </Typography>        
      </CardContent>     
        </Card>   
    </Grid>
  )
}

export default NoPostFeed