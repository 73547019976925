import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import examService from "./examService";

const exam = JSON.parse(localStorage.getItem('exam'))

const initialState = {
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
  examId: exam ? exam.examId : null,
  name: exam ? exam.name : null,
  logo: exam ? exam.logo : null,
  pic: exam ? exam.pic : null,
  followers: exam ? exam.followers : null,
//   activeMembers: org ? org.activeMembers.length : null,
//   sectorName: org ? org.sectorName : null,
//   isEduInstitution: org ? org.isEduInstitution : null,
//   city: org ? org.city : null,
//   state: org ? org.state : null,
//   country: org ? org.country : null,
//   isModerator: org ? org.isModerator : null,
}

// Fetch Org
export const fetchExam = createAsyncThunk('exam/fetch', async (exam, thunkAPI) => {
  try {
    return await examService.fetchExam(exam)
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

export const examSlice = createSlice({
  name: 'exam',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false
      state.isSuccess = false
      state.isError = false
      state.message = ''
      // state.firstName = ''
      // state.email = ''
      // state.status = ''
      // state.profile_pic = ''
    },
    updateLogo: (state, action) => {
      state.logo = action.payload
    },
    updatePic: (state, action) => {
      state.pic = action.payload
    },
    updateDesc: (state, action) => {
      state.desc = action.payload
    },
    addFollowers: (state, action) => {      
      state.followers = state.followers.concat(action.payload)  
    }, 
    removeFollowers: (state, action) => {      
      state.followers = state.followers.filter(i => i !== action.payload) 
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchExam.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchExam.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.examId = action.payload.examId
        state.name = action.payload.name
        state.pic = action.payload.pic
        state.logo = action.payload.logo
        state.followers = action.payload.followers
        // state.activeMembers = action.payload.activeMembers.length
        // state.sectorName = action.payload.sectorName
        // state.isEduInstitution = action.payload.isEduInstitution
        // state.city = action.payload.city
        // state.state = action.payload.state
        // state.country = action.payload.country
        // state.isModerator = action.payload.isModerator
      })
      .addCase(fetchExam.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
        state.examId = null
        state.name = null
        state.pic = null
        state.logo = null
        state.followers = null
        // state.activeMembers = null
        // state.sectorName = null
        // state.isEduInstitution = null
        // state.city = null
        // state.state = null
        // state.country = null
        // state.isModerator = null
      })

  },
})

export const { reset, updateLogo, updatePic, updateDesc, addFollowers, removeFollowers } = examSlice.actions
export default examSlice.reducer