import React, { useEffect, useState } from 'react'
import { Avatar, Card, Grid, Typography, Box, InputLabel, CircularProgress, Button, Snackbar } from '@mui/material'
import ManRoundedIcon from '@mui/icons-material/ManRounded';
import WorkRoundedIcon from '@mui/icons-material/WorkRounded';
import SchoolRoundedIcon from '@mui/icons-material/SchoolRounded';
import BusinessRoundedIcon from '@mui/icons-material/BusinessRounded';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import PersonAddDisabledOutlinedIcon from '@mui/icons-material/PersonAddDisabledOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import PersonRemoveOutlinedIcon from '@mui/icons-material/PersonRemoveOutlined';
import axios, { axiosPrivate } from '../../api/axios';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import styled from 'styled-components';
import { reset, update } from '../../features/userAuth/authSlice';
import { fetchOrg } from '../../features/org/orgSlice';
import ConnectionsDropModal from '../modals/ConnectionDropModal';
import { userFetch } from '../../features/user/userSlice';

const ProfileImg = styled.img`
  border-radius: 6px;
  margin: 20px;
  width: 200px;
  height: 256px;
  object-fit: cover;
`;

const ProfileSidebar = (props) => {

  useAxiosPrivate();
  const dispatch = useDispatch();
  const { userId } = useParams()
  // console.log(userId)

  const { orgId, user_id, profile_pic, firstName, lastName, designation, org, course, isVerified, isPublic } = useSelector(
    (state) => state.user
  )

  // console.log(orgId)

  // const { connections, fetchConnections, connectionRequestFetched, fetchIncommingConnectionRequest } = props

  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false)
  // const [connectionsArray, setConnectionsArray] = useState()  
  // const [connectionSentFetch, setConnectionSentFetch] = useState([]);
  const [loadingFirstButton, setLoadingFirstButton] = useState(false);
  const [loadingSecondButton, setLoadingSecondButton] = useState(false);
  const [connectionStatus, setConnectionStatus] = useState(null);
 
  // const connectionsIds = connections && connections?.map((item) => item._id)
  // let connectionsArray = connectionsIds
  // let connectionRequestArray = connectionRequestFetched && connectionRequestFetched.map((item) => item.user.id);

  // const connectionsSentArray = (connectionSentFetch && connectionSentFetch?.connectionData?.receiverId)

  // console.log(connections)
  // console.log(connectionRequestFetched)
  // console.log(connectionSentFetch.connectionData)

  // let connectionsSentArray;

  // useEffect(() => { 

  // const connectionsIds = connections?.map((item) => item._id)

  // }, [connectionsIds]) 
  // console.log(connectionStatus)
  
  let connectionState;

  if (connectionStatus === null) {
    connectionState = 1;
  }

  if (connectionStatus?.isAccepted === false && connectionStatus?.sender === userId) {
    connectionState = 2;
  }

  if (connectionStatus?.isAccepted === false && connectionStatus?.receiver._id === userId) {
    connectionState = 3;
  }

  if (connectionStatus?.isAccepted) {
    connectionState = 4;
  }

  const handleClickOpenModal = (e) => {
    setOpenModal(true);
  }

  

  const fetchUserConnectionStatus = async () => {   
    try {
     
      const res = await axiosPrivate.get(`/api/user/connection_status/${userId}/fetch`)
      if (res.status === 200) {       
        const data = res.data.data
        setConnectionStatus(data)
      }
    } catch (err) {
      console.error(err);
    }
  };

  React.useEffect(() => {
    fetchUserConnectionStatus();
  }, [userId])

  const handleClickDrop = async () => {
    try {
      setLoading(true)
      const res = await axiosPrivate.get(`/api/user/${userId}/disconnect`)
      if (res) {
        fetchUserConnectionStatus()
        setLoading(false)
        setOpenModal(false);
      }
    } catch (err) {
      console.error(err);
    }
  }

  const handleClickClose = () => {
    setOpenModal(false);
    console.log("close")
  }

  const handleClickConnect = async () => {
    try {      
      setLoadingFirstButton(true)
      const res = await axiosPrivate.get(`/api/user/connection_request/${userId}/send`)
      if (res) {
        fetchUserConnectionStatus()
        setLoadingFirstButton(false)             
      }
    } catch (err) {
      setLoadingFirstButton(false)      
      console.error(err);
    }
  }
  
  const handleConnectionAccept = async () => {
    try {      
      setLoadingFirstButton(true)
      const requestId = connectionStatus._id

      const res = await axiosPrivate.get(`/api/user/connection_request/${requestId}/accept`)
      if (res.status === 200) {
        fetchUserConnectionStatus()
        setLoadingFirstButton(false)         
      }
    } catch (err) {
      setLoadingFirstButton(false)     
      console.error(err);
    }
  }

  const handleConnectionReject = async () => {
    try {     
      setLoadingSecondButton(true)
      const requestId = connectionStatus._id
      const res = await axiosPrivate.get(`/api/user/connection_request/${requestId}/decline`)
      if (res.status === 200) {
        fetchUserConnectionStatus()
        setLoadingSecondButton(false)       
      }
    } catch (err) {
      setLoadingSecondButton(false)     
      console.error(err);
    }
  }

  const cancelConnectionRequest = async () => {
    try {
      setLoadingFirstButton(true)
      const requestId = connectionStatus._id
      const res = await axiosPrivate.get(`/api/user/connection_request/${requestId}/cancel`)
      if (res.status === 200) {
        fetchUserConnectionStatus()
        setLoadingFirstButton(false)
      }
    } catch (err) {
      console.error(err);
    }
    setLoadingFirstButton(false)
  };

  return (
    <Card sx={{ my: '10px' }} >
      <Grid container spacing={0.5} sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row', md: 'column' }, alignItems: 'center' }} >
               <Grid item  >
           {profile_pic ? <ProfileImg src={profile_pic} alt='P' /> : <Avatar variant="rounded" sx={{ m: '20px', width: '200px', height: '256px' }} />}
         </Grid>
         <Grid item sx={{ display: 'flex', flexDirection: 'column' }} >
         <Grid item sx={{ display: 'block' }} >
           <Grid item >
             <Typography component="div" sx={{ fontSize: '15px', fontWeight: 500, display: 'flex', alignItems: 'center', maxWidth: { xs: '350px', sm: '350px', md: '220px', lg: '220px' }, overflow: 'hidden', whiteSpace: 'nowrap' }}>
               <ManRoundedIcon fontSize="1.5 rem" sx={{ mx: '5px' }} /> {firstName} {lastName}
             </Typography>
           </Grid>
           <Grid item >
             { isVerified && isPublic ? <Typography component="div" sx={{ fontSize: '12px',  display: 'flex', alignItems: 'center', maxWidth: { xs: '350px', sm: '350px', md: '220px', lg: '220px' }, overflow: 'hidden', whiteSpace: 'nowrap' }}>
               <SchoolRoundedIcon fontSize="1.3 rem" sx={{ mx: '5px', }} /> {course}
             </Typography>
             :
             <Typography component="div" sx={{ fontSize: '12px',  display: 'flex', alignItems: 'center', maxWidth: { xs: '350px', sm: '350px', md: '220px', lg: '220px' }, overflow: 'hidden', whiteSpace: 'nowrap' }}>
               <SchoolRoundedIcon fontSize="1.3 rem" sx={{ mx: '5px', }} /> Unspecified
             </Typography>
             }
           </Grid>
          
           {isVerified && isPublic && <Grid item>
             <Typography component="div" sx={{ fontSize: '12px', display: 'flex', alignItems: 'center', maxWidth: { xs: '350px', sm: '350px', md: '220px', lg: '220px' }, overflow: 'hidden', whiteSpace: 'nowrap' }}>
               <WorkRoundedIcon fontSize="1.3 rem" sx={{ mx: '5px', }} /> {designation}
             </Typography>
           </Grid>}
           {isVerified && isPublic && <Grid item component={Link} to={`/org/${orgId}`} sx={{ textDecoration: 'none', color: 'inherit' }}>
             <Typography gutterBottom component="div" sx={{ fontSize: '12px', display: 'flex', alignItems: 'center', maxWidth: { xs: '350px', sm: '350px', md: '220px', lg: '220px' }, overflow: 'hidden', whiteSpace: 'nowrap' }}>
               <BusinessRoundedIcon fontSize="1.3 rem" sx={{ mx: '5px', }} /> {org}
             </Typography>
           </Grid> }
           </Grid>

      <Grid item sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'relative' }}>
        {(() => {
          switch (connectionState) {
            case 1:              
              return (<>
                <Button variant="outlined" color='warning' onClick={handleClickConnect} sx={{ textTransform: 'none', fontSize: '11px', mx: '4px', my: '7px', width: '100px' }} startIcon={<PersonAddIcon fontSize="small" />}> Connect
                </Button>
                <Button variant="outlined" disabled sx={{ textTransform: 'none', fontSize: '11px', mx: '4px', my: '7px', width: '100px' }} startIcon={<ChatBubbleIcon fontSize="small" />}> Message
                </Button>
              </>)

            case 2:              
              return (<>
               <Button variant="outlined" color='warning' onClick={handleConnectionAccept} sx={{ textTransform: 'none', fontSize: '11px', mx: '4px', my: '7px', width: '100px' }} startIcon={<HowToRegIcon fontSize="small" />}> Accept
                 </Button>                
                 <Button variant="outlined" color='warning' onClick={handleConnectionReject} sx={{textTransform: 'none', fontSize: '11px', mx: '4px', my: '7px', width: '100px' }} startIcon={<NotInterestedIcon fontSize="small" />}> Decline
                 </Button>                
              </>)

            case 3:
              return (<>                
                <Button variant="outlined" color='warning' onClick={cancelConnectionRequest} sx={{textTransform: 'none', fontSize: '11px', mx: '4px', my: '7px', width: '100px' }} startIcon={<PersonAddDisabledOutlinedIcon fontSize="small" />}> Cancel
                </Button>
                <Button variant="outlined" color='warning' disabled sx={{textTransform: 'none', fontSize: '11px', mx: '4px', my: '7px', width: '100px' }} startIcon={<ChatBubbleIcon fontSize="small" />}> Message
                </Button>
              </>)

            case 4:
              return (<>
                 <Button variant="outlined" color='warning' onClick={handleClickOpenModal} sx={{ textTransform: 'none', fontSize: '11px', mx: '4px', my: '7px', width: '100px' }} startIcon={<PersonRemoveIcon fontSize="small" />}> Remove
                 </Button>
                 <Button variant="outlined" color='warning' disabled sx={{ textTransform: 'none', fontSize: '11px', mx: '4px', my: '7px', width: '100px' }} startIcon={<ChatBubbleIcon fontSize="small" />}> Message
                 </Button>
              </>)
          }
        }
        )()} 

          {
            loadingFirstButton && <CircularProgress
              // key ={element._id}
              size={20}
              sx={{
                color: 'white',
                position: 'absolute',
                // top: '50%',
                left: '25%',
                // marginTop: '-12px',
                // paddingLeft: '-20px',
              }}
            />
          }
          {
            loadingSecondButton && <CircularProgress
              // key ={element._id}
              size={20}
              sx={{
                color: 'white',
                position: 'absolute',
                // top: '50%',
                left: '75%',
                // marginTop: '-12px',
                // paddingLeft: '-20px',
              }}
            />
          } 
        </Grid>  
        </Grid>

      </Grid>
      <ConnectionsDropModal openModal={openModal} handleClickClose={handleClickClose} handleClickDrop={handleClickDrop} loading={loading} />
    </Card>
  )
}

export default ProfileSidebar



// import React, { useEffect, useState } from 'react'
// import { Avatar, Card, Grid, Typography, Box, InputLabel, CircularProgress, Button, Snackbar } from '@mui/material'
// import ManRoundedIcon from '@mui/icons-material/ManRounded';
// import WorkRoundedIcon from '@mui/icons-material/WorkRounded';
// import SchoolRoundedIcon from '@mui/icons-material/SchoolRounded';
// import BusinessRoundedIcon from '@mui/icons-material/BusinessRounded';
// import NotInterestedIcon from '@mui/icons-material/NotInterested';
// import HowToRegIcon from '@mui/icons-material/HowToReg';
// import PersonAddIcon from '@mui/icons-material/PersonAdd';
// import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
// import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
// import PersonAddDisabledOutlinedIcon from '@mui/icons-material/PersonAddDisabledOutlined';
// import { useDispatch, useSelector } from 'react-redux';
// import { Link, useParams } from 'react-router-dom';
// import PersonRemoveOutlinedIcon from '@mui/icons-material/PersonRemoveOutlined';
// import axios, { axiosPrivate } from '../../api/axios';
// import useAxiosPrivate from '../../hooks/useAxiosPrivate';
// import styled from 'styled-components';
// import { reset, update } from '../../features/userAuth/authSlice';
// import { fetchOrg } from '../../features/org/orgSlice';
// import ConnectionsDropModal from '../modals/ConnectionDropModal';
// import { userFetch } from '../../features/user/userSlice';

// const ProfileImg = styled.img`
//   border-radius: 6px;
//   margin: 20px;
//   width: 200px;
//   height: 256px;
//   object-fit: cover;
// `;

// const ProfileSidebar = (props) => {

//   useAxiosPrivate();
//   const dispatch = useDispatch();
//   // const { user_id} = useParams()
//   // console.log(user_id)

//   const { orgId, user_id, profile_pic, firstName, lastName, designation, org, course, isVerified } = useSelector(
//     (state) => state.user
//   )

//   const { connections, fetchConnections, connectionRequestFetched, fetchIncommingConnectionRequest } = props

//   const [openModal, setOpenModal] = useState(false);
//   const [loading, setLoading] = useState(false)
//   // const [connectionsArray, setConnectionsArray] = useState()  
//   const [connectionSentFetch, setConnectionSentFetch] = useState([]);
//   const [loadingFirstButton, setLoadingFirstButton] = useState(false);
//   const [loadingSecondButton, setLoadingSecondButton] = useState(false);

//   const connectionsIds = connections && connections?.map((item) => item._id)
//   let connectionsArray = connectionsIds
//   let connectionRequestArray = connectionRequestFetched && connectionRequestFetched.map((item) => item.user.id);

//   const connectionsSentArray = (connectionSentFetch && connectionSentFetch?.connectionData?.receiverId)

//   // console.log(connections)
//   // console.log(connectionRequestFetched)
//   // console.log(connectionSentFetch.connectionData)

//   // let connectionsSentArray;

//   // useEffect(() => { 

//   // const connectionsIds = connections?.map((item) => item._id)

//   // }, [connectionsIds]) 
  

//   const handleClickOpenModal = (e) => {
//     setOpenModal(true);
//   }

//   const handleClickDrop = async () => {

//     try {
//       setLoading(true)
//       const res = await axiosPrivate.get(`/api/user/${user_id}/disconnect`)
//       if (res) {
//         fetchConnections()
//         setLoading(false)
//         setOpenModal(false);
//       }
//     } catch (err) {
//       console.error(err);
//     }
//   }

//   const handleClickClose = () => {
//     setOpenModal(false);
//     console.log("close")
//   }

//   const handleClickConnect = async() => {

//     try {
//       // setKeyValue(connectionsId)
//       setLoadingFirstButton(true)
//       const res = await axiosPrivate.get(`/api/user/connection_request/${user_id}/send`)
//       if (res) {                                                
//           fetchSentConnectionRequest()
//           setLoadingFirstButton(false)
//           // setKeyValue(connectionsId)
//           // followersArray.push(userId)
//           // console.log(res)
//           // const data = res.data.data        
//       }
//   } catch (err) {
//     setLoadingFirstButton(false)
//       // setKeyValue("")
//       console.error(err);
//   }
   
//   }

//   const cancelConnectionRequest = async () => {

//     // e.preventDedault() 
//     try {
//       setLoadingFirstButton(true)
//       const filterValue = connectionSentFetch.connections.filter(item =>
//         item.receiver._id === user_id)
//       const requestId = Object.assign({}, ...filterValue)

//       const res = await axiosPrivate.get(`/api/user/connection_request/${requestId._id}/cancel`)
//       if (res) {
//         const data = res.data.connections
//         fetchSentConnectionRequest()
//       }

//     } catch (err) {
//       console.error(err);
//     }
//     setLoadingFirstButton(false)
//   };

//   const fetchSentConnectionRequest = async () => {
//     // e.preventDedault() 
//     try {
//       // setLoading(true)
//       const res = await axiosPrivate.get(`/api/user/connection_request/sent`)
//       if (res) {
//         // setLoading(false)
//         // const data = res.data.orgPost
//         // setOrgPost(data)
//         const data = res.data.connections
//         setConnectionSentFetch(data)


//       }
//     } catch (err) {
//       console.error(err);
//     }
//   };

//   React.useEffect(() => {
//     fetchSentConnectionRequest();
//   }, [])

//   const handleConnectionAccept = async() => {
//     try {
//       // setKeyValue(requestId)
//       setLoadingFirstButton(true)
//       const filterValue = connectionRequestFetched.filter(item =>
//         item.user.id === user_id)
//         // console.log(filterValue)
//       const requestId = Object.assign({}, ...filterValue)
//       // console.log(requestId)

//       const res = await axiosPrivate.get(`/api/user/connection_request/${requestId.id}/accept`)
//       if (res) {
//         fetchIncommingConnectionRequest()
//         fetchConnections()
//         setLoadingFirstButton(false)
//         // setKeyValue("")
//         // orgArray.pop()

//         // const filtered = connectionRequestArray.indexOf(requestId)
        
//         // if (filtered === -1) {
//         //   connectionRequestArray.splice(filtered, 1)
//         // }
        
//         // const data = res.data.data        
//       }
//     } catch (err) {
//       setLoadingFirstButton(false)
//       // setKeyValue("")
//       console.error(err);
//     }
//   }

//   const handleConnectionReject = async() => {
//     try {
//       // setKeyValueCloseIcon(requestId)
//       setLoadingSecondButton(true)
//       const filterValue = connectionRequestFetched.filter(item =>
//         item.user.id === user_id)        
//       const requestId = Object.assign({}, ...filterValue)
//       const res = await axiosPrivate.get(`/api/user/connection_request/${requestId.id}/decline`)
//       if (res) {
//         fetchIncommingConnectionRequest()
//         setLoadingSecondButton(false)
//         // setKeyValueCloseIcon("")
//         // orgArray.pop()

//         // const filtered = connectionRequestArray.indexOf(requestId)        
//         // if (filtered === -1) {
//         //   connectionRequestArray.splice(filtered, 1)
//         // }
//         // return;
//         //  console.log(connectionRequestArray.splice(filtered, 1))
//       }
//     } catch (err) {
//       setLoadingSecondButton(false)
//       // setKeyValueCloseIcon("")
//       console.error(err);
//     }
//   }


//   //  setConnectionsArray(connectionsMapped)

//   // console.log(!connectionRequestArray?.includes(user_id))
//   // console.log(connectionRequestArray)

//   return (
//     <Card sx={{ my: '10px' }} >
//       <Grid container spacing={0.5} sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row', md: 'column' }, alignItems: 'center' }} >
//         <Grid item  >
//           {profile_pic ? <ProfileImg src={profile_pic} alt='P' /> : <Avatar variant="rounded" sx={{ m: '20px', width: '200px', height: '256px' }} />}

//         </Grid>

//         <Grid item sx={{ display: 'block' }} >
//           <Grid item >
//             <Typography component="div" sx={{ fontSize: '16px', fontWeight: 500, display: 'flex', alignItems: 'center', maxWidth: { xs: '350px', sm: '350px', md: '220px', lg: '220px' }, overflow: 'hidden', whiteSpace: 'nowrap' }}>
//               <ManRoundedIcon fontSize="1.5 rem" sx={{ mx: '5px' }} /> {firstName} {lastName}
//             </Typography>
//           </Grid>
//           <Grid item >
//             { isVerified ? <Typography component="div" sx={{ fontSize: '13px',  display: 'flex', alignItems: 'center', maxWidth: { xs: '350px', sm: '350px', md: '220px', lg: '220px' }, overflow: 'hidden', whiteSpace: 'nowrap' }}>
//               <SchoolRoundedIcon fontSize="1.3 rem" sx={{ mx: '5px', }} /> {course}
//             </Typography>
//             :
//             <Typography component="div" sx={{ fontSize: '13px',  display: 'flex', alignItems: 'center', maxWidth: { xs: '350px', sm: '350px', md: '220px', lg: '220px' }, overflow: 'hidden', whiteSpace: 'nowrap' }}>
//               <SchoolRoundedIcon fontSize="1.3 rem" sx={{ mx: '5px', }} /> Unspecified
//             </Typography>
//             }
//           </Grid>
          
//           {isVerified && <Grid item>
//             <Typography component="div" sx={{ fontSize: '13px', display: 'flex', alignItems: 'center', maxWidth: { xs: '350px', sm: '350px', md: '220px', lg: '220px' }, overflow: 'hidden', whiteSpace: 'nowrap' }}>
//               <WorkRoundedIcon fontSize="1.3 rem" sx={{ mx: '5px', }} /> {designation}
//             </Typography>
//           </Grid>}
//           {isVerified && <Grid item component={Link} to={`/org/${orgId}`} sx={{ textDecoration: 'none', color: 'inherit' }}>
//             <Typography gutterBottom component="div" sx={{ fontSize: '13px', display: 'flex', alignItems: 'center', maxWidth: { xs: '350px', sm: '350px', md: '220px', lg: '220px' }, overflow: 'hidden', whiteSpace: 'nowrap' }}>
//               <BusinessRoundedIcon fontSize="1.3 rem" sx={{ mx: '5px', }} /> {org}
//             </Typography>
//           </Grid> }

//           <Grid item sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'relative' }}>

//             {!connectionsArray?.includes(user_id) && !connectionsSentArray?.includes(user_id)
//               && !connectionRequestArray?.includes(user_id)
//               && <> 
//                 <Button variant="outlined" onClick={handleClickConnect} sx={{ color: 'orange', borderColor: 'orange', textTransform: 'none', fontSize: '11px', mx: '4px', my: '7px', width: '100px' }} startIcon={<PersonAddIcon fontSize="small" />}> Connect
//                 </Button>                                                              
//                 <Button variant="outlined" disabled sx={{ color: 'orange', borderColor: 'orange', textTransform: 'none', fontSize: '11px', mx: '4px', my: '7px', width: '100px' }} startIcon={<ChatBubbleIcon fontSize="small" />}> Message
//                 </Button>
//               </>
//             }

//             {
//               connectionsArray?.includes(user_id) && (<>
//                 <Button variant="outlined" onClick={handleClickOpenModal} sx={{ color: 'orange', borderColor: 'orange', textTransform: 'none', fontSize: '11px', mx: '4px', my: '7px', width: '100px' }} startIcon={<PersonRemoveIcon fontSize="small" />}> Remove
//                 </Button>
//                 <Button variant="outlined" disabled sx={{ color: 'orange', borderColor: 'orange', textTransform: 'none', fontSize: '11px', mx: '4px', my: '7px', width: '100px' }} startIcon={<ChatBubbleIcon fontSize="small" />}> Message
//                 </Button>
//               </>
//               )
//             }

//             {
//               connectionsSentArray?.includes(user_id) && (<> 
//                 <Button variant="outlined" onClick={cancelConnectionRequest} sx={{ color: 'orange', borderColor: 'orange', textTransform: 'none', fontSize: '11px', mx: '4px', my: '7px', width: '100px' }} startIcon={<PersonAddDisabledOutlinedIcon fontSize="small" />}> Cancel
//                 </Button>
               
//                 <Button variant="outlined" disabled sx={{ color: 'orange', borderColor: 'orange', textTransform: 'none', fontSize: '11px', mx: '4px', my: '7px', width: '100px' }} startIcon={<ChatBubbleIcon fontSize="small" />}> Message
//                 </Button>
//               </>
//               )
//             }

//             {
//               (connectionRequestArray?.includes(user_id)) && (<> 
//                 <Button variant="outlined" onClick={handleConnectionAccept} sx={{ color: 'orange', borderColor: 'orange', textTransform: 'none', fontSize: '11px', mx: '4px', my: '7px', width: '100px' }} startIcon={<HowToRegIcon fontSize="small" />}> Accept
//                 </Button>
                
//                 <Button variant="outlined" onClick={handleConnectionReject} sx={{ color: 'orange', borderColor: 'orange', textTransform: 'none', fontSize: '11px', mx: '4px', my: '7px', width: '100px' }} startIcon={<NotInterestedIcon fontSize="small" />}> Decline
//                 </Button>
//               </>
//               )
//             }

           
//             {
//               loadingFirstButton && <CircularProgress
//               // key ={element._id}
//               size={20}
//               sx={{
//                 color: 'white',
//                 position: 'absolute',
//                 // top: '50%',
//                 left: '25%',
//                 // marginTop: '-12px',
//                 // paddingLeft: '-20px',
//               }}
//               />
//             }

// {
//               loadingSecondButton && <CircularProgress
//               // key ={element._id}
//               size={20}
//               sx={{
//                 color: 'white',
//                 position: 'absolute',
//                 // top: '50%',
//                 left: '75%',
//                 // marginTop: '-12px',
//                 // paddingLeft: '-20px',
//               }}
//               />
//             }
           

//           </Grid>
//         </Grid>
//       </Grid>

//       <ConnectionsDropModal openModal={openModal} handleClickClose={handleClickClose} handleClickDrop={handleClickDrop} loading={loading} />

//     </Card>
//   )
// }

// export default ProfileSidebar