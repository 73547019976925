import * as React from 'react';
// import React from 'react'
import { axiosPrivate } from '../../api/axios';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import styled from 'styled-components';
import { Box, Typography } from '@mui/material';
import moment from 'moment';
import { DataGrid, GridToolbarQuickFilter, GridLinkOperator, gridClasses } from '@mui/x-data-grid';
import ViewResumePdfModal from '../modals/ViewResumePdfModal';
import { useParams } from 'react-router-dom';

const NoData = styled.div`
display: flex;
max-height: 450px;
align-items: center;
justify-content: center;
font-size: 24px;
padding-top: 40px;
// color: darkslategray;
`

function QuickSearchToolbar() {
  return (
    <Box
      sx={{
        p: 0.5,
        pb: 0,
      }}
    >
      <GridToolbarQuickFilter
        quickFilterParser={(searchInput) =>
          searchInput
            .split(',')
            .map((value) => value.trim())
            .filter((value) => value !== '')
        }
      />
    </Box>
  );
}


export default function TableJobApplications({ selectedItem, setSelectedItem, applicants, loading }) {

  useAxiosPrivate()
  const { orgId } = useParams()

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [enquiry, setEnquiry] = React.useState([])
  const [selectedRow, setSelectedRow] = React.useState({})
  const [open, setOpen] = React.useState(false);
  const [pdfUrl, setPdfUrl] = React.useState()
  const [course, setCourse] = React.useState([])
  const [selectInstitution, setSelectInstitution] = React.useState("");
  const [selectCourse, setSelectCourse] = React.useState("");
  // const [loading, setLoading] = React.useState(false)  
  const [openModal, setOpenModal] = React.useState(false);
  const [openRevertModal, setOpenRevertModal] = React.useState(false);
  const [openRefuteModal, setOpenRefuteModal] = React.useState(false);
  const [errors, setErrors] = React.useState({});
  const [pageSize, setPageSize] = React.useState(10);
  const [rowId, setRowId] = React.useState(null);
  const [rowData, setRowData] = React.useState({})

  let flattenedData = [];
  const flatData = (applicants) => {
    applicants.forEach((item) => {
      console.log(item)
      item.status = selectedItem.shortlisted && selectedItem.shortlisted.includes(item._id) ? "Shortlisted" :
        // <Typography variant='body2' color='text.success'>Shortlisted</Typography>  
        selectedItem.rejected && selectedItem.rejected.includes(item._id) ? "Rejected" : "Pending"
      // <Typography variant='body2' color='text.error'>Rejected</Typography> :
      // (<Typography variant='body2' color='text.info'>Pending</Typography>)       
      // delete item.sender         
      flattenedData.push(item)
    })
  }

  const columns = React.useMemo(
    () => [
      {
        field: 'firstName',
        headerName: 'First Name',
        width: 170,
      },
      {
        field: 'lastName',
        headerName: 'Last Name',
        width: 170,
      },
      {
        field: 'recentDesignation',
        headerName: 'Holding Designation',
        width: 200,
      },
      {
        field: 'recentCourse',
        headerName: 'Qualification',
        width: 170,
      },
      {
        field: 'status',
        headerName: 'Status',
        width: 90,
      },

      { field: '_id', hide: true },

    ],
    [rowId]
  );



  React.useEffect(() => {
    // handleFetch() 
    if (selectedItem) flatData(applicants)
  }, [selectedItem])

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // console.log(selectedRow)

  // const selectedRowClick = (e) => {
  //   e.preventDefault();
  //   setPage(newPage);
  // };

  const handleClose = () => {
    setOpen(false);
  };
  const handleNestedClose = () => {
    setOpenModal(false);
    setOpenRevertModal(false);
    setOpenRefuteModal(false);
  };

  console.log(applicants)

  return (
    <Box sx={{ width: '75vw', height: 475, }}>
      {
        loading ? <Typography>Loading...</Typography> : applicants.length !== 0 ?
          <>
            {/* <Typography
        variant="h5"
        component="h5"
        sx={{ textAlign: 'center', my: 1 }}
      >
        Admission Enquiries
      </Typography> */}
            <DataGrid
              // {...user}
              columns={columns}
              getRowId={(row) => row._id}
              // getRowId={(row) => row.id }
              rowsPerPageOptions={[10, 25, 50, 100]}
              // rows={
              //   isAdmin(currentUser)
              //     ? rooms
              //     : rooms.filter((room) => room.uid === currentUser.id)
              // }
              rows={applicants}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              onSelectionModelChange={(id) => {
                const selectedId = new Set(id);
                const selectedRowData = applicants.filter((row) => selectedId.has(row._id.toString()));
                // const selectedOrgId = selectedRowData[0]._id
                setSelectedRow(selectedRowData)
                // console.log(selectedOrgId)
                // handleFetchOrgById(selectedOrgId)
                setOpen(true)
              }}

              sx={{ '& .MuiDataGrid-columnHeaders': { bgcolor: 'background.paper' }, '& .MuiDataGrid-footerContainer': { bgcolor: 'background.paper' } }}
              initialState={{
                // ...user.initialState,
                // pagination,
                filter: {
                  filterModel: {
                    items: [],
                    quickFilterLogicOperator: GridLinkOperator.Or,
                  },
                },
              }}
              components={{ Toolbar: QuickSearchToolbar }}
            />
            {open && <ViewResumePdfModal
              // handleFetch={handleFetch} 
              open={open}
              orgId={orgId}
              setOpen={setOpen}
              handleClose={handleClose}
              selectedRow={selectedRow[0]}
              pdfUrl={pdfUrl}
              setPdfUrl={setPdfUrl}
              selectedItem={selectedItem}
              setSelectedItem={setSelectedItem}
            />}
          </> :
          <Typography>No Data Remaining!!!</Typography>
      }

    </Box>
  );
}
