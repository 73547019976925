import React, { forwardRef, useContext, useEffect, useRef, useState } from "react";
// import { PostContext } from "../Context";
import { Avatar, CardHeader, CardMedia, IconButton, Typography, Button, DialogContent, DialogActions, Box, TextareaAutosize, Grid, Divider, Dialog, Backdrop, CircularProgress } from '@mui/material';
import InsertPhotoOutlinedIcon from '@mui/icons-material/InsertPhotoOutlined';
import VideoFileOutlinedIcon from '@mui/icons-material/VideoFileOutlined';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import { useSelector } from "react-redux";
import experiences from '../../experience.json'
import jobTypes from '../../jobType.json'
import jobModes from '../../jobMode.json'
import { FormAdmin, UseFormAdmin } from "../components/UseFormAdmin";
import { axiosPrivate } from "../../api/axios";
import Controls from "../../components/controls/Controls";
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({

    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
      // backgroundColor: 'beige',
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
      // backgroundColor: 'beige',
    },
  }));

const genderItems = [
  { id: 'Male', title: 'Male' },
  { id: 'Female', title: 'Female' },
  { id: 'Any', title: 'Any' },
]

export function CreateJobModalAdmin(props) {

  const {
   org,
   open,
   setOpen,
   onClose,
   submitLoading,
    setSubmitLoading,
    setSeverity,
    setOpenSnackbar,  
  } = props
  

  const ref = React.useRef()

  const [loading, setLoading] = useState(false)

  const date = new Date();

  const initialValues = {
    designation: null,
    experience: { name: "0-1 Year"},
    jobType: { name: "Full Time"},
    jobMode: { name: "Onsite"},
    lastDate: new Date().setDate(date.getDate() + 30),
    extendedDate: new Date().setDate(date.getDate() + 30),
    salary: "",
    gender: "Any",
    title: "",
    description: "", 
    roles: "",
    location: [],   
    includeHQ: true,
    vacancies: 1,
    url: "",
  }

  const {     
    values,
    setValues,
    resetForm,
    optionsData,
    setOptionsData,
    handleInputChange,  
  } = UseFormAdmin(initialValues);

  const experience = experiences.experience;
  const jobType = jobTypes.jobType;
  const jobMode = jobModes.jobMode;

  useEffect(() => {
    handleFetch()
  }, [])

  console.log(org)

  const handleFetch = async () => {
    try {      
      setLoading(true)
      setOptionsData([])
      const res = await axiosPrivate.get(`/api/org/${org.org._id}/designations/fetch`)
      if (res) {
        setLoading(false)
        const data = res.data.data
        setOptionsData(data)
      }
    } catch (err) {
      console.error(err);
    }
  }

  let rolesContent
  const save = (data) => {
   rolesContent = data 
   console.log(data)  
     };

     let locationsData;

     locationsData = values.location?.map(item => item.id)     
   
     if (values.includeHQ) {
       locationsData.push(org.org.locationId)    
     }
     if((locationsData.includes(org.org.locationId)) && (!values.includeHQ)) {
       locationsData.filter(item => item !== org.org.locationId)    
     }

    //  console.log(values)

  const handleJobSubmit = async () => {
    ref.current?.save()
    setSubmitLoading(true)

    const jobInfo = {
      designation: values.designation?.id,
      experience: values.experience.name,
      jobType: values.jobType.name,
      jobMode: values.jobMode.name,
      salary: values.salary,
      gender: values.gender,
      lastDate: values.lastDate,
      extendedDate: values.lastDate,
      vacancies: values.vacancies,
      url: values.url,
      roles: rolesContent === undefined ? "" : rolesContent,
      location:  JSON.stringify(values.location?.length !== 0 ? locationsData : org.org.locationId),   
    }
    console.log(jobInfo)

    const formData = new FormData()

    for (let key in jobInfo) {
      formData.append(key, jobInfo[key])
    }
    // for (let file of Object.keys(files)) {
    //   formData.append('files', files[file])
    // }

    try {   
    const res = await axiosPrivate.post(`api/post/${org.org._id}/jobpost/admin`, formData);
          if (!res) return
         
          if (res.status === 201) {
            // setSubmitted(true)
            setOpen(false);
            resetForm()           
            // console.log(jobFeed)
            // jobFeed.unshift(res.data.post)            
            // setJobPost(jobFeed)
            // setJobPost(res.data.post)
            setSubmitLoading(false)
            setSeverity("success")
            setOpenSnackbar(true)
          }
        //   setLoading(false)
                   

      } catch (err) {
        console.error(err);
        setSubmitLoading(false)
        setSeverity("error")
            setOpenSnackbar(true)
        // setLoading(false)
        // setSubmitted(false)
      }
  };

 
  return (
    <FormAdmin>
     <BootstrapDialog
            onClose={onClose}
            aria-labelledby="customized-dialog-title"
            open={open}>   
      <DialogContent dividers>
        <Typography gutterBottom variant="h4">
          Hire Candidates!
        </Typography>
        {/* <FormJobPost > */}
        <Box display="flex" flexDirection="column" rowGap={3} mt={2}>
          <Controls.AutoComplete
            name="designation"
            label="Select Designation"
            // size='small'
            // listFontSize='14px'
            // labelFontSize='12px'
            // inputFontSize='12px'
            optionsData={optionsData}
            setOptionsData={setOptionsData}
            values={values}
            setValues={setValues}
            disableClearable={true}
            required={true}
            // handleFetch={handleFetch} 
            loading={loading}
          />

          <Controls.AutoComplete
            name="experience"
            label="Select Minimum Experience"
            // size='small'
            // listFontSize='14px'
            // labelFontSize='12px'
            // inputFontSize='12px'
            disableClearable={true}
            optionsData={experience}
            values={values}
            setValues={setValues}
            defaultValue={experience[0]}
            // handleFetch={handleFetch} 
            loading={loading}
          />

          <Controls.AutoComplete
            name="jobType"
            label="Select Job Type"
            // size='small'
            // listFontSize='14px'
            // labelFontSize='12px'
            // inputFontSize='12px'
            disableClearable={true}
            optionsData={jobType}
            values={values}
            setValues={setValues}
            defaultValue={jobType[0]}
            // handleFetch={handleFetch} 
            loading={loading}
          />

          <Controls.AutoComplete
            name="jobMode"
            label="Select Job Mode"
            // size='small'
            // listFontSize='14px'
            // labelFontSize='12px'
            // inputFontSize='12px'
            disableClearable={true}
            optionsData={jobMode}
            values={values}
            setValues={setValues}
            defaultValue={jobMode[0]}
            // handleFetch={handleFetch} 
            loading={loading}
          />
          
<Controls.Input
            name="vacancies"
            label="No. of Vacancies"
            value={values.vacancies}
            onChange={handleInputChange}
          // error={errors.salary}
          />

          <Controls.Input
            name="salary"
            label="Salary"
            value={values.salary}
            onChange={handleInputChange}
          // error={errors.salary}
          />

<Controls.Rgroup
            name="gender"
            label="Select Gender *"
            value={values.gender}
            onChange={handleInputChange}
            items={genderItems}
          // error={errors.homeTown}
          />

          <Controls.DPicker
                name="lastDate"
                label="Last Date"
                value={values.lastDate}
                onChange={handleInputChange}
                disablePast={true}
                // error={errors.lastDate}
              />

          {/* {
              locationId.length !== 0 && */}
          <Controls.Places
            name="location"
            label="Select Branches"
            // size='small'
            // listFontSize='14px'
            // labelFontSize='12px'
            // inputFontSize='12px'
            values={values}
            setValues={setValues}
            multiple={true}
            disableClearable={true}
          // onChange={handleInputChange}
          // error={errors.BranchesLocations}

          />
          {/* } */}

          {/* {
              locationId.length !== 0 && */}

<Controls.Input
            name="url"
            label="Url (If Applying through External Site)"
            placeholder="https://externalwebsite.com/careers"
            value={values.url}
            onChange={handleInputChange}
          // error={errors.salary}
          />

          <Controls.Chkbox
            name="includeHQ"
            label="Include Headquaters"
            value={values.includeHQ}
            onChange={handleInputChange}
          />
          {/* } */}

          {/* <Controls.TextareaAutosizeBox
            name="jobDescription"
            label="Job Description *"
            placeholder="Enter Job Description"
            minRows={4}
            maxRows={7}
            value={values.jobDescription}
            onChange={handleInputChange}
          /> */}

          {/* <Controls.TextareaAutosizeBox
            name="roles"
            label="Roles and Responsibilities *"
            placeholder="Enter Roles and Responsibilities"
            minRows={7}
            maxRows={10}
            value={values.roles}
            onChange={handleInputChange}
          /> */}
          <Divider sx={{ mb: -3 }} />
          <Box sx={{ mb: '5px' }}>
            <Controls.MuiRte
              name="roles"
              label="Enter Roles and Responsibilities"
              values={values}
              setValues={setValues}
              ref={ref}
              // content={content}
              save={save}
            // onChange={handleInputChange}
            />
          </Box>
        </Box>

      </DialogContent>

      <DialogActions>
        {/* <IconButton> <InsertPhotoOutlinedIcon fontSize="medium" sx={{ color: 'orange' }} /> </IconButton>
        <IconButton> <VideoFileOutlinedIcon fontSize="medium" sx={{ color: 'orange' }} /> </IconButton> */}

        <Box sx={{ flexGrow: 1 }} />
        <Button variant="outlined" onClick={onClose} sx={{ color: 'orange', borderColor: 'orange', textTransform: 'none', fontSize: '11px' }} startIcon={<CloseIcon fontSize="small" />}>  Close
        </Button>

        <Button variant="outlined" onClick={handleJobSubmit} sx={{ color: 'orange', borderColor: 'orange', textTransform: 'none', fontSize: '11px' }} startIcon={<SendOutlinedIcon fontSize="small" />}>  Post
        </Button>
      </DialogActions>
      {submitLoading && (
                  <Backdrop
                  sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                  open={submitLoading}                  
                >
                  <CircularProgress
                    size={24}
                    sx={{
                      color: 'inherit',
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      marginTop: '-12px',
                      marginLeft: '-12px',
                    }}
                  />
                  </Backdrop> ) }
      </BootstrapDialog>

    </FormAdmin>
  );
}
