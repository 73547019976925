import * as React from 'react';
// import React from 'react'
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';

import { axiosPrivate } from '../../api/axios';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import styled from 'styled-components';
import { Box, Typography } from '@mui/material';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import TableJobApplications from './TableJobApplications';
import { DataGrid, GridToolbarQuickFilter, GridLinkOperator, gridClasses } from '@mui/x-data-grid';

const NoData = styled.div`
display: flex;
max-height: 450px;
align-items: center;
justify-content: center;
font-size: 24px;
padding-top: 40px;
// color: darkslategray;
`

function QuickSearchToolbar() {
  return (
    <Box
      sx={{
        p: 0.5,
        pb: 0,
      }}
    >
      <GridToolbarQuickFilter
        quickFilterParser={(searchInput) =>
          searchInput
            .split(',')
            .map((value) => value.trim())
            .filter((value) => value !== '')
        }
      />
    </Box>
  );
}

// const columns = [

//   {
//     id: 'designationName',
//     label: 'Designation',
//     minWidth: 200,
//   },
//   {
//     id: 'createdAt',
//     label: 'Created Date',
//     minWidth: 120,
//     // dateSetting: { locale: "en-IN"}
//     // align: 'right',
//     // format: (value) => value.toFixed(2),
//     // renderOption: (params) =>
//     //     moment(params.row.createdAt).format('YYYY-MM-DD HH:MM:SS'),

//   },
//   {
//     id: 'extendedDate',
//     label: 'Last Date',
//     minWidth: 120,
//   },
//   {
//     id: 'candidates',
//     label: 'Applications',
//     minWidth: 100,
//   },
//   {
//     id: 'shortlisted',
//     label: 'Shortlisted',
//     minWidth: 100,
//   },
//   {
//     id: 'status',
//     label: 'Status',
//     minWidth: 100,
//   },

// ];

export default function TableJobDetails({ jobDetailsClicked, setJobDetailsClicked, handleFetchJobDetails }) {

  useAxiosPrivate()
  const { orgId } = useParams()

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [user, setUser] = React.useState([])
  // const [job, setJob] = React.useState()
  const [selectedRow, setSelectedRow] = React.useState({})
  // const [jobDetailsClicked, setJobDetailsClicked] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false)
  const [openModal, setOpenModal] = React.useState(false);
  const [openRevertModal, setOpenRevertModal] = React.useState(false);
  const [openRefuteModal, setOpenRefuteModal] = React.useState(false);
  const [errors, setErrors] = React.useState({});
  const [pageSize, setPageSize] = React.useState(10);
  const [rowId, setRowId] = React.useState(null);


  // let flattendObj = [];
  //       const flattenObject = (data, keyName) => {
  //         Object.keys(data).forEach(key => {
  //           var newKey = `${keyName}_${key}`
  //           if (typeof data[key] === "object") {
  //             // calling the function again
  //             flattenObject(data[key], newKey);
  //           } else {
  //             flattendObj[newKey] = data[key];
  //           }
  //         });
  //       };

  const millisec = Date.now()
  const date = new Date(millisec)

  let flattenedData = [];
  const flatData = (memberData) => {
    memberData.forEach((item) => {
      // console.log(item.shortlisted.length)          
      item.designationName = item.designation[0].name
      // item.lastName = item.sender.lastName
      // item.senderId = item.sender._id
      delete item.designation
      item.candidates = item.applied_candidates.length
      item.shortlisted = item.shortlisted.length
      item.status = item.extendedDate < date.toISOString() ? "Expired" : "Active"
      item.createdAt = moment(item.createdAt).format("DD-MM-YYYY")
      item.extendedDate = moment(item.extendedDate).format("DD-MM-YYYY")
      delete item.applied_candidates
      // delete item.shortlisted

      flattenedData.push(item)
    })
  }

  const columns = React.useMemo(
    () => [
      {
        field: 'designationName',
        headerName: 'Designation',
        width: 200,
      },
      {
        field: 'createdAt',
        headerName: 'Created At',
        width: 120,
      },
      {
        field: 'extendedDate',
        headerName: 'Extended Date',
        width: 200,
      },
      {
        field: 'candidates',
        headerName: 'Applicants',
        width: 70,
      },
      {
        field: 'shortlisted',
        headerName: 'Shortlisted',
        width: 70,
      },
      {
        field: 'status',
        headerName: 'Status',
        width: 100,
      },

      { field: '_id', hide: true },

    ],
    [rowId]
  );

  const handleFetch = async () => {
    try {
      setLoading(true)
      const res = await axiosPrivate.get(`/api/post/${orgId}/jobfeed/dashboard`)
      if (res) {
        setLoading(false)
        const memberData = res.data.data
        console.log(memberData)
        flatData(memberData)
        setUser(flattenedData)
      }
    } catch (err) {
      console.error(err);
    }
  }

  // const handleFetchJobDetails = async (jobId) => {
  //   console.log(jobId)
  //   try {
  //     setLoading(true)
  //     const res = await axiosPrivate.get(`/api/post/job/${jobId}`)
  //     if (res) {
  //       setLoading(false)
  //       const data = res.data.data
  //       // console.log(data)        
  //       setJob(data)
  //     }
  //   } catch (err) {
  //     console.error(err);
  //   }
  // }
  // console.log(user)

  React.useEffect(() => {
    handleFetch()
  }, [])

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleNestedClose = () => {
    setOpenModal(false);
    setOpenRevertModal(false);
    setOpenRefuteModal(false);

  };

  return (
    <Box sx={{ width: '75vw', height: 475, }}>

      {
        loading ? <Typography>Loading...</Typography> : user.length !== 0 ?
          <>

            <DataGrid
              // {...user}
              columns={columns}
              getRowId={(row) => row._id}
              // getRowId={(row) => row.id }
              rowsPerPageOptions={[10, 25, 50, 100]}
              // rows={
              //   isAdmin(currentUser)
              //     ? rooms
              //     : rooms.filter((room) => room.uid === currentUser.id)
              // }
              rows={user}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              onSelectionModelChange={(id) => {
                const selectedId = new Set(id);
                const selectedRowData = user.filter((row) => selectedId.has(row._id.toString()));
                // const selectedOrgId = selectedRowData[0]._id
                setSelectedRow(selectedRowData)
                console.log(selectedRowData)
                // handleFetchOrgById(selectedOrgId)
                setOpen(true)
                handleFetchJobDetails(selectedRowData[0]._id)
                setJobDetailsClicked(true)
                setOpenModal(false)
                setErrors({})
              }}

              sx={{ '& .MuiDataGrid-columnHeaders': { bgcolor: 'background.paper' }, '& .MuiDataGrid-footerContainer': { bgcolor: 'background.paper' } }}
              initialState={{
                // ...user.initialState,
                // pagination,
                filter: {
                  filterModel: {
                    items: [],
                    quickFilterLogicOperator: GridLinkOperator.Or,
                  },
                },
              }}
              components={{ Toolbar: QuickSearchToolbar }}
            />


            {/* <TableContainer sx={{ maxHeight: 475, }}>
              <Table stickyHeader aria-label="sticky table"

              >
                <TableHead sx={{ '& .MuiTableCell-head': { bgcolor: 'background.paper' } }} >
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                      // option={column.renderOption}                 
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>

                </TableHead>
                <TableBody>
                  {user
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      return (
                        <TableRow onClick={() => {
                          return (
                            <>
                              {handleFetchJobDetails(row._id)}
                              {setJobDetailsClicked(true)}
                              {setSelectedRow(row)}
                              {setOpen(true)}
                              {setOpenModal(false)}
                              {setOpenRevertModal(false)}
                              {setOpenRefuteModal(false)}
                              {setErrors({})}
                            </>
                          )
                        }
                        }
                          hover role="checkbox" tabIndex={-1} key={row._id} sx={{ cursor: 'pointer' }}>
                          {columns.map((column) => {
                            const value = row[column.id];
                            return (
                              <TableCell key={column.id} align={column.align}>
                                {column.format && typeof value === 'number'
                                  ? column.format(value)
                                  : value}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={user.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            /> */}
            {/* {
              jobDetailsClicked &&
              <TableJobApplications selectedItems={job} />
            } */}

          </> :
          <Typography>No Data Remaining!!!</Typography>
      }

    </Box>
  );
}
