import { Box, Card, CardHeader } from '@mui/material'
import React from 'react'

export const AdminNotifications = () => {
  return (
    <Box>
    <Card sx={{ maxWidth: '250px', height: '300px', }}>
        <CardHeader 
        title="User Requests User Requests User Requests User Requests User Requests User Requests User Requests"
        />
        jfgfkdj
    </Card>
</Box>
  )
}
