import React, { useState } from "react";
import { styled as styledMui } from '@mui/material/styles';
import { Button, Box, Typography, DialogTitle, IconButton, Grid, Dialog, Card, CardHeader, Avatar, CardContent, CardMedia, CardActions, DialogContent, Chip, DialogActions, Snackbar, Backdrop, CircularProgress } from "@mui/material";
import PropTypes from 'prop-types';
import CorporateFareTwoToneIcon from '@mui/icons-material/CorporateFareTwoTone';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ShareIcon from '@mui/icons-material/Share';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { fetchOrg } from "../../features/org/orgSlice";
import moment from 'moment'
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import ReactHtmlParser from 'react-html-parser'
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { axiosPrivate } from "../../api/axios";
import { stateToHTML } from 'draft-js-export-html';
import { convertFromRaw } from 'draft-js'
import MuiAlert from '@mui/material/Alert';

const OrgLogo = styled.img`
  border-radius: 50%;  
  width: 40px;
  height: 40px;
  object-fit: cover;
`;

const BootstrapDialog = styledMui(Dialog)(({ theme }) => ({

  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
    // backgroundColor: 'beige',
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
    // backgroundColor: 'beige',
  },
}));

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function JobCardModal(props) {
  const { onClose, onSubmit, open, element, bookmarkLoading, setBookmarkLoading, keyValue, setKeyValue, userId } = props

  // const { logo, orgName, pic, activeMembers, orgId, orgType, headquarters } = useSelector(
  //     (state) => state.org
  // )
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // useAxiosPrivate() 
  // const ref = useRef()

  const [loading, setLoading] = useState(false)
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [severity, setSeverity] = React.useState();
  const [applied, setApplied] = React.useState([]);
  const [message, setMessage] = React.useState();

  // let applied = []
  let htmlContent
  if (element.roles !== "") {
    const content = JSON.parse(element?.roles)

    // const htmlcontent = '<p> Hello </p>'
    const convertedContent = convertFromRaw(content)

    htmlContent = stateToHTML(convertedContent)
    console.log(htmlContent)
  }

  const millisec = Date.now()
  const date = new Date(millisec)

  const jobId = element._id;
  
  const handleApplyJob = async () => {
    try {
      console.log("applying")
      if (element.url !=="") {
        setLoading(true)
        const res = await axiosPrivate.put(`/api/post/${jobId}/apply`)
        if (res.status === 201) {       
        window.open(`${element.url}`, '_blank', 'noopener noreferrer')
        setLoading(false)    
        onClose()
        setApplied([...applied, jobId]) 
      }
      } else {
      setLoading(true)
      const res = await axiosPrivate.put(`/api/post/${jobId}/apply`)
      if (res.status === 201) {
        setLoading(false)
        onClose()
        setApplied([...applied, jobId])
        setMessage(res.data)
        setSeverity("success")
        setOpenSnackbar(true)
      }
    }
    } catch (err) {
      console.error(err);
      setLoading(false)
      onClose()
      setMessage(err.response.data)
      setSeverity("warning")
      setOpenSnackbar(true)
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  let employee = ""
  if (element.org.activeMembers?.length === 0 || element.org.activeMembers?.length <= 100) {
    employee = "1-100 Employees"
  }
  else if (element.org.activeMembers?.length === 101 || element.org.activeMembers?.length <= 1000) {
    employee = "100-1k Employees"
  }
  else if (element.org.activeMembers?.length === 1001 || element.org.activeMembers?.length <= 5000) {
    employee = "1k-5k Employees"
  }
  else if (element.org.activeMembers?.length === 5001 || element.org.activeMembers?.length <= 10000) {
    employee = "5k-10k Employees"
  }
  else if (element.org.activeMembers?.length > 10000) {
    employee = "10k+ Employees"
  }



  return (
    <>
      {element &&
        <BootstrapDialog
          onClose={onClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >

          <CardHeader sx={{ m: '1px', overflowX: 'hidden', overflowY: 'hidden' }}
            avatar={element.org?.logo !== "" ? <OrgLogo src={element.org?.logo} alt='L' style={{ cursor: 'pointer' }} onClick={() => { navigate(`/org/${element.org._id}`) }} /> :
              <CorporateFareTwoToneIcon variant="rounded" sx={{ width: '45px', height: '45px', bgcolor: 'lightGray', color: 'gray', borderRadius: '5px', cursor: 'pointer' }} onClick={() => { navigate(`/org/${element.org._id}`) }} />
            }

            titleTypographyProps={{ variant: 'h5', fontSize: '12px', fontWeight: '400', overflow: 'hidden', whiteSpace: 'nowrap' }}
            title={<Box sx={{ maxWidth: '200px', cursor: 'pointer' }} onClick={() => {
              // dispatch(fetchOrg(element.org._id))              
              navigate(`/org/${element.org._id}`)
            }}> {element.org.name} </Box>}            
            subheader={<div>{ (element.org.sector.id !== 'PBS101') && <Typography variant='h6' sx={{ fontSize: '9px', fontWeight: '300' }} > {employee} </Typography>}
              <Typography variant='h6' sx={{ fontSize: '9px', fontWeight: '200', lineHeight: '0.5rem' }} >Posted on {moment(element.createdAt).format("DD-MM-YYYY")}.</Typography></div>
            }

            action={
              <IconButton onClick={onClose} >
                <CloseIcon />
              </IconButton>
            }
          />

          <DialogContent dividers sx={{ overflowX: 'hidden' }}>
            <Typography variant="h2" color="text.secondary" sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', fontSize: '1.25rem' }}>
              {element.designation.name}&nbsp;
              <Typography variant="body2" color="text.secondary" sx={{ fontSize: '11px', fontStyle: 'italic' }}>
                {element.experience}
              </Typography>
            </Typography>

            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <PlaceOutlinedIcon fontSize='12px' />
              <Typography variant="h6" color="text.secondary" sx={{ display: 'flex', flexWrap: 'wrap', fontSize: '12px', }} >
                {element.location.slice(0, 10).map((item, index) => <Box key={item.id} sx={{ mx: '3px' }}>
                  {item.district}, {item.state}
                </Box>)}
              </Typography>
              {
                (element.location.length - 10 > 0) &&
                <Typography variant="h6" color="text.secondary" sx={{ display: 'flex', flexWrap: 'wrap', fontSize: '12px', }} >
                  + {element.location.length - 2}
                </Typography>
              }

            </Box>

            <Grid gap sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', m: '7px' }}>
              <Chip label={element.jobType} />
              <Chip label={element.jobMode} />
              {
                element.gender !== "Any" && <Chip label={element.gender + " Only"} />
              }

            </Grid>

            <Grid gap sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', my: '10px' }}>
              {element.salary !== "" &&
                <Chip label={element.salary} variant="outlined" />
              }
              {
                element.vacancies !== 0 &&
              <Chip label={element.vacancies + " Vacancy(s)"} variant="outlined" />
              }
            </Grid>

            {/* <Grid gap sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', my: '10px' }}>
              {element.designation.courses.slice(0, 3).map((item) => <Box key={item.name} sx={{ mx: '6px' }}>
                <Chip label={item.name} variant="outlined" color="success" />                
              </Box>)}
              {
                  (element.designation.courses.length - 3 > 0) &&
                  <Chip label={ "+ " + (element.designation.courses.length - 3) + " Others"} variant="outlined" color="success" />
                }
            </Grid> */}


            <Typography variant="body2" color="text.secondary" sx={{ mt: '15px' }} >
              {ReactHtmlParser(htmlContent)}
            </Typography>
          </DialogContent>

          { userId ?
            ((date.toISOString() < element?.extendedDate) &&
            <DialogActions>
              {!element.saved.includes(userId) ?
                <Button color="warning" variant="outlined"
                  disabled={bookmarkLoading && keyValue === element._id && true}
                  onClick={async (event) => {
                    const jobId = element._id
                    console.log(element)
                    let savedArray = element.saved;
                    // console.log(postId)
                    // onClose() 
                    try {
                      setKeyValue(element._id)
                      setBookmarkLoading(true)
                      const res = await axiosPrivate.put(`/api/post/${jobId}/add`)
                      if (res.status === 201) {
                        setBookmarkLoading(false)
                        setKeyValue("")
                        savedArray.push(userId)
                      }
                    } catch (err) {
                      setBookmarkLoading(false)
                      setKeyValue("")
                      console.error(err);
                    }
                  }}
                  sx={{ textTransform: 'none', fontSize: '11px' }} startIcon={<BookmarkBorderOutlinedIcon fontSize="small" />}>  Save
                </Button>
                :
                <Button color="warning" variant="outlined"
                  disabled={bookmarkLoading && keyValue === element._id && true}
                  onClick={async (event) => {
                    const jobId = element._id
                    console.log(element)
                    let savedArray = element.saved;
                    // onClose()
                    try {
                      setKeyValue(element._id)
                      setBookmarkLoading(true)
                      const res = await axiosPrivate.put(`/api/post/${jobId}/remove`)
                      if (res.status === 201) {
                        setBookmarkLoading(false)
                        setKeyValue("")
                        const filtered = savedArray.indexOf(userId)
                        if (filtered !== -1) {
                          savedArray.splice(filtered, 1)
                        }
                        console.log(savedArray)
                      }
                    } catch (err) {
                      setBookmarkLoading(false)
                      setKeyValue("")
                      console.error(err);
                    }
                  }}
                  sx={{ textTransform: 'none', fontSize: '11px' }} startIcon={<BookmarkIcon fontSize="small" />}>  Remove
                </Button>
              }
              <Box sx={{ flexGrow: 1 }} />
              {element.applied_candidates.includes(userId) || applied.includes(jobId) ?
                <Button color="warning" variant="outlined" disabled sx={{ textTransform: 'none', fontSize: '11px' }} startIcon={<SendOutlinedIcon fontSize="small" />}>  Applied
                </Button> :
                <Button color="warning" variant="outlined" onClick={handleApplyJob} sx={{ textTransform: 'none', fontSize: '11px' }} startIcon={<SendOutlinedIcon fontSize="small" />}>  Apply
                </Button>}
            </DialogActions>)
            :
            (<DialogActions sx={{display: 'flex', justifyContent: 'center'}}>
            <Typography>Login to Apply</Typography>
            </DialogActions>)
          } 

          {loading && (
            <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={loading}
            >
              <CircularProgress
                size={24}
                sx={{
                  color: 'inherit',
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: '-12px',
                  marginLeft: '-12px',
                }}
              />
            </Backdrop>)}

        </BootstrapDialog>}

      {
        openSnackbar &&
        <Snackbar open={openSnackbar} autoHideDuration={3000} onClose={handleSnackbarClose}>
          <Alert onClose={handleSnackbarClose} severity={severity} sx={{ width: '100%' }}>
            {message}
          </Alert>
        </Snackbar>
      }
    </>

  );
}