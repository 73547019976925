import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import adminService from "./adminService";

// Get user from localStorage
const admin = JSON.parse(localStorage.getItem('admin'))

const initialState = {
  status: admin ? admin.status : null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
  admin: admin ? admin : null,
  userName: admin ? admin.userName : null,
    
}

// Login admin
export const login = createAsyncThunk('admin/login', async (admin, thunkAPI) => {
  try {
    return await adminService.login(admin)
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

export const logout = createAsyncThunk('admin/logout', async (thunkAPI) => {
  try {
    await adminService.logout()
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString()
    return thunkAPI.rejectWithValue(message)
  }
  
})

export const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false
      state.isSuccess = false
      state.isError = false
      state.message = ''     
    },       
  },
  extraReducers: (builder) => {
    builder      
      .addCase(login.pending, (state) => {
        state.isLoading = true
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.status = action.payload.status        
        state.userName = action.payload.userName        
        state.admin = action.payload
      })
      .addCase(login.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
        state.admin = null
      })
      .addCase(logout.fulfilled, (state) => {
        
        state.admin = null
        state.firstName = null
        state.lastName = null
        state.email = null
        state.profile_pic = null
        state.status = null
        state.designation = null
        state.course = null
        state.org = null
        state.orgId = null        
      })
  },
})

export const { reset } = adminSlice.actions
export default adminSlice.reducer