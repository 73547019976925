import { Box, Grid } from '@mui/material'
import React from 'react'
import AdminNav from '../components/AdminNav'
import { AdminNotifications } from '../components/AdminNotifications'
import { AdminPageDetails } from '../components/AdminPageDetails'
import { Logo } from '../components/Logo'
import SidePanelList from '../components/SidePanelList'
import TableCourseList from '../components/TableCourseList'
import TableDesignationList from '../components/TableDesignationList'

const DesignationListPage = () => {
  return (
    <>     
    <Box sx={{
      bgcolor: '#051145 ', display: 'flex', flexDirection: 'column', color: '#f7faf5', alignContent: 'stretch',
      px: '7px', py: '10px', height: '97vh', width: '230px', position: 'fixed'
    }}>
      <Logo />
      <SidePanelList />
    </Box>

    <Box sx={{ bgcolor: '#353839', ml: '242px', height: '100vh',overflow: 'scroll'}}>
      <AdminNav />

      <Grid container spacing={3} sx={{ p: '10px'   }}>
        <Grid item gap={2}  md={9} xl={9} sx={{ display: 'flex', justifyContent: 'spaceEvenly', minWidth: '320px' }}>
             <TableDesignationList />                      
        </Grid>

        <Grid item gap={2} md={3} xl={3} sx={{ display: 'flex', flexDirection: 'column'}} >
          <AdminPageDetails />
            <AdminNotifications />                   
        </Grid>
      </Grid>
    </Box>
  </>
  )
}
export default DesignationListPage
