import React, { useState } from 'react'

export function UseForm(initialValues, validateOnChange = false, validate) {
    
    const [values, setValues] = useState(initialValues);
    const [errors, setErrors] = useState({});
    const [files, setFiles] = useState({});  
    const [loading, setLoading] = useState(false); 
    const [optionsData, setOptionsData] = React.useState([]);
    const [country, setCountry] = React.useState("");
    const [state, setState] = React.useState("");
    const [checkedItems, setCheckedItems] = React.useState([]);     

    const handleInputChange = e => {        
        const { name, value } = e.target
        setValues({
            ...values,
            [name]: value,
        }) 
        console.log(name) 
        if (validateOnChange) 
        validate({ [name]: value})           
        console.log(value)            
    } 

    const handleChkboxItems = (event) => {               
        let updatedList = [...checkedItems];   
        if (event.target.checked) {
          updatedList = [...checkedItems, event.target.value];
        } else {
          updatedList.splice(checkedItems.indexOf(event.target.value), 1);
        }
        setCheckedItems(updatedList);
    }   

    const resetForm = () => {
        setValues(initialValues)             
        setErrors({})
    }
     
    return {
        values,
        setValues,
        errors,
        setErrors,
        files,
        setFiles,
        loading,
        setLoading,
        optionsData,
        setOptionsData,
        country,
        setCountry,
        state,
        setState,
        checkedItems,
        setCheckedItems,
        handleInputChange,
        handleChkboxItems,
        resetForm,             
    }
}

export function Form(props) {
    
    const { children, ...other } = props;
    return (
        <form style={{ width: '100%',}} autoComplete="off" {...other}>
            {props.children}
        </form>
    )
}
