import { Card, Typography } from '@mui/material'
import React from 'react'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

function AdsCard() {
  return (
    <Card><Typography variant='h6' sx={{ mx: '3px', mb: '5px', fontSize: '8px', color: 'gray' }} > Advertisement</Typography>
<InfoOutlinedIcon sx={{fontSize: '0.8rem', color:'green'}} />
    </Card>
  )
}

export default AdsCard