import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import 'react-image-crop/dist/ReactCrop.css'
// import { Box } from '@mui/system';
import React, { lazy, Suspense, useState } from 'react';
import Home from './pages/Home';
import Login from './pages/Login';
// import Profile from './pages/Profile';
// import Search from './pages/Search';
// import MyAccount from './pages/MyAccount';
// import OrgPage from './pages/OrgPage';
// import AboutOrgPage from './pages/AboutOrgPage';
// import JobOrgPage from './pages/JobOrgPage';
// import Jobs from './pages/Jobs';
// import Career from './pages/Career';
// import OrgPostPage from './pages/OrgPostPage';
// import NotFound from './pages/NotFound';
// import Following from './pages/Following';
// import Connections from './pages/Connections';
// import Posts from './pages/Posts';
// import PostPage from './pages/PostPage';
// import CompetitiveExam from './pages/CompetitiveExam';
// import CompetitiveExamPage from './pages/CompetitiveExamPage';
// import Course from './pages/Course';
import AdminLogin from './admin/pages/AdminLogin';
// import Dashboard from './admin/pages/Dashboard';
// import AdminProtected from './components/AdminProtected';
// import AuthProtected from './components/AuthProtected';
// import { UserRequestPage } from './admin/pages/UserRequestPage';
import { Backdrop, CircularProgress, Paper, ThemeProvider } from '@mui/material';
import { darkTheme, lightTheme } from './theme';
import { useSelector } from 'react-redux';
import OrgJobPage from './pages/OrgJobPage';
import RegisterCompetitiveExam from './admin/pages/RegisterCompetitiveExam';
import CompetitiveExamListPage from './admin/pages/CompetitiveExamListPage';
import CourseListPage from './admin/pages/CourseListPage';
import DesignationListPage from './admin/pages/DesignationListPage';
import EditProfile from './pages/EditProfile';
import OrgSettings from './pages/OrgSettings';
import ResumeForPdf from './pages/ResumeForPdf';
import PupLogin from './pup/PupLogin';
import Inbox from './pages/Inbox';
import ScrollToTop from './components/ScrollToTop';
// import ExamsUnauthorized from './pages/ExamsUnauthorized';
// import InstitutionsUnauthorized from './pages/InstitutionsUnauthorized';
// import OrgDetailsAdmin from './admin/pages/OrgDetailsAdmin';
// import JobsUnauthorized from './pages/JobsAunauthorized';
// import EquiryListAllPage from './admin/pages/EquiryListAllPage';
// import TableEnquiryAll from './admin/components/TableEnquiryAll';
// import AddOrganization from './pages/AddOrganization';
// import NotesPage from './pages/NotesPage';

// import { RegisterOrg } from './admin/pages/RegisterOrg';
// import  OrgsListPage  from './admin/pages/OrgsListPage';
// import { MemberRequestPage } from './admin/pages/MemberRequestPage';
// import { RegisterCourse } from './admin/pages/RegisterCourse';
// import { RegisterDesignation } from './admin/pages/RegisterDesignation';

// const Home = lazy(() => import('./pages/Home'))
// const Login = lazy(() => import('./pages/Login'))
const Profile = lazy(() => import('./pages/Profile'))
const Search = lazy(() => import('./pages/Search'))
const MyAccount = lazy(() => import('./pages/MyAccount'))
const OrgPage = lazy(() => import('./pages/OrgPage'))
const AboutOrgPage = lazy(() => import('./pages/AboutOrgPage'))
const JobOrgPage = lazy(() => import('./pages/JobOrgPage'))
const Jobs = lazy(() => import('./pages/Jobs'))
const Career = lazy(() => import('./pages/Career'))
const OrgPostPage = lazy(() => import('./pages/OrgPostPage'))
const NotFound = lazy(() => import('./pages/NotFound'))
const Following = lazy(() => import('./pages/Following'))
const Connections = lazy(() => import('./pages/Connections'))
const Posts = lazy(() => import('./pages/Posts'))
const PostPage = lazy(() => import('./pages/PostPage'))
const CompetitiveExam = lazy(() => import('./pages/CompetitiveExam'))
const CompetitiveExamPost = lazy(() => import('./pages/CompetitiveExamPost'))
const Course = lazy(() => import('./pages/Course'))
const PrivacyPolicy = lazy(() => import('./pages/PrivacyPolicy'))
const TermsConditions = lazy(() => import('./pages/TermsConditions'))
const NotesPage = lazy(() => import('./pages/NotesPage'))
const AddOrganization = lazy(() => import('./pages/AddOrganization'))
const JobsUnauthorized = lazy(() => import('./pages/JobsAunauthorized'))
const InstitutionsUnauthorized = lazy(() => import('./pages/InstitutionsUnauthorized'))
const ExamsUnauthorized = lazy(() => import('./pages/ExamsUnauthorized'))
const ExamPage = lazy(() => import('./pages/ExamPage'))

// const AdminLogin = lazy(() => import('./admin/pages/AdminLogin'))
const Dashboard = lazy(() => import('./admin/pages/Dashboard'))
const UserRequestPage = lazy(() => import('./admin/pages/UserRequestPage'))
const RegisterOrg = lazy(() => import('./admin/pages/RegisterOrg'))
const OrgsListPage = lazy(() => import('./admin/pages/OrgsListPage'))
const MemberRequestPage = lazy(() => import('./admin/pages/MemberRequestPage'))
const RegisterCourse = lazy(() => import('./admin/pages/RegisterCourse'))
const RegisterDesignation = lazy(() => import('./admin/pages/RegisterDesignation'))
const EquiryListAllPage = lazy(() => import('./admin/pages/EquiryListAllPage'))
const OrgDetailsAdmin = lazy(() => import('./admin/pages/OrgDetailsAdmin'))

const AdminProtected = lazy(() => import('./components/AdminProtected'))
const AuthProtected = lazy(() => import('./components/AuthProtected'))

function App() {
  const theme = useSelector((state) => state.theme);
  // const [loading, setLoading] = useState(false)
  const helmetContext = {};

  return (
    <ThemeProvider theme={theme.darkTheme ? darkTheme : lightTheme}>
      <Suspense fallback={
        <CircularProgress
          size={32}
          sx={{
            bgcolor: 'black',
            color: 'inherit',
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: '-12px',
            marginLeft: '-12px',
          }}
        />
      }>
        <HelmetProvider context={helmetContext}>
        <Router >
          {/* <Paper elevation={0} sx={{ bgcolor: "#444444", minHeight: '100vh',  paddingBottom: '50px' }} > */}
          <Paper elevation={0} square={true} sx={{  minHeight: '100vh',  }} >
              <ScrollToTop />
            <Routes>

              {/* Public route */}

              <Route path="login" element={<Login />} />
              {/* <Route path="career" element={<Career />} /> */}
              <Route path="*" element={<NotFound />} />
              <Route path="job/:jobId" element={<OrgJobPage />} />
              <Route path="org/:orgId/:slug/about" element={<AboutOrgPage />} />
              <Route path="page/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="page/terms-conditions" element={<TermsConditions />} />
              <Route path="post/:postId" element={<OrgPostPage />} />
              <Route path="jobs" element={<JobsUnauthorized />} />
              <Route path="institutions" element={<InstitutionsUnauthorized />} />
              <Route path="exams" element={<ExamsUnauthorized />} />
              

              <Route path="resume/:userId" element={<ResumeForPdf />} />
              <Route path="pup/login/:userId" element={<PupLogin />} />


              {/* Protected route */}
              <Route element={<AuthProtected />} >
                <Route path="feed" element={<Home />} />
                <Route path="/" element={<Jobs />} />
                <Route path="myaccount" element={<MyAccount />} />
                <Route path="user/:slug/:userId" element={<Profile />} />
                <Route path="search" element={<Search />} />
                
                <Route path="org/:orgId" element={<JobOrgPage />} />
                {/* <Route path="org/:orgId/about" element={<AboutOrgPage />} /> */}
                <Route path="org/:orgId/feed" element={<OrgPage />} />                
                <Route path="org/:orgId/settings" element={<OrgSettings />} />
                <Route path="org/:orgId/notes" element={<NotesPage />} />
                <Route path="org/create" element={<AddOrganization />} />
                <Route path="exam/:slug/:examId" element={<ExamPage />} />
                {/* <Route path="job/:jobId" element={<OrgJobPage />} /> */}

                <Route path="/:userId/following" element={<Following />} />
                <Route path="/:userId/connections" element={<Connections />} />
                <Route path="/:userId/posts" element={<Posts />} />
                <Route path="/:userId/post/1" element={<PostPage />} />

                {/* <Route path="exam/:examId" element={<CompetitiveExam />} /> */}
                <Route path="neet/post/1" element={<CompetitiveExamPost />} />

                <Route path="course" element={<Course />} />
                <Route path="career" element={<Career />} />

                <Route path="/:userId/edit" element={<EditProfile />} />
                <Route path="/user/inbox" element={<Inbox />} />
              </Route>
              
              {/* Admin route */}

              <Route path="admin/login" element={<AdminLogin />} />

              <Route element={<AdminProtected />} >
                <Route path="admin/dashboard" element={<Dashboard />} />
                <Route path="admin/user-request" element={<UserRequestPage />} />
                <Route path="admin/member-request" element={<MemberRequestPage />} />
                <Route path="admin/admission-enquiry" element={<EquiryListAllPage />} />

                <Route path="admin/org/:orgId" element={<OrgDetailsAdmin />} />

                <Route path="admin/register-org" element={<RegisterOrg />} />
                <Route path="admin/org-list" element={<OrgsListPage />} />

                <Route path="admin/register-course" element={<RegisterCourse />} />
                <Route path="admin/course-list" element={<CourseListPage />} />

                <Route path="admin/register-designation" element={<RegisterDesignation />} />
                <Route path="admin/designation-list" element={<DesignationListPage />} />

                <Route path="admin/register-competitive-exam" element={<RegisterCompetitiveExam />} />
                <Route path="admin/competitive-exam-list" element={<CompetitiveExamListPage />} />
              </Route>
              {/* </Suspense> */}

            </Routes>
          </Paper>
        </Router>
        </HelmetProvider>
      </Suspense>
    </ThemeProvider>
  );
}

export default App;
